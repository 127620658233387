import { TitleSecondary } from 'components/common/text/index.style'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  background-color: #f5f7f7;
`

export const StatisticCard = styled.div`
  padding: 20px;
  margin-bottom: 8px;
  margin-right: 8px;
  margin-left: 12px;

  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px -4px 8px 0px rgba(31, 41, 51, 0.02),
    0px 4px 8px 0px rgba(31, 41, 51, 0.02);

  font-size: 18px;
  font-weight: 600;
  color: black;

  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    div {
      color: #42c1bf;
    }
  }
`

export const StatisticCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ isActive }) => (isActive ? '#42c1bf' : '#1f2933')};
  transition: all 0.2s ease;
  svg {
    transform: ${({ isActive }) => (isActive ? 'none' : 'rotate(180deg)')};
  }
`

export const StyledTable = styled.table`
  border-spacing: 0;
  margin-top: 20px;
  width: 100%;

  td {
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.144px;

    color: #1f2933;
  }

  tbody {
    tr {
      background: rgba(245, 247, 247, 0.6);
    }

    tr {
      td:first-child {
        font-weight: 800;
        border-left: 1px solid #f1f1f1;

        text-align: start;
      }
      td:last-child {
        border-right: 1px solid #f1f1f1;
      }

      td:nth-child(2) {
        font-weight: 800;
        color: #42c1bf;
      }

      td {
        border-bottom: 0.8px solid #f1f1f1;

        text-align: end;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
  thead {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.144px;
    tr {
      background: #f5f7f7;
      td:first-child {
        border-top-left-radius: 8px;
        border-top: 1px solid #f1f1f1;
        border-left: 1px solid #f1f1f1;

        text-align: start;
      }
      td:last-child {
        border-top-right-radius: 8px;
        border-top: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
      }
      td {
        border-left: none;
        border-top: 1px solid #f1f1f1;

        text-align: end;
      }
    }
  }
`

export const HeaderText = styled(TitleSecondary)`
  margin-top: 24px;
  margin-left: 12px;
`

export const CategoriesCard = styled.div`
  padding: 10px 12px;
  border-radius: 12px;
  background-color: ${({ active }) => (active ? '#42C1BF' : 'white')};
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
  color: ${({ active }) => (active ? 'white' : '#616e7c')};

  cursor: pointer;
  transition: all 0.15s ease;

  &:hover {
    color: ${({ active }) => (active ? 'white' : '#42c1bf')};
  }
`

export const CategoriesSwiper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 100%;

  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CategoriesSwiperWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  position: sticky;
  top: 0;
  padding: 1rem 0 1.5rem 0.75rem;

  background: #f5f7f7;
  opacity: 1;
  background: linear-gradient(
    180deg,
    #f5f7f7 83.93%,
    rgba(245, 247, 247, 0) 100%
  );
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ leftGradient }) =>
      `${leftGradient > 64 ? 64 : leftGradient}px`};
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0),
      ${({ leftGradient }) =>
        leftGradient > 0 ? '#f5f7f7' : 'rgba(255, 255, 255, 0)'}
    );
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${({ rightGradient }) =>
      `${rightGradient > 64 ? 64 : rightGradient}px`};
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #f5f7f7);
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const TableName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.168px;
`

//оставляем не влияет
export const TableWrapper = styled.div`
  overflow-y: auto;
`
