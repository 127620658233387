import styled from 'styled-components'

export const ReagentNumber = styled.div`
  bottom: 5px;
  width: 28px;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  position: absolute;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px -4px 8px 0px rgba(31, 41, 51, 0.02),
    0px 4px 8px 0px rgba(31, 41, 51, 0.02);
`
