import { t } from 'i18next'

import { setStorage } from 'utils/storage/storage'

export const REDIRECT_SMILES = 'redirect_smiles'
export const REDIRECT_SPECTRA_TYPE = 'redirect_spectra_type'
export const REDIRECT_REACTIONS_TYPE = 'redirect_reactions_type'

export const openTab = (path, redirectParams) => {
  redirectParams &&
    Object.entries(redirectParams).map(([key, value]) => setStorage(key, value))
  window.open(path)
}

