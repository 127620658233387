export const LOAD_BASKETS_INIT = 'LOAD_BASKETS_INIT'
export const LOAD_BASKETS_SUCCESS = 'LOAD_BASKETS_SUCCESS'
export const LOAD_BASKETS_FAIL = 'LOAD_BASKETS_FAIL'

export const LOAD_LAST_CHANGE_INIT = 'LOAD_LAST_CHANGE_INIT'
export const LOAD_LAST_CHANGE_SUCCESS = 'LOAD_LAST_CHANGE_SUCCESS'
export const LOAD_LAST_CHANGE_FAIL = 'LOAD_LAST_CHANGE_FAIL'

export const LOAD_MOLECULES_INIT = 'LOAD_MOLECULES_INIT'
export const LOAD_MOLECULES_SUCCESS = 'LOAD_MOLECULES_SUCCESS'
export const LOAD_MOLECULES_FAIL = 'LOAD_MOLECULES_FAIL'
export const RESET_MOLECULES = 'RESET_MOLECULES'

export const DELETE_MOLECULE_INIT = 'DELETE_MOLECULE_INIT'
export const DELETE_MOLECULE_SUCCESS = 'DELETE_MOLECULE_SUCCESS'
export const DELETE_MOLECULE_FAIL = 'DELETE_MOLECULE_FAIL'

export const CREATE_MOLECULE_INIT = 'CREATE_MOLECULE_INIT'
export const CREATE_MOLECULE_SUCCESS = 'CREATE_MOLECULE_SUCCESS'
export const CREATE_MOLECULE_FAIL = 'CREATE_MOLECULE_FAIL'

export const CREATE_MOLECULES_FROM_FILE_INIT = 'CREATE_MOLECULES_FROM_FILE_INIT'
export const CREATE_MOLECULES_FROM_FILE_SUCCESS =
  'CREATE_MOLECULES_FROM_FILE_SUCCESS'
export const CREATE_MOLECULES_FROM_FILE_FAIL = 'CREATE_MOLECULES_FROM_FILE_FAIL'

export const CREATE_MOLECULES_PACK_INIT = 'CREATE_MOLECULES_PACK_INIT'
export const CREATE_MOLECULES_PACK_SUCCESS = 'CREATE_MOLECULES_PACK_SUCCESS'
export const CREATE_MOLECULES_PACK_FAIL = 'CREATE_MOLECULES_PACK_FAIL'

export const UPDATE_MOLECULE_INIT = 'UPDATE_MOLECULE_INIT'
export const UPDATE_MOLECULE_SUCCESS = 'UPDATE_MOLECULE_SUCCESS'
export const UPDATE_MOLECULE_FAIL = 'UPDATE_MOLECULE_FAIL'

export const MOVE_MOLECULE_INIT = 'MOVE_MOLECULE_INIT'
export const MOVE_MOLECULE_SUCCESS = 'MOVE_MOLECULE_SUCCESS'
export const MOVE_MOLECULE_FAIL = 'MOVE_MOLECULE_FAIL'

export const JOIN_BASKETS_INIT = 'JOIN_BASKETS_INIT'
export const JOIN_BASKETS_SUCCESS = 'JOIN_BASKETS_SUCCESS'
export const JOIN_BASKETS_FAIL = 'JOIN_BASKETS_FAIL'

export const ADD_BASKET_INIT = 'ADD_BASKET_INIT'
export const ADD_BASKET_SUCCESS = 'ADD_BASKET_SUCCESS'
export const ADD_BASKET_FAIL = 'ADD_BASKET_FAIL'

export const DELETE_BASKET_INIT = 'DELETE_BASKET_INIT'
export const DELETE_BASKET_SUCCESS = 'DELETE_BASKET_SUCCESS'
export const DELETE_BASKET_FAIL = 'DELETE_BASKET_FAIL'

export const COPY_BASKET_INIT = 'COPY_BASKET_INIT'
export const COPY_BASKET_SUCCESS = 'COPY_BASKET_SUCCESS'
export const COPY_BASKET_FAIL = 'COPY_BASKET_FAIL'

export const UPDATE_BASKET_INIT = 'UPDATE_BASKET_INIT'
export const UPDATE_BASKET_SUCCESS = 'UPDATE_BASKET_SUCCESS'
export const UPDATE_BASKET_FAIL = 'UPDATE_BASKET_FAIL'

export const LOAD_MORE_MOLECULES_INIT = 'LOAD_MORE_MOLECULES_INIT'
export const LOAD_MORE_MOLECULES_SUCCESS = 'LOAD_MORE_MOLECULES_SUCCESS'
export const LOAD_MORE_MOLECULES_FAIL = 'LOAD_MORE_MOLECULES_FAIL'

export const LOAD_TLIGHT_INIT = 'LOAD_TLIGHT_INIT'
export const LOAD_TLIGHT_SUCCESS = 'LOAD_TLIGHT_SUCCESS'
export const LOAD_TLIGHT_FAIL = 'LOAD_TLIGHT_FAIL'

export const CALC_TLIGHT_INIT = 'CALC_TLIGHT_INIT'
export const CALC_TLIGHT_SUCCESS = 'CALC_TLIGHT_SUCCESS'
export const CALC_TLIGHT_FAIL = 'CALC_TLIGHT_FAIL'

export const SET_SEARCH = 'SET_SEARCH'
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'

export const SET_SEARCH_V2 = 'SET_SEARCH_V2'
export const RESET_SEARCH = 'RESET_SEARCH'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
export const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR'
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
export const SET_SEARCH_TRANSIT_LOADING = 'SET_SEARCH_TRANSIT_LOADING'
export const SET_SEARCH_TRANSIT_DONE = 'SET_SEARCH_TRANSIT_DONE'
export const SET_SEARCH_TRANSIT_ERROR = 'SET_SEARCH_TRANSIT_ERROR'
export const SHOW_SEARCH_V2_RESULT = 'SHOW_SEARCH_V2_RESULT'

export const SET_BINGO_SEARCH = 'SET_BINGO_SEARCH'
export const SET_BINGO_SEARCH_LOADING = 'SET_BINGO_SEARCH_LOADING'
export const SET_BINGO_SEARCH_ERROR = 'SET_BINGO_SEARCH_ERROR'
export const SET_BINGO_SEARCH_RESULT = 'SET_BINGO_SEARCH_RESULT'
export const SET_BINGO_SEARCH_PAGE_CACHE = 'SET_BINGO_SEARCH_PAGE_CACHE'

export const SET_BINGO_SEARCH_TASK_ID = 'SET_BINGO_SEARCH_TASK_ID'
export const SET_BINGO_SEARCH_IS_LONG_TASK = 'SET_BINGO_SEARCH_IS_LONG_TASK'
export const SET_BINGO_SEARCH_CANCEL = 'SET_BINGO_SEARCH_CANCEL'

export const SET_LIT_SEARCH = 'SET_LIT_SEARCH'
export const SET_LIT_SEARCH_RESULT = 'SET_LIT_SEARCH_RESULT'
export const SET_LIT_SEARCH_LOADING = 'SET_LIT_SEARCH_LOADING'
export const SET_LIT_SEARCH_ERROR = 'SET_LIT_SEARCH_ERROR'
export const SET_LIT_SEARCH_TASK_ID = 'SET_LIT_SEARCH_TASK_ID'
export const RESET_LIT_SEARCH = 'RESET_LIT_SEARCH'

export const SET_SEARCHV2_STATE = 'SET_SEARCHV2_STATE'
export const SET_BINGO_SEARCH_STATE = 'SET_BINGO_SEARCH_STATE'
export const SET_LIT_SEARCH_STATE = 'SET_LIT_SEARCH_STATE'

export const UPDATE_CRUD_STATE = 'UPDATE_CRUD_STATE'
