import {
  BINGO_PAGINATION_LIMIT,
  LIT_PAGINATION_LIMIT,
} from 'store/sagas/utils/config'
import {
  LOAD_BASKETS_INIT,
  LOAD_LAST_CHANGE_INIT,
  LOAD_MOLECULES_INIT,
  DELETE_MOLECULE_INIT,
  CREATE_MOLECULE_INIT,
  UPDATE_MOLECULE_INIT,
  JOIN_BASKETS_INIT,
  ADD_BASKET_INIT,
  DELETE_BASKET_INIT,
  COPY_BASKET_INIT,
  UPDATE_BASKET_INIT,
  LOAD_MORE_MOLECULES_INIT,
  MOVE_MOLECULE_INIT,
  CREATE_MOLECULES_PACK_INIT,
  CALC_TLIGHT_INIT,
  SET_SEARCH,
  SET_SEARCH_STRING,
  CREATE_MOLECULES_FROM_FILE_INIT,
  RESET_SEARCH,
  SET_SEARCH_V2,
  SET_LIT_SEARCH,
  SET_BINGO_SEARCH,
  SET_BINGO_SEARCH_PAGE_CACHE,
  RESET_LIT_SEARCH,
  SET_BINGO_SEARCH_CANCEL,
  RESET_MOLECULES,
  SET_BINGO_SEARCH_STATE,
  SET_SEARCHV2_STATE,
  SET_LIT_SEARCH_STATE,
  UPDATE_CRUD_STATE,
} from '../constants/crud'

export const loadBaskets = ({ withPublic }) => {
  return {
    type: LOAD_BASKETS_INIT,
    withPublic,
  }
}

export const loadLastChange = () => {
  return {
    type: LOAD_LAST_CHANGE_INIT,
  }
}

export const calcTLight = (ids, basket) => {
  return {
    type: CALC_TLIGHT_INIT,
    ids,
    basket,
  }
}

export const updateCrudState = (state) => {
  return {
    type: UPDATE_CRUD_STATE,
    state,
  }
}

export const loadMoreMolecules = ({ basket, limit, page }) => {
  return {
    type: LOAD_MORE_MOLECULES_INIT,
    basket,
    limit,
    page,
  }
}

export const loadMolecules = ({ basket, search }) => {
  return {
    type: LOAD_MOLECULES_INIT,
    basket,
    search,
  }
}
export const resetMolecules = () => {
  return {
    type: RESET_MOLECULES,
  }
}

export const setSearch = (ids) => {
  return {
    type: SET_SEARCH,
    ids,
  }
}

export const deleteMolecule = (id, basket) => {
  return {
    type: DELETE_MOLECULE_INIT,
    id,
    basket,
  }
}

export const moveMolecules = (target, source, molecules, label, t) => {
  return {
    type: MOVE_MOLECULE_INIT,
    target,
    source,
    molecules,
    label,
    t,
  }
}

export const addMolecule = (
  basket,
  smiles,
  shouldGoToBasket,
  label,
  t,
  isTargetEqualsSource,
  isSelected
) => {
  return {
    type: CREATE_MOLECULE_INIT,
    basket,
    smiles,
    shouldGoToBasket,
    label,
    t,
    isTargetEqualsSource,
    isSelected,
  }
}

export const addMoleculesFromFile = (
  basket,
  file,
  smilesTargetCol,
  delimiter
) => {
  return {
    type: CREATE_MOLECULES_FROM_FILE_INIT,
    basket,
    file,
    smiles_target_col: smilesTargetCol,
    delimiter,
  }
}

export const addMoleculesPack = (basket, list, t) => {
  return {
    type: CREATE_MOLECULES_PACK_INIT,
    basket,
    list,
    t,
  }
}

export const updateMolecule = (id, basket, smiles) => {
  return {
    type: UPDATE_MOLECULE_INIT,
    id,
    basket,
    smiles,
  }
}

export const joinBaskets = (data) => {
  return {
    type: JOIN_BASKETS_INIT,
    data,
  }
}

export const addBasket = (name, desc) => {
  return {
    type: ADD_BASKET_INIT,
    name,
    desc: desc ? desc : ' ',
  }
}

export const deleteBasket = (list) => {
  return {
    type: DELETE_BASKET_INIT,
    list,
  }
}

export const copyBasket = (data) => {
  return {
    type: COPY_BASKET_INIT,
    data,
  }
}

export const updateBasket = (id, name, desc) => {
  return {
    type: UPDATE_BASKET_INIT,
    id,
    name,
    desc: desc ? desc : ' ',
  }
}

export const setSearchString = (text) => {
  return {
    type: SET_SEARCH_STRING,
    text,
  }
}

export const setSearchV2 = ({
  ids = [],
  smiles = '',
  text = '',
  label = '',
  name = '',
  basket = null,
}) => {
  return {
    type: SET_SEARCH_V2,
    ids,
    smiles,
    text,
    label,
    name,
    basket,
  }
}

export const resetSearch = () => {
  return {
    type: RESET_SEARCH,
  }
}

export const setBingoSearch = ({
  ids = [],
  smiles = '',
  text = '',
  label = '',
  name = '',
  task_uuid = '',
  offset = 0,
  limit = BINGO_PAGINATION_LIMIT,
}) => {
  return {
    type: SET_BINGO_SEARCH,
    ids,
    smiles,
    text,
    label,
    name,
    task_uuid,
    offset,
    limit,
  }
}
export const setBingoSearchState = (state) => {
  return { type: SET_BINGO_SEARCH_STATE, state }
}
export const setSearchV2State = (state) => {
  return { type: SET_SEARCHV2_STATE, state }
}

export const setLitSearchState = (state) => {
  return { type: SET_LIT_SEARCH_STATE, state }
}

export const setCachedPage = ({ pageItems, pageNumber }) => {
  return { type: SET_BINGO_SEARCH_PAGE_CACHE, pageItems, pageNumber }
}

export const cancelBingoSearch = () => {
  return {
    type: SET_BINGO_SEARCH_CANCEL,
  }
}

export const setLiteratureSearch = ({
  task_uuid = '',
  offset = 0,
  limit = LIT_PAGINATION_LIMIT,
}) => {
  return {
    type: SET_LIT_SEARCH,
    task_uuid,
    offset,
    limit,
  }
}

export const resetLitSearch = () => {
  return {
    type: RESET_LIT_SEARCH,
  }
}
