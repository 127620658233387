import React from 'react'
import Scrollbars from 'react-scrollbars-custom'

const CustomScrollbar = React.memo((props) => {
  const {
    scrollbarEnabled,
    addWrapper,
    innerRef,
    noScrollX,
    noScrollY,
    translateContentSizeYToHolder,
    onScroll,
    disableTrackYWidthCompensation,
    ...scrollbarProps
  } = props || {}

  if (!props.scrollbarEnabled) {
    return props.className && addWrapper ? (
      <div className={props.className}>{props.children}</div>
    ) : (
      <>{props.children}</>
    )
  }

  return (
    <Scrollbars
      data-test="custom-scrollbar"
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      noScrollX={noScrollX}
      noScrollY={noScrollY}
      {...scrollbarProps}
      className={`custom-scrollbar ${scrollbarProps.className || ''}`}
      ref={innerRef}
      removeTracksWhenNotUsed={true}
      onScroll={onScroll}
      disableTrackYWidthCompensation={disableTrackYWidthCompensation}
    />
  )
})

CustomScrollbar.defaultProps = {
  scrollbarEnabled: true,
  noScrollX: true,
  noScrollY: false,
  translateContentSizeYToHolder: false,
}

CustomScrollbar.displayName = 'CustomScrollbar'
export default CustomScrollbar
