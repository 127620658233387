/*eslint-disable*/
import { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import {
  Element,
  Label,
  SidebarElement,
  SidebarWrapper,
  LogoWrapper,
  IconWrapper,
  SidebarBottomElement,
  SidebarLinks,
  UserElement,
  UserLabel,
  FeedbackButton,
  Sidebar,
  Notifications,
  NotificationsCount,
  SidebarBottomLinks,
  IconButton,
  SimpleNotification,
  RevealWrapper,
  SidebarCloseButton,
  AnimWrapper,
  SidebarIconWrapper,
} from './index.style'
import Filter from '../Filter'
import FeedbackForm from '../FeedbackForm'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { settingsSidebarToggle } from '../../store/actions/settings'
import { ReactComponent as EnLogo } from '../../assets/syntelly_logo_en.svg'
import { ReactComponent as RuLogo } from '../../assets/syntelly_logo_ru.svg'
import LoginPage from '../LoginPage'
import AccountInfo from 'components/AccountInfo'
import { useAuth } from 'utils/auth/auth'
import { toggleUserProfile } from '../../store/actions/auth'
import { setOpenFilter } from '../../store/actions/filter'
import { hideNotifications } from 'store/actions/notifications'
import RegistrationPage from 'components/RegistrationPage'

import { linksList } from '../../config/linksList'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import LiteratureFilter from 'components/Search/LiteratureFilter/index'
import { Subheadline } from 'components/common/text/index.style'
import Icon from 'components/Icon'
import { openFeedbackForm, closeFeedbackForm } from 'store/actions/feedback'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import SidebarButton from 'components/SidebarButton'
import '../common/tooltip/style.css'
import { useTheme } from 'styled-components'
import AccSvg from '../../assets/accountSvg'
import { resetPastResult } from 'store/actions/searchHistory'

const MOLEDITOR_URL = '/moleditor'

const pagesWithoutFeedbackBtn = [MOLEDITOR_URL]

const SideBarView = ({
  push,
  isFull,
  roles = [],
  path,
  isSidebarCollapsed,
  settingsSidebarToggle,
  userdata,
  filterOpen,
  setOpenFilter,
  tasks,
  notifications,
  isHidden,
  hideNotifications,
  isFeedbackFormOpen,
  openFeedbackForm,
  closeFeedbackForm,
  simpleNotification,
  ctxMenu,
  showHistory,
  pastResult,
  resetPastResult,
}) => {
  const dispatch = useDispatch()
  const showProfile = useSelector((store) => store.auth.isShowProfile)
  const [selected, setSelected] = useState(-1)
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false)
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [isUserHovered, setIsUserHovered] = useState(false)

  const { t, i18n } = useTranslation()
  const currentLang = i18n?.language
  const { logout, isAuthenticated } = useAuth()
  const notLength = tasks.length + notifications.length
  const location = useLocation()
  const [showTooltip, setShowTooltip] = useState(true)
  const theme = useTheme()
  const isProfileMenuActive = ctxMenu?.menu === 'profileMenu'

  const { text: simpleNotifyText, visible: isSimpleNotifyVisible } =
    simpleNotification

  const handleRegister = () => {
    setShowLogin(false)
    setShowRegister(true)
  }

  const handleOutsideClick = () => {
    setIsLanguageSelectOpen(false)
    setIsUserSelectOpen(false)
  }

  const isAdmin = () => {
    if (roles.includes('admin')) return true
  }

  const goto = (link) => (e) => {
    e.preventDefault()
    if (link === location?.pathname) return
    const isFilterBtnClicked = e.target.id?.includes('sidebar-filter-btn')
    location?.pathname
    if (isMobile) settingsSidebarToggle()
    if (
      (!link?.includes('search') || !link?.includes('molecule/')) &&
      !!pastResult &&
      !isFilterBtnClicked
    ) {
      resetPastResult()
    }
    filterOpen && setOpenFilter(false)
    push(link)
  }

  const handleGoLogin = () => {
    setShowLogin(true)
    setShowRegister(false)
  }

  useEffect(() => {
    const res = linksList.find(({ link }) => {
      if (link === path) return true
      if (/^\/molecules\/.*/.test(path) && link === '/molecules') {
        return true
      }
      return false
    })

    if (res) setSelected(res.id)
    else if (path === '/') setSelected(0)
    else setSelected(-1)
  }, [path, t])

  useEffect(() => {
    if (isLanguageSelectOpen || isUserSelectOpen) {
      document.addEventListener('click', handleOutsideClick)
      return () => {
        document.removeEventListener('click', handleOutsideClick)
      }
    }
  }, [isLanguageSelectOpen, isUserSelectOpen])

  useEffect(() => {
    if (filterOpen) setShowTooltip(true)
  }, [filterOpen])

  const handleSidebarToggle = () => {
    if (filterOpen) {
      setOpenFilter(false)
    }
    settingsSidebarToggle()
  }

  return (
    <>
      <SidebarWrapper>
        <Sidebar isCollapsed={isSidebarCollapsed || filterOpen}>
          <div>
            <LogoWrapper isCollapsed={isSidebarCollapsed || filterOpen}>
              {isSidebarCollapsed || filterOpen ? (
                <RevealWrapper
                  transparent={true}
                  withShadow={true}
                  id="sidebar-toggle-btn"
                  onClick={handleSidebarToggle}
                >
                  <Icon iconType="reveal" size="1.25rem" />
                </RevealWrapper>
              ) : i18n.language === 'en' ? (
                <>
                  <EnLogo
                    onClick={handleSidebarToggle}
                    style={{ cursor: 'pointer' }}
                  />
                  <SidebarCloseButton
                    id="sidebar-hide-btn"
                    onClick={handleSidebarToggle}
                  >
                    <Icon iconType="hide" />
                  </SidebarCloseButton>
                </>
              ) : (
                <>
                  <RuLogo
                    onClick={handleSidebarToggle}
                    style={{ cursor: 'pointer' }}
                  />
                  <SidebarCloseButton
                    id="sidebar-hide-btn"
                    onClick={handleSidebarToggle}
                  >
                    <Icon iconType="hide" />
                  </SidebarCloseButton>
                </>
              )}
            </LogoWrapper>
            <SidebarLinks>
              {linksList
                .filter(({ demo }) => {
                  if (isFull) {
                    return true
                  } else {
                    return demo
                  }
                })
                .filter(({ admin }) => {
                  if (admin && isAdmin()) return true
                  if (!admin) return true
                })
                .filter(({ onMobile }) => (isMobile ? onMobile : true))
                .map((el) => {
                  return (
                    <div key={el.id}>
                      <SidebarElement
                        href={el.link}
                        onClick={goto(el.link)}
                        selected={selected === el.id}
                        isCollapsed={isSidebarCollapsed || filterOpen}
                        data-tooltip-id={
                          isSidebarCollapsed && showTooltip && el.id
                        }
                        data-tooltip-content={t(`${el.name}`)}
                      >
                        <SidebarIconWrapper>
                          <Icon iconType={el.img} size="1.25rem" />
                        </SidebarIconWrapper>
                        <AnimWrapper
                          opened={!isSidebarCollapsed && !filterOpen}
                        >
                          <Label>{t(`${el.name}`)}</Label>
                        </AnimWrapper>

                        <AnimWrapper
                          opened={!isSidebarCollapsed && !filterOpen}
                        >
                          {!isSidebarCollapsed && !filterOpen && el?.tag && (
                            <Icon
                              iconType={el.tag}
                              size="1.5rem"
                              width={'32px'}
                            />
                          )}
                        </AnimWrapper>

                        {el.id === 0 &&
                          selected === el.id &&
                          !filterOpen &&
                          !showHistory && (
                            <>
                              <IconButton
                                id="sidebar-filter-btn"
                                type="accent"
                                height="fit-content"
                                onClick={() => {
                                  setOpenFilter(true)
                                  setShowTooltip(false)
                                }}
                                isSidebarCollapsed={isSidebarCollapsed}
                                data-tooltip-id={el.id.toString()}
                                data-tooltip-content={t('sidebar.open_filter')}
                              >
                                <Icon
                                  id="sidebar-filter-btn-icon"
                                  iconType={'filters'}
                                  size={isSidebarCollapsed ? '1rem' : '1.25rem'}
                                />
                              </IconButton>
                            </>
                          )}
                      </SidebarElement>
                      {showTooltip && (
                        <ReactTooltip
                          id={el.id.toString()}
                          className="c-tooltip c-tooltip-base"
                          classNameArrow="c-tooltip-arrow"
                          place={'right'}
                          offset={18}
                        />
                      )}
                    </div>
                  )
                })}
            </SidebarLinks>
          </div>
          <SidebarBottomLinks isCollapsed={isSidebarCollapsed || filterOpen}>
            <SidebarBottomElement width="100%" alignItems="center">
              {currentLang === 'ru' && (
                <SidebarButton
                  blockID="telegram-block"
                  labelID="telegram-label"
                  type="telegram"
                  showLabel={!isSidebarCollapsed && !filterOpen}
                />
              )}
              <SidebarButton
                showLabel={!isSidebarCollapsed && !filterOpen}
                blockID="sidebar-lang-block"
                labelID="sidebar-lang-label"
                type="sidebarLanguage"
              />
            </SidebarBottomElement>
            {isAuthenticated && (
              <Element
                width={isSidebarCollapsed || filterOpen ? '2.25rem' : '18rem'}
                gap="0.75rem"
              >
                <IconWrapper
                  onClick={() => dispatch(toggleUserProfile(true))}
                  data-tooltip-id="user-menu"
                  isHovered={isUserHovered}
                  onMouseEnter={() => setIsUserHovered(true)}
                  onMouseLeave={() => setIsUserHovered(false)}
                >
                  <AccSvg
                    headColor={
                      isUserHovered
                        ? theme.colors.icons.accentPrimary
                        : theme.colors.icons.secondary
                    }
                    bodyColor={
                      isUserHovered
                        ? theme.colors.icons.accentSecondary
                        : theme.colors.icons.tertiary
                    }
                  />
                </IconWrapper>
                {isSidebarCollapsed && (
                  <ReactTooltip
                    id="user-menu"
                    place={'top-end'}
                    className="c-tooltip c-tooltip-base"
                    classNameArrow="c-tooltip-arrow"
                    offset={12}
                  >
                    {t('sidebar.user')}
                  </ReactTooltip>
                )}
                <AnimWrapper
                  opened={!isSidebarCollapsed && !filterOpen}
                  flex="1"
                >
                  <UserElement
                    gap="0.75rem"
                    opened={!isSidebarCollapsed && !filterOpen}
                  >
                    <div>
                      <Label
                        id="sidebar-username"
                        width={'12.5rem'}
                        isHovered={isUserHovered}
                        onMouseEnter={() => setIsUserHovered(true)}
                        onMouseLeave={() => setIsUserHovered(false)}
                        onClick={() => dispatch(toggleUserProfile(true))}
                      >{`${userdata.name ?? ''} ${
                        userdata.surname ?? ''
                      }`}</Label>
                      <UserLabel
                        onClick={() => dispatch(toggleUserProfile(true))}
                        width={'12.5rem'}
                        isHovered={isUserHovered}
                        onMouseEnter={() => setIsUserHovered(true)}
                        onMouseLeave={() => setIsUserHovered(false)}
                        style={{ paddingTop: '0.25rem' }}
                      >
                        {userdata.email}
                      </UserLabel>
                    </div>
                    <>
                      <IconWrapper
                        width="1.5rem"
                        height="1.5rem"
                        transparent={true}
                        color={
                          isProfileMenuActive
                            ? theme.colors.icons.accentPrimary
                            : theme.colors.icons.tertiary
                        }
                        onClick={(e) => {
                          dispatch(
                            handleShowContextMenu({
                              e,
                              menu: 'profileMenu',
                            })
                          )
                        }}
                        id="sidebar-user-menu"
                      >
                        <Icon iconType={'menu'} size="1.25rem" />
                      </IconWrapper>
                    </>
                  </UserElement>
                </AnimWrapper>
              </Element>
            )}
          </SidebarBottomLinks>
        </Sidebar>
        <Filter />
      </SidebarWrapper>

      {isAuthenticated &&
        !pagesWithoutFeedbackBtn.includes(location.pathname) && (
          <FeedbackButton
            id="feedback-button"
            onClick={() => openFeedbackForm()}
          >
            <Icon iconType={'feedback'} />
          </FeedbackButton>
        )}

      <Notifications
        onClick={() => hideNotifications()}
        visible={isHidden && !!notLength}
      >
        <Icon iconType="notification" />
        <NotificationsCount>
          <Subheadline fontWeight={500} color="#42C1BF">
            {notLength}
          </Subheadline>
        </NotificationsCount>
      </Notifications>

      <SimpleNotification visible={!!simpleNotifyText && isSimpleNotifyVisible}>
        <Label>{t(simpleNotifyText)}</Label>
      </SimpleNotification>

      {isFeedbackFormOpen && (
        <FeedbackForm
          handleClose={closeFeedbackForm}
          handleSave={() => {
            setShowAlert(true)
          }}
        />
      )}
      {showLogin && (
        <LoginPage
          handleClose={() => setShowLogin(false)}
          handleRegister={handleRegister}
        />
      )}
      {showProfile && (
        <AccountInfo handleClose={() => dispatch(toggleUserProfile(false))} />
      )}

      {showRegister && (
        <RegistrationPage
          handleClose={() => setShowRegister(false)}
          handleGoLogin={handleGoLogin}
        />
      )}
      <LiteratureFilter />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isFull: state.auth.userdata.isfull,
    roles: state.auth.userdata.roles,
    path: state.router.location.pathname,
    userdata: state.auth.userdata,
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
    filterOpen: state.filter.open,
    isHidden: state.notifications.isHidden,
    notifications: state.notifications.notificationsList,
    tasks: state.tasks,
    isFeedbackFormOpen: state.feedback.show,
    lang: state.settings.lang,
    simpleNotification: state.notifications.simpleNotification,
    ctxMenu: state.contextMenu.contextMenuState,
    showHistory: state.searchHistory.showHistory,
    pastResult: state.searchHistory.pastResult,
  }
}

const mapDispathToProps = {
  push,
  settingsSidebarToggle,
  setOpenFilter,
  hideNotifications,
  openFeedbackForm,
  closeFeedbackForm,
  resetPastResult,
}

export default connect(mapStateToProps, mapDispathToProps)(SideBarView)
