import {
  RESET_FILTER,
  SET_OPEN_FILTER,
  SET_FILTER_CONFIG,
  SET_FILTER_BUTTONS_DISABLED,
  SET_FILTER_BUTTONS_ACTIVE,
  SET_MARKUSH_MOLECULE_WEIGHT,
  SET_MARKUSH_INPUTS_DISABLED,
  SET_MARKUSH_MOLECULE_MAX_WEIGHT,
  RESET_MARKUSH_MOLECULE_WEIGHT,
  SET_LIT_DOC_TYPE,
} from '../constants/filter'

export const initialState = {
  open: false,
  config: {
    match_type: 'exact match',
    disabled: false,
    similarity: {
      0: 0,
      1: 100,
    },
    molecular_weight_disabled: true,
    molecular_weight_default: { 0: '', 1: '' },
    molecular_weight: { 0: '', 1: '' },
  },
}

const filterReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_OPEN_FILTER:
      return {
        ...state,
        open: payload.open,
      }

    case SET_FILTER_CONFIG:
      return {
        ...state,
        config: payload.config,
      }
    case SET_FILTER_BUTTONS_DISABLED:
      return {
        ...state,
        config: { ...state.config, disabled: true },
      }
    case SET_FILTER_BUTTONS_ACTIVE:
      return {
        ...state,
        config: { ...state.config, disabled: false },
      }
    case RESET_FILTER:
      return {
        ...state,
        config: initialState.config,
      }

    case SET_MARKUSH_MOLECULE_WEIGHT:
      return {
        ...state,
        config: {
          ...state.config,
          molecular_weight: {
            0: payload.weight,
            1: (Number(payload.weight) + 100).toFixed(3),
          },
          molecular_weight_default: {
            0: payload.weight,
            1: (Number(payload.weight) + 100).toFixed(3),
          },
        },
      }
    case SET_MARKUSH_MOLECULE_MAX_WEIGHT:
      return {
        ...state,
        config: {
          ...state.config,
          molecular_weight: {
            ...state.config.molecular_weight,
            1: payload.weight,
          },
        },
      }

    case SET_MARKUSH_INPUTS_DISABLED:
      return {
        ...state,
        config: {
          ...state.config,
          molecular_weight_disabled: payload.value,
        },
      }

    case RESET_MARKUSH_MOLECULE_WEIGHT:
      return {
        ...state,
        config: {
          ...state.config,
          molecular_weight_default: { 0: '', 1: '' },
          molecular_weight: { 0: '', 1: '' },
          molecular_weight_disabled: true,
        },
      }
    case SET_LIT_DOC_TYPE:
      return {
        ...state,
        config: { ...state.config, document_type: payload.docType },
      }
    default:
      return state
  }
}

export default filterReducer
