import { ru } from 'date-fns/locale'

import { addMinutes, format, parseISO } from 'date-fns'

export const getGroupedHistory = (history, userLang) => {
  const data = history?.map((el) => {
    const utcDate = parseISO(el.created_at)
    const timeZoneOffset = -new Date().getTimezoneOffset()
    const modifiedDate = addMinutes(utcDate, timeZoneOffset)

    const date =
      userLang === 'ru'
        ? format(modifiedDate, 'dd MMMM yyyy года', {
            locale: ru,
          })
        : format(modifiedDate, 'MMMM d, yyyy', {})

    const time =
      userLang === 'ru'
        ? format(modifiedDate, 'HH:mm')
        : format(modifiedDate, 'h:mm a')
    return { ...el, date, time }
  })

  const groupedData = data.reduce((acc, curr) => {
    const { date, ...rest } = curr
    if (!acc[date]) {
      acc[date] = { date, values: [rest] }
    } else {
      acc[date].values.push(rest)
    }
    return acc
  }, {})
  return Object.values(groupedData)
}

export const getSearchText = (query) => {
  if (query.type === 'full_text_search') {
    if (query?.params?.title?.values) return query?.params?.title?.values[0]
    if (query?.params?.name) return query.params.name
  }
  if (query.type === 'molecules_search') {
    if (query?.params?.searchV2?.text) return query.params.searchV2.text
  }
  return ''
}
