import React, { useEffect, useRef, useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import SmilesDrawer from 'smiles-drawer'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { addMolecule } from 'store/actions/crud'
import BasketsListModal from 'components/BasketsListModal'
import MoleculeIdWithButtons from 'components/MoleculeIdWithButtons'
import { toggleAddMoleculeDialog } from 'store/actions/retrosynthesis'

import './index.css'
import Icon from '../Icon'
import {
  Tags,
  Block,
  AddNew,
  Smiles,
  ToolsBtn,
  Container,
  IconButton,
  ToolButton,
  ToolsBtnNew,
  OneMolecula,
  SmallBackdrop,
  SmallCardHambButton,
  SmilesWithIconContainer,
  SmilesViewContainer,
  EmptySmilesView,
  MoleculeName,
  BestSmilesViewContainer,
  Info,
} from './index.style'
import { setStorage } from 'utils/storage/storage'
import MoleculeFooter from './components/MoleculeFooter'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import {
  closeAddMoleculeDialog,
  openAddMoleculeDialog,
  setEditorIndividualId,
} from 'store/actions/molecule'
import IdBlock from 'components/IdBlock'
import { SMILES_DRAWER_OPTIONS } from 'config/constants'
import { setOpenFilter } from 'store/actions/filter'
import CustomTag from 'components/common/customTag'
import { Caption } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'

const MoleculeViewer = React.memo((props) => {
  /** INIT - START */
  const {
    params,
    noDelete,
    noEdit,
    onDelete,
    onEdit,
    withData,
    num,
    smiles,
    method,
    clicknaddMode,
    noMenu,
    size,
    noPadding,
    cursor = 'pointer',
    showId,
    type,
    width,
    height,
    fixedSize,
    onShowProps,
    coordinates,
    isFastView,
    withColors,
    showIdWithoutMenu,
    isOnlyThreeDotsShow = true,
    noHover,
    isRetrosynthesisMolecule,
    openRetrosynthMolMenu,
    isMolMenuOpen,
    isGoToSectionMenu = false,
    handleClick,
    isAddDialogOpen,
    currentBasketId,
    handleClickFromHistory,
    referencesCount,
    tagType = 'grey',
    isBestMatch = false,
    text,
    isShowMolName = false,
    isFullWidth = false,
    noContainerHover = false,
  } = props || {}
  const theme = useTheme()
  const smilesViewRef = useRef(null)
  const dispatch = useDispatch()
  const pathname = useSelector((state) => state.router.location.pathname)
  const searchState = useSelector((store) => store.search)
  const crudState = useSelector((store) => store.crud)
  const filterConfig = useSelector((store) => store.filter.config)
  const isFilterOpen = useSelector((state) => state.filter.open)
  const moleculeDialogId = useSelector(
    (state) => state.molecule.isAddMoleculeDialogOpen
  )
  const isAddMoleculeDialogOpen = (num || smiles) === moleculeDialogId
  const { t } = useTranslation()
  const [state, setState] = useState({
    hashid: uuidv4(),
    isMoleculeHovered: false,
  })

  const isNotShowSomeLinks = ['/reactions', '/synmap'].includes(pathname)
  const isReactions = ['/reactions'].includes(pathname)
  const isSynMap = ['/synmap'].includes(pathname)
  const isDatasetMoleculesList = pathname?.includes('molecules/')
  /** INIT - END */

  /** METHODS - START */
  const getSizeValues = (step) => {
    if (width && height) return { width: width, height: height }
    const defaultSize = { width: 190, height: 170 }
    const widthStep = 50
    const heightStep = 30
    return {
      width: defaultSize.width + step * widthStep,
      height: defaultSize.height + step * heightStep,
    }
  }

  const preparedSmiles = useMemo(() => {
    if (!smiles) return 'O=C(C)Oc1ccccc1C(=O)O'
    if (
      method &&
      (method === 'markush' || method === 'sub') &&
      smiles.includes('|')
    ) {
      return smiles.split('|')[0]?.trim()
    } else {
      return smiles
    }
  }, [smiles, method])

  const drawSmiles = () => {
    const { size } = props || {}
    const { width, height } = getSizeValues(size)
    const smilesDrawer = new SmilesDrawer.SvgDrawer({
      width,
      height,
      ...SMILES_DRAWER_OPTIONS,
    })
    let element = smilesViewRef.current

    SmilesDrawer.parse(preparedSmiles, (tree) => {
      smilesDrawer.draw(tree, element)
    })
  }

  const handleEdit = (e) => {
    e.stopPropagation()
    onEdit(num)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    onDelete(num)
  }

  const handleMoleculeClick = (e) => {
    e?.stopPropagation()
    isFilterOpen && dispatch(setOpenFilter(false))
    if (handleClickFromHistory) {
      handleClickFromHistory()
      return
    }
    if (isRetrosynthesisMolecule) return
    if (e.ctrlKey || e.metaKey) {
      handleClick({ id: num, smiles }, 'ctrl')
    } else if (e.shiftKey) {
      handleClick({ id: num, smiles }, 'shift')
    } else if ((!clicknaddMode && showId) || showIdWithoutMenu) {
      dispatch(
        push(`/molecule/${num}`, {
          prev: pathname,
          searchState,
          crudState,
          filterConfig,
        })
      )
    } else onShowProps(num)
  }

  const handleShowPreview = (e) => {
    e?.stopPropagation()
    !!num && onShowProps(num)
  }
  const handleAddMolecule = (e) => {
    e?.stopPropagation()
    dispatch(openAddMoleculeDialog(num))
  }

  const openIndividual = (e, smiles) => {
    e.stopPropagation()
    if (smiles) {
      isFilterOpen && dispatch(setOpenFilter(false))
      dispatch(push(`/moleditor/${encodeURIComponent(smiles)}`))
      dispatch(setEditorIndividualId(+num))
    }
  }
  const toggleMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: isGoToSectionMenu ? 'goToSectionMenu' : 'moleculeMenu',
        item: isGoToSectionMenu
          ? { smiles, num }
          : {
              num,
              smiles,
              onShowProps,
              isNotShowSomeLinks,
              isSynMap,
              isReactions,
            },
      })
    )
  }

  const handleAddMoleculeToBasket = (ids, newBasketName) => {
    const smilesArray = [
      {
        smiles,
      },
    ]

    if (ids?.length) {
      ids.forEach((el) =>
        dispatch(addMolecule(el?.id, smilesArray, false, el?.name, t))
      )
    } else {
      dispatch(addMolecule(-1, smilesArray, false, newBasketName, t))
    }
  }

  const goToLiterature = () => {
    setStorage('redirect_search_text', smiles)
    window.open('/search')
  }

  const goToReactions = () => {
    // need code
  }
  /** METHODS - END */

  // /** EFFECTS - START */
  useEffect(() => {
    if (isDatasetMoleculesList) return
    drawSmiles()
  })

  useEffect(() => {
    if (isDatasetMoleculesList) {
      drawSmiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparedSmiles, isDatasetMoleculesList])

  // /** EFFECTS - END */

  /** CONFIGS - START */

  const iconButtonsConfig = [
    {
      icon: 'molecularEditor',
      onClick: (e) => openIndividual(e, smiles),
      tooltipText: t('molecule_viewer.edit_in_molecular_editor'),
      isHidden: isOnlyThreeDotsShow,
      dataTest: 'molecule-card-btn-molecular-editor-div',
    },
    {
      icon: 'add',
      onClick: handleAddMolecule,
      tooltipText: t('molecule_viewer.add_to_dataset'),
      isHidden: isOnlyThreeDotsShow,
      dataTest: 'molecule-card-btn-add-to-dataset-div',
    },
    {
      icon: 'eyeOpen',
      onClick: handleShowPreview,
      tooltipText: t('molecule_viewer.fast_view'),
      isHidden: isOnlyThreeDotsShow,
      dataTest: 'molecule-card-btn-fast-view-div',
    },
    {
      icon: 'threeDots',
      onClick: toggleMenu,
      tooltipText: '',
      dataTest: 'molecule-card-btn-context-menu-div',
    },
  ]
  /** CONFIGS - END */

  return (
    <>
      <Container
        className="mol-viewer-container"
        onMouseEnter={() =>
          setState((prevState) => ({
            ...prevState,
            isMoleculeHovered: true,
          }))
        }
        onMouseLeave={(e) =>
          setState((prevState) => ({
            ...prevState,
            isMoleculeHovered: false,
          }))
        }
        isBestMatch={isBestMatch}
        isFullWidth={isFullWidth}
        noContainerHover={noContainerHover}
      >
        <OneMolecula
          isRetrosynthesisMolecule={isRetrosynthesisMolecule}
          onClick={handleMoleculeClick}
          type={(clicknaddMode ? 'add-new' : type) || undefined}
          cursor={cursor}
          noHover={noHover}
          isBestMatch={isBestMatch}
        >
          {isBestMatch && (
            <BestSmilesViewContainer>
              <svg ref={smilesViewRef} width={132} height={108} />
            </BestSmilesViewContainer>
          )}
          <Info isBestMatch={isBestMatch}>
            {((!clicknaddMode && showId) || showIdWithoutMenu) &&
              !isRetrosynthesisMolecule && (
                <MoleculeIdWithButtons
                  {...{
                    num,
                    noPadding,
                    showIdWithoutMenu,
                    iconButtonsConfig,
                    isGoToSectionMenu,
                  }}
                  noIdText={t('molecule_viewer.molecule_generated')}
                  noIdDescription={t(
                    'molecule_viewer.molecule_generated_description'
                  )}
                  type={tagType}
                  withTooltip={false}
                  isBestMatch={isBestMatch}
                />
              )}
            {!isBestMatch && (
              <SmilesViewContainer
                title={clicknaddMode ? 'Click to add molecule' : smiles}
                width={fixedSize ? width : undefined}
                height={fixedSize ? height : undefined}
                className="canvas-container"
              >
                {clicknaddMode ? (
                  <EmptySmilesView
                    width={getSizeValues(size)?.width}
                    height={getSizeValues(size)?.height}
                    data-canvas="mol-viewer-canvas"
                  />
                ) : (
                  <svg
                    className={
                      isRetrosynthesisMolecule ? 'retrosynth-mol-svg' : ''
                    }
                    ref={smilesViewRef}
                    width={getSizeValues(size)?.width}
                    height={getSizeValues(size)?.height}
                  />
                )}
              </SmilesViewContainer>
            )}
            {!!coordinates && (
              <>
                {isFastView && (
                  <SmilesWithIconContainer>
                    <Smiles>{smiles}</Smiles>
                  </SmilesWithIconContainer>
                )}
                <Tags withMargin={!isFastView} withBackground={!isFastView}>
                  <CustomTag>
                    {'X: '}
                    {coordinates.x.toFixed(2)}
                  </CustomTag>
                  <CustomTag>
                    {'Y: '}
                    {coordinates.y.toFixed(2)}
                  </CustomTag>
                </Tags>
              </>
            )}
            {!clicknaddMode && !noMenu && noEdit && noDelete && !showId && (
              <SmallCardHambButton onClick={toggleMenu}>
                <Icon iconType="threeDots" />
              </SmallCardHambButton>
            )}
            {clicknaddMode || (noEdit && noDelete) || showId ? null : (
              <ToolsBtn>
                <ToolButton size="small">
                  {(!!noEdit && !!noDelete) || noMenu ? null : (
                    <Icon
                      iconType="threeDots"
                      onClick={toggleMenu}
                      size="1.25rem"
                    />
                  )}
                  {noEdit ? null : (
                    <Icon
                      iconType="edit"
                      onClick={handleEdit}
                      size="1.25rem"
                      dataTest="molecule-edit-btn"
                    />
                  )}
                  {noDelete ? null : (
                    <Icon
                      iconType="deleted"
                      onClick={handleDelete}
                      size="1.25rem"
                      dataTest="molecule-delete-btn"
                    />
                  )}
                </ToolButton>
              </ToolsBtn>
            )}
            {isRetrosynthesisMolecule && (
              <SmallBackdrop
                withBlur={isMolMenuOpen || state.isMoleculeHovered}
              >
                <Block>
                  <IdBlock
                    num={num}
                    noIdText={t('molecule_viewer.molecule_generated')}
                    noIdDescription={t(
                      'molecule_viewer.molecule_generated_description'
                    )}
                    type="white"
                    withTooltip={false}
                  />
                </Block>
                <ToolsBtnNew
                  isMolMenuOpen={isMolMenuOpen}
                  isHovered={state.isMoleculeHovered}
                >
                  <ToolButton size="small" withDisabledIcon={!num}>
                    <Icon
                      iconType="threeDots"
                      onClick={(e) => {
                        e.stopPropagation()
                        openRetrosynthMolMenu()
                      }}
                      size="1.25rem"
                      dataTest={'retro-molecule-card-btn-context-menu'}
                    />
                    <Icon
                      iconType="eyeOpen"
                      onClick={handleShowPreview}
                      size="1.25rem"
                      dataTest={'retro-molecule-card-btn-fast-view'}
                    />
                    <Icon
                      iconType="add"
                      onClick={(e) => {
                        e.stopPropagation()
                        dispatch(toggleAddMoleculeDialog([smiles]))
                      }}
                      size="1.25rem"
                      dataTest={'retro-molecule-card-btn-add-to-dataset'}
                    />
                  </ToolButton>
                </ToolsBtnNew>
              </SmallBackdrop>
            )}

            {(isShowMolName || !!text) && (
              <MoleculeName
                fontWeight={theme.fontWeight.semibold}
                isBestMatch={isBestMatch}
              >
                {!!text && text}
              </MoleculeName>
            )}

            {withData && (
              <MoleculeFooter
                {...{ params, withColors, referencesCount }}
                onLinkActionClick={(value) => {
                  value === 'literature' ? goToLiterature() : goToReactions()
                }}
                isBestMatch={isBestMatch}
              />
            )}
          </Info>
          {clicknaddMode && (
            <AddNew isAddDialogOpen={isAddDialogOpen}>
              <IconButton $onhover={false} size="small">
                <Icon iconType="add" size="1rem" />
              </IconButton>
              <Caption color="inherit" fontWeight={400}>
                {' '}
                {t('molecule_viewer.add_text')}
              </Caption>
            </AddNew>
          )}
        </OneMolecula>
      </Container>

      {isAddMoleculeDialogOpen && (
        <BasketsListModal
          onAgree={handleAddMoleculeToBasket}
          onClose={() => dispatch(closeAddMoleculeDialog())}
          withPublic={false}
          withNew={true}
          actionText={t('molecule_viewer.add_to_dataset')}
          onlyOne
          currentBasketId={currentBasketId}
        />
      )}
    </>
  )
})

MoleculeViewer.displayName = 'MoleculeViewer'
export default MoleculeViewer
