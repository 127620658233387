import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'

import { Container } from './index.style'
import InfoWithProgress from '../InfoWithProgress'

const Activation = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <Icon iconType="mail2" size="4.75rem" />
        <InfoWithProgress
          showProgress={false}
          title={t('registration.last_step_left')}
          description={t('registration.confirm_your_email')}
        />
      </Container>
    </>
  )
}

export default Activation
