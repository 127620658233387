import { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import MoleculesList from '../pages/MoleculesList'
// import ReactionList from '../pages/ReactionList'
import NotFound from '../components/NotFound'
import AuthRoute from './AuthRoute'
import IndividualPage from '../components/IndividualPage'
import Loader from '../components/Loader'
import SmilesIupacConverter from '../components/SmilesIupacConverter'
import BillingAdminView from '../components/BillingAdminView'
import AdminView from '../components/AdminView'
import AdminPluginsView from '../components/AdminPluginsView'
import AdminViewerView from '../components/AdminViewerView'
import FeedbackViewer from '../components/FeedbackViewer'
import LoginPage from '../components/LoginPage'
import RegistrationPage from '../components/RegistrationPage'
import AdminLogView from '../components/AdminLogView'
import AdminUsersView from '../components/AdminUsersView'
import AdminBanListView from '../components/AdminBanListView'
import StatisticsPage from '../components/StatisticsPage'
import RecoveryPasswordPage from '../components/RecoveryPasswordPage'
import LoadingOverlay from '../components/LoadingOverlay'
import AdminInviteView from '../components/AdminInviteView'
import Baskets from 'pages/Baskets'
import SpectraPredictionPage from 'pages/SpectraPrediction'
import { MoleculePage } from 'pages/MoleculePage'
import SynthCost from 'pages/SynthCost'
import Pdf2Smiles from 'pages/Pdf2Smiles'
import Pdf2SmilesViewer from 'pages/Pdf2SmilesViewer'
import LiteraturePage from 'pages/LiteraturePage'
import ReactionListNew from 'pages/ReactionListNew'
import RegistrationPageNew from 'components/RegistrationPageNew'

const Reinvent = lazy(() => import('../components/Reinvent'))

const Router = () => {
  return (
    <>
      <Loader />
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <AuthRoute path="/" exact component={MoleculesList} />
          <AuthRoute path="/search" exact component={MoleculesList} />
          <AuthRoute path="/datasets" exact component={Baskets} />
          <AuthRoute path="/molecules/:id" component={MoleculesList} />
          <AuthRoute path="/reactions" component={ReactionListNew} />
          <AuthRoute path="/spectra" component={SpectraPredictionPage} />

          <AuthRoute path="/synmap" component={Reinvent} />
          <AuthRoute path="/admin/billing" component={BillingAdminView} />
          <AuthRoute path="/admin/plugins" component={AdminPluginsView} />
          <AuthRoute path="/admin/viewer" component={AdminViewerView} />
          <AuthRoute path="/admin/feedback" component={FeedbackViewer} />
          <AuthRoute path="/admin/log" component={AdminLogView} />
          <AuthRoute path="/admin/users" component={AdminUsersView} />
          <AuthRoute path="/admin/banlist" component={AdminBanListView} />
          <AuthRoute path="/admin/invite" component={AdminInviteView} />
          <AuthRoute path="/admin/" component={AdminView} />

          <AuthRoute path="/smiles2iupac" component={SmilesIupacConverter} />
          <Route
            path={['/recovery/:id', '/recovery']}
            component={RecoveryPasswordPage}
          />
          <Route path={['/login/:id', '/login']} component={LoginPage} />
          <AuthRoute path="/statistics" component={StatisticsPage} />
          <Route path="/register" component={RegistrationPage} />
          <Route path="/register2" component={RegistrationPageNew} />
          <AuthRoute path="/moleditor/:smiles?" component={IndividualPage} />
          <AuthRoute path="/molecule/:id" component={MoleculePage} />
          <AuthRoute path="/pdf2smiles" exact component={Pdf2Smiles} />
          <AuthRoute path="/pdf2smiles/:id?" component={Pdf2SmilesViewer} />
          <AuthRoute path="/literature/:type_id" component={LiteraturePage} />

          <AuthRoute path="/synthcost" component={SynthCost} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  )
}

export default Router
