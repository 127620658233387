import { Subheadline, TextBody } from 'components/common/text/index.style'
import styled from 'styled-components'

export const CardWrapper = styled.div`
  padding: ${({ padding }) => (padding ? padding : '20px')};
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '12px')};
  position: relative;
  border-radius: 12px;
  background: white;

  cursor: ${({ isFullMode }) => (isFullMode ? 'default' : 'pointer')};

  &:hover {
    .header-title {
      color: #42c1bf;
    }
  }

  em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.text.accentPrimary};
    background: ${({ theme, isDialog }) =>
      isDialog
        ? `${theme.colors.backgrounds.accentSecondary}40`
        : theme.colors.backgrounds.white};
    border-radius: 0.375rem;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 95%;
`

export const Title = styled(TextBody)`
  white-space: normal;
  max-width: 85%;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  transition: all 0.15s ease;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const TypeRow = styled(Row)`
  & > svg:not(.copy-name-icon) {
    color: ${({ theme }) => theme.colors.statuses.link};
    cursor: pointer;
  }
  .copy-name-icon {
    color: ${({ theme }) => theme.colors.icons.tertiary};

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`

export const TypeNumber = styled(Subheadline)`
  color: ${({ theme, isLink }) =>
    isLink ? theme.colors.statuses.link : theme.colors.text.primary};
  text-decoration: none;
  & a {
    color: inherit;
    text-decoration: inherit;
  }
`

export const CardInfo = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const InfoRow = styled(Row)`
  display: ${({ display }) => (display ? display : 'flex')};
  justify-content: start;
  font-size: 11px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: 12px;
  flex-wrap: wrap;

  & > div {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: auto;
`

export const Option = styled(Row)`
  color: ${({ disabled }) => (disabled ? '#CED6E3' : '#2D3745')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#CED6E3' : '#42c1bf')};
  }
`

export const CardButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > svg {
    color: #cbd2d9;
  }

  &:hover {
    & > svg {
      color: #42c1bf;
    }
  }
`

export const EyeButton = styled(CardButton)`
  position: absolute;
  top: 1rem;
  right: 2.75rem;
`

export const DotsButton = styled(CardButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
export const DialogCardAbstract = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6e809d;
  cursor: inherit;
`

export const CardAbstract = styled(DialogCardAbstract)`
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

export const CaptionAuthors = styled.div`
  color: ${({ color }) => (color ? color : '#CED6E3')};
  font-size: 11px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

export const DialogCardFullText = styled.div`
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.text[12])};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '16px')};
  cursor: inherit;
`

export const DialogCardFullTextBlock = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
  margin-top: 1rem;
  user-select: none;

  & span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  & p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    margin: 0;
  }
`
export const FullTextSelectorBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: ${({ theme, showFullText }) =>
    showFullText ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: ${({ theme, showFullText }) => theme.colors.text.accentPrimary};
  }
`
