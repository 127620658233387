import {
  Caption,
  Headline,
  Separator,
  Subheadline,
} from 'components/common/text/index.style'
import { useCallback, useMemo, useState } from 'react'
import Latex from 'react-latex-next'
import { format } from 'date-fns'
import {
  CardWrapper,
  CardHeader,
  TypeRow,
  TypeNumber,
  Title,
  CardInfo,
  InfoRow,
  CardFooter,
  Option,
  DotsButton,
  EyeButton,
  CardAbstract,
  CaptionAuthors,
} from './index.style'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { setLitDialogConfig, setOpenLitDialog } from 'store/actions/literature'
import {
  LANGUAGES_KEYS,
  footerOptions,
  getLanguageKeys,
  languages,
} from './config'
import { addSimpleNotification } from '../../store/actions/notifications'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import CustomButton from 'components/common/customButton'
import { useTheme } from 'styled-components'

const LiteratureCard = ({ data, litSearchSmiles }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const documentCode = data.type === 'patent' ? data.patent_number : data.doi
  const [currentLanguage, setCurrentLanguage] = useState(
    data?.language ||
      Object.keys(data).filter((k) => LANGUAGES_KEYS.includes(k))?.[0]
  )

  const handleOpenContextMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'literatureCardMenu',
        item: {
          dataType: data?.type,
          documentCode,
          disabledActions: {
            copy_literature_link: true,
          },
        },
      })
    )
  }

  const handleOpenDialog = useCallback(
    (e) => {
      if (e.target.tagName === 'A') return
      dispatch(setOpenLitDialog(true))
      dispatch(
        setLitDialogConfig({
          id: data?.[currentLanguage]?.id ?? data?.['en']?.id, // ?? data?.['en']?.id временная мера, убрать, как только бэк начнет отдавать правильные ключи в data (data.languge "ru"->есть ключ data.ru:{}, data.languge "de"->есть ключ de:{},)
          type: data?.type,
          litSearchSmiles,
          language: currentLanguage,
          title: data?.[currentLanguage]?.title,
          abstract: data?.[currentLanguage]?.abstract,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, dispatch]
  )

  const authors = useMemo(() => {
    if (
      !data?.[currentLanguage]?.authors ||
      !data?.[currentLanguage].authors?.length
    )
      return ''
    return data[currentLanguage].authors.join(', ')
  }, [data, currentLanguage])

  const owners = useMemo(() => {
    if (
      !data?.[currentLanguage]?.company ||
      !data?.[currentLanguage].company?.length
    )
      return ''
    return data[currentLanguage].company.join(', ')
  }, [data, currentLanguage])

  const changeLanguage = (e) => {
    e.stopPropagation()
    setCurrentLanguage(currentLanguage === 'en' ? 'ru' : 'en')
  }

  const isLanguageChangeable = useMemo(
    () => getLanguageKeys(data)?.length > 1,
    [data]
  )

  return (
    <>
      <CardWrapper isFullMode={false} onClick={handleOpenDialog} padding="1rem">
        <CardHeader>
          <Headline
            className="header-title"
            fontWeight={500}
            style={{ maxWidth: 'calc(100% - 4.5rem)' }}
          >
            <Latex>
              {data?.[currentLanguage]?.title
                ? String.raw({
                    raw: data?.[currentLanguage]?.title,
                  }).replaceAll(/\\/g, '\\')
                : ''}
            </Latex>
          </Headline>
          <TypeRow>
            <Subheadline fontWeight={400}>
              {data.type === 'article'
                ? t('literatureCard.doi')
                : t('literatureCard.patentNumber')}
            </Subheadline>
            <TypeNumber fontWeight={400} isLink={data.type === 'article'}>
              {data.type === 'article' ? (
                <a
                  target="_blank"
                  href={`https://doi.org/${documentCode}`}
                  rel="noreferrer"
                >
                  {documentCode}
                </a>
              ) : (
                documentCode
              )}
            </TypeNumber>
            {data.type === 'article' && <Icon iconType="share" size="1rem" />}
            <Icon
              className="copy-name-icon"
              iconType="copy"
              size="0.75rem"
              dataTest="literature-card-copy-btn"
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(documentCode)
                navigator.clipboard.writeText(documentCode)
                dispatch(
                  addSimpleNotification(
                    `notification.${
                      data.type === 'article'
                        ? 'DOI_copied'
                        : 'patent_number_copied'
                    }`
                  )
                )
              }}
            />
          </TypeRow>
          <CardAbstract>
            <Latex>
              {data?.[currentLanguage]?.abstract
                ? String.raw({
                    raw: data?.[currentLanguage]?.abstract,
                  }).replaceAll(/\\/g, '\\')
                : ''}
            </Latex>
          </CardAbstract>
        </CardHeader>

        {isLanguageChangeable && (
          <CustomButton
            type={'text_accent'}
            gap={'0.25rem'}
            onClick={changeLanguage}
          >
            <div>
              {currentLanguage === data?.language
                ? t('literatureCard.translate')
                : t('literatureCard.show_original')}
            </div>
            <Icon iconType={'translate'} size={'1rem'} />
          </CustomButton>
        )}
        <CardInfo>
          {authors && (
            <CaptionAuthors color={theme.colors.text.secondary}>
              {`${t('literatureCard.authors')} ${authors}`}
            </CaptionAuthors>
          )}
          {data?.[currentLanguage]?.company && (
            <CaptionAuthors color={theme.colors.text.secondary}>
              {`${t('literatureCard.owners')}: ${owners}`}
            </CaptionAuthors>
          )}
          <InfoRow fontWeight={400}>
            {data?.language && (
              <Caption fontWeight={400} color={theme.colors.text.secondary}>
                {languages[data.language]}
              </Caption>
            )}
            {!!data?.[currentLanguage]?.journal?.length && (
              <>
                {data?.language && (
                  <Separator>
                    <Icon iconType="dotSeparator" size="1rem" />
                  </Separator>
                )}
                <Caption
                  fontSize="11px"
                  fontWeight={400}
                  color={theme.colors.text.secondary}
                >
                  {data[currentLanguage].journal?.map((el) => el)}
                </Caption>
              </>
            )}
            {!!data?.[currentLanguage]?.publisher && (
              <>
                {(data?.language ||
                  !!data?.[currentLanguage]?.journal?.length) && (
                  <Separator>
                    <Icon iconType="dotSeparator" size="1rem" />
                  </Separator>
                )}
                <Caption fontWeight={400} color={theme.colors.text.secondary}>
                  {data?.[currentLanguage]?.publisher}
                </Caption>
              </>
            )}
            {!!data?.published_date && (
              <>
                {(data?.language ||
                  !!data?.[currentLanguage]?.journal?.length ||
                  !!data?.[currentLanguage]?.publisher) && (
                  <Separator>
                    <Icon iconType="dotSeparator" size="1rem" />
                  </Separator>
                )}
                <Caption fontWeight={400} color={theme.colors.text.secondary}>
                  {format(new Date(data?.published_date), 'dd.MM.yyyy')}
                </Caption>
              </>
            )}
          </InfoRow>
        </CardInfo>
        <CardFooter>
          {footerOptions.map((el, key) => {
            return (
              <Option
                key={`${el.value}-${key}`}
                disabled={el.isDisabled}
                onClick={(e) => {
                  if (el.isDisabled) e.stopPropagation()
                }}
              >
                <Subheadline color="inherit" fontWeight={400}>
                  {t(el.value)}{' '}
                  {!!data[el.numberPointer] && `(${data[el.numberPointer]})`}
                </Subheadline>
                <Icon iconType={'arrowRight'} size="1rem" />
              </Option>
            )
          })}
        </CardFooter>
        <DotsButton
          onClick={handleOpenContextMenu}
          data-test="literature-card-context-menu-btn"
        >
          <Icon iconType={'threeDots'} size="1.25rem" />
        </DotsButton>
        <EyeButton>
          <Icon iconType={'eyeOpen'} size="1.25rem" />
        </EyeButton>
      </CardWrapper>{' '}
    </>
  )
}

export default connect()(LiteratureCard)
