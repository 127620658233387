import {
  GETME_FAIL,
  GETME_INIT,
  SAVE_USER_TEMP_CREDS,
  CLEAR_USER_TEMP_CREDS,
  TOGGLE_USER_PROFILE,
} from '../constants/auth'

export const loadMe = () => {
  return {
    type: GETME_INIT,
  }
}

export const resetMe = () => {
  return {
    type: GETME_FAIL,
  }
}

export const setUserTempCreds = (value) => {
  return {
    type: SAVE_USER_TEMP_CREDS,
    userCreds: value,
  }
}

export const clearUserTempCreds = () => {
  return {
    type: CLEAR_USER_TEMP_CREDS,
  }
}
export const toggleUserProfile = (value) => {
  return {
    type: TOGGLE_USER_PROFILE,
    isShowProfile: value,
  }
}
