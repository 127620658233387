import Icon from 'components/Icon'

import { Indicator } from './index.style'
import { getApplicabilityColors } from './utils/utils'

const ApplicabilityIndicator = ({ number }) => {
  const { color, backgroundColor } = getApplicabilityColors(number)

  return (
    <Indicator {...{ color, backgroundColor }}>
      {number !== null ? (
        `${Math.ceil(number * 100)}%`
      ) : (
        <Icon iconType="close" />
      )}
    </Indicator>
  )
}
export default ApplicabilityIndicator
