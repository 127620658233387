export const FREESEARCH_MAX = 7

export const PAGINATION_SIBLINGS_COUNT = 1
export const PAGINATION_VISIBLE_PAGES_COUNT = 5
export const FIRST_PAGE_IDX = 1

export const MIDDLE_RANGE_COUNT = 4

export const MOLECULES_LIST_PATH = 'molecules'
export const SEARCH_PATH = 'search'
export const REACTIONS_PATH = 'reactions'
export const SYNTHCOST_PATH = 'synthcost'

export const initMoleculesState = {
  editor: false,
  showSettings: false,
  editId: null,
  editSmile: null,
  selected: [],
  withData: true,
  withColors: true,
  showProps: false,
  propsId: null,
  molId: null,
  showMove: false,
  baskets: [],
  showSearchBar: false,
  basketData: null,
  openAdd: false,
  isUploadNotifActive: false,
  scrollTopEnable: false,
  tasks: [],
  isBottom: false,
}

export const SCROLL_OFFSET = 100
