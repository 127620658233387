import styled, { css } from 'styled-components'

import CustomInput from 'components/common/customInput'
import { CustomInputField } from 'components/common/customInput/index.style'
import { Subheadline, TitleTertiary } from 'components/common/text/index.style'
import {
  HIGHLIGHTS_SIDEBAR_WIDTH_M,
  HIGHLIGHTS_SIDEBAR_WIDTH_S,
} from './config/config'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 12px;
  position: relative;
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const DocumentContainer = styled.div`
  width: ${({ isSidebarCollapsed }) =>
    `calc(100% - ${
      isSidebarCollapsed
        ? HIGHLIGHTS_SIDEBAR_WIDTH_M
        : HIGHLIGHTS_SIDEBAR_WIDTH_S
    })`};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: ${({ isDocumentHeaderCollapsed }) =>
    isDocumentHeaderCollapsed ? '0' : '1.5rem'};
  position: relative;
`

export const DocumentName = styled(TitleTertiary)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  border-radius: 12px;

  ${({ isDocumentHeaderCollapsed }) =>
    isDocumentHeaderCollapsed
      ? css`
          z-index: 2;
          position: absolute;
          top: 12px;
          padding: 12px 16px;
          left: 50%;
          transform: translate(-50%, 0);
          right: 12px;
          align-items: center;
          max-width: calc(100% - 32px);
          width: max-content;

          background-color: rgba(243, 245, 248);
          box-shadow: 0px 0px 3px 0px rgba(129, 152, 186, 0.04),
            0px 0px 4px 0px rgba(129, 152, 186, 0.06),
            0px 0px 10px 0px rgba(129, 152, 186, 0.14);
          backdrop-filter: blur(40px);
        `
      : css`
          margin-right: 12px;
          align-items: start;
        `}

  ${DocumentName} {
    -webkit-line-clamp: ${({ isDocumentHeaderCollapsed }) =>
      isDocumentHeaderCollapsed ? '1' : '2'};
  }
`

export const BackButtonContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  > svg {
    width: 16px;
    height: 16px;
  }
`

export const DocumentTooltipName = styled.div`
  max-width: 500px;
`

export const PageSelector = styled.div`
  z-index: 2;
  position: absolute;
  left: 8rem;
  bottom: 1.5rem;
  padding: 0.25rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 82px;
  height: 44px;
  border-radius: 12px;
  background: rgba(245, 247, 247, 0.6);
  box-shadow: 0px 0px 3px 0px rgba(31, 41, 51, 0.04),
    0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px 0px 10px 0px rgba(31, 41, 51, 0.14);
  backdrop-filter: blur(40px);
`

export const PagesCount = styled(Subheadline)`
  user-select: none;
`

export const CurrentPageInput = styled(CustomInput)`
  width: 36px;

  & ${CustomInputField} {
    padding: 12px 6px;
    color: #616e7c;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }
`
