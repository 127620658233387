import styled from 'styled-components'

import { TitleTertiary } from 'components/common/text/index.style'
import {
  HIGHLIGHTS_SIDEBAR_WIDTH_M,
  HIGHLIGHTS_SIDEBAR_WIDTH_S,
} from '../config/config'

export const Container = styled.div`
  width: ${({ isSidebarCollapsed }) =>
    isSidebarCollapsed
      ? HIGHLIGHTS_SIDEBAR_WIDTH_M
      : HIGHLIGHTS_SIDEBAR_WIDTH_S};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1.5rem 0 0.875rem;
`

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${TitleTertiary} {
    > sup {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100% - 36px);
  margin-right: 6px;
  padding-left: 14px;
  padding-right: 6px;

  > :last-child {
    margin-bottom: 24px;
  }
`

export const Highlight = styled.div`
  border-radius: 12px;
  background: #fff;
  cursor: pointer;
  border: 1.2px solid;
  border-color: ${({ isSelected }) => (isSelected ? '#42C1BF' : 'transparent')};
  box-shadow: ${({ isCurrent }) =>
    isCurrent
      ? '0px 0px 6px 0px rgba(129, 152, 186, 0.08), 0px -4px 10px 0px rgba(129, 152, 186, 0.04), 0px 4px 10px 0px rgba(129, 152, 186, 0.04)'
      : 'none'};
  user-select: none;
`

export const EmptyHighlight = styled.div`
  min-height: 220px;
`
