import React, { useState, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  NewButton,
  Wrapper,
  LoadButton,
  ModalTitle,
  CloseBtn,
  ModalHeader,
  ModalDialog,
  Modal,
  Filter,
  FilterInput,
  ModalBtns,
  CreateBtn,
  ListText,
  SelectedList,
  SelectedItem,
  ListContainer,
} from './index.style'
import { addBasket, loadBaskets } from '../../store/actions/crud'
import Icon from 'components/Icon'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomScrollbar from 'components/CustomScrollbar'

const BasketsListModal = ({
  baskets,
  onAgree,
  onClose,
  withPublic,
  loadBaskets,
  withNew = true,
  actionText,
  reactionsBaskets,
  selectedDatasets,
  currentBasketId,
  onlyOne,
  loading,
}) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState([])
  const [view, setView] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const { t } = useTranslation()

  const handleChange = (e) => {
    const value = e.target.value
    setFilterValue(value)
  }

  useEffect(() => {
    loadBaskets && loadBaskets({ withPublic: true })
  }, [loadBaskets])

  useEffect(() => {
    const processedSets = baskets.filter(
      (basket) => basket.status !== 'pending'
    )
    setView(processedSets)
  }, [baskets, reactionsBaskets])

  const handleCreate = () => {
    dispatch(addBasket(filterValue))
  }

  const handleAgree = () => {
    onAgree(selected, filterValue)
    onClose()
  }

  const handleSelect = (id, name) => () => {
    const isSelected = selected.find(({ id: itemId }) => itemId === id)
    if (isSelected) {
      const filtered = selected.filter((el) => el.id !== id)
      setSelected(filtered)
    } else if (onlyOne && selected.length > 0 && !isSelected)
      setSelected([{ id, name }])
    else setSelected([...selected, { id, name }])
  }

  const handleRemoveSelected = (id) => {
    const filtered = selected.filter((el) => el.id !== id)
    setSelected(filtered)
  }

  const filtered = useMemo(
    () =>
      (filterValue
        ? view.filter(({ name }) =>
            name.toLowerCase().includes(filterValue.toLowerCase())
          )
        : view
      ).filter((basket) => {
        return !(
          (withPublic === false && basket.public) ||
          selectedDatasets.includes(basket.id) ||
          basket.id.toString() === currentBasketId
        )
      }) ?? [],
    [currentBasketId, filterValue, selectedDatasets, view, withPublic]
  )

  return (
    <Modal>
      <ModalDialog
        onClose={onClose}
        open={true}
        fullWidth
        maxWidth="xs"
        style={{ borderRadius: '0.75rem' }}
      >
        <ModalHeader>
          <ModalTitle>{t('baskets.modal.choose_set_text')}</ModalTitle>
          <CloseBtn onClick={onClose}>
            <Icon iconType="close" />
          </CloseBtn>
        </ModalHeader>

        <Filter>
          <Icon iconType="searchSmall" />
          <FilterInput
            placeholder={t(
              withNew
                ? 'baskets.modal.placeholder'
                : 'baskets.modal.placeholder_filter'
            )}
            onChange={handleChange}
          />
        </Filter>

        {!onlyOne && (
          <SelectedList>
            {selected.map(({ name, id }, key) => {
              return (
                <SelectedItem key={key}>
                  <span>{name}</span>
                  <button onClick={() => handleRemoveSelected(id)}>
                    <Icon iconType="close" />
                  </button>
                </SelectedItem>
              )
            })}
          </SelectedList>
        )}
        <ListContainer>
          <ListText>{t('baskets.modal.choose_from_list')}</ListText>
            <Wrapper>
          <CustomScrollbar>
              {!loading &&
                filtered.map((el) => {
                  const isSelected = selected.find(({ id }) => id === el.id)
                  return (
                    <NewButton
                      onClick={handleSelect(el.id, el.name)}
                      selected={isSelected}
                      key={el.id}
                      data-test="choose-baskets-list"
                    >
                      <span>{el.name}</span>
                      {isSelected && <Icon iconType="tick" />}
                    </NewButton>
                  )
                })}
              {loading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '220px',
                  }}
                >
                  <LoaderRestyling />
                </div>
              )}
          </CustomScrollbar>
            </Wrapper>
        </ListContainer>

        <ModalBtns>
          {withNew && (
            <CreateBtn
              onClick={() =>
                filterValue && !filtered.length ? handleCreate() : {}
              }
              active={filterValue && !filtered.length}
            >
              {t('baskets.modal.create_new_basket')}
            </CreateBtn>
          )}
          <LoadButton
            disabled={!selected.length}
            onClick={!selected.length ? () => {} : handleAgree}
          >
            <span>{actionText}</span>
          </LoadButton>
        </ModalBtns>
      </ModalDialog>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    baskets: state.crud.baskets,
    selectedDatasets: state.mmap.selectedDatasets,
    loading: state.crud.loading,
  }
}

const mapDispatchToProps = {
  loadBaskets,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketsListModal)
