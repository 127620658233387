import React, { useState, useEffect, useContext, createContext } from 'react'
import { fetch, store } from '../../services/rest'
import { readStorage, removeFromStorage, setStorage } from '../storage/storage'
import { useTranslation } from 'react-i18next'

export const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user] = useState({})
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const initAuth = async () => {
      try {
        setLoading(true)
        await fetch('/profile')
        setIsAuthenticated(true)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response) {
          if (error.response.status === 401) {
            deleteToken()
          }
        }
        console.log(error)
      }
    }
    if (getToken()) {
      initAuth()
    }
  }, [])

  const getToken = () => {
    const res = readStorage('s_token')
    return res
  }

  const putToken = (token) => {
    setStorage('s_token', token)
  }

  const deleteToken = () => {
    removeFromStorage('s_token')
  }

  const login = async (mail, password) => {
    try {
      setLoading(true)
      if (mail && typeof mail === 'string') mail = mail.toLowerCase()
      const { data } = await store('/profile/login', { mail, password })
      putToken(data.token)
      setIsAuthenticated(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      throw new Error(
        error.response ? error.response.data : t('account.somethingWentWrong')
      )
    }
  }

  const logout = async () => {
    deleteToken()
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
