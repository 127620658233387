import Icon from 'components/Icon'
import {
  InputBlockBtnCross,
  SearchBlock,
} from 'components/main-search-bar/style/index.style'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem;
  flex-direction: column;

  & .loader {
    margin-top: 7rem;
  }
  a {
    color: #616e7c;
  }
`
export const HeaderContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  max-width: 39.375rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
`

export const LineBlock = styled.div`
  gap: 8px;
  display: grid;
  grid-template-columns: 1fr auto;

  &.custom-convert {
    height: 2.25rem;
  }
`

export const ResultBlock = styled.div`
  width: 100%;
  color: ${({ error }) => (error ? 'red' : 'black')};
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0.75rem;
  gap: 1.25rem;
  height: ${({ fullHeight }) => fullHeight && '100%'};
`

export const ResultItem = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  line-height: 1rem;

  color: #1f2933;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.168px;

  &::placeholder {
    color: #cbd2d9;
  }

  &:disabled {
    background: #ffffff;
  }
`

export const InputBlock = styled(SearchBlock)`
  gap: 0.75rem;
  height: 2.25rem;
  border-radius: 0.75rem;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 0.375rem 0.5rem 0.75rem;
`

export const CrossBtn = styled(InputBlockBtnCross)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  padding: 0;
  justify-content: center;

  & > svg {
    width: 1rem;
    height: 1rem;
  }
`

export const Status = styled.div`
  gap: 0.25rem;
  width: 5.313rem;
  min-width: 5.313rem;
  display: flex;
  font-size: 0.625rem;
  line-height: 0.75rem;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  align-items: center;
  border-radius: 0.75rem;
  justify-content: center;
  color: ${({ isHighScore }) => (isHighScore ? '#34C759' : '#FF3B30')};
  background-color: ${({ isHighScore }) =>
    isHighScore ? 'rgba(52, 199, 89, 0.1)' : 'rgba(255, 192, 188, 0.3)'};
`

export const NameBlock = styled.div`
  gap: 0.25rem;
  display: flex;
  font-size: 0.75rem;
  line-height: 1rem;
  align-items: center;
  svg {
    min-width: 1rem;
  }
`

export const StyledIcon = styled(Icon)`
  color: ${({ info }) => (info ? '#1F2933' : '#78909c')};
  cursor: pointer;
  &:hover {
    color: #42c1bf;
  }
`

export const SwitcherWrapper = styled.div`
  margin-top: -0.5rem;
`
