import { useMemo } from 'react'
import { NmrChartCard } from './index.style'
import { ChartCard } from 'pages/SpectraPrediction/index.style'
import NmrTable from './nmrTable'
import NmrChart from './nmrChart'
import HydroChart from './HydroChart'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Headline } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'

const NmrChartsBlock = ({ nmrData, nmrMethod }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const shiftValues = nmrData.shifts

  // find all shift duplicates
  const duplicates = useMemo(() => {
    const dupl = {}
    shiftValues.forEach((el, index) => {
      if (dupl[el]) dupl[el] = [...dupl[el], index]
      else dupl[el] = [index]
    })
    return dupl
  }, [shiftValues])

  return (
    <ChartCard>
      <Headline color={theme.colors.text.primary}>
        {t('spectra.calculated_title')}
      </Headline>

      {nmrMethod.value !== 'hydrogen' ? (
        <NmrChartCard>
          <NmrChart duplicates={duplicates} />
          <NmrTable duplicates={duplicates} />
        </NmrChartCard>
      ) : (
        <NmrChartCard>
          <HydroChart duplicates={duplicates} />
          <NmrTable duplicates={duplicates} />
        </NmrChartCard>
      )}
    </ChartCard>
  )
}

const mapStateToProps = (state) => {
  return {
    nmrMethod: state.spectra.nmr.method,
    nmrData: state.spectra.nmr.data,
  }
}

export default connect(mapStateToProps)(NmrChartsBlock)
