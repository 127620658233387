import {
  VariantWrapper as VariantWrapperStyle,
  VariantBlock as VariantBlockStyle,
  VariantBlockTitle,
  VariantBlockList,
  VariantBlockListWrapper,
} from '../style/index.style'
import { useLocation } from 'react-router-dom'
import {
  SYNTHCOST_PATH,
  MOLECULES_LIST_PATH,
} from 'pages/MoleculesList/config/config'
import { useTranslation } from 'react-i18next'
import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'

export const VariantBlock = ({
  data,
  handleSelect,
  withMaxHeight,
  withSelectStyles = false,
  withIcon = false,
  iconType,
  className,
  title,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isStandartSearchBar = !(
    location.pathname.split('/').includes(MOLECULES_LIST_PATH) ||
    location.pathname.split('/').includes(SYNTHCOST_PATH)
  )

  return (
    <VariantBlockStyle withSelectStyles={withSelectStyles}>
      <VariantBlockList>
        {/* {(!withSelectStyles || title) && (
          <VariantBlockTitle>
            {t(title ? title : 'searchVariants.similar_structures')}
          </VariantBlockTitle>
        )} */}
        <VariantBlockListWrapper withMaxHeight={withMaxHeight}>
          <CustomScrollbar
            style={{
              height: `${data?.length * 2.25}rem`,
              ...(withMaxHeight && { maxHeight: '15rem' }),
            }}
          >
            {data?.map(({ label, id, smiles, name }, idx) => (
              <VariantWrapperStyle
                key={idx}
                onMouseDown={() => handleSelect(id, smiles, name, label)}
                isStandartSearchBar={isStandartSearchBar}
                withSelectStyles={withSelectStyles}
                data-test="variants-block-item"
                className={`${
                  withIcon ? `variant-flex-block` : ''
                } ${className}`}
              >
                {withIcon && iconType && (
                  <Icon iconType={iconType} size="1.25rem" />
                )}
                <div>{label}</div>
              </VariantWrapperStyle>
            ))}
          </CustomScrollbar>
        </VariantBlockListWrapper>
      </VariantBlockList>
    </VariantBlockStyle>
  )
}
