import {
  SETTINGS_FREEMOL_INC,
  SETTINGS_FREEREACTIONS_INC,
  SETTINGS_FREEREACTIONS_RESET,
  SETTINGS_FREESEARCH_INC,
  SETTINGS_FREESEARCH_RESET,
  SETTINGS_SIDEBAR_TOGGLE,
  SETTINGS_MOLSIZE,
  SET_SHOW_PUBLIC_BUSKETS,
  SETTINGS_SET_SHOW_PUBLIC_PDF,
  SETTINGS_LANG_CHANGE,
  SETTINGS_SHOW_TIPS_TOGGLE,
} from '../constants/settings'

export const settingsMolsize = (value) => {
  return {
    type: SETTINGS_MOLSIZE,
    value,
  }
}

export const settingsSidebarToggle = () => {
  return {
    type: SETTINGS_SIDEBAR_TOGGLE,
  }
}

export const settingsFreeMolInc = () => {
  return {
    type: SETTINGS_FREEMOL_INC,
  }
}

export const settingsFreeReactionsInc = () => {
  return {
    type: SETTINGS_FREEREACTIONS_INC,
  }
}

export const settingsFreeReactionsReset = () => {
  return {
    type: SETTINGS_FREEREACTIONS_RESET,
  }
}

export const settingsFreeSearchInc = () => {
  return {
    type: SETTINGS_FREESEARCH_INC,
  }
}

export const settingsFreeSearchReset = () => {
  return {
    type: SETTINGS_FREESEARCH_RESET,
  }
}

export const setShowPublicBaskets = (showPublicBaskets) => {
  return {
    type: SET_SHOW_PUBLIC_BUSKETS,
    showPublicBaskets,
  }
}

export const setShowPublicPdf = (showPublicPdf) => {
  return {
    type: SETTINGS_SET_SHOW_PUBLIC_PDF,
    showPublicPdf,
  }
}

export const settingsLangChange = (lang) => {
  return {
    type: SETTINGS_LANG_CHANGE,
    lang,
  }
}

export const settingsShowTipsToggle = (value) => {
  return {
    type: SETTINGS_SHOW_TIPS_TOGGLE,
    value,
  }
}
