import { memo } from 'react'
import { SkeletonWrapper } from './index.style'

const SkeletonComponent = memo(
  ({ width, minWidth, height, variant = 'light' }) => {
    return (
      <SkeletonWrapper
        width={width}
        height={height}
        minWidth={minWidth}
        variant={variant}
      />
    )
  }
)

SkeletonComponent.displayName = 'SkeletonComponent'
export default SkeletonComponent
