export const tabs = (history) => [
  { title: 'search.search_history.all', disabled: false },
  {
    title: 'search.search_history.structures',
    disabled: !history?.some(
      (item) =>
        item?.query?.type === 'molecules_search' ||
        item?.query?.type === 'bingo_search'
    ),
  },
  // { title: 'search.search_history.reactions', disabled: true },
  {
    title: 'search.search_history.literature',
    disabled: !history?.some(
      (item) => item?.query?.type === 'full_text_search'
    ),
  },
]
