import {
  Back,
  BackImage,
  BackImageContainer,
  Contact,
  ContactContent,
  ContactIcon,
  Contacts,
  ContactsContainer,
  ContactsHeader,
  Container,
  Content,
  Description,
  Header,
  Info,
  Logo,
  Social,
  SocialIcon,
  Socials,
  TimerContainer,
  TimerInfo,
  Wrapper,
} from './index.style'
import logoImg from '../../assets/logo_en.svg'
import techImg from '../../assets/tech_works.svg'
import CountdownTimer from 'components/CountdownTimer'
import { useTranslation } from 'react-i18next'
import { contactsConfig, socialConfig } from './config'

const TechnicalWorks = ({ targetDate }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Container>
        <Content>
          <Logo src={logoImg} alt="logo" />
          <Info>
            <Header>{t('techWorks.header')}</Header>
            <Description>{t('techWorks.desc')}</Description>
          </Info>
          {targetDate > new Date() && (
            <TimerContainer>
              <TimerInfo>{t('techWorks.timerInfo')}</TimerInfo>
              <CountdownTimer targetDate={targetDate} />
            </TimerContainer>
          )}
          <ContactsContainer>
            <ContactsHeader>{t('techWorks.contactUs')}</ContactsHeader>
            <Contacts>
              {contactsConfig.map(({ icon, content, alt }, i) => (
                <Contact key={`contact-${i}`}>
                  <ContactIcon src={icon} alt={alt}></ContactIcon>
                  <ContactContent>{content}</ContactContent>
                </Contact>
              ))}
            </Contacts>
            <Socials>
              {socialConfig.map(({ icon, link, alt }, i) => (
                <Social
                  key={`social-${i}`}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon src={icon} alt={alt} />
                </Social>
              ))}
            </Socials>
          </ContactsContainer>
        </Content>
        <Back>
          <BackImageContainer>
            <BackImage src={techImg} alt={'technical works'} />
          </BackImageContainer>
        </Back>
      </Container>
    </Wrapper>
  )
}

export default TechnicalWorks
