import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'

import { Container } from './index.style'

const ActionButtons = ({ buttonsConfig, isDownloadMenuShow }) => {
  return (
    <Container>
      {buttonsConfig?.map(({ icon, text, disabled, onClick }, i) => {
        return (
          <CustomButton
            type="text"
            key={i}
            onClick={(e) => {
              e.stopPropagation()
              onClick(e)
            }}
            gap="4px"
            disabled={disabled}
            active={icon === 'upload' && isDownloadMenuShow}
          >
            <Icon iconType={icon} size='1rem' />
            {text}
          </CustomButton>
        )
      })}
    </Container>
  )
}

export default ActionButtons
