import { default as styled } from 'styled-components'

export const Header = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media print {
    width: max-content;
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 1.5rem;
  display: flex;
  gap: 16px;
`

export const ButtonContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`
export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
