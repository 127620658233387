import {
  GETME_FAIL,
  GETME_SUCCESS,
  SAVE_USER_TEMP_CREDS,
  CLEAR_USER_TEMP_CREDS,
  TOGGLE_USER_PROFILE,
} from '../constants/auth'
const initialState = {
  userdata: {},
  userCreds: { email: '', password: '' },
  isShowProfile: false,
}

const authReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GETME_SUCCESS:
      return { ...state, userdata: payload.userdata }
    case GETME_FAIL:
      return { ...state, userdata: {} }
    case SAVE_USER_TEMP_CREDS:
      return { ...state, userCreds: { ...payload.userCreds } }
    case CLEAR_USER_TEMP_CREDS:
      return { ...state, userCreds: { email: '', password: '' } }
    case TOGGLE_USER_PROFILE:
      return { ...state, isShowProfile: payload.isShowProfile }
    default:
      return state
  }
}

export default authReducer
