import styled from 'styled-components'

export const DataContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: ${({ isBestMatch }) => !isBestMatch && 'column'};
  padding: ${({ isBestMatch }) => !isBestMatch && '0px 1rem 1rem 1rem'};
  justify-content: ${({ isBestMatch }) => isBestMatch && 'space-between'};
  margin-top: ${({ isBestMatch }) => isBestMatch && 'auto'};
`

export const Info = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`

export const Params = styled.div`
  display: flex;
  gap: 8px;
`
export const DataElement = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #6e809d;
`
export const DataLabel = styled.div`
  text-transform: capitalize;
  align-self: flex-start;
`

export const Point = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`
