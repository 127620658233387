const AccSvg = ({ headColor, bodyColor}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Type=Account">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 18C4 15.2386 6.23858 13 9 13H15C17.7614 13 20 15.2386 20 18C20 19.6569 18.6569 21 17 21H7C5.34315 21 4 19.6569 4 18Z"
          fill={bodyColor}
        />
        <path
          id="Shape_2"
          d="M12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3Z"
          fill={headColor}
        />
      </g>
    </svg>
  )
}

export default AccSvg
