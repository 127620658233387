import styled from 'styled-components'

export const Row = styled.div`
& .info {
	white-space: nowrap;
}
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  svg {
    color: #ced6e3;
  }
  .info{
		&:hover {
    svg {
      color: #42c1bf;
    }
  }
`

export const Column = styled.div`
  display: flex;
  position: relative;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`

export const ApplicabilityBlock = styled.div`
  display: flex;
  flex-direction: column;
  top: 28px;
  right: 0px;
  gap: 16px;
  width: 423px;
  padding: 16px;
  border-radius: 12px;
  position: absolute;
  z-index: 5;
  background-color: #f3f5f8;
  box-shadow: 0 0 3px 0 rgba(129, 152, 186, 0.04),
    0 0 4px 0 rgba(129, 152, 186, 0.06), 0 0 10px 0 rgba(129, 152, 186, 0.14);
`
