import { memo, useEffect, useRef, useState } from 'react'
import { TopBluredBlock, BottomBluredBlock } from './index.style'
import Icon from 'components/Icon'

const CustomBtnsScroller = memo(
  ({ children, innerRef, extraTopStyles, extraBottomStyles }) => {
    const [canScrollUp, setCanScrollUp] = useState(false)
    const [canScrollDown, setCanScrollDown] = useState(true)
    const [isTopBlockEntered, setIsTopBlockEntered] = useState(false)
    const [isBottomBlockEntered, setIsBottomBlockEntered] = useState(false)

    const topBlockRef = useRef(null)
    const bottomBlockRef = useRef(null)

    useEffect(() => {
      let scrollDownInterval
      let scrollUpInterval

      const scrollDown = () => {
        if (innerRef.current) {
          innerRef.current.scrollTop += 1.8
          const canScroll =
            innerRef.current.scrollHeight -
              (innerRef.current.scrollTop + innerRef.current.clientHeight) >
            1
          if (!canScroll) {
            setCanScrollDown(false)
            clearInterval(scrollDownInterval)
            setIsBottomBlockEntered(false)
          }
        }
      }

      const scrollUp = () => {
        if (innerRef.current) {
          innerRef.current.scrollTop -= 1.2
          const canScroll = innerRef.current.scrollTop !== 0
          if (!canScroll) {
            setCanScrollUp(false)
            clearInterval(scrollUpInterval)
            setIsTopBlockEntered(false)
          }
        }
      }

      if (isBottomBlockEntered && canScrollDown) {
        scrollDownInterval = setInterval(scrollDown, 1)
      }
      if (isTopBlockEntered && canScrollUp) {
        scrollUpInterval = setInterval(scrollUp, 1)
      }

      const handleScroll = () => {
        if (innerRef.current) {
          setCanScrollUp(innerRef.current.scrollTop !== 0)
          setCanScrollDown(
            innerRef.current.scrollHeight -
              (innerRef.current.scrollTop + innerRef.current.clientHeight) >
              0.5
          )
        }
      }

      if (innerRef.current) {
        innerRef.current.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (innerRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          innerRef.current.removeEventListener('scroll', handleScroll)
        }

        clearInterval(scrollDownInterval)
        clearInterval(scrollUpInterval)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBottomBlockEntered, isTopBlockEntered, canScrollUp, canScrollDown])

    useEffect(() => {
      if (!innerRef.current) return
      const handleResize = () => {
        setCanScrollUp(innerRef.current.scrollTop !== 0)
        setCanScrollDown(
          innerRef.current.scrollHeight -
            (innerRef.current.scrollTop + innerRef.current.clientHeight) >
            0.5
        )
      }

      const observer = new MutationObserver(handleResize)

      observer.observe(innerRef.current, {
        childList: true,
        subtree: true,
      })
      handleResize()

      return () => {
        observer.disconnect()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        {canScrollUp && (
          <TopBluredBlock
            ref={topBlockRef}
            onMouseEnter={() => setIsTopBlockEntered(true)}
            onMouseLeave={() => setIsTopBlockEntered(false)}
            onClick={(e) => e.stopPropagation()}
            extraStyles={extraTopStyles}
          >
            <Icon iconType="arrowTop" size="1rem" />
          </TopBluredBlock>
        )}
        {children}
        {canScrollDown && (
          <BottomBluredBlock
            ref={bottomBlockRef}
            onMouseEnter={() => setIsBottomBlockEntered(true)}
            onMouseLeave={() => setIsBottomBlockEntered(false)}
            onClick={(e) => e.stopPropagation()}
            extraStyles={extraBottomStyles}
          >
            <Icon iconType="arrowDown" size="1rem" />
          </BottomBluredBlock>
        )}
      </>
    )
  }
)

CustomBtnsScroller.displayName = 'CustomBtnsScroller'
export default CustomBtnsScroller
