import ReactDOM from 'react-dom'
import './styles/_fonts.css'
import './i18n'
import App from './components/App'
import './styles/ketcher.css'
import './styles/customScrollbar.css'
import './styles/agGrid.css'

export const init = async () => {
  // eslint-disable-next-line no-undef
  SENTRY_ENABLE && (await import('./services/sentry'))

  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(<App />, document.getElementById('root'))
}

init()
