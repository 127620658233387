import styled from 'styled-components'

export const InputLabelContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  height: 1rem;
  width: fit-content;
  align-items: center;
  & > svg {
    transition: all 0.2s ease;
  }
  .icon-login-validating {
    color: ${({ theme }) => theme.colors.statuses.success};
  }
  .icon-login-validated {
    color: ${({ theme }) => theme.colors.statuses.success};
  }
  .icon-login-error {
    color: ${({ theme }) => theme.colors.statuses.error};
    cursor: pointer;
  }
`

export const CustomInputLabel = styled.label`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.text[11]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[12]};
`

export const CustomInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: ${({ width }) => width};
`
export const CustomInputField = styled.input`
  width: 100%;
  height: 2.25rem;
  appearance: none;
  padding: ${({ showClearButton, withBeforeLabel, padding }) => {
    if (padding) return padding
    if (withBeforeLabel) {
      return '0.625rem 0.75rem 0.625rem 0.25rem'
    }
    return showClearButton
      ? '0.625rem 2.75rem 0.625rem 0.75rem'
      : '0.625rem 0.75rem 0.625rem 0.75rem'
  }};
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[16]};
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
  padding-left: ${({ showSearchIcon }) => showSearchIcon && '8px'};
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
`

export const CustomInputClearButton = styled.div`
  background-color: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.buttons.secondary
      : theme.colors.buttons.primary};
  color: ${({ theme }) => theme.colors.icons.tertiary};
  border-radius: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  padding: ${({ clearBtnProps }) =>
    clearBtnProps?.padding ? clearBtnProps.padding : '0.375rem'};
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  transition: all 0.2s ease;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const CustomInputFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;

  & > svg {
    margin-left: 0.75rem;
    transition: all 0.3s;
    color: ${({ theme, focused }) =>
      focused ? theme.colors.icons.primary : theme.colors.icons.tertiary};
  }
`
export const TooltipContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`
export const RequirementsBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const ReqWithIcon = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${({ isValid, theme }) =>
    isValid ? theme.colors.icons.accentPrimary : theme.colors.statuses.error};
`

export const PseudoLabel = styled.div`
  diplay: flex;
  align-items: center;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 0.75rem;
  color: #2d3745;
`

export const CustomInputValue = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[16]};
  left: ${({ afterLabelPadding }) => afterLabelPadding};
`
