import axios from 'axios'

import { API_URL, API_V2_URL } from '../config/config'
import { readStorage } from '../utils/storage/storage'

const apiHost = API_URL
const apiV2Host = API_V2_URL

export const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  const token = readStorage('s_token')
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return headers
}

const rest = axios.create({
  baseURL: apiHost,
})
const restV2 = axios.create({
  baseURL: apiV2Host,
})

export const fetch = (endpoint, apiVersion = 1) =>
  (apiVersion === 2 ? restV2 : rest).get(endpoint, { headers: getHeaders() })

export const store = (endpoint, data, headers, options = {}) =>
  rest.post(endpoint, data, {
    headers: { ...getHeaders(), ...headers },
    ...options,
  })

export const storeV2 = (endpoint, data, headers, options = {}) =>
  restV2.post(endpoint, data, {
    headers: { ...getHeaders(), ...headers },
    ...options,
  })

export function update(endpoint, data) {
  return rest.put(endpoint, data, {
    headers: getHeaders(),
  })
}

export function destroy(endpoint, data) {
  return rest.delete(endpoint, {
    headers: getHeaders(),
    data,
  })
}

export default rest
