import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
} from 'store/constants/feedbackForm'

const initialState = {
  show: false,
}

const feedbackReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case OPEN_FEEDBACK_FORM:
      return {
        ...state,
        show: true,
      }
    case CLOSE_FEEDBACK_FORM:
      return {
        ...state,
        show: false,
      }

    default:
      return state
  }
}

export default feedbackReducer
