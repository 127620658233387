import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CustomToggle from 'components/common/customToggle'
import { toggleApplicability } from 'store/actions/molecule'
import { Subheadline } from 'components/common/text/index.style'
import ApplicabilityIndicator from 'components/applicability-indicator'

import { applicabilityTypes } from './config'
import { Row, Column, ApplicabilityBlock } from './index.style'

const Applicability = ({ applicability, toggleApplicability, isFullMode }) => {
  const [isApplicabBlockShow, setIsApplicabBlockShow] = useState(false)
  const { t } = useTranslation()

  return (
    <Row data-test="applicability" className={'hidden-for-print'}>
      <Column gap="16px">
        <CustomToggle
          withTooltip
          value={applicability}
          onClick={toggleApplicability}
          type={isFullMode ? 'white' : 'grey'}
          label={t('moleculeslist.models_applicability')}
          onMouseOver={() => setIsApplicabBlockShow(true)}
          onMouseLeave={() => setIsApplicabBlockShow(false)}
        />
        {isApplicabBlockShow && (
          <ApplicabilityBlock>
            <Subheadline>
              {t('moleculeslist.applicability_indicator_description')}
            </Subheadline>
            {applicabilityTypes.map(({ number, description }, ind) => (
              <Row key={ind} alignItems={ind === 1 ? 'flex-start' : 'center'}>
                <ApplicabilityIndicator {...{ number }} />
                <Subheadline>{t(`${description}`)}</Subheadline>
              </Row>
            ))}
          </ApplicabilityBlock>
        )}
      </Column>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    applicability: state.molecule.applicability.show,
  }
}

const mapDispatchToProps = {
  toggleApplicability,
}

export default connect(mapStateToProps, mapDispatchToProps)(Applicability)
