import { SHOW_CONFIRM, HIDE_CONFIRM } from '../constants/confirm'

const initialState = {
  show: false,
  text: null,
  onConfirm: null,
  description: null,
}

const confirmationReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SHOW_CONFIRM:
      return {
        ...state,
        show: true,
        text: payload.text,
        onConfirm: payload.onConfirm,
        description: payload.description,
      }

    case HIDE_CONFIRM:
      return initialState

    default:
      return state
  }
}

export default confirmationReducer
