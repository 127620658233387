import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
  margin: 0.75rem 0;
  width: 100%;
`

export const CommentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FeedbackLabel = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 20px;
`

export const CommentElement = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 150px 200px 1fr;
  background-color: ${({ handled }) => (handled ? '#fff;' : '#ddd;')};
  font-weight: ${({ handled }) => (handled ? '400;' : '600;')};
  padding: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ImageBlock = styled.img`
  max-width: 100%;
  z-index: 1;
  position: relative;
  user-select: none;
`
