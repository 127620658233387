import { useTranslation } from 'react-i18next'
import SmilesDrawer from 'smiles-drawer'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import ReactDom from 'react-dom'

import Icon from 'components/Icon'
import {
  Buttons,
  Container,
  Image,
  Smiles,
  SmilesScore,
  SmilesSvg,
  SmilesViewBar,
  SmilesViewWrapper,
  StyledSmilesView,
} from './index.style'
import { Subheadline } from 'components/common/text/index.style'
import { copyToClipboard } from 'utils/common/common'
import { addSimpleNotification } from 'store/actions/notifications'
import {
  confirmDeleteHighlights,
  editHighlightSmiles,
  getSmilesDrawerSize,
} from '../config/config'
import Ketcher from 'components/Ketcher'
import '../../../components/common/tooltip/style.css'
import CustomButton from 'components/common/customButton'
import { SMILES_DRAWER_OPTIONS } from 'config/constants'
import { setSelectedSmiles } from 'store/actions/pdf2smiles'

const SmilesViewer = ({ image, smiles, score, variant, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showKetcher, setShowKetcher] = useState(false)

  const copySmiles = () => {
    copyToClipboard(smiles)

    dispatch(addSimpleNotification('notification.smiles_copied'))
  }

  const buttonsConfig = [
    {
      icon: 'molecularEditor',
      text: 'pdf2smiles.viewer.edit',
      onClick: () => setShowKetcher(true),
    },
    {
      icon: 'add',
      text: 'pdf2smiles.viewer.to_dataset',
      onClick: () => {
        dispatch(setSelectedSmiles(smiles))
      },
    },
    {
      icon: 'deleted',
      text: 'pdf2smiles.viewer.delete',
      onClick: () => confirmDeleteHighlights([id]),
    },
  ]

  return (
    <Container variant={variant}>
      <SmilesViewWrapper>
        <SmilesView image={image} score={score} variant={variant} />
        <SmilesView smiles={smiles} variant={variant} />
      </SmilesViewWrapper>
      <Smiles>
        <CustomButton type="icon" onClick={copySmiles}>
          <Icon iconType="copy" size="1rem" />
        </CustomButton>
        <Subheadline fontWeight={500} name="smiles">
          {smiles}
        </Subheadline>
      </Smiles>
      <Buttons>
        {buttonsConfig?.map(({ icon, text, onClick }, i) => (
          <CustomButton
            key={i}
            type={'text'}
            gap={'0.25rem'}
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
          >
            <Icon iconType={icon} size={'1rem'} />
            {t(text)}
          </CustomButton>
        ))}
      </Buttons>
      {showKetcher &&
        ReactDom.createPortal(
          <Ketcher
            handleData={(sm) => editHighlightSmiles(id, sm)}
            closeKetcher={() => setShowKetcher(false)}
            smiles={smiles}
          />,
          document.body
        )}
    </Container>
  )
}

export default SmilesViewer

const SmilesView = ({ image, smiles, score, variant }) => {
  const { t } = useTranslation()
  const smilesRef = useRef(null)

  useEffect(() => {
    if (!smiles) return

    const smileDrawer = new SmilesDrawer.SvgDrawer({
      ...getSmilesDrawerSize(variant),
      ...SMILES_DRAWER_OPTIONS,
    })
    const element = smilesRef.current
    SmilesDrawer.parse(smiles, (tree) => smileDrawer.draw(tree, element))
  }, [smiles, variant])

  const roundedScore = Math.round(score * 1000) / 1000

  return (
    <StyledSmilesView variant={variant}>
      <SmilesViewBar>
        {score && (
          <SmilesScore fontWeight={500} score={roundedScore} variant={variant}>
            {t('pdf2smiles.viewer.score', {
              score: roundedScore,
            })}
          </SmilesScore>
        )}
      </SmilesViewBar>
      {image ? (
        <Image
          src={image}
          alt={t('pdf2smiles.viewer.smiles_in_pdf')}
          variant={variant}
        />
      ) : (
        <SmilesSvg ref={smilesRef} />
      )}
      <Icon
        iconType={image ? 'borderBox' : 'magic'}
        class={'smiles-type-icon'}
      />
    </StyledSmilesView>
  )
}
