import styled from 'styled-components'

import { SelectContainer } from 'components/CustomSelect/index.style'

export const Container = styled.div`
  gap: 0.75rem;
  display: flex;
  width: fit-content;
  ${SelectContainer} {
    height: unset;
    width: unset;
    background: ${({ theme }) => theme.colors.backgrounds.primary};
    min-width: 16.5rem;
    box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
      0px -4px 8px 0px rgba(31, 41, 51, 0.02),
      0px 4px 8px 0px rgba(31, 41, 51, 0.02);
  }
`
