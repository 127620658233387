import { connect } from 'react-redux'
import { Container, Content, SideBar, BackgroundBlur } from './index.style'
import SideBarView from '../SideBarView'
import RoutesList from '../../routers'
import ConfirmationDialog from '../ConfirmationDialog'
import MobileInDev from '../MobileInDev'
import Notifications from 'components/Notifications'
import { useAuth } from 'utils/auth/auth'

const ViewContainer = ({ showConfirmation, showBlur }) => {
  const { isAuthenticated } = useAuth()

  return (
    <>
      <Container>
        <SideBar>
          <SideBarView />
        </SideBar>
        <Content>
          <RoutesList />
        </Content>
      </Container>
      {showConfirmation && <ConfirmationDialog />}
      {showBlur && <BackgroundBlur />}
      <Notifications />
      <MobileInDev />
    </>
  )
}

const mapStateToProps = (state) => ({
  showConfirmation: state.confirmation.show,
  showBlur: state.blur.showBlur,
})

export default connect(mapStateToProps, null)(ViewContainer)
