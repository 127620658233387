import styled, { css } from 'styled-components'

import { Caption, TextBody } from 'components/common/text/index.style'

export const DocumentWrapper = styled.div`
  padding: 20px;
  width: 254px;
  height: 306px;
  border-radius: 12px 12px 12px 12px;
  background-color: #e6ebeb;
`

export const DocumentImage = styled.img`
  width: 214px;
  height: 100%;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px -4px 8px 0px rgba(31, 41, 51, 0.02),
    0px 4px 8px 0px rgba(31, 41, 51, 0.02);
`

export const DocumentCard = styled.div`
  padding: 20px;
  width: 254px;
  height: 180px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px -4px 16px 0px rgba(31, 41, 51, 0.04);
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
`

export const DocumentCardArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DocumentCardTopArea = styled(DocumentCardArea)`
  margin-bottom: 12px;
`

export const DocumentName = styled(TextBody)`
  transition: all 0.2s ease;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const DocumentTooltipName = styled.div`
  max-width: 500px;
`

export const DocumentStructureCount = styled(Caption)`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #f5f7f7;
`

export const DocumentMenuButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cbd2d9;
  transition: all 0.2s ease;

  &:hover {
    color: #42c1bf;
  }
`
export const DocumentCardBottomArea = styled(DocumentCardArea)`
  margin-top: auto;
`

export const DocumentArrow = styled.div`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: #616e7c;
  display: flex;
  visibility: ${({ menuOpened }) => (menuOpened ? 'visible' : 'hidden')};
  transition: all 0.2s ease;
`

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 12px 12px 12px 12px;
  height: fit-content;

  :hover {
    box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
      0px -4px 8px 0px rgba(31, 41, 51, 0.02),
      0px 4px 8px 0px rgba(31, 41, 51, 0.02);

    ${DocumentName}, ${DocumentArrow} {
      color: #42c1bf;
    }

    ${DocumentArrow} {
      visibility: visible;
    }
  }
`
