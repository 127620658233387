export const eco = ['acute aquatic toxicity']
export const drugs = [
  'oprea',
  'lipinski',
  'ghose',
  'veber',
  'pains',
  'drug-likeness',
  'topological polar surface area',
]
export const toxy = [
  'acute toxicity swallowed',
  'qsar',
  'selective target-organ or system toxicity single exposure',
]
export const synt = ['scscore syba', 'scscore comp']
