import {
  LOAD_BASKETS_INIT,
  LOAD_BASKETS_SUCCESS,
  LOAD_BASKETS_FAIL,
  LOAD_LAST_CHANGE_INIT,
  LOAD_LAST_CHANGE_SUCCESS,
  LOAD_LAST_CHANGE_FAIL,
  LOAD_MOLECULES_INIT,
  LOAD_MOLECULES_FAIL,
  LOAD_MOLECULES_SUCCESS,
  LOAD_MORE_MOLECULES_SUCCESS,
  LOAD_MORE_MOLECULES_INIT,
  JOIN_BASKETS_INIT,
  JOIN_BASKETS_FAIL,
  JOIN_BASKETS_SUCCESS,
  DELETE_MOLECULE_INIT,
  DELETE_MOLECULE_FAIL,
  DELETE_MOLECULE_SUCCESS,
  MOVE_MOLECULE_INIT,
  MOVE_MOLECULE_FAIL,
  MOVE_MOLECULE_SUCCESS,
  CREATE_MOLECULE_INIT,
  CREATE_MOLECULE_FAIL,
  CREATE_MOLECULE_SUCCESS,
  UPDATE_MOLECULE_INIT,
  UPDATE_MOLECULE_FAIL,
  UPDATE_MOLECULE_SUCCESS,
  ADD_BASKET_INIT,
  ADD_BASKET_FAIL,
  ADD_BASKET_SUCCESS,
  DELETE_BASKET_INIT,
  DELETE_BASKET_FAIL,
  DELETE_BASKET_SUCCESS,
  COPY_BASKET_INIT,
  COPY_BASKET_FAIL,
  COPY_BASKET_SUCCESS,
  UPDATE_BASKET_INIT,
  UPDATE_BASKET_FAIL,
  UPDATE_BASKET_SUCCESS,
  LOAD_TLIGHT_SUCCESS,
  CREATE_MOLECULES_PACK_SUCCESS,
  CREATE_MOLECULES_PACK_FAIL,
  CREATE_MOLECULES_PACK_INIT,
  SET_SEARCH,
  SET_SEARCH_STRING,
  CREATE_MOLECULES_FROM_FILE_INIT,
  CREATE_MOLECULES_FROM_FILE_FAIL,
  CREATE_MOLECULES_FROM_FILE_SUCCESS,
  RESET_SEARCH,
  SET_SEARCH_ERROR,
  SET_SEARCH_LOADING,
  SET_SEARCH_RESULT,
  SET_SEARCH_V2,
  SET_SEARCH_TRANSIT_LOADING,
  SET_SEARCH_TRANSIT_ERROR,
  SET_SEARCH_TRANSIT_DONE,
  SET_LIT_SEARCH,
  SET_LIT_SEARCH_RESULT,
  SET_LIT_SEARCH_LOADING,
  SET_LIT_SEARCH_ERROR,
  SET_BINGO_SEARCH,
  SET_BINGO_SEARCH_LOADING,
  SET_BINGO_SEARCH_ERROR,
  SET_BINGO_SEARCH_RESULT,
  SET_BINGO_SEARCH_PAGE_CACHE,
  SET_LIT_SEARCH_TASK_ID,
  RESET_LIT_SEARCH,
  SET_BINGO_SEARCH_TASK_ID,
  SET_BINGO_SEARCH_IS_LONG_TASK,
  SET_BINGO_SEARCH_CANCEL,
  RESET_MOLECULES,
  SET_BINGO_SEARCH_STATE,
  SET_SEARCHV2_STATE,
  SET_LIT_SEARCH_STATE,
  UPDATE_CRUD_STATE,
} from '../constants/crud'

const initialState = {
  baskets: [],
  openedBasketID: null,
  molecules: [],
  tlight: [],
  nextPage: null,
  public: true,
  error: false,
  search: [],
  lastChange: [],
  searchString: '',
  loading: false,
  lastChangeLoading: false,
  pending: false,
  searchV2: {
    ids: [],
    smiles: '',
    text: '',
    label: '',
    name: '',
    status: 'hold',
    total: 0,
    result: {
      exact_match: null,
      similar_results: [],
    },
  },
  bingoSearch: {
    status: 'hold',
    result: [],
    cache: {
      pageNumber: 0,
      pageItems: [],
    },
    taskId: '',
    isLongTask: false,
  },
  litSearch: {
    status: 'hold',
    result: {
      smiles: '',
      data: [],
    },
    taskId: '',
    totalLitFound: 0,
  },
}

const crudReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case LOAD_BASKETS_INIT:
      return { ...state, baskets: [], loading: true, error: false }
    case LOAD_BASKETS_SUCCESS:
      return { ...state, baskets: payload.data, loading: false, search: [] }
    case LOAD_BASKETS_FAIL: {
      return { ...state, baskets: [], error: payload.error, loading: false }
    }
    case LOAD_MORE_MOLECULES_INIT:
      return {
        ...state,
        nextPage: null,
        loading: true,
      }
    case LOAD_MOLECULES_INIT:
      return {
        ...state,
        molecules: [],
        tlight: [],
        loading: true,
        error: false,
        nextPage: null,
        pending: false,
      }
    case LOAD_MOLECULES_SUCCESS:
      return {
        ...state,
        molecules: payload.data,
        withSearch: payload.withSearch,
        loading: false,
        nextPage: payload.nextPage,
        public: payload.public,
        error: false,
        pending: payload.pending,
        openedBasketID: payload.openedBasketID,
      }
    case LOAD_MORE_MOLECULES_SUCCESS:
      return {
        ...state,
        molecules: [...payload.data],
        loading: false,
        nextPage: payload.nextPage,
        error: false,
      }
    case LOAD_MOLECULES_FAIL: {
      return {
        ...state,
        molecules: [],
        openedBasketID: null,
        error: payload.error,
        loading: false,
        nextPage: null,
        public: true,
      }
    }

    case RESET_MOLECULES: {
      return {
        ...state,
        molecules: [],
        openedBasketID: null,
        error: false,
        loading: false,
        nextPage: null,
        public: true,
      }
    }

    case LOAD_LAST_CHANGE_INIT:
      return { ...state, lastChangeLoading: true, error: false }
    case LOAD_LAST_CHANGE_FAIL:
      return { ...state, lastChangeLoading: false, error: payload.error }
    case LOAD_LAST_CHANGE_SUCCESS:
      return {
        ...state,
        lastChange: payload.data,
        lastChangeLoading: false,
        error: false,
      }
    case JOIN_BASKETS_INIT:
      return { ...state, loading: true, error: false }
    case JOIN_BASKETS_FAIL:
      return { ...state, loading: false, error: payload.error }
    case JOIN_BASKETS_SUCCESS:
      return { ...state, loading: false, error: false }
    case DELETE_MOLECULE_INIT:
      return { ...state, loading: true, error: false }
    case DELETE_MOLECULE_FAIL:
      return { ...state, loading: false, error: payload.error }
    case DELETE_MOLECULE_SUCCESS:
      return { ...state, loading: false, error: false }
    case COPY_BASKET_INIT:
      return { ...state, loading: true, error: false }
    case COPY_BASKET_FAIL:
      return { ...state, loading: false, error: payload.error }
    case COPY_BASKET_SUCCESS:
      return { ...state, loading: false, error: false }
    case MOVE_MOLECULE_INIT:
      return { ...state, loading: true, error: false }
    case MOVE_MOLECULE_FAIL:
      return { ...state, loading: false, error: payload.error }
    case MOVE_MOLECULE_SUCCESS:
      return { ...state, loading: false, error: false }
    case CREATE_MOLECULE_INIT:
      return { ...state, loading: true, error: false }
    case CREATE_MOLECULE_FAIL:
      return { ...state, loading: false, error: false }
    case CREATE_MOLECULE_SUCCESS:
      return { ...state, loading: false, error: false }
    case CREATE_MOLECULES_FROM_FILE_INIT:
      return { ...state, loading: true, error: false }
    case CREATE_MOLECULES_FROM_FILE_FAIL:
      return { ...state, loading: false, error: payload.error }
    case CREATE_MOLECULES_FROM_FILE_SUCCESS:
      return { ...state, loading: false, error: false }
    case CREATE_MOLECULES_PACK_INIT:
      return { ...state, loading: true, error: false }
    case CREATE_MOLECULES_PACK_FAIL:
      return { ...state, loading: false, error: payload.error }
    case CREATE_MOLECULES_PACK_SUCCESS:
      return { ...state, loading: false, error: false }
    case UPDATE_MOLECULE_INIT:
      return { ...state, loading: true, error: false }
    case UPDATE_MOLECULE_FAIL:
      return { ...state, loading: false, error: payload.error }
    case UPDATE_MOLECULE_SUCCESS:
      return { ...state, loading: false, error: false }
    case ADD_BASKET_INIT:
      return { ...state, loading: true, error: false }
    case ADD_BASKET_FAIL:
      return { ...state, loading: false, error: payload.error }
    case ADD_BASKET_SUCCESS:
      return { ...state, loading: false, error: false }
    case DELETE_BASKET_INIT:
      return { ...state, loading: true, error: false }
    case DELETE_BASKET_FAIL:
      return { ...state, loading: false, error: payload.error }
    case DELETE_BASKET_SUCCESS:
      return { ...state, loading: false, error: false }
    case UPDATE_BASKET_INIT:
      return { ...state, loading: true, error: false }
    case UPDATE_BASKET_FAIL:
      return { ...state, loading: false, error: payload.error }
    case UPDATE_BASKET_SUCCESS:
      return { ...state, loading: false, error: false }
    case LOAD_TLIGHT_SUCCESS:
      return {
        ...state,
        tlight: [...state.tlight, ...payload.tlight],
      }
    case UPDATE_CRUD_STATE:
      return {
        ...state,
        ...payload.state,
      }
    case SET_SEARCH:
      return {
        ...state,
        search: payload.ids,
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: payload.text,
      }
    case SET_SEARCH_V2: {
      return {
        ...state,
        searchV2: {
          ...state.searchV2,
          label: payload.label,
          name: payload.name,
        },
      }
    }
    case SET_BINGO_SEARCH: {
      return {
        ...state,
        bingoSearch: { ...state.bingoSearch, result: [] },
      }
    }
    case SET_BINGO_SEARCH_LOADING: {
      return {
        ...state,
        bingoSearch: { ...state.bingoSearch, status: 'loading' },
      }
    }
    case SET_BINGO_SEARCH_ERROR: {
      return {
        ...state,
        bingoSearch: {
          ...state.bingoSearch,
          status: 'error',
          isLongTask: false,
        },
      }
    }
    case SET_BINGO_SEARCH_RESULT: {
      return {
        ...state,
        bingoSearch: {
          ...state.bingoSearch,
          status: 'done',
          result: [...payload.result],
        },
      }
    }

    case SET_BINGO_SEARCH_TASK_ID: {
      return {
        ...state,
        bingoSearch: { ...state.bingoSearch, taskId: payload.taskId },
      }
    }

    case SET_BINGO_SEARCH_PAGE_CACHE: {
      return {
        ...state,
        bingoSearch: {
          ...state.bingoSearch,
          cache: {
            pageItems: [...payload.pageItems],
            pageNumber: payload.pageNumber,
          },
        },
      }
    }

    case SET_BINGO_SEARCH_STATE: {
      return {
        ...state,
        bingoSearch: payload.state,
      }
    }

    case SET_SEARCHV2_STATE: {
      return {
        ...state,
        searchV2: payload.state,
      }
    }

    case SET_LIT_SEARCH_STATE: {
      return {
        ...state,
        litSearch: payload.state,
      }
    }

    case RESET_SEARCH:
      return {
        ...state,
        searchV2: initialState.searchV2,
        bingoSearch: initialState.bingoSearch,
      }

    case SET_BINGO_SEARCH_IS_LONG_TASK: {
      return {
        ...state,
        bingoSearch: { ...state.bingoSearch, isLongTask: payload.isLongTask },
      }
    }

    case SET_BINGO_SEARCH_CANCEL: {
      return {
        ...state,
        bingoSearch: { ...state.bingoSearch, status: 'done', result: [] },
      }
    }

    case SET_SEARCH_ERROR:
      return {
        ...state,
        searchV2: { ...state.searchV2, status: 'error' },
      }
    case SET_SEARCH_LOADING:
      return {
        ...state,
        searchV2: { ...state.searchV2, status: 'loading' },
      }
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchV2: {
          ...state.searchV2,
          status: 'done',
          result: payload.result,
          total: payload.total,
        },
      }
    case SET_SEARCH_TRANSIT_LOADING:
      return {
        ...state,
        searchV2: { ...state.searchV2, status: 'transit_loading' },
      }
    case SET_SEARCH_TRANSIT_ERROR:
      return {
        ...state,
        searchV2: { ...state.searchV2, status: 'transit_error' },
      }
    case SET_SEARCH_TRANSIT_DONE:
      return {
        ...state,
        searchV2: { ...state.searchV2, status: 'transit_done' },
      }
    case SET_LIT_SEARCH:
      return {
        ...state,
        litSearch: { ...state.litSearch, result: { smiles: '', data: [] } },
      }
    case SET_LIT_SEARCH_RESULT:
      return {
        ...state,
        litSearch: {
          ...state.litSearch,
          status: 'done',
          result: {
            data: payload.result.result.data,
            smiles:
              payload.result.metadata.query.params?.structures_ids
                ?.smiles_list?.[0],
          },
          totalLitFound: payload.totalLitFound,
        },
      }
    case SET_LIT_SEARCH_LOADING:
      return {
        ...state,
        litSearch: {
          ...state.litSearch,
          status: 'loading',
        },
      }
    case SET_LIT_SEARCH_ERROR:
      return {
        ...state,
        litSearch: {
          ...state.litSearch,
          status: 'error',
          taskId: '',
        },
      }
    case SET_LIT_SEARCH_TASK_ID: {
      return {
        ...state,
        litSearch: { ...state.litSearch, taskId: payload.taskId },
      }
    }
    case RESET_LIT_SEARCH:
      return {
        ...state,
        litSearch: {
          status: 'hold',
          result: { smiles: '', data: [] },
          taskId: '',
          totalLitFound: 0,
        },
      }
    default:
      return state
  }
}

export default crudReducer
