import { v4 as uuidv4 } from 'uuid'
import {
  LOAD_BASKETS_INIT,
  LOAD_BASKETS_FAIL,
  LOAD_BASKETS_SUCCESS,
  LOAD_LAST_CHANGE_INIT,
  LOAD_LAST_CHANGE_SUCCESS,
  LOAD_LAST_CHANGE_FAIL,
  LOAD_MOLECULES_INIT,
  LOAD_MOLECULES_FAIL,
  LOAD_MOLECULES_SUCCESS,
  DELETE_MOLECULE_INIT,
  DELETE_MOLECULE_SUCCESS,
  DELETE_MOLECULE_FAIL,
  CREATE_MOLECULE_INIT,
  CREATE_MOLECULE_SUCCESS,
  CREATE_MOLECULE_FAIL,
  UPDATE_MOLECULE_INIT,
  UPDATE_MOLECULE_FAIL,
  UPDATE_MOLECULE_SUCCESS,
  ADD_BASKET_INIT,
  DELETE_BASKET_INIT,
  DELETE_BASKET_SUCCESS,
  DELETE_BASKET_FAIL,
  COPY_BASKET_INIT,
  COPY_BASKET_SUCCESS,
  COPY_BASKET_FAIL,
  JOIN_BASKETS_INIT,
  JOIN_BASKETS_SUCCESS,
  JOIN_BASKETS_FAIL,
  ADD_BASKET_SUCCESS,
  ADD_BASKET_FAIL,
  UPDATE_BASKET_INIT,
  UPDATE_BASKET_FAIL,
  UPDATE_BASKET_SUCCESS,
  LOAD_MORE_MOLECULES_SUCCESS,
  LOAD_MORE_MOLECULES_FAIL,
  LOAD_MORE_MOLECULES_INIT,
  MOVE_MOLECULE_INIT,
  MOVE_MOLECULE_FAIL,
  MOVE_MOLECULE_SUCCESS,
  LOAD_TLIGHT_INIT,
  LOAD_TLIGHT_SUCCESS,
  LOAD_TLIGHT_FAIL,
  CREATE_MOLECULES_PACK_SUCCESS,
  CREATE_MOLECULES_PACK_FAIL,
  CREATE_MOLECULES_PACK_INIT,
  CALC_TLIGHT_INIT,
  CALC_TLIGHT_FAIL,
  CREATE_MOLECULES_FROM_FILE_INIT,
  SET_SEARCH_ERROR,
  SET_SEARCH_LOADING,
  SET_SEARCH_V2,
  SET_SEARCH_RESULT,
  SET_SEARCH_TRANSIT_LOADING,
  SET_SEARCH_TRANSIT_ERROR,
  SET_SEARCH_TRANSIT_DONE,
  SET_LIT_SEARCH_LOADING,
  SET_LIT_SEARCH_RESULT,
  SET_LIT_SEARCH_ERROR,
  SET_LIT_SEARCH,
  SET_BINGO_SEARCH_LOADING,
  SET_BINGO_SEARCH_PAGE_CACHE,
  SET_BINGO_SEARCH_ERROR,
  SET_BINGO_SEARCH,
  SET_BINGO_SEARCH_RESULT,
  SET_BINGO_SEARCH_TASK_ID,
  SET_LIT_SEARCH_TASK_ID,
  SET_BINGO_SEARCH_IS_LONG_TASK,
  SHOW_SEARCH_V2_RESULT,
} from '../constants/crud'
import { SET_PAGINATION_CONFIG } from '../constants/search'
import { ADD_NOTIFICATION } from '../constants/notifications'
import { fetch, store, destroy, update, storeV2 } from '../../services/rest'

import {
  put,
  takeLatest,
  takeEvery,
  takeLeading,
  select,
  delay,
} from 'redux-saga/effects'
import {
  SET_FILTER_BUTTONS_ACTIVE,
  SET_FILTER_BUTTONS_DISABLED,
  SET_MARKUSH_MOLECULE_MAX_WEIGHT,
} from 'store/constants/filter'
import { CONFLICT_OPTIONS } from 'components/Search/LiteratureFilter/constants'
import { SET_TEXT } from '../constants/search'
import {
  SET_LIT_FILTER_CONFIG,
  SET_PREV_SEARCH_DATA_ID,
} from 'store/constants/literature'
import { t } from 'i18next'
import {
  checkMarkushFieldsError,
  countAddedMolItems,
  getBasketsQueryParams,
  getMarkushNotification,
  getSmilesBySuccessType,
} from './utils/utils'
import { BINGO_PAGINATION_LIMIT } from './utils/config'
import { setPrevSearchDataId } from 'store/actions/literature'
import {
  REMOVE_BASKET_IDS_FROM_DB_ERROR,
  REMOVE_BASKET_IDS_FROM_DB_INIT,
  REMOVE_BASKET_IDS_FROM_DB_SUCCESS,
} from 'store/constants/basket'
import { compareArrayValues } from 'pages/Baskets/helpers/helpers'

const MOVE_ERROR_MESSAGES = [
  'User is not source and target owner',
  'At least one of molecules already exists in target or does not exist in source',
]

function* loadBasket({ withPublic }) {
  try {
    let resp
    const sortingConfig = yield select((state) => state.basket.sorting)

    resp = yield fetch(
      `/baskets?${getBasketsQueryParams(sortingConfig, withPublic)}`
    )

    yield put({
      type: LOAD_BASKETS_SUCCESS,
      data: resp.data,
    })
  } catch (err) {
    yield put({
      type: LOAD_BASKETS_FAIL,
      error: err,
    })
  }
}

function* loadLastChange() {
  try {
    const resp = yield fetch('/last-change')

    yield put({
      type: LOAD_LAST_CHANGE_SUCCESS,
      data: resp.data,
    })
  } catch (err) {
    yield put({
      type: LOAD_LAST_CHANGE_FAIL,
      error: err,
    })
  }
}

function* loadMolecules({ basket, search }) {
  try {
    const req = { legacySearch: search, withPredict: true }
    const { data } = yield store(
      `/molecules/${isNaN(basket) ? 1 : basket}`,
      req
    )

    if (data) {
      const config = {
        total: data.count,
        pagesAmount: Math.ceil(data.count / 50),
        perPage: 50,
        activePage: 1,
      }

      yield put({
        type: SET_PAGINATION_CONFIG,
        config,
      })
    }

    const ids = data.data.map((e) => e.id)
    if (ids.length > 0) {
      yield put({
        type: LOAD_TLIGHT_INIT,
        ids,
      })
    }

    yield put({
      type: LOAD_MOLECULES_SUCCESS,
      openedBasketID: isNaN(basket) ? 1 : basket,
      data: data.data,
      nextPage: data.nextPage,
      public: data.public,
      withSearch: data.withSearch,
      pending: data.pending,
    })
  } catch (err) {
    yield put({
      type: LOAD_MOLECULES_FAIL,
      error: err,
    })
  }
}

function* calcTLight({ ids, basket }) {
  try {
    yield store('/predict_tlight', { ids, basket_id: basket })
    const notify = {
      id: uuidv4(),
      name: 'notification.calc_tlight_success',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
  } catch (e) {
    yield put({
      type: CALC_TLIGHT_FAIL,
      error: e,
    })
  }
}

function* loadMoreMolecules({ basket, limit, page }) {
  try {
    const req = { withPredict: true, limit, page }
    const { data } = yield store(`/molecules/${basket}`, req)

    const ids = data.data.map((e) => e.id)
    yield put({
      type: LOAD_TLIGHT_INIT,
      ids,
    })

    yield put({
      type: LOAD_MORE_MOLECULES_SUCCESS,
      data: data.data,
      nextPage: data.nextPage,
    })
  } catch (err) {
    put({
      type: LOAD_MORE_MOLECULES_FAIL,
      error: err,
    })
  }
}

function* loadTLight({ ids }) {
  try {
    let resp = yield store('/get_tlight', { ids })
    yield put({
      type: LOAD_TLIGHT_SUCCESS,
      tlight: resp.data,
    })
  } catch (e) {
    put({
      type: LOAD_TLIGHT_FAIL,
      error: e,
    })
  }
}

function* createMolecule({
  basket,
  smiles,
  shouldGoToBasket,
  label,
  t,
  isTargetEqualsSource,
  isSelected,
}) {
  try {
    if (basket === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      basket = resp.data.id
    }

    const res = yield store(`/molecules_pack`, {
      params: {
        basket,
        list: smiles,
      },
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : smiles.length > 1
        ? 'notification.molecules_not_added_to_basket'
        : 'notification.molecule_not_added_to_basket'
      const translationNameParams = {
        name: label || '',
      }

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            translationNameParams,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            translationNameParams,
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (!succeedCount.length)
          yield put({
            type: CREATE_MOLECULE_FAIL,
          })
      }
      if (succeedCount.length) {
        yield put({
          type: CREATE_MOLECULE_SUCCESS,
        })

        if (shouldGoToBasket) {
          yield put({
            type: LOAD_MOLECULES_INIT,
            basket,
          })

          yield put({
            type: LOAD_BASKETS_INIT,
            withPublic: true,
          })
        }

        if (!failedCount.length) {
          const id = uuidv4()
          const oneMolText = isSelected
            ? 'notification.selected_molecule_add'
            : 'notification.molecule_add'

          const manyMolsText = isSelected
            ? 'notification.selected_molecules_add'
            : 'notification.molecules_add'

          const notify = {
            id,
            name: succeedCount.length > 1 ? manyMolsText : oneMolText,
            translationNameParams,
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
    }
  } catch (err) {
    yield put({
      type: CREATE_MOLECULE_FAIL,
    })

    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      translationNameParams: {
        name: label || '',
      },
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      params: {
        type: 'createMolecule',
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected,
      },
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* createMoleculesFromFile({
  basket,
  file,
  smiles_target_col,
  delimiter,
}) {
  try {
    const headers = { 'Content-type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('basket', basket)
    smiles_target_col && formData.append('csv_smiles_header', smiles_target_col)
    delimiter && formData.append('delimiter', delimiter)

    yield store('/uploadFileToBasket', formData, headers)
    yield put({
      type: CREATE_MOLECULE_SUCCESS,
    })

    let isBasketReady = false
    while (!isBasketReady) {
      const tasks = yield fetch(`/userTasksStatus`)
      yield delay(2000)
      if (
        tasks.data.some(
          (el) =>
            el?.basket_id === basket &&
            !(el?.status === 'initiated' || el?.status === 'running')
        )
      )
        isBasketReady = true
    }
    if (isBasketReady) {
      const openedBasket = yield select((state) => state.crud.openedBasketID)
      if (openedBasket === basket)
        yield put({
          type: LOAD_MOLECULES_INIT,
          basket,
        })
    }
  } catch (e) {
    yield put({
      type: CREATE_MOLECULES_PACK_FAIL,
      error: e,
    })
  }
}

function* createMoleculesPack({ basket, list, t }) {
  try {
    const res = yield store(`/molecules_pack`, {
      params: {
        basket,
        list,
      },
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : 'notification.molecules_not_added_to_basket'

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            translationNameParams: {
              name: '',
            },
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            autoRemove: false,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (!succeedCount.length)
          yield put({
            type: CREATE_MOLECULE_FAIL,
          })
      }

      if (succeedCount.length) {
        yield put({
          type: CREATE_MOLECULES_PACK_SUCCESS,
        })
        yield put({
          type: LOAD_MOLECULES_INIT,
          basket,
        })
        if (!failedCount?.length) {
          const id = uuidv4()

          const notify = {
            id,
            name:
              succeedCount?.length > 1
                ? 'notification.molecules_add'
                : 'notification.molecule_add',

            translationNameParams: {
              name: '',
            },
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
    }
  } catch (err) {
    yield put({
      type: CREATE_MOLECULE_FAIL,
    })

    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      translationNameParams: {
        name: '',
      },
      params: {
        type: 'createMolecule',
        basket,
        list,
        t,
      },
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* deleteMolecule({ id, basket }) {
  try {
    yield destroy(`/molecules/${id}`, { basket })
    yield put({
      type: DELETE_MOLECULE_SUCCESS,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
    yield put({
      type: LOAD_BASKETS_INIT,
      withPublic: true,
    })
  } catch (err) {
    yield put({
      type: DELETE_MOLECULE_FAIL,
      error: err,
    })
  }
}

function* updateMolecule({ id, basket, smiles }) {
  try {
    yield update(`/molecules/${id}`, { smiles, basket })
    yield put({
      type: UPDATE_MOLECULE_SUCCESS,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
  } catch (err) {
    yield put({
      type: UPDATE_MOLECULE_FAIL,
      error: err,
    })
  }
}

function* addBasket({ name, desc }) {
  try {
    yield store('/baskets', { name, desc })
    yield put({ type: ADD_BASKET_SUCCESS })
  } catch (e) {
    console.log(e)
    yield put({ type: ADD_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true })
  }
}

function* deleteBasket({ list }) {
  try {
    const res = yield store(`/delete-baskets/`, { list })
    if (res && res?.status === 200) {
      yield put({ type: DELETE_BASKET_SUCCESS })
      const notify = {
        id: uuidv4(),
        name:
          list?.length > 1
            ? 'notification.datasets_delete_success'
            : 'notification.dataset_delete_success',
        notification_type: 'cancel',
        autoRemove: true,
        params: {
          actionType: 'baskets-delete',
          deleteIDs: list,
        },
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log(e)
    yield put({ type: DELETE_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true })
  }
}

function* removeBasketFromDB(data) {
  const ids = data?.ids || []
  const lastChange = yield fetch(`/last-change`)
  const lastChangeRemovedIDs = lastChange?.data?.[0]?.deleted_baskets || []

  if (Array.isArray(ids) && Array.isArray(lastChangeRemovedIDs)) {
    const isEqualIDs = compareArrayValues(ids, lastChangeRemovedIDs)
    if (isEqualIDs) {
      try {
        const res = yield store(`/completely-remove-baskets/`, {
          list: [...ids],
        })
        if (res.status === 200)
          yield put({ type: REMOVE_BASKET_IDS_FROM_DB_SUCCESS })
      } catch (e) {
        console.log(e)
        yield put({ type: REMOVE_BASKET_IDS_FROM_DB_ERROR })
      }
    }
  }
}

function* copyBasket(data) {
  if (!data) return
  const { basket_id, new_basket_name, new_basket_desc } = data?.data || {}
  try {
    const res = yield store(`/copy-basket/`, {
      baskets: [
        {
          basket_id,
          new_basket_name,
          new_basket_desc,
        },
      ],
    })
    const isSuccess = res?.data === 'Success' && res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_copy_success'
        : 'notification.dataset_copy_error',
      notification_type: isSuccess ? 'success' : 'error',
      autoRemove: true,
    }
    yield put({ type: COPY_BASKET_SUCCESS })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: COPY_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true })
  }
}

function* joinBaskets(data) {
  if (!data) return
  const { basket_ids, new_basket_name, new_basket_desc } = data?.data || {}

  try {
    const res = yield store('/baskets_join', {
      basket_ids,
      new_basket_name,
      new_basket_desc,
    })
    const isSuccess = res?.data === 'Success' && res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_merge_success'
        : 'notification.dataset_merge_error',
      notification_type: isSuccess ? 'cancel' : 'error',
      autoRemove: true,
      params: {
        actionType: 'baskets-join',
        deleteIDs: basket_ids,
      },
    }
    yield put({ type: JOIN_BASKETS_SUCCESS })
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: JOIN_BASKETS_FAIL })

    const notify = {
      id: uuidv4(),
      name: 'notification.dataset_merge_error',
      notification_type: 'error',
      autoRemove: true,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* updateBasket({ id, name, desc }) {
  try {
    yield update(`/baskets/${id}`, { name, desc })
    yield put({ type: UPDATE_BASKET_SUCCESS })
  } catch (e) {
    console.log(e)
    yield put({ type: UPDATE_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true })
  }
}

function* moveMolecules({
  target,
  source,
  molecules,
  label = 'Moved molecules',
  t,
}) {
  try {
    if (target === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      target = resp.data.id
    }
    const res = yield store('/molecules_move', {
      params: {
        target,
        source,
        molecules,
      },
    })
    if (Object.prototype.hasOwnProperty.call(res?.data?.result, 'is_success')) {
      const { error_message, is_success } = res.data.result || {}
      const hasError = is_success === false && !!error_message
      const text =
        !!error_message && MOVE_ERROR_MESSAGES.includes(error_message)
          ? `notification.${error_message}`
          : 'notification.unknown_error'
      const id = uuidv4()
      let notify
      if (hasError) {
        notify = {
          id: uuidv4(),
          name: 'notification.molecules_move_error',
          text,
          notification_type: 'error',
          autoRemove: false,
        }
      } else {
        notify = {
          id,
          name:
            molecules.length > 1
              ? 'notification.selected_molecules_moved'
              : 'notification.selected_molecule_moved',
          translationNameParams: { name: label || '' },
          notification_type: 'success',
          timeout: 5000,
          autoRemove: true,
          withActions: target === source ? false : true,
          params: {
            target,
          },
        }
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
      if (!hasError) {
        yield put({
          type: MOVE_MOLECULE_SUCCESS,
        })
      } else {
        yield put({ type: MOVE_MOLECULE_FAIL })
      }
    }
  } catch (e) {
    yield put({ type: MOVE_MOLECULE_FAIL })
  } finally {
    yield delay(1000)
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket: source,
    })
    yield put({
      type: LOAD_BASKETS_INIT,
      withPublic: true,
    })
  }
}
/*eslint-disable*/

export function* searchV2({ ids = [], smiles = '', text = '', basket = null }) {
  try {
    yield put({ type: SET_BINGO_SEARCH_TASK_ID, taskId: '' })
    yield put({ type: SET_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    const openedBasket = yield select((state) => state.crud.openedBasketID)
    const request = {
      searchV2: { ids, smiles, text },
      basket: openedBasket || basket || 1,
    }
    const { data } = yield storeV2('/molecule/molecules_search', request)
    const { exact_match, similar_results } = data.data

    const tlight_data = exact_match
      ? [
          {
            id: exact_match.id,
            params: {
              bio: exact_match?.tlight?.bio ?? 0,
              eco: exact_match?.tlight?.eco ?? 0,
              phys: exact_match?.tlight?.phys ?? 0,
              tox: exact_match?.tlight?.tox ?? 0,
            },
          },
          ...similar_results.map((el) => ({
            id: el.id,
            params: {
              bio: el?.tlight?.bio ?? 0,
              eco: el?.tlight?.eco ?? 0,
              phys: el?.tlight?.phys ?? 0,
              tox: el?.tlight?.tox ?? 0,
            },
          })),
        ]
      : similar_results.map((e) => e.id)

    if (tlight_data.length > 0) {
      yield put({
        type: LOAD_TLIGHT_SUCCESS,
        tlight: tlight_data,
      })
    }
    yield put({
      type: SET_SEARCH_RESULT,
      result: data.data,
      public: data.public,
      total: data.total,
    })

    const config = yield select((state) => state.filter.config)
    if (config?.match_type === 'exact match' && !exact_match) {
      const id = uuidv4()
      const notify = {
        id,
        name: t(
          similar_results.length > 0
            ? 'notification.we_couldnt_found_best_match'
            : 'notification.we_couldnt_find_desired_structure'
        ),
        text: t(
          similar_results.length > 0
            ? 'notification.showed_you_similar_results'
            : 'notification.check_your_spelling_or_enter_SMILES'
        ),
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_SEARCH_ERROR })
  } finally {
    yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
  }
}

export function* showSearchV2Result({ taskUuid }) {
  try {
    yield put({ type: SET_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })

    let status = 'initiated'
    while (status === 'initiated') {
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=0&limit=100`
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        const idList = searchData.data.result.data.map((e) => e.id)
        if (idList.length > 0) {
          yield put({
            type: LOAD_TLIGHT_INIT,
            ids: idList,
          })
        }

        yield put({
          type: SET_SEARCH_RESULT,
          result: searchData.data.result.data,
        })

        break
      }

      if (status === 'error') {
        yield put({
          type: SET_SEARCH_RESULT,
          result: [],
          public: true,
        })
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: SET_SEARCH_RESULT,
      result: [],
      public: true,
    })
  }
}

export function* bingoSearch({
  ids = [],
  smiles = '',
  text = '',
  task_uuid,
  offset = 0,
  limit = BINGO_PAGINATION_LIMIT,
  showPastResult = false,
}) {
  try {
    let taskUuid = task_uuid

    const config = yield select((state) => state.filter.config)
    const {
      similarity,
      match_type,
      molecular_weight,
      molecular_weight_default,
    } = config
    const isMarkush = match_type === 'markush structures'

    const weightDiff =
      Math.round(
        (Number(molecular_weight?.[1]) - Number(molecular_weight?.[0])) * 1000
      ) / 1000

    const isMarkushFieldsError =
      isMarkush &&
      checkMarkushFieldsError(
        molecular_weight,
        molecular_weight_default,
        weightDiff
      )

    yield put({ type: SET_BINGO_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    if (!taskUuid) {
      yield put({
        type: SET_BINGO_SEARCH_PAGE_CACHE,
        pageItems: [],
        pageNumber: 0,
      })
      yield put({ type: SET_BINGO_SEARCH_IS_LONG_TASK, isLongTask: true })

      const openedBasket = yield select((state) => state.crud.openedBasketID)
      const request = {
        searchV2: { ids, smiles, text },
        basket: openedBasket || 1,
      }
      let searchData

      try {
        const slicedString = text.slice(0, text.indexOf('|'))
        const isSubstructure = slicedString.split('').filter((el) => el === '*')

        if (
          isSubstructure.length > 2 ||
          (isMarkush && !text.includes('|')) ||
          isMarkushFieldsError
        ) {
          const { notificationName, translationNameParams } =
            getMarkushNotification(
              text,
              isSubstructure,
              isMarkush,
              molecular_weight,
              molecular_weight_default,
              weightDiff
            )
          const notify = {
            id: uuidv4(),
            name: notificationName,
            translationNameParams,
            notification_type: 'error',
            timeout: 5000,
            autoRemove: true,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
          if (!showPastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
          yield put({ type: SET_BINGO_SEARCH_ERROR })
          yield put({ type: SET_SEARCH_TRANSIT_ERROR })
          return
        }
        yield put({ type: SET_SEARCH_TRANSIT_LOADING })

        if (!isSubstructure.length) {
          const { data } = yield storeV2('/molecule/molecules_search', request)
          searchData = data.data
        }

        yield put({ type: SET_SEARCH_TRANSIT_DONE })
      } catch (e) {
        console.log(e)
        yield put({ type: SET_SEARCH_TRANSIT_ERROR })
        yield put({ type: SET_BINGO_SEARCH_RESULT, result: [] })
      }

      const foundSmiles = searchData?.exact_match
        ? searchData.exact_match.smiles
        : searchData?.similar_results?.[0]?.smiles

      if (foundSmiles || isMarkush) {
        if (!isMarkush) yield put({ type: SET_TEXT, text: foundSmiles })
        let body = {
          smiles: foundSmiles,
          method: 'sub',
        }
        if (match_type === 'similar structures') {
          body = {
            smiles: foundSmiles,
            method: 'sim',
            metric: 'Tanimoto',
            bottom: Number(similarity[0]),
            top: Number(similarity[1]),
          }
        }
        if (isMarkush) {
          const getTop = () => {
            if (Number(molecular_weight?.[0]) && !molecular_weight?.[1])
              return Number(molecular_weight?.[0]) + 100
            if (molecular_weight?.[1]) return Number(molecular_weight[1])
            return 100
          }

          body = {
            smiles: text,
            method: 'markush',
            bottom:
              molecular_weight && molecular_weight[0]
                ? Number(molecular_weight[0])
                : 0,
            top: getTop(),
          }
          yield put({
            type: SET_MARKUSH_MOLECULE_MAX_WEIGHT,
            weight: body.top.toString(),
          })
        }
        const { data } = yield store(`/bingo_search`, body)
        taskUuid = data.task_uuid
      } else {
        let body = {
          smiles: request.searchV2.text,
          method: 'sub',
        }
        const { data } = yield store(`/bingo_search`, body)
        taskUuid = data.task_uuid
      }

      yield put({
        type: SET_BINGO_SEARCH_TASK_ID,
        taskId: taskUuid,
      })
    }

    let status = 'initiated'
    while (status === 'initiated') {
      const bingoStatus = yield select((state) => state.crud.bingoSearch.status)
      if (bingoStatus === 'done') {
        yield put({ type: SET_BINGO_SEARCH_IS_LONG_TASK, isLongTask: false })
        if (!showPastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
        break
      }
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offset}&limit=${limit}`
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        const idList = searchData.data.result.data.map((e) => e.id)
        if (idList.length > 0) {
          yield put({
            type: LOAD_TLIGHT_INIT,
            ids: idList,
          })
        }
        yield put({
          type: SET_BINGO_SEARCH_RESULT,
          result: searchData.data.result.data,
        })
        if (!task_uuid) {
          yield put({
            type: SET_BINGO_SEARCH_IS_LONG_TASK,
            isLongTask: false,
          })

          const searchHistoryData = yield fetch(
            `/search_history?offset=0&limit=1`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / 50
              ),
              perPage: 50,
              activePage: 1,
            }

            yield put({
              type: SET_PAGINATION_CONFIG,
              config,
            })
          }
        }
        if (!showPastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
      }
      if (status === 'error') {
        if (showPastResult)
          yield put({
            type: SET_BINGO_SEARCH_RESULT,
            result: [],
          })
        else {
          yield put({ type: SET_BINGO_SEARCH_ERROR })
          yield put({ type: SET_SEARCH_TRANSIT_ERROR })
          yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
          const notify = {
            id: uuidv4(),
            name: 'notification.we_couldnt_find_desired_structure',
            text: 'notification.check_your_spelling_or_enter_SMILES',
            notification_type: 'warning',
            autoRemove: true,
            timeout: 5000,
          }
          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
      if (status === 'initiated' && showPastResult) {
        yield put({
          type: SET_BINGO_SEARCH_RESULT,
          result: [],
        })
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_BINGO_SEARCH_ERROR })
    yield put({ type: SET_SEARCH_TRANSIT_ERROR })
    yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
    const notify = {
      id: uuidv4(),
      name: 'notification.we_couldnt_find_desired_structure',
      text: 'notification.check_your_spelling_or_enter_SMILES',
      notification_type: 'warning',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* literatureSearch({
  task_uuid = '',
  offset = 0,
  limit = 20,
  showPastResult = false,
}) {
  try {
    if (showPastResult) yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    else yield put({ type: SET_FILTER_BUTTONS_ACTIVE })

    let taskUuid = task_uuid
    yield put({ type: SET_LIT_SEARCH_LOADING })

    if (!taskUuid) {
      const notify = {
        id: uuidv4(),
        name: 'notification.literature_search_started',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
      const text = yield select((state) => state.search.searchText)
      const rightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )

      const leftFilterConfig = yield select((state) => state.filter.config)

      const sortingConfig = yield select((state) => state.search.sorting)
      // get searchDataId. If get obj with smiles and id -  we use structures_id . If get null - we use title and abstract. If we have rightFilterConfig.name - skip `/get_structure_by_default_view` step
      let searchDataId = null

      try {
        if (!rightFilterConfig?.name && !!text.trim().length) {
          yield put({ type: SET_SEARCH_TRANSIT_LOADING })
          const reqText = rightFilterConfig?.name ? '' : text
          const { data } = yield store(`/get_structure_by_default_view`, {
            text: reqText,
          })
          if (data?.id) searchDataId = data.id
          yield put({ type: SET_SEARCH_TRANSIT_DONE })
        }
      } catch (e) {
        console.log(e)
        yield put({ type: SET_SEARCH_TRANSIT_ERROR })
      }

      // get filtered type for searching in conflict conditions
      let filteredType = {
        type: !leftFilterConfig?.document_type?.length
          ? ['patent', 'article']
          : [...leftFilterConfig.document_type],
      }
      // get ( structuresId ) or ( title and abstract if molecules_search not found synId && if first request )
      let structuresId = {}
      const prevsearchDataId = yield select(
        (state) => state.literature.prevSearchDataId
      )

      if (searchDataId) {
        const { title, abstract, ...rest } = rightFilterConfig || {}
        yield put({
          type: SET_LIT_FILTER_CONFIG,
          config: prevsearchDataId === null ? { ...rest } : rightFilterConfig,
        })

        structuresId = {
          structures_ids: {
            operator: 'must',
            exact: true,
            values: [searchDataId],
          },
        }
      }
      // clear prev filter config if new text given  //если в абстракт или тайтл введено не подменять
      let filteredTitleAbstract = {}
      if (searchDataId === null && !rightFilterConfig?.name) {
        const isPrevSearch =
          rightFilterConfig?.title?.values?.includes(text) &&
          rightFilterConfig?.abstract?.values?.includes(text)

        const getUpdatedData = () => {
          const newObj = {
            title: {
              operator: 'must',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.title?.values
                        ? [...rightFilterConfig.title.values]
                        : [text]),
                    ],
            },
            abstract: {
              operator: 'must',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.abstract?.values
                        ? [...rightFilterConfig.abstract.values]
                        : [text]),
                    ],
            },
          }

          return {
            ...(newObj.title.values?.[0]?.length
              ? { title: newObj?.title }
              : {}),
            ...(newObj.abstract.values?.[0]?.length
              ? { abstract: newObj.abstract }
              : {}),
          }
        }
        const newObj = getUpdatedData()
        //delete structures_ids if searchData === null
        const { structures_ids, ...restConfig } = rightFilterConfig

        try {
          yield put({
            type: SET_LIT_FILTER_CONFIG,
            config: {
              ...restConfig,
              ...newObj,
            },
          })
          filteredTitleAbstract = { ...newObj }
        } catch (e) {
          console.log(e)
        }
      }
      yield put({
        type: SET_PREV_SEARCH_DATA_ID,
        prevSearchDataId: searchDataId,
      })
      setPrevSearchDataId(searchDataId)
      // left filter has priority over right one. If author in left filter - overwriting the right value with the left one
      const filteredAuthor = () => {
        if (leftFilterConfig?.author) {
          return {
            authors: {
              operator: 'must',
              exact: false,
              values: [leftFilterConfig.author],
            },
          }
        }
        return {}
      }

      // left filter has priority over right one. Left one filters out the right one
      const filteredLanguage = () => {
        const isRightFilterLanguage =
          rightFilterConfig?.language?.values?.length
        const isLeftFilterLanguage = leftFilterConfig?.language?.length

        if (!isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator:
                leftFilterConfig.language?.length > 1 ? 'should' : 'must',
              exact: true,
              values: [...leftFilterConfig.language],
            },
          }
        }
        if (isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator: rightFilterConfig.language.operator,
              exact: true,
              values: [
                ...rightFilterConfig.language.values.filter((value) =>
                  leftFilterConfig.language.includes(value)
                ),
              ],
            },
          }
        }
        if (isRightFilterLanguage && !isLeftFilterLanguage) {
          return {
            language: {
              operator:
                rightFilterConfig.language.values?.length > 1
                  ? rightFilterConfig.language.operator
                  : 'must',
              exact: true,
              values: [...rightFilterConfig.language.values],
            },
          }
        }
        return {}
      }

      // update right filter config after change when add structures id above
      const newRightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )

      if (
        newRightFilterConfig?.published_date &&
        !Object.values(newRightFilterConfig.published_date).some((el) => el)
      ) {
        delete newRightFilterConfig.published_date
      }

      // only left filter
      const filteredPublishedDate = () => {
        const isLeftFilterDate = Object.values(
          leftFilterConfig?.published_date || []
        ).some((el) => el)

        if (!isLeftFilterDate) return {}

        const fromDate = leftFilterConfig?.published_date[0]
          ? {
              from_date: `${Number(leftFilterConfig?.published_date[0])}-01-01`,
            }
          : {}

        const toDate = leftFilterConfig?.published_date[1]
          ? {
              to_date: `${Number(leftFilterConfig?.published_date[1])}-12-31`,
            }
          : {}
        return { published_date: { ...fromDate, ...toDate } }
      }

      const sorting = () => {
        if (sortingConfig.type === 'relevance') return {}
        else
          return {
            sort: {
              key: sortingConfig.type,
              order_type: sortingConfig.direction,
            },
          }
      }
      const { title, abstract, ...rest } = newRightFilterConfig

      const keysToCheck = ['title', 'abstract'] //rm from final config keys with empty value
      for (const key of keysToCheck) {
        if (
          newRightFilterConfig.hasOwnProperty(key) &&
          newRightFilterConfig[key]['values'] &&
          newRightFilterConfig[key]['values']?.length === 1 &&
          newRightFilterConfig[key]['values'][0] === ''
        ) {
          delete newRightFilterConfig[key]
        }
      }
      const config = {
        ...newRightFilterConfig,
        ...filteredTitleAbstract,
        ...filteredType,
        ...structuresId,
        ...filteredAuthor(),
        ...filteredLanguage(),
        ...filteredPublishedDate(),
        ...sorting(),
      }

      // check conflcited types
      if (filteredType?.type?.length === 1) {
        const conflictCondition = CONFLICT_OPTIONS[filteredType.type[0]]
        const configValues = Object.keys(config)

        conflictCondition.map((condition) => {
          if (configValues.includes(condition)) {
            delete config[condition]
          }
        })
      }
      const { data } = yield store(`/full-text-search`, config)

      if (!data.task_uuid) throw new Error('error')
      taskUuid = data.task_uuid

      yield put({
        type: SET_LIT_SEARCH_TASK_ID,
        taskId: taskUuid,
      })
    }

    let status = 'initiated'
    while (status === 'initiated' || status === 'running') {
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offset}&limit=${limit}`
      )

      status = searchData.data.metadata.status
      if (status === 'ok') {
        yield put({
          type: SET_LIT_SEARCH_RESULT,
          result: searchData.data,
          totalLitFound: searchData.data.result.total,
        })
        if (!task_uuid) {
          const searchHistoryData = yield fetch(
            `/search_history?offset=0&limit=1`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / limit
              ),
              perPage: limit,
              activePage: 1,
            }

            yield put({
              type: SET_PAGINATION_CONFIG,
              config,
            })
          }

          const notify = {
            id: uuidv4(),
            name: 'notification.literature_search_finished',
            autoRemove: true,
            notification_type: 'success',
            timeout: 5000,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_LIT_SEARCH_ERROR })
  }
}

export function* loadTLightWatcher() {
  yield takeEvery(LOAD_TLIGHT_INIT, loadTLight)
}

export function* addBasketWatcher() {
  yield takeEvery(ADD_BASKET_INIT, addBasket)
}
export function* deleteBasketWatcher() {
  yield takeEvery(DELETE_BASKET_INIT, deleteBasket)
}
export function* copyBasketWatcher() {
  yield takeEvery(COPY_BASKET_INIT, copyBasket)
}
export function* joinBasketWatcher() {
  yield takeEvery(JOIN_BASKETS_INIT, joinBaskets)
}

export function* basketLoadWatcher() {
  yield takeLatest(LOAD_BASKETS_INIT, loadBasket)
}

export function* loadLastChangeWatcher() {
  yield takeLatest(LOAD_LAST_CHANGE_INIT, loadLastChange)
}

export function* moleculesLoadWatcher() {
  yield takeLatest(LOAD_MOLECULES_INIT, loadMolecules)
}

export function* moleculeCreateWatcher() {
  yield takeEvery(CREATE_MOLECULE_INIT, createMolecule)
}

export function* moleculeCreateFromFileWatcher() {
  yield takeEvery(CREATE_MOLECULES_FROM_FILE_INIT, createMoleculesFromFile)
}

export function* moleculeDeleteWatcher() {
  yield takeLatest(DELETE_MOLECULE_INIT, deleteMolecule)
}

export function* moleculeUpdateWatcher() {
  yield takeEvery(UPDATE_MOLECULE_INIT, updateMolecule)
}

export function* updateBasketWatcher() {
  yield takeEvery(UPDATE_BASKET_INIT, updateBasket)
}

export function* loadMoreMoleculesWatcher() {
  yield takeLeading(LOAD_MORE_MOLECULES_INIT, loadMoreMolecules)
}

export function* moveMoleculesWatcher() {
  yield takeEvery(MOVE_MOLECULE_INIT, moveMolecules)
}

export function* createMoleculesPackWatcher() {
  yield takeEvery(CREATE_MOLECULES_PACK_INIT, createMoleculesPack)
}

export function* calcTLightWatcher() {
  yield takeEvery(CALC_TLIGHT_INIT, calcTLight)
}

export function* searchV2Watcher() {
  yield takeEvery(SET_SEARCH_V2, searchV2)
}

export function* showSearchV2ResultWatcher() {
  yield takeEvery(SHOW_SEARCH_V2_RESULT, showSearchV2Result)
}

export function* bingoSearchWatcher() {
  yield takeEvery(SET_BINGO_SEARCH, bingoSearch)
}

export function* bingoMarkushSearchWatcher() {
  yield takeEvery(SET_BINGO_MARKUSH_SEARCH, bingoMarkushSearch)
}

export function* litSearchWatcher() {
  yield takeEvery(SET_LIT_SEARCH, literatureSearch)
}

export function* removeBasketFromDBWatcher() {
  yield takeEvery(REMOVE_BASKET_IDS_FROM_DB_INIT, removeBasketFromDB)
}
