import CustomButton from 'components/common/customButton'
import { Caption } from 'components/common/text/index.style'
import styled from 'styled-components'

const RELIABLE_SCORE = 0.995
const getSmilesViewSize = ({ variant }) => {
  if (variant === 'modal') return '160px'
  if (variant === 'medium') return '180px'
  return '156px'
}

const getContainerSpacing = ({ variant }) =>
  variant === 'modal' ? '12px' : '16px'

export const SmilesViewWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledSmilesView = styled.div`
  padding: ${({ variant }) => (variant === 'modal' ? '8px' : '0')};
  width: 50%;
  min-width: ${getSmilesViewSize};
  height: ${getSmilesViewSize};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(45, 55, 69, 0.08);
  box-shadow: 0px -4px 12px 0px rgba(45, 55, 69, 0.1);
  box-shadow: 0px 4px 12px 0px rgba(45, 55, 69, 0.1);
  > .smiles-type-icon {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    color: #42c1bf;
    margin-right: auto;
    left: 0.5rem;
    bottom: 0.5rem;
  }
`

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  padding: ${({ variant }) =>
    variant === 'modal' ? '1.5rem 0.5rem' : '2rem 0.5rem'};
`

export const SmilesViewBar = styled.div`
  display: flex;
  width: calc(100% - 16px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 8px;

  > svg {
    width: 20px;
    height: 20px;
    color: #42c1bf;
    margin-left: auto;
  }
`

export const SmilesScore = styled(Caption)`
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  color: ${({ score }) => (score < RELIABLE_SCORE ? '#F04438' : '#12B76A')};
  user-select: none;
  font-size: 11px;
  line-height: 0.75rem;
  border-radius: ${({ variant }) => (variant === 'modal' ? '8px' : '0')};
  background: ${({ variant, theme }) =>
    variant === 'modal' ? '#f5f7f7' : 'none'};
`

export const Smiles = styled.div`
  display: flex;
  gap: 4px;

  > svg {
    cursor: pointer;

    &:hover {
      color: #42c1bf;
    }
  }
`

export const Container = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  row-gap: 1rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  width: ${({ variant }) => (variant === 'modal' ? '352px' : 'auto')};
  background-color: ${({ variant, theme }) =>
    variant === 'modal'
      ? theme?.colors?.backgrounds?.contextMenu
      : theme?.colors?.backgrounds?.primary};
  ${SmilesViewWrapper} {
    gap: ${({ variant }) => (variant === 'modal' ? '8px' : '12px')};
  }
`

export const EditSmilesButton = styled(CustomButton)`
  background-color: #f3f5f8;
  color: #2d3745;
  padding: 10px;
  position: absolute;
  right: 0;
  bottom: 0;

  > svg {
    width: 16px;
    height: 16px;
  }

  &:active {
    background-color: #f3f5f8;
    color: #42c1bf;
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const SmilesSvg = styled.svg`
  width: 9rem;
  height: 9rem;
`
