import styled from 'styled-components'
import { ReactComponent as LoaderImg } from '../../assets/loader.svg'

export const SpectraWrapper = styled.div`
  padding: 1.5rem 1.5rem 0.75rem 0.75rem;
  width: 100%;
`

export const HeaderDescription = styled.div`
  max-width: 724px;
  margin-top: 0.5rem;

  color: ${({ theme }) => theme.colors.text.secondary};
`
export const InformationCard = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 24px;
  padding: 20px;

  background: white;
  border-radius: ${({ calculated }) =>
    calculated ? '12px 12px 0px 0px' : '12px'};

  @media (max-width: 1380px) {
    flex-direction: ${({ isSidebarCollapsed }) =>
      isSidebarCollapsed ? 'row' : 'column'};
    align-items: flex-start;
  }

  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ChartCard = styled(InformationCard)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 0px 0px 12px 12px;
`

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  flex: 1;
`

export const SpectralLabel = styled.div`
  margin-bottom: 8px;
`

export const MS = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;

  &
    div:not(.custom-scrollbar):not(.ScrollbarsCustom-Wrapper):not(
      .ScrollbarsCustom
    ):not(.ScrollbarsCustom-Track):not(.custom-scroll-wrapper):not(
      .ScrollbarsCustom-Scroller
    ):not(.ScrollbarsCustom-Content) {
    width: 120px;
  }
`

export const NMR = styled.div`
  margin-top: 20px;
`

export const Infrared = styled(NMR)``

export const Tabs = styled.div`
  display: flex;
  gap: 8px;
`

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ width }) => width ?? 'fit-content'};
  height: ${({ height }) => height ?? 'fit-content'};
  padding: 10px 12px;
  border-radius: 12px;
  background: ${({ active, theme }) =>
    active
      ? theme.colors.backgrounds.accent
      : theme.colors.backgrounds.secondary};

  color: ${({ active, theme }) =>
    active ? theme.colors.text.white : theme.colors.text.tertiary};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;
`

export const PseudoSelect = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  width: 120px;
  padding: 10px 16px;

  border-radius: 12px;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};

  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 8px;
`

export const MoleculeWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 18.875rem;
  position: relative;

  background: ${({ drawed }) => (drawed ? '#fff' : '#f5f7f7')};
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.colors.text.primary};

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  box-shadow: ${({ drawed }) =>
    drawed
      ? '0px 4px 12px 0px rgba(45, 55, 69, 0.04), 0px -4px 12px 0px rgba(45, 55, 69, 0.04), 0px 0px 4px 0px rgba(45, 55, 69, 0.02)'
      : ''};

  @media (max-width: 1180px) {
    margin-top: 24px;
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
  width: 48px;
  height: 48px;
  border-radius: 8px;

  background: white;

  & svg {
    color: inherit;
  }
`

export const Rect = styled.rect`
  fill: ${({ active }) => (active ? '#42c1bf' : '')};
`

export const TooltipData = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  flex-direction: column;
  gap: 6px;

  & div {
    display: flex;
    gap: 8px;
    height: 16px;
    justify-content: space-between;
    white-space: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    & > p:first-child {
      color: ${({ theme }) => theme.colors.text.secondary};
    }

    & > p:last-child {
      max-width: 114px;
    }
  }

  & > div:last-child {
    height: fit-content;

    & > p:last-child {
      word-wrap: break-word;
      text-align: end;
    }
  }
`

export const InfraTooltipData = styled(TooltipData)`
  gap: 4px;
  & div {
    font-weight: 400;

    & > p:last-child {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }

  & > div:last-child {
    height: fit-content;
    & > p:last-child {
      word-wrap: break-word;
      text-align: end;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`

export const Loader = styled(LoaderImg)`
  color: white;
`
export const ChartSvg = styled.svg`
  position: relative;
  touch-action: none;
  user-select: none;
`

export const TableWrapper = styled.div`
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;
  width: 288px;
  height: fit-content;
`

export const StyledTr = styled.tr`
  background: ${({ active, theme }) =>
    active
      ? theme.colors.backgrounds.tertiary
      : theme.colors.backgrounds.secondary};
  color: ${({ active, theme }) =>
    active ? theme.colors.text.accentPrimary : theme.colors.text.primary};
  text-align: center;
  transition: all 0.15s ease;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.backgrounds.tertiary};
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }

  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 8px 10px 12px;
  }
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px 12px 10px 8px;
  }

  td {
    padding: 10px 8px;
  }
`

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 280px;
  table-layout: fixed;

  td {
    text-align: center;
  }

  thead {
    cursor: default;
    tr {
      background: ${({ theme }) => theme.colors.backgrounds.primary};

      td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 10px 8px 10px 12px;
      }
      td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 10px 12px 10px 8px;
      }
      td {
        width: 90px;
        text-align: center;
        padding: 10px 8px;
      }
    }
  }
`

export const Number = styled.div`
  background: #ffffff;
  padding: 4px;

  position: absolute;
  top: -10px;
  z-index: 2000;
`

export const StyledImg = styled.img`
  margin-left: 48px;
  margin-top: -20px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const SwitcherWrapper = styled.div`
  margin-top: 0.75rem;
`
