import {
  SET_PAGINATION_CONFIG,
  RESET_PAGINATION,
  SET_SEARCH_TYPE,
  SET_TEXT,
  SET_DRAWN_SMILES,
  SET_SEARCH_SORTING_PARAM,
  SET_SORTING_CONFIG,
  RESET_SORTING_CONFIG,
  UPDATE_SEARCH_STATE,
} from '../constants/search'

export const setPaginationConfig = (config) => {
  return {
    type: SET_PAGINATION_CONFIG,
    config,
  }
}
export const resetPagination = () => {
  return {
    type: RESET_PAGINATION,
  }
}

export const setSearchType = (value) => {
  return {
    type: SET_SEARCH_TYPE,
    value,
  }
}

export const setSearchSortingParam = (key, value) => {
  return {
    type: SET_SEARCH_SORTING_PARAM,
    key,
    value,
  }
}

export const setSearchSortingConfig = (config) => {
  return {
    type: SET_SORTING_CONFIG,
    config,
  }
}

export const resetSearchSortingConfig = () => {
  return {
    type: RESET_SORTING_CONFIG,
  }
}

export const setSearchText = (text) => {
  return {
    type: SET_TEXT,
    text,
  }
}
export const setDrawnSmiles = (smiles) => {
  return {
    type: SET_DRAWN_SMILES,
    smiles,
  }
}

export const updateSearchState = (newState) => {
  return {
    type: UPDATE_SEARCH_STATE,
    newState,
  }
}
