import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f5f7f7;
  color: #000000;
  padding-top: 52px;
  padding-left: 100px;
  font-weight: 400;

  @media (max-width: 1023px) {
    padding-top: 0;
    padding-left: 0;
  }

  @media (min-width: 1920px) {
    padding-top: 180px;
    padding-left: 140px;
  }

  @media (min-width: 2560px) {
    padding-top: 220px;
    padding-left: 200px;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 110px;
  max-height: 100%;
  max-width: 100%;

  @media (max-width: 1439px) {
    gap: 30px;
  }

  @media (min-width: 1920px) {
    gap: 200px;
  }
`

export const Logo = styled.img`
  width: 124px;
  height: 24px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;

  @media (max-width: 1023px) {
    margin: 0 auto;
    padding: 2rem 0.5rem;
  }

  @media (min-width: 1920px) {
    gap: 64px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 30px;

  @media (min-width: 1920px) {
    margin-top: 0;
  }
`

export const Header = styled.div`
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 600;

  @media (max-width: 1439px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 22px;
`

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const TimerInfo = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
`

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (min-width: 1920px) {
    margin-bottom: 0;
  }
`
export const ContactsHeader = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 1rem;
`
export const Contacts = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 25px;

  @media (min-width: 1920px) {
    margin-bottom: 2rem;
  }
`
export const Contact = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const ContactIcon = styled.img`
  height: 20px;
`
export const ContactContent = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
`
export const Socials = styled.div`
  display: flex;
  gap: 1rem;
`

export const Social = styled.a`
  display: block;
`

export const SocialIcon = styled.img`
  height: 24px;
`

export const Back = styled.div`
  flex-grow: 1;
  margin-top: auto;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const BackImageContainer = styled.div`
  max-width: 651px;
  max-height: 464px;

  @media (max-width: 1439px) {
    max-width: 480px;
  }
`

export const BackImage = styled.img`
  width: 100%;
  height: 100%;
`
