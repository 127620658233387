import styled from 'styled-components'
import { Subheadline } from 'components/common/text/index.style'

export const Container = styled.div`
  width: 100%;
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems && alignItems};
`
export const DialogActions = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
`
export const DropzoneWrapper = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const ThumbnailsBlock = styled.div`
  gap: 0.5rem;
  display: flex;
  padding-bottom: 0.5rem;
  position:relative;

  &:after {
    right: 0;
    content: '';
    height: 6rem;
    width: 2.25rem;
    bottom: 8.5rem;
    position: absolute;
    background: linear-gradient(
      270deg,
      #fff 66.67%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

export const ThumbnailContainer = styled.div`
  gap: 0.25rem;
  display: flex;
  position: relative;
  flex-direction: column;
`

export const Thumbnail = styled.img`
  width: 8.25rem;
  height: 4.5rem;
  border-radius: 0.75rem;
`

export const FileInfo = styled.div`
  gap: 0.5rem;
  display: grid;
  width: 8.25rem;
  cursor: pointer;
  grid-template-columns: 1fr 1rem;

  div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    svg {
      transition: all 0.3s ease;
      color: ${({ theme }) => theme.colors.statuses.error};
    }
  }
`

export const OpenedMessage = styled.div`
  display: grid;
  padding: 0.75rem;
  row-gap: 0.75rem;
  background-color: lightblue;
`

export const DataNameBlock = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.75rem;
  justify-content: space-between;
`
export const SuccessText = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
export const AdditionalText = styled(Subheadline)`
  white-space: pre-line;
`
