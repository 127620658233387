export const getApplicabilityColors = (value) => {
  if (value === null)
    return {
      color: '#2E90FA',
      backgroundColor: 'rgba(46, 144, 250, 0.16)',
    }
  if (value <= 0.2)
    return {
      color: '#F04438',
      backgroundColor: 'rgba(240, 68, 56, 0.08)',
    }
  if (value <= 0.5)
    return {
      color: '#F79009',
      backgroundColor: 'rgba(247, 144, 9, 0.08)',
    }
  return {
    color: '#12B76A',
    backgroundColor: 'rgba(18, 183, 106, 0.08)',
  }
}
