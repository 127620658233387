import { v4 as uuidv4 } from 'uuid'
import { useState, useEffect } from 'react'
import { push } from 'connected-react-router'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import Promo from 'components/Promo'
import history from 'services/history'

import {
  Label,
  Caption,
  TextMedium,
  TitleTertiary,
} from 'components/common/text/index.style'
import { useAuth } from 'utils/auth/auth'
import { fetch } from 'services/rest'
import LoadingOverlay from 'components/LoadingOverlay'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { addNotification } from 'store/actions/notifications'
import { emailReg, validatePasswordLength } from 'utils/common/common'
import { clearUserTempCreds, setUserTempCreds } from 'store/actions/auth'
import {
  Wrapper,
  LoginBlock,
  SignUpText,
  WelcomeBlock,
  Descriptions,
  RegisterButton,
  LoginWithPromo,
  SignUpWithCopyRight,
  PasswordActionsBlock,
  TitleWithDescription,
} from './index.style'
import SidebarButton from 'components/SidebarButton'

const LoginPage = ({ push, handleRegister, path, match }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const tempUserCreds = useSelector((store) => store.auth?.userCreds)
  const { t } = useTranslation()

  const logoImage = useGetLogoImage()
  const { isAuthenticated, login, loading: authLoading } = useAuth()
  const [data, setData] = useState({
    login: tempUserCreds?.email ?? '',
    password: tempUserCreds?.password ?? '',
    showPassword: false,
  })
  const [activationError, setActivationError] = useState('')
  const [isActivated, setIsActivated] = useState(false)

  const [loading, setLoading] = useState(false)
  const [rememberData, setRememberData] = useState(false)

  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')

  const isLoginDisabled =
    !data.login || !data.password || !!emailError || !!passwordError

  const handleActivate = async (token) => {
    try {
      await fetch(`/profile/activate?token=${token}`)
      setIsActivated(true)
    } catch (e) {
      setActivationError(
        e?.response?.data || t('notification.wrong_activation')
      )
    }
  }

  useEffect(() => {
    const token = match?.params?.id
    if (token) {
      handleActivate(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  useEffect(() => {
    if (isAuthenticated) {
      const haasPrevPath =
        history?.location?.state?.prevPath &&
        history?.location?.state?.prevPath !== '/'
      haasPrevPath ? push(history.location.state.prevPath) : push('/search')
    }
  }, [isAuthenticated, push])

  const handleChange = (value, id) => {
    id === 'login' && !!emailError && setEmailError('')
    id === 'password' && !!passwordError && setPasswordError('')
    setData({ ...data, [id]: value })
  }

  const handleLogin = async () => {
    if (isLoginDisabled) return

    if (!emailReg.test(data.login)) {
      setEmailError('account.emailError')
      return
    }

    if (!validatePasswordLength(data.password)) {
      setPasswordError('account.passwordPlaceholder')
      return
    }

    try {
      setLoading(true)
      await login(data.login, data.password)
      setLoading(false)
      dispatch(clearUserTempCreds())
    } catch (e) {
      setLoading(false)

      if (!e.message.includes('400')) {
        const id = uuidv4()
        const errorToShow =
          e.message === 'Wrong email or password'
            ? 'account.wrongEmailOrPassword'
            : e.message

        const notify = {
          id,
          name: 'notification.error',
          text: errorToShow,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText: errorToShow === 'account.wrongEmailOrPassword',
        }

        dispatch(addNotification(notify))
      } else {
        setActivationError(e.message)
      }
    }
  }

  const checkEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  const handleRegisterClick = () => {
    if (path === '/login') {
      if (!!data.login || !!data.password) {
        dispatch(
          setUserTempCreds({ email: data.login, password: data.password })
        )
      }
      push('/register')
    } else {
      handleRegister()
    }
  }

  return (authLoading && !loading) || isAuthenticated ? (
    <LoadingOverlay />
  ) : (
    <Wrapper>
      <LoginWithPromo>
        <LoginBlock onClick={(e) => e.stopPropagation()} method="dialog">
          <img src={logoImage} alt="syntelly" />
          {!activationError && !isActivated && (
            <>
              <WelcomeBlock>
                <TitleTertiary
                  fontWeight={theme.fontWeight.semibold}
                  style={{ margin: 0 }}
                >
                  {t('account.welcome')}
                </TitleTertiary>
                <TextMedium
                  fontWeight={theme.fontWeight.light}
                  color={theme.colors.text.secondary}
                  style={{ margin: 0, display: 'block' }}
                >
                  {t('account.enter_your_data')}
                </TextMedium>
              </WelcomeBlock>

              <CustomInput
                id="login"
                label={t('account.email')}
                placeholder={t('account.emailPlaceholder')}
                withClearButton
                value={data.login}
                onKeyUp={checkEnter}
                onChange={(value) => handleChange(value, 'login')}
                type="email"
                autoComplete="email"
                error={emailError}
              />

              <>
                <CustomInput
                  id="password"
                  label={t('account.password')}
                  placeholder={t('account.password_placeholder')}
                  withClearButton
                  value={data.password}
                  onKeyUp={checkEnter}
                  onChange={(value) => handleChange(value, 'password')}
                  type="password"
                  autoComplete={'current-password'}
                  error={passwordError}
                />
              </>

              <PasswordActionsBlock>
                {/* <CheckboxWithTitle
                  value={rememberData}
                  onChange={() => setRememberData((prev) => !prev)}
                  title={t('account.remember_data')}
                /> */}

                <CustomButton
                  type="text"
                  onClick={() => push('/recovery')}
                  data-test="forgot-password-btn"
                >
                  {t('account.forgotPassword')}
                </CustomButton>
              </PasswordActionsBlock>

              <CustomButton
                width="100%"
                type="accent"
                onClick={handleLogin}
                disabled={isLoginDisabled}
                data-test="login-btn"
              >
                {loading ? (
                  <Icon size="1rem" iconType="loader" />
                ) : (
                  t('account.signIn')
                )}
              </CustomButton>
            </>
          )}

          {!!activationError && (
            <>
              <TitleWithDescription>
                <TitleTertiary>
                  {t('registration.activation_failed_title')}
                </TitleTertiary>
                <TextMedium
                  color={theme.colors.text.secondary}
                  fontWeight={theme.fontWeight.light}
                >
                  {t('registration.activation_failed_description')}
                </TextMedium>
              </TitleWithDescription>
            </>
          )}
          {isActivated && (
            <>
              <Icon iconType="success2" size="3.75rem" />
              <TitleWithDescription>
                <TitleTertiary>
                  {t('registration.registered_success_title')}
                </TitleTertiary>
                <Descriptions>
                  <TextMedium
                    color={theme.colors.text.secondary}
                    fontWeight={theme.fontWeight.light}
                  >
                    {t('registration.registered_success_description')}
                  </TextMedium>
                  <TextMedium
                    color={theme.colors.text.secondary}
                    fontWeight={theme.fontWeight.light}
                  >
                    {t('registration.registered_success_description_2')}
                  </TextMedium>
                </Descriptions>
              </TitleWithDescription>
              <CustomButton
                type="accent"
                width="100%"
                onClick={() => setIsActivated(false)}
                data-test="success-registration-btn"
              >
                {t('registration.innovation_here')}
              </CustomButton>
            </>
          )}

          <SignUpWithCopyRight>
            {!isActivated && (
              <SignUpText>
                <Label
                  style={{ margin: 0 }}
                  color={theme.colors.text.secondary}
                >
                  {' '}
                  {t('account.hasNoAccount')}
                </Label>
                <RegisterButton
                  type="text"
                  onClick={handleRegisterClick}
                  gap="0.25rem"
                  data-test="register-btn"
                >
                  {t('account.signUp')}
                  <Icon iconType="arrowRight" size="1rem" />
                </RegisterButton>
              </SignUpText>
            )}

            <SidebarButton />
            <Caption
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.tertiary}
            >
              {t('account.copyRight').toUpperCase()}
              {new Date().getFullYear()}
            </Caption>
          </SignUpWithCopyRight>
        </LoginBlock>
        <Promo />
      </LoginWithPromo>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    path: state.router.location.pathname,
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
