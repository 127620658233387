import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import Icon from 'components/Icon'
import { cancel } from 'store/actions/confirm'
import CustomButton from 'components/common/customButton'
import { Text, Buttons, Content, TextContent } from './index.style'

const ConfirmationDialog = () => {
  const dispatch = useDispatch()
  const confirmState = useSelector((store) => store.confirmation) || {}
  const { text, description, onConfirm } = confirmState
  const { t } = useTranslation()

  return (
    <CustomDialog
      gap="24px"
      width="fit-content"
      onClose={() => {}}
      closeByEsc={false}
      showCloseIcon={false}
      outboundClickEnabled={false}
    >
      <Content>
        <Icon iconType="warning" />
        <TextContent>
          {onConfirm && (
            <CustomDialogHeader>
              {t('confirmation.confirm_action')}
            </CustomDialogHeader>
          )}
          <TextContent gap="4px">
            <Text fontWeight="300" color="#6E809D">
              {text}
            </Text>
            {!!description && (
              <Text fontWeight="300" color="#6E809D">
                {description}
              </Text>
            )}
          </TextContent>
        </TextContent>
        <Buttons withConfirm={onConfirm}>
          {onConfirm && (
            <CustomButton type="accent" onClick={() => dispatch(cancel())}>
              {t('confirmation.no')}
            </CustomButton>
          )}
          <CustomButton
            type="secondary"
            onClick={() => {
              onConfirm && onConfirm()
              dispatch(cancel())
            }}
          >
            {onConfirm ? t('confirmation.yes') : 'OK'}
          </CustomButton>
        </Buttons>
      </Content>
    </CustomDialog>
  )
}

ConfirmationDialog.displayName = 'ConfirmationDialog'
export default ConfirmationDialog
