import { v4 as uuidv4 } from 'uuid'
import Latex from 'react-latex-next'
import { CustomDialog } from '../../common/customDialog'
import {
  CardWrapper,
  CardHeader,
  TypeRow,
  DialogCardAbstract,
  CardInfo,
  InfoRow,
  DialogCardFullTextBlock,
  FullTextSelectorBlock,
  DialogCardFullText,
} from '../index.style'
import {
  TextNumber,
  ButtonsBlock,
  TabsBlock,
  LoaderWrapper,
} from './index.style'
import {
  TextBody,
  TitleSecondary,
  Caption,
  Separator,
} from 'components/common/text/index.style'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { setOpenLitDialog } from 'store/actions/literature'
import { format } from 'date-fns'
import { languages, TABS_CONFIG } from '../config'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import {
  addNotification,
  addSimpleNotification,
} from '../../../store/actions/notifications'
import Carousel from '../carousel'
import { useEffect, useState } from 'react'
import { fetch } from 'services/rest'
import LoaderRestyling from 'components/LoaderRestyling'
import CustomTab from 'components/common/customTab'

const LiteratureDialog = ({ data }) => {
  const [carouselCounter, setCarouselCounter] = useState(0)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [docData, setDocData] = useState({})
  const [withCarousel, setWithCarousel] = useState(false)
  const [showFullText, setShowFullText] = useState(false)
  const handlePrevClick = () => {
    setCarouselCounter((prev) => prev - 1)
  }

  const handleNextClick = () => {
    const currentTabArrLength =
      docData[TABS_CONFIG[activeTabIndex].numberPointer].length

    if (carouselCounter + 3 < currentTabArrLength)
      setCarouselCounter((prev) => prev + 1)
  }

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const documentCode =
    data.type === 'patent' ? docData.patent_number : docData.doi

  useEffect(() => {
    const getDocData = async () => {
      try {
        const { data: fetchData } = await fetch(
          data.type === 'patent'
            ? `/patents/${data.id}?language=${data.language}`
            : `/articles/${data.id}`
        )
        setDocData(fetchData)
        setLoading(false)
        TABS_CONFIG.forEach((el) => {
          if (fetchData?.[el.numberPointer]?.length > 0) setWithCarousel(true)
        })
      } catch (e) {
        console.log(e)
        dispatch(setOpenLitDialog(false))
        const notify = {
          id: uuidv4(),
          name: 'literatureCard.error',
          text: e.message,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText: false,
        }
        dispatch(addNotification(notify))
      }
    }
    getDocData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenIndividualLitPage = () =>
    window.open(
      `/literature/${data.type}-${
        data.type === 'patent' ? data.language : ''
      }-${data.id}`
    )

  const {
    language,
    journal,
    publisher,
    company,
    authors,
    published_date,
    text,
  } = docData || {}

  return (
    <CustomDialog
      width={'950px'}
      onClose={() => dispatch(setOpenLitDialog(false))}
      maxHeight={'620px'}
      gap={'16px'}
      overflowY={'scroll'}
      height="none"
    >
      {loading ? (
        <LoaderWrapper>
          <LoaderRestyling />
        </LoaderWrapper>
      ) : (
        <CardWrapper padding="0px" gap="24px" isFullMode={true} isDialog={true}>
          <CardHeader>
            <TitleSecondary id={`literature-card-title-${data.id}`}>
              <Latex>
                {data?.title
                  ? String.raw({ raw: data?.title }).replaceAll(/\\/g, '\\')
                  : ''}
              </Latex>
            </TitleSecondary>
            <TypeRow>
              <TextBody fontWeight={600}>
                {docData.type === 'article'
                  ? t('literatureCard.doi')
                  : t('literatureCard.patentNumber')}
              </TextBody>
              <TextNumber fontWeight={600}>
                {data.type === 'article' ? (
                  <a
                    target="_blank"
                    href={`https://doi.org/${documentCode}`}
                    rel="noreferrer"
                  >
                    {documentCode}
                  </a>
                ) : (
                  documentCode
                )}
              </TextNumber>
              <Icon
                className="copy-name-icon"
                iconType="copy"
                size="0.875rem"
                onClick={() => {
                  navigator.clipboard.writeText(documentCode)
                  dispatch(
                    addSimpleNotification(
                      `notification.${
                        docData.type === 'article'
                          ? 'DOI_copied'
                          : 'patent_number_copied'
                      }`
                    )
                  )
                  navigator.clipboard.writeText(documentCode)
                }}
              />
            </TypeRow>
            <CardInfo marginTop="4px">
              {authors?.length && (
                <InfoRow display={'flex'}>
                  {t('literatureCard.authors')} {authors.join(', ')}
                </InfoRow>
              )}
              {company?.length && (
                <InfoRow display={'flex'}>{`${t('literatureCard.owners')}:
                ${company.join(', ')}
              `}</InfoRow>
              )}

              <InfoRow>
                {language && <Caption>{languages[language]}</Caption>}

                {journal && (
                  <>
                    {language && (
                      <Separator>
                        <Icon iconType="dotSeparator" size="1rem" />
                      </Separator>
                    )}
                    <Caption>{journal?.map((el) => el)}</Caption>
                  </>
                )}

                {publisher && (
                  <>
                    {(language || journal) && (
                      <Separator>
                        <Icon iconType="dotSeparator" size="1rem" />
                      </Separator>
                    )}
                    <Caption>{publisher}</Caption>
                  </>
                )}

                {!!published_date && (
                  <>
                    {(language || journal || publisher) && (
                      <Separator>
                        <Icon iconType="dotSeparator" size="1rem" />
                      </Separator>
                    )}
                    <Caption>
                      {format(new Date(published_date), 'dd.MM.yyyy')}
                    </Caption>
                  </>
                )}
              </InfoRow>
            </CardInfo>
            <ButtonsBlock>
              <CustomButton
                type="accent"
                height="36px"
                onClick={handleOpenIndividualLitPage}
              >
                {t('literatureCard.onIndividualPage')}
                <Icon iconType={'arrowRight'} size={'1rem'} />
              </CustomButton>
              <CustomButton type="secondary" height="36px" disabled>
                <Icon iconType="share" size="1rem" />
                {t('literatureCard.copyLink')}
              </CustomButton>
              <CustomButton
                type="secondary"
                height="36px"
                onClick={() => {
                  navigator.clipboard.writeText(documentCode)
                  dispatch(
                    addSimpleNotification(
                      `notification.${
                        docData.type === 'article'
                          ? 'DOI_copied'
                          : 'patent_number_copied'
                      }`
                    )
                  )

                  navigator.clipboard.writeText(documentCode)
                }}
              >
                <Icon iconType="copy" size="1rem" />
                {docData.type === 'article'
                  ? t('literatureCard.copyDOI')
                  : t('literatureCard.copyPatentNumber')}
              </CustomButton>
            </ButtonsBlock>
          </CardHeader>
          <DialogCardAbstract>
            <Latex>
              {data?.abstract
                ? String.raw({ raw: data?.abstract }).replaceAll(/\\/g, '\\')
                : ''}
            </Latex>
            {text && (
              <>
                <DialogCardFullTextBlock>
                  <span>{t('literatureCard.full_text')}</span>
                  <FullTextSelectorBlock
                    showFullText={showFullText}
                    onClick={() => setShowFullText(!showFullText)}
                  >
                    <p>{t('literatureCard.show')}</p>
                    <Icon
                      iconType={showFullText ? 'arrowTop' : 'arrowDown'}
                      size="1rem"
                    />
                  </FullTextSelectorBlock>
                </DialogCardFullTextBlock>
                {showFullText && (
                  <DialogCardFullText>{text}</DialogCardFullText>
                )}
              </>
            )}
          </DialogCardAbstract>
          {withCarousel && (
            <>
              <TabsBlock>
                <div>
                  {TABS_CONFIG.map((tab, index) => (
                    <CustomTab
                      key={uuidv4()}
                      isActive={index === activeTabIndex}
                      disabled={tab.isDisabled}
                      type="grey"
                    >
                      {t(tab.value)}{' '}
                      {docData[tab.numberPointer]?.length &&
                        `(${docData[tab.numberPointer].length})`}
                    </CustomTab>
                  ))}
                </div>
                <div>
                  <CustomButton
                    width="2.25rem"
                    type="secondary"
                    defaultPadding="0.625rem"
                    onClick={handlePrevClick}
                    disabled={carouselCounter === 0}
                  >
                    <Icon iconType="arrowLeft" size="1rem" />
                  </CustomButton>
                  <CustomButton
                    type="secondary"
                    width="2.25rem"
                    defaultPadding="0.625rem"
                    onClick={handleNextClick}
                    disabled={
                      carouselCounter + 3 ===
                        docData[TABS_CONFIG[activeTabIndex].numberPointer]
                          ?.length ||
                      (docData[TABS_CONFIG[activeTabIndex].numberPointer]
                        ?.length &&
                        docData[TABS_CONFIG[activeTabIndex].numberPointer]
                          .length < 4)
                    }
                  >
                    <Icon iconType="arrowRight" size="1rem" />
                  </CustomButton>
                </div>
              </TabsBlock>

              <Carousel
                isDialog
                litSearchSmiles={data.litSearchSmiles}
                carouselCounter={carouselCounter}
                structures={
                  data.litSearchSmiles
                    ? docData.structures.sort((a, b) =>
                        a.smiles === data.litSearchSmiles
                          ? -1
                          : b.smiles === data.litSearchSmiles
                          ? 1
                          : 0
                      )
                    : docData.structures
                }
              />
            </>
          )}
        </CardWrapper>
      )}
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.literature.dialog.config,
  }
}

export default connect(mapStateToProps)(LiteratureDialog)
