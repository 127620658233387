import styled from 'styled-components'

export const CustomButtonElement = styled.div`
  width: ${({ width }) => width};
  height: ${({ size, type, height }) => {
    if (height) return height
    if (type === 'text' || type === 'icon' || type === 'text_accent')
      return 'fit-content'

    if (size === 'standard') {
      return type === 'icon_primary' || type === 'icon_secondary'
        ? '1.25rem'
        : '2.25rem'
    }
    if (size === 'big') {
      return type === 'icon_primary' || type === 'icon_secondary'
        ? '1.75rem'
        : '2.75rem'
    }
    return 'auto'
  }};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  display: flex;
  gap: ${({ gap }) => gap};
  padding: ${({ size, type, defaultPadding }) => {
    if (defaultPadding) return defaultPadding
    if (type === 'text' || type === 'icon' || type === 'text_accent') return '0'
    if (size === 'standard') {
      return type === 'icon_primary' || type === 'icon_secondary'
        ? '.25rem'
        : '.625rem .75rem'
    }
    if (size === 'big') {
      return type === 'icon_primary' || type === 'icon_secondary'
        ? '.375rem'
        : '.75rem 1rem'
    }
  }};
  justify-content: center;
  align-items: center;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '0.75rem'};
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;

  font-weight: 400;
  user-select: none;

  font-size: ${({ size }) => {
    if (size === 'large') return '0.875rem'
    return '0.75rem'
  }};

  line-height: ${({ size }) => {
    if (size === 'large') return '1.25rem'
    return '1rem'
  }};

  color: ${({ type, disabled, theme, defaultColor }) => {
    if (defaultColor) return defaultColor
    if (type === 'primary' || type === 'secondary' || type === 'icon')
      return disabled ? theme.colors.text.tertiary : theme.colors.text.primary //primary, secondary
    if (type === 'accent')
      return disabled
        ? theme.colors.text.accentSecondary
        : theme.colors.text.white
    if (type === 'icon_primary' || type === 'icon_secondary')
      return disabled
        ? theme.colors.icons.quaternary
        : theme.colors.icons.tertiary
    if (type === 'text_accent')
      return disabled
        ? theme.colors.text.secondary
        : theme.colors.text.accentPrimary
    if (type === 'text') {
      return disabled ? theme.colors.text.tertiary : theme.colors.text.primary
    }
  }};

  background-color: ${({ type, disabled, theme }) => {
    if (type === 'primary' || type === 'icon_primary')
      return theme.colors.buttons.primary
    if (type === 'secondary' || type === 'icon_secondary')
      return theme.colors.buttons.secondary
    if (type === 'accent')
      return disabled
        ? theme.colors.buttons.accentDisabled
        : theme.colors.buttons.accent
    return 'transparent'
  }};

  &:hover {
    color: ${({ type, disabled, theme }) => {
      if (disabled) return
      if (type === 'text_accent') return theme.colors.text.accentHover
      if (type === 'accent') return theme.colors.text.white
      return theme.colors.icons.accentPrimary
    }};
    background-color: ${({ type, disabled, theme }) => {
      if (disabled) return
      if (type === 'accent') return theme.colors.buttons.accentHover
    }};
  }

  &:active {
    color: ${({ type, disabled, theme }) => {
      if (disabled) return
      if (type === 'primary' || type === 'secondary' || type === 'accent') {
        return theme.colors.text.white
      }
      return theme.colors.text.accentPrimary
    }};
    background-color: ${({ type, disabled, theme }) => {
      if (disabled) return
      if (type === 'primary' || type === 'secondary' || type === 'accent')
        return theme.colors.buttons.accent
    }};
  }
`
