import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetch } from 'services/rest'

import LoadingOverlay from 'components/LoadingOverlay'
import { TABS_CONFIG } from 'components/LiteratureCard/config'
import CustomScrollbar from 'components/CustomScrollbar'

import LeftPanel from './leftPanel'
import RightPanel from './rightPanel'
import { Container } from './index.style'
import AuthorsDialog from './authorsDialog'

const LiteraturePage = () => {
  const [loading, setLoading] = useState(true)
  const [docData, setDocData] = useState({})
  const [withCarousel, setWithCarousel] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [showFullText, setShowFullText] = useState(false)

  const { type_id } = useParams()

  useEffect(() => {
    const getDocData = async () => {
      setLoading(true)
      try {
        const params = type_id.split('-')
        const type = params[0]
        const { data: fetchData } = await fetch(
          type === 'patent'
            ? `/patents/${params[2]}?language=${params[1]}`
            : `/articles/${params[2]}`
        )
        setDocData(fetchData)
        setLoading(false)
        TABS_CONFIG.forEach((el) => {
          if (fetchData?.[el.numberPointer]?.length > 0) setWithCarousel(true)
        })
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }
    getDocData()
  }, [type_id])

  if (loading) return <LoadingOverlay />

  return (
    <>
      <Container id="literature-page-container">
        <CustomScrollbar
          className="margin-4-bottom margin--4-right"
          disableTrackYWidthCompensation={true}
        >
          <LeftPanel
            docData={docData}
            withCarousel={withCarousel}
            showFullText={showFullText}
            setShowFullText={setShowFullText}
          />
        </CustomScrollbar>
        <RightPanel docData={docData} setDialogOpen={setDialogOpen} />
      </Container>
      {dialogOpen && (
        <AuthorsDialog setDialogOpen={setDialogOpen} docData={docData} />
      )}
    </>
  )
}

export default LiteraturePage
