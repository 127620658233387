import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  max-height: 100%;
  overflow-y: hidden;

  @media print {
    max-height: none;
    flex-direction: column;
    min-width: 100vw;
    padding-left: 4px;
    padding-right: 4px;

    & .hidden-for-print {
      display: none;
    }
  }
`

export const Structure = styled.div`
  width: ${({ isFullMode }) => (isFullMode ? '33%' : 'max-content')};
  padding-top: ${({ isShowBackBtn, isFullMode }) =>
    isFullMode && !isShowBackBtn && '1.75rem'};
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media print {
    width: 100%;
  }
`

export const MoleculeViewerWrapper = styled.div`
  border-radius: 12px;
  border: ${({ isFullMode }) => (isFullMode ? 'none' : '1px solid #f1f1f1')};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  @media print {
    width: 100%;
    border: none;
  }
`

export const References = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media print {
    display: none;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`

export const WrapperContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${({ width }) => width && width};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  flex: 0.6;
  height: 100%;
  max-height: 100%;
  border-radius: 12px;

  @media print {
    width: 100%;
    max-width: none;
    max-height: none;
  }
`

export const PropertiesWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;

  @media print {
    width: 100%;
    max-width: none;
  }
`

export const Properties = styled.div`
  width: 100%;
  max-height: calc(100% - 40px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  @media print {
    max-height: none;
  }

  & > div {
    &:last-of-type {
      border-bottom: none;
    }
  }
`

export const Navigation = styled.div`
  height: max-content;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #42c1bf;
  width: 20%;
`

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & :hover {
    color: #42c1bf;
  }

  @media print {
    display: none;
  }
`

export const NavigationItemMarker = styled.div`
  width: 16px;
  min-width: 16px;
  height: 1px;
  background-color: #42c1bf;
  border-radius: 4px;

  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: opacity 0.8s;
`

export const NavigationItemName = styled.div`
  padding: 8px;
`

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  display: none;

  @media print {
    display: block;
    margin: 0 auto;
    height: 70px;
  }
`

export const Footer = styled.div`
  display: none;

  @media print {
    display: flex;
    width: 20%;
    justify-content: center;
    align-self: center;

    position: fixed;
    bottom: -2cm;
    text-align: center;

    color: #cbd2d9;
    font-size: 10px;
    line-height: 12px;
  }
`
