export const selectorPositionConfig = {
  structures: {
    1: 'left',
    3: 'center',
    6: 'right',
  },
  literature: {
    list: 'left',
    brick: 'center',
  },
  sortDirection: {
    desc: 'left',
    asc: 'center',
  },
}
