import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadMe } from '../../store/actions/auth'
import { useAuth } from '../../utils/auth/auth'

const Loader = ({ loadMe }) => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      loadMe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <div />
}

const mapDispatchToProps = {
  loadMe,
}

export default connect(null, mapDispatchToProps)(Loader)
