import axios from 'axios'
import { connect } from 'react-redux'
import { Editor } from 'ketcher-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withResizeDetector } from 'react-resize-detector'
import { StandaloneStructServiceProvider } from 'ketcher-standalone'

import 'ketcher-react/dist/index.css'
import { getHeaders } from 'services/rest'
import CustomButton from 'components/common/customButton'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { CustomDialog } from 'components/common/customDialog'
import { getMarkushMoleculeWeight } from 'store/actions/filter'
import { v4 as uuidv4 } from 'uuid'

import {
  KetcherBox,
  DialogActions,
  KetcherWrapper,
  KetcherDialogWrapper,
} from './index.style'
import { API_URL } from '../../config/config'
import { addNotification } from 'store/actions/notifications'

const structServiceProvider = new StandaloneStructServiceProvider()

const Ketcher = ({
  width,
  smiles,
  height,
  disabled,
  noDialog,
  handleData,
  showSmiles,
  closeKetcher,
  withCloseButton = false,
  isFilterDisabled,
  filterType,
  getMarkushMoleculeWeight,
  addNotification,
}) => {
  const smiles2molLink = `${API_URL}smiles2mol`

  const [isReady, setIsReady] = useState(false)
  const { t } = useTranslation()
  const { height: pageHeight } = useWindowDimensions()

  const handleInit = async (ketcher) => {
    global.ketcher = ketcher
    setIsReady(true)
  }

  useEffect(() => {
    if (smiles && isReady && !showSmiles) {
      afterKetcherLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smiles, isReady, showSmiles])

  const afterKetcherLoad = async () => {
    const mol = await convertSmilesToMolecule(smiles)
    if (mol && global.ketcher) {
      setTimeout(() => global.ketcher.setMolecule(mol))
    }
  }

  const convertSmilesToMolecule = async (smiles) => {
    try {
      const { data } = await axios.post(
        smiles2molLink,
        {
          smiles,
        },
        {
          headers: getHeaders(),
        }
      )
      return data
    } catch (err) {
      if (err?.message) {
        const isInvalidSmiles = err?.response?.data?.detail === 'Invalid smiles'
        const id = uuidv4()
        const notify = {
          id,
          name: 'notification.error',
          text: isInvalidSmiles
            ? 'spectra.error.not_valid_smiles'
            : err.message,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
        }
        addNotification(notify)
      }
    }
  }

  const closeWithSave = async () => {
    if (global.ketcher) {
      const newSmiles = await global.ketcher.getSmiles(
        !isFilterDisabled && filterType === 'markush structures'
      )
      handleData(newSmiles)
      if (filterType === 'markush structures')
        getMarkushMoleculeWeight(newSmiles)
      closeKetcher()
    }
  }

  return noDialog ? (
    <KetcherBox>
      <KetcherWrapper width={width} height={height}>
        <Editor
          structServiceProvider={structServiceProvider}
          errorHandler={(err) => console.log(err)}
          onInit={handleInit}
          buttons={{
            arom: { hidden: true },
            layout: { hidden: true },
            clean: { hidden: true },
            dearom: { hidden: true },
            cip: { hidden: true },
            check: { hidden: true },
            recognize: { hidden: true },
            analyse: { hidden: true },
            miew: { hidden: true },
            about: { hidden: true },
            help: { hidden: true },
          }}
        />
      </KetcherWrapper>
    </KetcherBox>
  ) : (
    <CustomDialog
      width="70vw"
      gap="1.25rem"
      position="fixed"
      minWidth="43.125rem"
      onClose={closeKetcher}
      withCloseButton={withCloseButton}
      withPortal={true}
      outBoundId="ketcher"
      outboundExceptions={[
        'ToolbarMultiToolItem-module_icon',
        'ToolbarMultiToolItem-module',
        'ToolbarMultiToolItem-module_portal',
        'ActionButton-module_button',
        'ToolbarMultiToolItem-module_portal',
        'DefaultMultiTool-module_default',
      ]}
    >
      <KetcherDialogWrapper height={pageHeight > 768 ? '37.5rem' : '25rem'}>
        <Editor
          structServiceProvider={structServiceProvider}
          errorHandler={(err) => console.log(err)}
          onInit={handleInit}
          buttons={{
            arom: { hidden: true },
            layout: { hidden: true },
            clean: { hidden: true },
            dearom: { hidden: true },
            cip: { hidden: true },
            check: { hidden: true },
            recognize: { hidden: true },
            analyse: { hidden: true },
            miew: { hidden: true },
            about: { hidden: true },
            help: { hidden: true },
          }}
        />
      </KetcherDialogWrapper>

      <DialogActions>
        {withCloseButton ? (
          <>
            <CustomButton
              width="100%"
              type="secondary"
              onClick={disabled ? () => {} : closeKetcher}
              disabled={disabled}
            >
              {t('ketcher.close')}
            </CustomButton>
            <CustomButton
              width="100%"
              type="accent"
              onClick={disabled ? () => {} : closeWithSave}
              disabled={disabled}
            >
              {t('ketcher.save')}
            </CustomButton>
          </>
        ) : (
          <CustomButton
            width="100%"
            type="accent"
            disabled={disabled}
            onClick={disabled ? () => {} : closeWithSave}
          >
            {t('ketcher.search')}
          </CustomButton>
        )}
      </DialogActions>
    </CustomDialog>
  )
}
const mapStateToProps = (state) => {
  return {
    filterType: state.filter.config.match_type,
    isFilterDisabled: state.filter.config.disabled,
  }
}

const mapDispatchToProps = {
  getMarkushMoleculeWeight,
  addNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withResizeDetector(Ketcher))
