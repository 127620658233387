import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  NullL10n,
} from 'pdfjs-dist/web/pdf_viewer'
import debounce from 'lodash.debounce'
import ReactDom from 'react-dom'
import { InView } from 'react-intersection-observer'
import { Provider, connect } from 'react-redux'
import store from 'store'

import { Container, PdfDocument, PdfPreview, PdfWrapper } from './index.style'
import {
  setCurrentPage,
  setIsDocumentHeaderCollapsed,
} from 'store/actions/pdf2smiles'

import {
  PDF_SCROLL_MARGIN,
  findOrCreateContainerLayer,
  parseHighlightIdFromHash,
  resetHash,
  scaledToViewport,
} from '../config/config'
import AreaHighlight from '../AreaHighlight'
import CanvasPreviewElement from './previewCanvas'

const PdfViewer = memo(
  ({
    pdfDocument,
    setCurrentPage,
    setIsDocumentHeaderCollapsed,
    pdf2Smiles,
  }) => {
    const { markup, currentPage, isDocumentHeaderCollapsed } = pdf2Smiles
    const [selectedPage, setSelectedPage] = useState(currentPage)
    const [canvasPreviewElements, setCanvasElements] = useState([])

    let viewer
    const eventBus = new EventBus()
    const linkService = new PDFLinkService({
      eventBus: eventBus,
      externalLinkTarget: 2,
    })
    const unsubscribeRef = useRef(null)
    const containerRef = useRef(null)

    const init = () => {
      viewer =
        viewer ||
        new PDFViewer({
          container: containerRef.current,
          eventBus: eventBus,
          textLayerMode: 2,
          removePageBorders: true,
          linkService: linkService,
          l10n: NullL10n,
          annotationMode: 0,
        })

      linkService.setDocument(pdfDocument)
      linkService.setViewer(viewer)
      viewer.setDocument(pdfDocument)

      window.PdfViewer = viewer
    }

    const onTextLayerRendered = () => {
      renderHighlights()
    }

    const groupHighlightsByPage = useCallback(() => {
      const pageNumbers = new Set(markup.map((h) => h.page_number))

      const groupedHighlights = {}
      for (const pageNumber of pageNumbers) {
        groupedHighlights[pageNumber] = groupedHighlights[pageNumber] || []
        for (const highlight of markup) {
          if (pageNumber === highlight.page_number) {
            groupedHighlights[pageNumber].push(highlight)
          }
        }
      }

      return groupedHighlights
    }, [markup])

    const findOrCreateHighlightLayer = useCallback(
      (page) => {
        const { div } = viewer?.getPageView(page) || {}

        if (!div) {
          return null
        }

        return findOrCreateContainerLayer(div, 'highlight-layer')
      },
      [viewer]
    )

    const scaledPositionToViewport = useCallback(
      (page_number, bbox) => {
        const viewport = viewer.getPageView(page_number).viewport

        return scaledToViewport(bbox, viewport)
      },
      [viewer]
    )
    const pdfPreview = document.getElementById('pdf-preview')
    const container = document.getElementById('pdfDocument')

    useEffect(() => {
      if (pdfPreview) {
        const elements = []
        for (let page = 1; page <= pdfDocument.numPages; page++) {
          elements.push(
            <CanvasPreviewElement
              id={`canvas-preview-item-${page}`}
              key={page}
              page={page}
              currentPage={currentPage}
              changePageHandler={scrollerFn}
              pdfDocument={pdfDocument}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          )
        }
        setCanvasElements(elements)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfPreview, selectedPage])

    const renderHighlights = useCallback(() => {
      const highlightsByPage = groupHighlightsByPage()

      for (
        let pageNumber = 0;
        pageNumber < pdfDocument.numPages;
        pageNumber++
      ) {
        const highlightLayer = findOrCreateHighlightLayer(pageNumber)

        if (highlightLayer) {
          ReactDom.render(
            <Provider store={store}>
              <InView>
                {({ inView, ref }) => (
                  <div ref={ref} className="highlight-inview-container">
                    {(highlightsByPage[String(pageNumber)] || []).map(
                      ({ bbox, page_number, id, ...highlight }, i) => {
                        const viewportHighlight = {
                          id,
                          ...highlight,
                          page_number,
                          bbox: scaledPositionToViewport(page_number, bbox),
                        }

                        return (
                          <Fragment key={i}>
                            {inView ? (
                              <AreaHighlight highlight={viewportHighlight} />
                            ) : null}
                          </Fragment>
                        )
                      }
                    )}
                  </div>
                )}
              </InView>
            </Provider>,
            highlightLayer
          )
        }
      }
    }, [
      findOrCreateHighlightLayer,
      groupHighlightsByPage,
      pdfDocument.numPages,
      scaledPositionToViewport,
    ])

    const handleScaleValue = () => {
      if (viewer) {
        viewer.currentScaleValue = 'page-width'
      }
    }

    const setDebouncedScaleValue = debounce(handleScaleValue, 500)

    const onDocumentReady = () => {
      handleScaleValue()
    }
    const scrollerFn = (page) => {
      window.PdfViewer?.scrollPageIntoView({
        pageNumber: page,
        scrollContainer: container,
      })
    }

    const handlePreviewScroll = (page) => {
      const id = `canvas-preview-item-${page}`
      const targetElement = document.getElementById(id)
      if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
      }
    }
    const onPageChanging = () => {
      setCurrentPage(viewer?.currentPageNumber)
      setSelectedPage(viewer?.currentPageNumber)
    }

    const onScrollDocument = (e) => {
      setIsDocumentHeaderCollapsed(!!e.target.scrollTop)
    }

    const attachRef = useCallback((ref) => {
      containerRef.current = ref
      if (typeof unsubscribeRef?.current === 'function')
        unsubscribeRef.current()

      if (ref) {
        const { ownerDocument: doc } = ref
        eventBus.on('textlayerrendered', onTextLayerRendered)
        eventBus.on('pagesinit', onDocumentReady)
        eventBus.on('pagechanging', onPageChanging, true)
        doc.defaultView?.addEventListener('resize', setDebouncedScaleValue)
        const resizeObserver = new ResizeObserver(setDebouncedScaleValue)
        resizeObserver.observe(ref)
        ref.addEventListener(
          'scrollend',
          ()=>handlePreviewScroll(viewer?.currentPageNumber)
        )
        ref.addEventListener('scroll', onScrollDocument)

        unsubscribeRef.current = () => {
          eventBus.off('pagesinit', onDocumentReady)
          eventBus.off('textlayerrendered', onTextLayerRendered)
          eventBus.off('pagechanging', onPageChanging, true)
          doc.defaultView?.removeEventListener('resize', setDebouncedScaleValue)
          if (resizeObserver) resizeObserver.disconnect()
          ref.removeEventListener(
            'scrollend',
            ()=>handlePreviewScroll(viewer?.currentPageNumber)
          )
          ref.removeEventListener('scroll', onScrollDocument)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onScroll = () => {
      resetHash()
      renderHighlights()

      viewer.container.removeEventListener('scroll', onScroll)
    }

    const scrollTo = (highlight) => {
      const { page_number, bbox } = highlight

      viewer.container.removeEventListener('scroll', onScroll)

      const pageViewport = viewer.getPageView(page_number).viewport

      viewer.scrollPageIntoView({
        pageNumber: page_number + 1,
        destArray: [
          null,
          { name: 'XYZ' },
          ...pageViewport.convertToPdfPoint(
            0,
            scaledToViewport(bbox, pageViewport).top - PDF_SCROLL_MARGIN
          ),
          0,
        ],
      })

      renderHighlights()

      setTimeout(() => {
        viewer.container.addEventListener('scroll', onScroll)
      }, 100)
    }

    const scrollToHighlightFromHash = () => {
      const id = parseHighlightIdFromHash()
      if (!id) return

      const highlight = markup.find((h) => h.id === id)
      if (!highlight) return

      scrollTo(highlight)
    }

    useEffect(() => {
      init()
      setIsDocumentHeaderCollapsed(false)
      window.addEventListener('hashchange', scrollToHighlightFromHash)
      return () => {
        if (typeof unsubscribeRef?.current === 'function')
          unsubscribeRef.current()
        window.removeEventListener('hashchange', scrollToHighlightFromHash)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      renderHighlights()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markup])

    return (
      <PdfWrapper>
        <PdfPreview
          isDocumentHeaderCollapsed={isDocumentHeaderCollapsed}
          id="pdf-preview"
        >
          {canvasPreviewElements}
        </PdfPreview>
        <Container>
          <PdfDocument
            ref={attachRef}
            onContextMenu={(e) => e.preventDefault()}
            id={'pdfDocument'}
          >
            <div className="pdfViewer" />
          </PdfDocument>
        </Container>
      </PdfWrapper>
    )
  }
)

PdfViewer.displayName = 'PdfViewer'

const mapStateToProps = (state) => {
  return {
    pdf2Smiles: state.pdf2Smiles,
  }
}

const mapDispatchToProps = {
  setCurrentPage,
  setIsDocumentHeaderCollapsed,
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer)
