import styled, { css, keyframes } from 'styled-components'

const activeHighlight = keyframes`
  0% {
    background-position: 10% 50%;
  }
  50% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 10% 50%;
  }
`

const animationStyle = css`
  animation: ${activeHighlight} 3s ease infinite;
`

export const Box = styled.div`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border-radius: 4px;
  background: rgba(66, 193, 191, 0.16);
  cursor: pointer;

  &:hover {
    background: rgba(66, 193, 191, 0.28);
    box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
      0px -4px 8px 0px rgba(31, 41, 51, 0.02),
      0px 4px 8px 0px rgba(31, 41, 51, 0.02);
  }

  &.isCurrent-true {
    ${animationStyle}
    background-image: linear-gradient(
      270deg,
      rgba(66, 193, 191, 0) 0%,
      rgba(66, 193, 191, 0.44) 48.44%,
      rgba(66, 193, 191, 0) 100%
    );
    border: 1px solid rgba(66, 193, 191, 1);
    transition: all 3s ease;
    background-size: 200% 100%;
  }
`
