import { v4 as uuidv4 } from 'uuid'
import { put, select, takeEvery } from 'redux-saga/effects'
import { t } from 'i18next'

import {
  PDF2SMILES_GET_DOCS_URL,
  PDF2SMILES_UPDATE_DOCUMENT_URL,
} from 'pages/Pdf2Smiles/config/config'
import {
  PDF2SMILES_DELETE_HIGHLIGHTS,
  PDF2SMILES_GET_DOCUMENT_ERROR,
  PDF2SMILES_GET_DOCUMENT_INIT,
  PDF2SMILES_GET_DOCUMENT_SUCCESS,
  PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID,
  PDF2SMILES_SET_SELECTED_HIGHLIGHTS,
  PDF2SMILES_UPDATE_HIGHLIGHT_SMILES,
  PDF2SMILES_UPDATE_MARKUP,
} from 'store/constants/pdf2smiles'
import { store } from 'services/rest'
import { ADD_NOTIFICATION } from 'store/constants/notifications'

function* getPdf2SmilesDocument({ id }) {
  let isError = false

  try {
    const res = yield store(PDF2SMILES_GET_DOCS_URL, {
      params: {
        filters: {
          ids: [id],
        },
      },
    })
    if (res?.data?.result?.items?.length === 1) {
      const { filename, file_url, markup } = res.data.result.items[0]
      yield put({
        type: PDF2SMILES_GET_DOCUMENT_SUCCESS,
        id,
        filename,
        file_url,
        markup,
      })
    } else {
      isError = true
    }
  } catch (e) {
    isError = true
  } finally {
    if (isError) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notifications.loading_error',
        notification_type: 'error',
        text: 'pdf2smiles.viewer.failed_to_load_document',
      }

      yield put({
        type: PDF2SMILES_GET_DOCUMENT_ERROR,
      })

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  }
}

function* deletePdf2SmilesHighlights({ ids, document_id }) {
  const pdf2Smiles = yield select((state) => state.pdf2Smiles)
  const { markup, currentHighlightId } = pdf2Smiles
  const filteredHighlights = markup.filter((h) => !ids.includes(h.id))
  try {
    yield store(PDF2SMILES_UPDATE_DOCUMENT_URL, {
      params: {
        document_id,
        markup: filteredHighlights,
      },
    })

    yield put({
      type: PDF2SMILES_UPDATE_MARKUP,
      markup: filteredHighlights,
    })

    if (ids.length > 1) {
      yield put({
        type: PDF2SMILES_SET_SELECTED_HIGHLIGHTS,
        selectedHighlights: [],
      })
    }

    if (ids.includes(currentHighlightId)) {
      yield put({
        type: PDF2SMILES_SET_CURRENT_HIGHLIGHT_ID,
        currentHighlightId: '',
      })
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text:
        ids.length === 1
          ? 'pdf2smiles.viewer.failed_to_delete_molecule'
          : 'pdf2smiles.viewer.failed_to_delete_molecules',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* editHighlightSmiles({ id, smiles, document_id }) {
  if (!smiles) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text: 'pdf2smiles.viewer.invalid_smiles',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
    return
  }

  const markup = yield select((state) => state.pdf2Smiles.markup)

  try {
    const newMarkup = markup.map((h) =>
      h.id === id
        ? {
            ...h,
            structure: {
              ...h.structure,
              smiles: [smiles, ...h.structure.smiles],
            },
          }
        : h
    )
    yield store(PDF2SMILES_UPDATE_DOCUMENT_URL, {
      params: {
        document_id,
        markup: newMarkup,
      },
    })

    yield put({
      type: PDF2SMILES_UPDATE_MARKUP,
      markup: newMarkup,
    })
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.error',
      notification_type: 'error',
      text: 'pdf2smiles.viewer.failed_to_edit_smiles',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* getPdf2SmilesDocumentWatcher() {
  yield takeEvery(PDF2SMILES_GET_DOCUMENT_INIT, getPdf2SmilesDocument)
}

export function* deleteHighlightsWatcher() {
  yield takeEvery(PDF2SMILES_DELETE_HIGHLIGHTS, deletePdf2SmilesHighlights)
}

export function* editSmilesWatcher() {
  yield takeEvery(PDF2SMILES_UPDATE_HIGHLIGHT_SMILES, editHighlightSmiles)
}

const watchers = [
  getPdf2SmilesDocumentWatcher(),
  deleteHighlightsWatcher(),
  editSmilesWatcher(),
]
export default watchers
