import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Icon from 'components/Icon'
import { BigHumbButton } from 'components/MoleculeViewer/index.style'
import { Buttons } from './index.style'

const IconButtons = ({ iconButtonsConfig, num }) => (
  <Buttons>
    {iconButtonsConfig?.map(
      ({ icon, isHidden, tooltipText, onClick, dataTest, ref }, i) => {
        return (
          !isHidden && (
            <React.Fragment key={i}>
              <BigHumbButton
                onClick={(e) => {
                  e.stopPropagation()
                  onClick(e)
                }}
                data-tooltip-id={`${icon}-${num}`}
                data-test={dataTest}
                ref={ref}
              >
                <Icon iconType={icon} size="1.25rem" />
              </BigHumbButton>
              {!!tooltipText && (
                <ReactTooltip
                  id={`${icon}-${num}`}
                  className="c-tooltip c-tooltip-base c-tooltip-molviewer"
                  classNameArrow="c-tooltip-arrow"
                  offset={18}
                  place="bottom"
                  positionStrategy="fixed"
                >
                  {tooltipText}
                </ReactTooltip>
              )}
            </React.Fragment>
          )
        )
      }
    )}
  </Buttons>
)
export default IconButtons
