import styled from 'styled-components'

export const NotificationsCount = styled.div`
  border-radius: 0.5rem;
  padding: 0.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 1.5rem;

  background: ${({ theme }) => theme.colors.buttons.primary};

  box-shadow: 0px 0px 4px 0px rgba(45, 55, 69, 0.02),
    0px -4px 12px 0px rgba(45, 55, 69, 0.04),
    0px 4px 12px 0px rgba(45, 55, 69, 0.04);
`
