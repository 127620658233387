export const CONTEXT_MENU_OFFSET_RIGHT = 300
export const CONTEXT_MENU_ITEM_HEIGHT = 32

export const ContextMenuConfig = {
  moleculeMenu: (item) => [
    ...(item?.isSynMap ? [{ action: 'molecule_information' }] : []),
    { action: 'copy_smiles' },
    ...(item?.isNotShowSomeLinks ? [] : [{ action: 'download_mol' }]),
    ...(item?.isNotShowSomeLinks && !item.isReactions
      ? []
      : [{ action: 'transfer_to' }]),
    ...(item?.isNotShowSomeLinks ? [] : [{ action: 'copy_link' }]),
    ...(!item?.isNotShowSomeLinks || item?.isReactions
      ? []
      : [{ action: 'open_individual' }]),
    { action: 'download_png' },
    ...(!(item?.smiles && item?.isNotShowSomeLinks)
      ? []
      : [{ action: 'add_to_dataset' }]),
  ],
  moleculeDownloadMenu: (item) => [
    { action: 'download_png' },
    { action: 'download_pdf' },
    { action: 'download_mol' },
  ],
  goToSectionMenu: () => [
    { action: 'go_molecular_editor' },
    { action: 'go_synthesis' },
    { action: 'go_retrosynthesis' },
    { action: 'go_nmr_spectra' },
    { action: 'go_mass_spectra' },
    { action: 'go_ir_spectra' },
    { action: 'go_synthesis_cost' },
  ],
  languageMenu: () => [{ action: 'switch_ru' }, { action: 'switch_en' }],
  profileMenu: ({ isAuthenticated, language }) => [
    ...(isAuthenticated ? [{ action: 'user_profile' }] : []),
    ...(isAuthenticated ? [{ action: 'exit_app' }] : []),
    { action: 'divider' }, //разделительная полоса
    { action: 'about_us' },
    { action: 'user_guide' },
    ...(language === 'en' ? [{ action: 'open_telegram' }] : []),
  ],
  pdf2smilesMenu: () => [{ action: 'edit_name' }, { action: 'delete_pdf' }],
  dataSetMenu: (isSelected) => [
    ...(isSelected
      ? [{ action: 'deselect_dataset' }]
      : [{ action: 'select_dataset' }]),
    { action: 'edit_dataset' },
    { action: 'view_log' },
    { action: 'delete_dataset' },
  ],
  literatureCardMenu: (dataType) => [
    ...(dataType === 'article' ? [{ action: 'copy_doi_number' }] : []),
    ...(dataType !== 'article' ? [{ action: 'copy_patent_number' }] : []),
    { action: 'copy_literature_link' },
  ],
  literatureFilterMenu: (config) => {
    const { documentTypes, rightConfig } = config
    const isCommon =
      !documentTypes.length ||
      (documentTypes.includes('patent') && documentTypes.includes('article'))
    const isArticle =
      documentTypes.length === 1 && documentTypes.includes('article')
    const isPatent =
      documentTypes.length === 1 && documentTypes.includes('patent')

    const getAction = (filterKey, actionKey) => {
      return !rightConfig.includes(filterKey) ? [{ action: actionKey }] : []
    }

    return [
      ...getAction('title', 'add_condition_title'),
      ...getAction('abstract', 'add_condition_abstract'),
      ...getAction('authors', 'add_condition_authors'),
      ...(isCommon || isArticle ? [{ action: 'add_condition_doi' }] : []),
      ...(isCommon || isPatent
        ? [{ action: 'add_condition_patent_number' }]
        : []),

      //   { action: 'add_condition_language' },
      //   { action: 'add_condition_published_date' },
      ...(!documentTypes.length || documentTypes.includes('article')
        ? [
            ...getAction('journal', 'add_condition_journal'),
            ...getAction('publisher', 'add_condition_publisher'),
          ]
        : []),
      ...(!documentTypes.length || documentTypes.includes('patent')
        ? [
            ...getAction('company', 'add_condition_company'),
            ...getAction('applicant', 'add_condition_applicant'),
            ...getAction('text', 'add_condition_text'),
            ...getAction('claims', 'add_condition_claims'),
          ]
        : []),
    ]
  },
  highlightsSidebarMenu: () => [
    { action: 'highlights_download_csv' },
    { action: 'highlights_download_png' },
  ],
  synthCostMenu: () => [
    { action: 'download_schemes_excel' },
    { action: 'download_schemes_pdf' },
    { action: 'download_schemes_csv' },
  ],
  generatorParamsMenu: () => [
    { action: 'add_qed' },
    { action: 'add_cats' },
    { action: 'add_boiling_point' },
    { action: 'add_melting_point' },
    { action: 'add_mouse_oral_ld50' },
    { action: 'add_logp' },
    { action: 'add_logs' },
    { action: 'add_dmso_solubility' },
    { action: 'add_complexity' },
    { action: 'add_syba' },
    { action: 'add_brutto' },
  ],
}

export const ContextMenuItemsConfig = {
  molecule_information: {
    titleKey: 'molecule_viewer.molecule_information',
    id: 'molecule_information',
  },
  copy_smiles: {
    titleKey: 'molecule_viewer.copy_smiles',
    id: 'copy_smiles',
  },
  download_mol: {
    titleKey: 'molecule_viewer.download_mol',
    id: 'download_mol',
  },
  transfer_to: {
    titleKey: 'molecule_viewer.transfer_to',
    id: 'transfer_to',
  },
  copy_link: {
    titleKey: 'molecule_viewer.copy_link',
    id: 'copy_link',
  },
  download_png: {
    titleKey: 'molecule_viewer.download_png_text',
    id: 'add_to_dataset',
  },
  download_pdf: {
    titleKey: 'molecule_viewer.download_pdf',
    id: 'download_pdf',
  },
  open_individual: {
    titleKey: 'molecule_viewer.open_in_individual_mode_text',
    id: 'open_individual',
  },
  add_to_dataset: {
    titleKey: 'molecule_viewer.add_to_dataset',
    id: 'add_to_dataset',
  },
  switch_en: {
    titleKey: 'English',
    id: 'switch_en',
  },
  switch_ru: {
    titleKey: 'Русский',
    id: 'switch_ru',
  },
  about_us: {
    titleKey: 'header.about',
    id: 'about_us',
    icon: 'team',
  },
  user_guide: {
    titleKey: 'header.user_guide',
    id: 'user_guide',
    icon: 'guide',
  },
  open_telegram: {
    titleKey: 'header.tlgrm',
    id: 'open_telegram',
    icon: 'telegram',
  },
  user_profile: {
    titleKey: 'header.profile',
    id: 'user_profile',
    icon: 'profile',
  },
  exit_app: {
    titleKey: 'header.exit',
    id: 'exit',
    icon: 'logout',
  },
  divider: {
    id: 'divider', //разделительная линия
  },
  edit_name: {
    titleKey: 'pdf2smiles.dashboard.menu.edit_name',
    id: 'edit_name',
  },
  delete_pdf: {
    titleKey: 'pdf2smiles.dashboard.menu.delete',
    id: 'delete_pdf',
  },
  select_dataset: {
    titleKey: 'baskets.basket_tools_menu.select',
    id: 'select_dataset',
  },
  deselect_dataset: {
    titleKey: 'baskets.basket_tools_menu.deselect',
    id: 'deselect_dataset',
  },
  edit_dataset: {
    titleKey: 'baskets.basket_tools_menu.edit',
    id: 'edit_dataset',
  },
  view_log: {
    titleKey: 'baskets.basket_tools_menu.viewing_log_messages',
    id: 'view_log',
  },
  delete_dataset: {
    titleKey: 'baskets.basket_tools_menu.delete',
    id: 'delete_dataset',
  },
  copy_literature_link: {
    titleKey: 'literatureCard.copyLink',
    id: 'copy_literature_link',
    disabled: true,
  },
  copy_patent_number: {
    titleKey: 'literatureCard.copyPatentNumber',
    id: 'copy_patent_number',
  },
  copy_doi_number: {
    titleKey: 'literatureCard.copyDOI',
    id: 'copy_doi_number',
  },
  go_molecular_editor: {
    titleKey: 'molecule_viewer.go_to_section.molecular_editor',
    id: 'go_molecular_editor',
  },
  go_synthesis: {
    titleKey: 'molecule_viewer.go_to_section.synthesis',
    id: 'go_synthesis',
  },
  go_retrosynthesis: {
    titleKey: 'molecule_viewer.go_to_section.retrosynthesis',
    id: 'go_retrosynthesis',
  },
  go_nmr_spectra: {
    titleKey: 'molecule_viewer.go_to_section.nmr_spectra',
    id: 'go_nmr_spectra',
  },
  go_mass_spectra: {
    titleKey: 'molecule_viewer.go_to_section.mass_spectra',
    id: 'go_mass_spectra',
  },
  go_ir_spectra: {
    titleKey: 'molecule_viewer.go_to_section.ir_spectra',
    id: 'go_ir_spectra',
  },
  go_synthesis_cost: {
    titleKey: 'molecule_viewer.go_to_section.synthesis_cost',
    id: 'go_synthesis_cost',
  },
  add_condition_title: {
    titleKey: 'filter.literature.title',
    id: 'add_condition_title',
  },
  add_condition_doi: {
    titleKey: 'filter.literature.doi',
    id: 'add_condition_doi',
  },
  add_condition_patent_number: {
    titleKey: 'filter.literature.patent_number',
    id: 'add_condition_patent_number',
  },
  add_condition_authors: {
    titleKey: 'filter.literature.authors',
    id: 'add_condition_authors',
  },
  add_condition_abstract: {
    titleKey: 'filter.literature.abstract',
    id: 'add_condition_abstract',
  },
  add_condition_language: {
    titleKey: 'filter.literature.language',
    id: 'add_condition_language',
  },
  add_condition_published_date: {
    titleKey: 'filter.literature.published_date',
    id: 'add_condition_published_date',
  },
  add_condition_journal: {
    titleKey: 'filter.literature.journal',
    id: 'add_condition_journal',
  },
  add_condition_publisher: {
    titleKey: 'filter.literature.publisher',
    id: 'add_condition_publisher',
  },
  add_condition_company: {
    titleKey: 'filter.literature.owner',
    id: 'add_condition_company',
  },
  add_condition_applicant: {
    titleKey: 'filter.literature.applicant',
    id: 'add_condition_applicant',
  },
  add_condition_text: {
    titleKey: 'filter.literature.text',
    id: 'add_condition_text',
  },
  add_condition_claims: {
    titleKey: 'filter.literature.claims',
    id: 'add_condition_claims',
  },
  highlights_download_csv: {
    titleKey: 'CSV',
    id: 'highlights_download_csv',
  },
  highlights_download_png: {
    titleKey: 'PNG',
    id: 'highlights_download_png',
  },
  download_schemes_excel: {
    titleKey: 'Excel',
    id: 'download_schemes_excel',
  },
  download_schemes_pdf: {
    titleKey: 'PDF',
    id: 'download_schemes_pdf',
  },
  download_schemes_csv: {
    titleKey: 'CSV',
    id: 'download_schemes_csv',
  },
  add_qed: { titleKey: 'QED', id: 'add_qed' },
  add_cats: {
    titleKey: 'CATS',
    id: 'add_cats',
  },
  add_boiling_point: {
    titleKey: 'Boiling point',
    id: 'add_boiling_point',
  },
  add_melting_point: {
    titleKey: 'Melting point',
    id: 'add_melting_point',
  },
  add_mouse_oral_ld50: {
    titleKey: 'Mouse Oral LD50',
    id: 'add_mouse_oral_ld50',
  },
  add_logp: {
    titleKey: 'LogP',
    id: 'add_logp',
  },
  add_logs: {
    titleKey: 'LogS',
    id: 'add_logs',
  },
  add_dmso_solubility: {
    titleKey: 'DMSO Solubility',
    id: 'add_dmso_solubility',
  },
  add_complexity: {
    titleKey: 'Complexity',
    id: 'add_complexity',
  },
  add_syba: {
    titleKey: 'Syba',
    id: 'add_syba',
  },
  add_brutto: {
    titleKey: 'Brutto',
    id: 'add_brutto',
  },
}
