export const getOptions = (noFiles) => [
  { value: 'smiles', label: 'moleculeslist.smiles_string' },
  { value: 'editor', label: 'moleculeslist.visual_editor' },
  { value: 'file', label: 'moleculeslist.load_from_file', hidden: noFiles },
  { value: 'synonyms', label: 'moleculeslist.search_in_synonyms' },
]

export const getIcon = (type) => {
  if (type.includes('csv')) return 'csv'
  if (type.includes('sdf')) return 'sdf'
  if (type.includes('smi')) return 'smi'
  return ''
}
