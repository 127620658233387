import { SHOW_CONFIRM, HIDE_CONFIRM } from '../constants/confirm'

export const confirm = (text, onConfirm, description) => ({
  type: SHOW_CONFIRM,
  text,
  onConfirm,
  description,
})

export const cancel = () => ({
  type: HIDE_CONFIRM,
})
