import styled, { css, keyframes } from 'styled-components'

const hideAnimation = keyframes`
  from {
    width: 5rem;
    overflow: hidden;
  }
  to {
    width: 0;
    padding: 0;
    gap: 0;
    svg {
      width: 0;
      height: 0;
    }
    right: 1rem;
    opacity: 1;
    overflow: hidden;
  }
`

export const NotificationsActions = styled.div`
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
  column-gap: 0.5rem;
  z-index: 5;
  pointer-events: auto;
  display: flex;
  opacity: 1;
  visibility: visible;

  right: 0;
  top: 0.25rem;
  align-self: end;
  position: relative;
  transition: all 0.3s ease-in-out;
  &.hidden {
    animation: ${hideAnimation} 0.2s forwards;
  }
  ${({ isActionsVisible }) =>
    !isActionsVisible &&
    css`
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    `}
`

export const NotificationsActionsBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border: none;
  outline: none;
  position: relative;
  background: rgba(243, 245, 248, 0.84);
  border-radius: 0.75rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  border: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};

  box-shadow: 0px 0px 8px 0px rgba(45, 55, 69, 0.2);
  backdrop-filter: blur(40px);
  width: fit-content;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.primary};
  }

  & > span {
    font-size: ${({ theme }) => theme.text[12]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1rem;
  }

  &:hover {
    transition: all 1s;

    & > span {
      opacity: 1;
      color: ${({ theme }) => theme.colors.text.accentPrimary};
      display: inline;
    }

    & > svg {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }

  &#hide {
    & > span {
      display: none;
    }

    &:hover > span {
      display: inline;
    }
  }
`
