import { connect, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { push } from 'connected-react-router'
import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

import {
  addMolecule,
  loadBaskets,
  setCachedPage,
  loadMolecules,
  moveMolecules,
  updateMolecule,
  loadMoreMolecules,
  setLiteratureSearch,
  addMoleculesFromFile,
  resetMolecules,
} from 'store/actions/crud'
import {
  settingsFreeSearchInc,
  settingsFreeSearchReset,
} from 'store/actions/settings'
import Icon from 'components/Icon'
import { fetch } from 'services/rest'
import Ketcher from 'components/Ketcher'
import LiteratureList from 'pages/LiteratureList'
import CustomSnackbar from 'components/HotTip/index'
import { setOpenFilter } from 'store/actions/filter'
import MoleculeDialog from 'components/Molecule/dialog'
import MoleculeViewer from 'components/MoleculeViewer'
import LoadingOverlay from 'components/LoadingOverlay'
import SearchHistory from 'components/Search/SearchHistory'
import BasketsListModal from 'components/BasketsListModal'
import { addNotification } from 'store/actions/notifications'
import LiteratureDialog from 'components/LiteratureCard/dialog'
import { Warning } from 'components/IndividualPage/index.style'
import { setSearchType, setSearchText } from 'store/actions/search'
import { readStorage, removeFromStorage } from 'utils/storage/storage'
import MoleculeAddViewRestyled from 'components/MoleculeAddViewRestyled'

import {
  MoveBtn,
  Container,
  InfoBlock,
  PageWrapper,
  ScrollTopBtn,
  SelectedAmount,
} from './index.style'

import {
  SCROLL_OFFSET,
  FREESEARCH_MAX,
  initMoleculesState,
  MOLECULES_LIST_PATH,
} from './config/config'
import Molecules from './Molecules'
import TopBlock from './TopBlock'
import Pagination from './Pagination'
import NotFoundInfo from './NotFoundInfo'
import CustomScrollbar from 'components/CustomScrollbar'
import { ListBlockContainer, TopBlockContainer } from './TopBlock/index.style'
import { parseCSVString } from './utils/utils'
import { CustomDialog } from 'components/common/customDialog'
import DatasetTable from 'components/DatasetTable'
import ExportBasketDialog from 'components/ExportBasketDialog'
import usePrevious from 'pages/Baskets/lib/usePrevious'
import { TotalFoundAmount } from './Panel/index.style'

const MoleculesList = memo(
  ({
    push,
    data,
    path,
    error,
    match,
    tasks,
    authId,
    baskets,
    pending,
    searchV2,
    loading,
    isPublic,
    nextPage,
    viewSize,
    litSearch,
    pagination,
    searchType,
    freeSearch,
    searchText,
    pastResult,
    loadBaskets,
    bingoSearch,
    addMolecule,
    loadMolecules,
    moveMolecules,
    setCachedPage,
    setOpenFilter,
    notifications,
    updateMolecule,
    basketSelected,
    resetMolecules,
    addNotification,
    filtersMatchType,
    addMoleculesFromFile,
    settingsFreeSearchInc,
    setSearchType,
    setSearchText,
    litDialogOpen,
    setLiteratureSearch,
    settingsFreeSearchReset,
    isFilterOpen,
    searchHistory,
    totalLitFound,
    litSearchStatus,
    litData,
  }) => {
    const tasksStatus = useSelector((state) => state.tasks)
    const prevTasksStatus = usePrevious(tasksStatus)
    const [state, setState] = useState(initMoleculesState)

    const handleRefresh = useCallback(() => {
      loadBaskets({ withPublic: false })
    }, [loadBaskets])

    useEffect(() => {
      if (
        tasksStatus &&
        prevTasksStatus &&
        tasksStatus.length !== prevTasksStatus.length &&
        tasksStatus.some((el) => el.name.includes(t('baskets.calculating')))
      ) {
        handleRefresh()
      }
    }, [handleRefresh, prevTasksStatus, tasksStatus])

    const {
      molId,
      editId,
      editor,
      openAdd,
      propsId,
      isBottom,
      showMove,
      selected,
      withData,
      editSmile,
      showProps,
      withColors,
      scrollTopEnable,
    } = state || {}

    const [showDatasetTable, setShowDatasetTable] = useState(false)
    const [datasetTableRows, setDatasetTableRows] = useState(false)
    const [showExportDialog, setShowExportDialog] = useState(false)
    const listRef = React.createRef()
    const scrollRef = useRef(null)
    const { t, i18n } = useTranslation()

    const basketId = parseInt(match.params.id)

    const { notificationsList, isHidden } = notifications
    const { showHistory } = searchHistory || {}

    const isNotificationsButtonShow =
      isHidden && notificationsList.length + tasks.length > 0

    const basketData = basketSelected
      ? basketSelected
      : baskets.find(({ id }) => id === basketId)

    const isSearchPage = !path.split('/').includes(MOLECULES_LIST_PATH)

    const showPaginationSearch =
      ((bingoSearch.status === 'done' &&
        !!(bingoSearch.taskId || pastResult)) ||
        (litSearch.status === 'done' && !!(litSearch.taskId || pastResult))) &&
      pagination?.pagesAmount > 1

    const showPaginationExact =
      filtersMatchType === 'exact match' &&
      searchV2.status === 'hold' &&
      (!!nextPage || pagination.pagesAmount > 1)

    const isMoleculeNotFound =
      (searchV2.status === 'done' && searchV2.total === 0) ||
      (bingoSearch.status === 'done' && bingoSearch.result.length === 0) ||
      (searchV2.status === 'transit_done' &&
        bingoSearch.result.length === 0 &&
        bingoSearch.status !== 'loading')

    const isStructureHistoryShow =
      isSearchPage &&
      searchType === 'structure' &&
      searchV2.status === 'hold' &&
      !pastResult

    const isLitHistoryShow =
      searchType === 'literature' && litSearch.status === 'hold' && !pastResult

    const showPagination =
      !(isStructureHistoryShow || isLitHistoryShow) &&
      (showPaginationSearch || showPaginationExact)

    const filteredBaskets = useMemo(
      () => baskets.filter((b) => b.public === false),
      [baskets]
    )

    const usedBasketsNames = useMemo(() => {
      return filteredBaskets.map((basket) => basket.name)
    }, [filteredBaskets])

    useEffect(() => {
      loadBaskets({ withPublic: true })

      if (authId) {
        settingsFreeSearchReset()
      }
      !isFilterOpen && setOpenFilter(true)
      const redirectSearchText = readStorage('redirect_search_text')
      if (!redirectSearchText) return
      setSearchType('literature')
      setSearchText(redirectSearchText)
      setLiteratureSearch({})
      removeFromStorage('redirect_search_text') //add opening logic
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      !isSearchPage && isFilterOpen && setOpenFilter(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchPage, isFilterOpen])

    useEffect(() => {
      basketId && loadMolecules({ basket: basketId })
      return () => resetMolecules()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basketId])

    useEffect(() => {
      handleCloseProps()
    }, [searchV2.label])

    useEffect(() => {
      if (pending) {
        push('/molecules')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pending])

    useEffect(() => {
      if (error && error?.response?.data?.detail !== 'Basket is pending') {
        const id = uuidv4()

        const notify = {
          id,
          name: 'spectra.error.error',
          text: error?.response?.data?.detail?.includes('inch')
            ? 'confirmation.no_smile'
            : error?.response?.data?.detail,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText: error?.response?.data?.detail?.includes('inch'),
        }

        addNotification(notify)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
      if (searchV2.status === 'done' && searchV2.total > 0) {
        settingsFreeSearchInc()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchV2.status, searchV2.total])

    useEffect(() => {
      if (Array.isArray(bingoSearch.result) && bingoSearch.result.length) {
        getBingoSearchPageElements()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bingoSearch?.result?.length])

    useEffect(() => {
      scrollRef?.current?.scrollTo(0, 0)
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          isBottom: pagination.activePage === pagination.pagesAmount,
        }))
      }, 0)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      litSearch.status,
      bingoSearch.status,
      pastResult,
      pagination.activePage,
    ])

    const handleScroll = () => {
      const d = document.getElementById('container')
      const isNotTop =
        scrollRef.current?.scrollValues?.scrollTop > SCROLL_OFFSET
      const isBottomCurrent =
        scrollRef.current?.scrollValues?.scrollTop +
          d?.getBoundingClientRect()?.height +
          80 >=
        scrollRef.current?.scrollValues?.scrollHeight

      if (
        isNotTop === state.scrollTopEnable &&
        bingoSearch.status !== 'loading' &&
        litSearch.status !== 'loading' &&
        isBottomCurrent === state.isBottom
      ) {
        return
      }
      setState((prev) => ({
        ...prev,
        scrollTopEnable: isNotTop,
        isBottom:
          bingoSearch.status !== 'loading' && litSearch.status !== 'loading'
            ? isBottomCurrent
            : prev.isBottom,
      }))
    }

    const handleScrollTop = () => scrollRef?.current?.scrollTo(0, 0)

    /* add new molecule to db */
    const addSmiToData = (smiles) => {
      const basketName = baskets?.find(({ id }) => id === basketId)?.name || ''

      addMolecule(basketId, smiles, true, basketName, t, true)
    }

    const handleCloseEditor = () => {
      unselectAll()
      setState((prev) => ({
        ...prev,
        editor: false,
        editId: null,
        editSmile: null,
      }))
    }

    const handleData = (smiles) => {
      /* сохранение / редактирование через кетчер */

      if (editId) {
        updateMolecule(editId, basketId, smiles)
      } else {
        addSmiToData(smiles)
        return
      }

      handleCloseEditor()
    }

    const handleEditMoleculeInEditor = (id) => {
      const editSmile = data
        .filter((el) => el.id === id)
        .map((el) => el.smiles)[0]
      setState((prev) => ({ ...prev, editId: id, editSmile, editor: true }))
    }

    const handleSelect = (molecule, type) => {
      let state = []
      switch (type) {
        case 'ctrl':
          if (selected.find((s) => s.id === molecule.id)) {
            state = selected.filter((el) => el.id !== molecule.id)
          } else {
            state = [...selected, molecule]
          }
          break
        case 'shift': {
          let toIdx = data.findIndex((e) => e.id === molecule.id)
          if (selected.length === 0) {
            state = data
              .filter((_, idx) => idx <= toIdx)
              .map((e) => ({
                id: e.id,
                smiles: e.smiles,
              }))
          } else {
            const start = selected.pop()
            const fromIdx = data.findIndex((e) => e.id === start.id)
            let list = data
              .filter(
                (_, idx) =>
                  idx <= Math.max(toIdx, fromIdx) &&
                  idx >= Math.min(toIdx, fromIdx)
              )
              .map((e) => ({
                id: e.id,
                smiles: e.smiles,
              }))
            state = [...selected, ...list]
            state = [...new Set(state)]
          }
          break
        }
        default:
          break
      }

      setState((prev) => ({ ...prev, selected: state }))
    }

    const unselectAll = () => setState((prev) => ({ ...prev, selected: [] }))

    const handleShowProps = (id, molId) =>
      setState((prev) => ({
        ...prev,
        showProps: true,
        propsId: id,
        molId: molId,
        selected: [],
      }))

    const handleCloseProps = () =>
      setState((prev) => ({
        ...prev,
        showProps: false,
        propsId: null,
        molId: null,
      }))

    const handleShowMove = async () => {
      const resp = await fetch('/baskets')
      const baskets = resp.data.filter((el) => el.id !== basketId || 1)

      setState((prev) => ({ ...prev, showMove: true, baskets }))
    }

    const handleCloseMove = () =>
      setState((prev) => ({ ...prev, showMove: false, baskets: [] }))

    const handleMove = (ids) => {
      ids.forEach((el) => {
        basketId && !isPublic
          ? moveMolecules(
              el.id,
              basketId || 1,
              selected.map(({ id }) => id),
              el?.name,
              t
            )
          : addMolecule(
              el.id,
              selected.map(({ smiles }) => ({
                smiles,
              })),
              false,
              el?.name,
              t
            )
      })

      unselectAll()
    }

    const handleAddNew = () => setState((prev) => ({ ...prev, openAdd: true }))

    const handleCloseAddModal = () =>
      setState((prev) => ({ ...prev, openAdd: false }))

    const openIndividual = () => {
      isFilterOpen && setOpenFilter(false)
      let dataToUse = ''

      if (searchV2?.label && searchV2.label.startsWith('SMILES')) {
        dataToUse = searchV2.name
      } else if (searchV2.label && searchV2.label.length) {
        dataToUse = searchV2.label
      } else if (searchText) {
        dataToUse = searchText
      }
      if (dataToUse.length) {
        push(`/moleditor/${encodeURIComponent(dataToUse)}`)
      }
    }

    const handleCachePage = (pageItems, pageNumber) =>
      setCachedPage({ pageItems, pageNumber })

    const getBingoSearchPageElements = () => {
      const { perPage, activePage } = pagination

      const cycleStartIndex =
        bingoSearch.result.length > perPage ? (activePage - 1) * perPage : 0

      const cycleEndIndex =
        bingoSearch.result.length < perPage
          ? bingoSearch.result.length
          : activePage * perPage > bingoSearch.result.length
          ? bingoSearch.result.length
          : activePage * perPage

      const pageItems = []
      for (let i = cycleStartIndex; i < cycleEndIndex; i++) {
        pageItems.push(bingoSearch.result[i])
      }

      handleCachePage(pageItems, activePage)
    }

    const handleAddSmiles = (arr) => {
      if (arr) addSmiToData(arr)
      handleCloseAddModal()
    }

    const name = useMemo(
      () =>
        i18n.language === 'ru' && basketData?.name_ru
          ? basketData.name_ru
          : basketData?.name,
      [basketData?.name, basketData?.name_ru, i18n.language]
    )

    const info = useMemo(
      () =>
        i18n.language === 'ru' && basketData?.info_ru
          ? basketData?.info_ru
          : basketData?.info,
      [basketData?.info, basketData?.info_ru, i18n.language]
    )

    const getDataForTable = async () => {
      try {
        const { data } = await fetch(
          `/download_basket?type=csv&basket=${basketId}`
        )
        const parsedData = parseCSVString(data)
        setDatasetTableRows(parsedData)
      } catch (e) {
        console.log(e)
      }
    }

    const handleShowDatasetTable = async () => {
      await getDataForTable()
      setShowExportDialog(false)
      setShowDatasetTable((prev) => !prev)
    }

    const calculateForExport = async () => {
      try {
        await fetch(`/calc_basket?basket=${basketId}`)
        return true
      } catch (e) {
        const id = uuidv4()
        const notify = {
          id,
          name: 'notification.error',
          text: 'baskets.failed_to_calculate_dataset',
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
        }

        addNotification(notify)
        return false
      }
    }

    if (error && error?.response?.status === 400) {
      push('/datasets')
      return null
    }

    if (!authId && freeSearch > FREESEARCH_MAX) {
      return <Warning>{t('individual.warn_text')}</Warning>
    }

    const scrollState = scrollRef?.current?.getScrollState() || {}
    const { clientHeight, scrollHeight, scrollTop } = scrollState || {}
    const isContainerNotScrollable = scrollRef?.current
      ? scrollHeight === clientHeight && scrollTop !== 0
      : false

    const isNeedToAddMolecule =
      data.length === 0 && searchV2.status === 'hold' && !loading && !!basketId

    return (
      <PageWrapper>
        {showProps && (
          <MoleculeDialog
            onClose={handleCloseProps}
            molId={molId}
            id={propsId}
            basketId={basketData?.id}
          />
        )}
        {openAdd && (
          <MoleculeAddViewRestyled
            handleSingleSmiles={handleAddSmiles}
            handleMultiSmiles={handleAddSmiles}
            handleClose={handleCloseAddModal}
            handleLoadFile={(file, smilesTargetCol, delimiter) => {
              addMoleculesFromFile(basketId, file, smilesTargetCol, delimiter)
            }}
          />
        )}
        {litDialogOpen && <LiteratureDialog />}
        <Container id="container" ref={listRef} showHistory={showHistory}>
          <CustomScrollbar
            innerRef={scrollRef}
            onScroll={handleScroll}
            className="flex-col-scrollbar"
          >
            {(!showHistory || !isSearchPage) && (
              <TopBlockContainer>
                <TopBlock
                  {...{
                    selected,
                    scrollTopEnable,
                  }}
                  showTools={
                    !pastResult &&
                    !isMoleculeNotFound &&
                    !isStructureHistoryShow &&
                    searchType === 'structure'
                  }
                  basketName={name}
                  basketInfo={info}
                  unselectAll={unselectAll}
                  onShowProps={handleShowProps}
                  currentBasketId={match.params.id}
                  onEdit={handleEditMoleculeInEditor}
                  handleAddNew={handleAddNew}
                  onShowAddDialog={handleAddNew}
                  basket={parseInt(match.params.id ?? 1)}
                  usedBasketsNames={usedBasketsNames}
                  isBasketLoading={!basketData}
                  isBasketEmpty={isNeedToAddMolecule}
                  onShowExportDialog={() => setShowExportDialog(true)}
                />
              </TopBlockContainer>
            )}
            {litSearchStatus === 'done' &&
              !!litData.length &&
              searchType === 'literature' &&
              totalLitFound > 0 && (
                <TotalFoundAmount>
                  {t('search.literatureFilters.sources')}
                  {totalLitFound}
                </TotalFoundAmount>
              )}
            <ListBlockContainer id="list-block-container">
              {searchType === 'structure' &&
                !isStructureHistoryShow &&
                data.length === 0 && (
                  <InfoBlock isNeedToAddMolecule={isNeedToAddMolecule}>
                    {loading && !propsId && <LoadingOverlay />}
                    {pending && (
                      <div style={{ margin: '50px' }}>
                        {t('moleculeslist.dataIsUploading')}
                      </div>
                    )}

                    {searchV2.status === 'error' && (
                      <span>{t('moleculeslist.error')}</span>
                    )}
                    {isMoleculeNotFound && (
                      <NotFoundInfo {...{ openIndividual }} />
                    )}
                    {isNeedToAddMolecule && (
                      <MoleculeViewer
                        size={viewSize}
                        clicknaddMode
                        handleClick={handleAddNew}
                        noEdit
                        noDelete
                        onShowProps={handleAddNew}
                        isOnlyThreeDotsShow={false}
                      />
                    )}
                  </InfoBlock>
                )}
              {searchType === 'structure' &&
                (searchV2.status === 'hold' &&
                isSearchPage &&
                isStructureHistoryShow ? (
                  <SearchHistory />
                ) : (
                  <Molecules
                    {...{
                      propsId,
                      withData,
                      showProps,
                      selected,
                      withColors,
                      showPagination,
                    }}
                    onSelect={handleSelect}
                    unselectAll={unselectAll}
                    onShowProps={handleShowProps}
                    currentBasketId={match.params.id}
                    onEdit={handleEditMoleculeInEditor}
                  />
                ))}

              {searchType === 'literature' && (
                <LiteratureList showPagination={showPaginationSearch} />
              )}

              {showPagination && (isBottom || isContainerNotScrollable) && (
                <Pagination />
              )}
              <ScrollTopBtn
                visible={scrollTopEnable}
                onClick={handleScrollTop}
                isNotificationsButtonShow={isNotificationsButtonShow}
              >
                <Icon iconType="arrowTop" size="1.25rem" />
              </ScrollTopBtn>
              {editor && (
                <Ketcher
                  handleData={handleData}
                  closeKetcher={handleCloseEditor}
                  smiles={editSmile}
                />
              )}
              {showMove && (
                <BasketsListModal
                  onAgree={handleMove}
                  onClose={handleCloseMove}
                  withPublic={false}
                  withNew={true}
                  actionText={t('baskets.modal.move')}
                  currentBasketId={match.params.id}
                  onlyOne
                />
              )}
              {!!data.length && <CustomSnackbar />}
              <MoveBtn visible={selected.length > 0} onClick={handleShowMove}>
                <Icon iconType="nut" />
                <span>
                  {t(
                    basketId && !isPublic
                      ? 'baskets.modal.create_or_move'
                      : 'baskets.modal.create_or_add'
                  )}
                </span>
                <SelectedAmount>
                  <span>{selected.length}</span>
                </SelectedAmount>
              </MoveBtn>
            </ListBlockContainer>
          </CustomScrollbar>
        </Container>
        {showDatasetTable && (
          <CustomDialog
            width="98vw"
            maxHeight="98vh"
            onClose={() => setShowDatasetTable(false)}
          >
            <DatasetTable rows={datasetTableRows} basketName={name} />
          </CustomDialog>
        )}
        {showExportDialog && (
          <ExportBasketDialog
            basketName={name}
            handleShowDatasetTable={handleShowDatasetTable}
            handleClose={() => setShowExportDialog(false)}
            calculateForExport={calculateForExport}
            tasksStatus={tasksStatus}
            bask={baskets.find(({ id }) => id === basketId)}
            type="analysis"
          />
        )}
      </PageWrapper>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    tasks: state.tasks,
    error: state.crud.error,
    text: state.search.text,
    data: state.crud.molecules,
    baskets: state.crud.baskets,
    pending: state.crud.pending,
    loading: state.crud.loading,
    isPublic: state.crud.public,
    searchV2: state.crud.searchV2,
    nextPage: state.crud.nextPage,
    authId: state.auth.userdata.id,
    litSearch: state.crud.litSearch,
    withSearch: state.crud.withSearch,
    viewSize: state.settings.viewSize,
    notifications: state.notifications,
    searchHistory: state.searchHistory,
    bingoSearch: state.crud.bingoSearch,
    pagination: state.search.pagination,
    searchType: state.search.searchType,
    searchText: state.search.searchText,
    path: state.router.location.pathname,
    freeSearch: state.settings.freeSearch,
    pastResult: state.searchHistory.pastResult,
    basketSelected: state.basket.basketSelected,
    litDialogOpen: state.literature.dialog.open,
    filtersMatchType: state.filter.config.match_type,
    isFilterOpen: state.filter.open,
    totalLitFound: state.crud.litSearch.totalLitFound,
    litSearchStatus: state.crud.litSearch.status,
    litData: state.crud.litSearch.result.data,
  }
}

const mapDispatchToProps = {
  push,
  addMolecule,
  loadBaskets,
  setCachedPage,
  setOpenFilter,
  loadMolecules,
  moveMolecules,
  setSearchType,
  setSearchText,
  updateMolecule,
  resetMolecules,
  addNotification,
  loadMoreMolecules,
  setLiteratureSearch,
  addMoleculesFromFile,
  settingsFreeSearchInc,
  settingsFreeSearchReset,
}

MoleculesList.displayName = 'MoleculesList'

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MoleculesList)
)
