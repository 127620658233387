import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetch, store, storeV2 } from 'services/rest'

import {
  LOAD_APPLICABILITY_INIT,
  LOAD_APPLICABILITY_SUCCESS,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_INIT,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_MOLECULE_PROPS_ERROR,
  LOAD_MOLECULE_PROPS_INIT,
  LOAD_MOLECULE_PROPS_SUCCESS,
  LOAD_RU_IUPAC_INIT,
  LOAD_RU_IUPAC_SUCCESS,
  LOAD_SERVICES_ERROR,
  LOAD_SERVICES_INIT,
  LOAD_SERVICES_SUCCESS,
  UPDATE_MOLECULE_PROPS_FINISHED,
  UPDATE_MOLECULE_PROPS_INIT,
  UPDATE_MOLECULE_PROPS_SUCCESS,
  LOAD_RU_IUPAC_ERROR,
} from 'store/constants/molecule'
import {
  getMoleculeBasketId,
  getMoleculeServices,
  getMoleculeStructuralData,
} from 'store/selectors'
import { addServiceResponceToData } from 'utils/molecule/addServiceResponceToData'
import { getSortedCategories } from 'utils/molecule/getSortedCategories'
import { handleCalculationsError } from 'utils/molecule/handleCalculationsError'

const EXCLUDED_SERVICES = ['nmr', 'reactions', 'user_params']

function* getCategories() {
  try {
    const { data } = yield fetch('/categories')
    yield put({
      type: LOAD_CATEGORIES_SUCCESS,
      data: getSortedCategories(data).filter(
        ({ category_name }) => !EXCLUDED_SERVICES.includes(category_name)
      ),
    })
  } catch (error) {
    yield put({
      type: LOAD_CATEGORIES_ERROR,
    })
  }
}

function* getServices() {
  try {
    const { data } = yield fetch('/services_list')
    yield put({
      type: LOAD_SERVICES_SUCCESS,
      data: data.filter((service) => !EXCLUDED_SERVICES.includes(service.name)),
    })
  } catch (error) {
    yield put({
      type: LOAD_SERVICES_ERROR,
    })
  }
}

function* getMoleculeProps({ mainId, basketId }) {
  let dataToUse
  try {
    if (!Number.isInteger(+mainId)) {
      const { data } = yield storeV2('molecule/preview', {
        params: {
          smiles: mainId,
        },
      })
      const { result } = data || {}
      dataToUse = result
    }
    if (Number.isInteger(+mainId)) {
      const { data: fetchData } = yield fetch(`/molecule/${mainId}`, 2)
      const structural = fetchData?.result || {}
      dataToUse = structural
    }

    const services = yield select(getMoleculeServices)

    let isNeedHidePred = false
    if (dataToUse.smiles) {
      const { data } = yield store('/check_inorganic', {
        smiles: dataToUse.smiles,
      })
      if (!data?.result) isNeedHidePred = true
    }

    const canUpdateProps = !!dataToUse.smiles && !!services?.length

    let molData = {
      ...dataToUse,
      structural: dataToUse,
    }

    yield put({
      type: LOAD_MOLECULE_PROPS_SUCCESS,
      mainId,
      basketId,
      data: molData,
      loading: canUpdateProps,
      isNeedHidePred,
    })

    if (canUpdateProps) {
      yield put({
        type: UPDATE_MOLECULE_PROPS_INIT,
      })
    }
  } catch (error) {
    yield put({
      type: LOAD_MOLECULE_PROPS_ERROR,
    })
  }
}

function* getServiceData({ name, view_type, functions, method }) {
  try {
    const structural = yield select(getMoleculeStructuralData)
    const basketId = yield select(getMoleculeBasketId)

    const { data } = yield store('/calculations', {
      service: name,
      input: Number.isInteger(basketId)
        ? { ...structural, basketId }
        : structural,
      ...(name === 'rdkit' && {
        method,
        params: {
          smiles: structural.smiles,
        },
      }),
    })

    if (data.status === 'ok') {
      const { data: updatedData, sources } = addServiceResponceToData(
        view_type,
        name === 'rdkit'
          ? [{ index: `rdkit_${method}`, value: data.result }]
          : data.result,
        name
      )

      if (data || sources) {
        yield put({
          type: UPDATE_MOLECULE_PROPS_SUCCESS,
          data: updatedData,
          sources,
        })
      }
    } else if (data.status === 'error') {
      yield handleCalculationsError(name)
    }
  } catch (error) {
    yield handleCalculationsError(name)
    // todo: add notification
  }
}

function* updateMoleculeProps() {
  const services = yield select(getMoleculeServices)

  const [rdkitFiltered] = services.filter((el) => el.name === 'rdkit')
  const rdkitList = rdkitFiltered.functions.map((el) =>
    call(getServiceData, { ...rdkitFiltered, method: el.name })
  )

  const promisesList = []

  for (let i = 0; i < services.length; i++) {
    if (services[i].name !== 'rdkit')
      promisesList.push(call(getServiceData, services[i]))
  }
  yield all([...promisesList, ...rdkitList])

  yield put({
    type: UPDATE_MOLECULE_PROPS_FINISHED,
  })
}

function* loadRuIupac({ iupac }) {
  const isErrorIupac = iupac === 'Unable to generate valid IUPAC name'
  if (isErrorIupac) {
    yield put({
      type: LOAD_RU_IUPAC_ERROR,
    })
    return
  }
  try {
    const res = yield store('/run-task', {
      service: 'iupac_en_to_ru',
      params: {
        iupac_en: iupac,
      },
    })
    if (res.status === 200) {
      yield put({
        type: LOAD_RU_IUPAC_SUCCESS,
        data: res.data.result,
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* loadApplicability({ smiles }) {
  try {
    const res = yield store('/run-task', {
      service: 'applicability_domain',
      params: {
        smiles: [smiles],
      },
      method: '',
      type: 'instant',
    })
    if (res.status === 200) {
      yield put({
        type: LOAD_APPLICABILITY_SUCCESS,
        data: res.data.result[0],
      })
    }
  } catch (error) {
    console.log('error', error)
  }
}

export function* getCategoriesWatcher() {
  yield takeEvery(LOAD_CATEGORIES_INIT, getCategories)
}

export function* getServicesWatcher() {
  yield takeEvery(LOAD_SERVICES_INIT, getServices)
}

export function* getMoleculePropsWatcher() {
  yield takeEvery(LOAD_MOLECULE_PROPS_INIT, getMoleculeProps)
}

export function* updateMoleculePropsWatcher() {
  yield takeEvery(UPDATE_MOLECULE_PROPS_INIT, updateMoleculeProps)
}

export function* loadRuIupacWatcher() {
  yield takeEvery(LOAD_RU_IUPAC_INIT, loadRuIupac)
}

export function* loadApplicabilityWatcher() {
  yield takeEvery(LOAD_APPLICABILITY_INIT, loadApplicability)
}

const watchers = [
  getCategoriesWatcher(),
  getServicesWatcher(),
  getMoleculePropsWatcher(),
  updateMoleculePropsWatcher(),
  loadRuIupacWatcher(),
  loadApplicabilityWatcher(),
]
export default watchers
