import { store } from "services/rest"
import * as FileSaver from 'file-saver'


export const getWrapperWidth = (isFullMode, isApplicabilityShow) => {
  if (isFullMode) {
    return {
      width: isApplicabilityShow ? 'calc(47% + 44px)' : '47%',
      maxWidth: isApplicabilityShow ? 'calc(47% + 44px)' : '47%',
    }
  } else {
    return {
      width: isApplicabilityShow ? 'calc(55% + 44px)' : '55%',
      maxWidth: '100%',
    }
  }
}

export const downloadMol= async (smiles)=>{
  if (!smiles || typeof smiles !== 'string') return
  try {
    const { data } = await store(`/smiles2mol_file`, {
      smiles,
    })
    if (data) {
      const molBlob = new Blob([data], { type: 'chemical/x-mdl-molfile' })
      FileSaver.saveAs(molBlob, 'molecule.mol')
    }
  } catch (e) {
    console.log(e)
  }
}

