import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
} from 'store/constants/feedbackForm'

export const openFeedbackForm = () => ({
  type: OPEN_FEEDBACK_FORM,
})

export const closeFeedbackForm = () => ({
  type: CLOSE_FEEDBACK_FORM,
})
