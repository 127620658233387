import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import gfm from 'remark-gfm'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { v4 as uuidv4 } from 'uuid'
import {
  ColoredSubheadline,
  Container,
  ExternalLink,
  Group,
  GroupedProperties,
  MarkdownParagraph,
  PropertySource,
  PropertyValue,
  CategorySort,
  CategorySortItem,
  PropertyRow,
  SynonymsHint,
} from './index.style'
import {
  CategoryHeader,
  CategoryContent,
  KeyValueProperty,
  DescriptedPropertyKey,
} from './index.style'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import {
  COPIED_PROPERTY_NAMES,
  POSSIBLE_ERR_VALUES_BY_GROUP,
  HEADERS_EXCEPTIONS,
  WARNING_PROPERTIES_VALUES,
  addSubTags,
  getCategoryId,
  getCategoryKey,
  getCustomHeadersDescription,
  getSourceByCategoryName,
  needHideProperty,
} from '../config/config'
import Icon from 'components/Icon'
import { LinkRenderer } from '../LinkRenderer'
import '../../common/tooltip/style.css'

import { copyToClipboard } from 'utils/common/common'
import { addSimpleNotification } from '../../../store/actions/notifications'
import { toxySortConfig } from './config'
import ApplicabilityIndicator from 'components/applicability-indicator'
import { setBlurNonApplicable } from 'store/actions/molecule'
import { useTheme } from 'styled-components'

export const Category = ({
  category_id,
  category_name,
  inner,
  data,
  sources,
  extended,
  setExtended,
  applicabilityData,
  isApplicabilityShow,
  isFullMode,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isExpanded = useMemo(
    () => extended.includes(category_id),
    [category_id, extended]
  )

  const [toxySortType, setToxySortType] = useState('all')

  const dispatch = useDispatch()

  const toggleIsExpanded = () => setExtended(category_id)

  const blurNonApplicable = useSelector(
    (store) => store.molecule.categories.blurNonApplicable
  )

  useEffect(() => {
    return () => dispatch(setBlurNonApplicable(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataByCategoryName = (name) => {
    const d = data?.[name]

    if (!d) return
    if (d && typeof d === 'object' && name !== 'drug') {
      const { value, interval, human_value, unit } = d

      return (
        <span>
          {value} {interval && `± ${interval}`}{' '}
          {human_value && `(${human_value})`} {unit}
        </span>
      )
    }

    if (d && name === 'rdkit_count_chemical_formula') {
      return addSubTags(d)
    }

    return d
  }

  const handleCopy = (name, value) => {
    copyToClipboard(value)
    let notifyName = ''
    if (name === 'smiles') notifyName = 'notification.smiles_copied'
    if (name === 'iupac') notifyName = 'notification.iupac_copy'

    dispatch(addSimpleNotification(notifyName))
  }

  const handleChangeSortType = (value) => setToxySortType(value)

  const innerFiltered = useMemo(() => {
    if (category_name === 'toxy') {
      if (toxySortType === 'all') return inner
      return inner.filter((el) =>
        toxySortType === 'lethal_dose_models'
          ? el.name.includes('LD50') || el.name.includes('LDLo')
          : !el.name.includes('LD50') && !el.name.includes('LDLo')
      )
    }
    return inner
  }, [category_name, inner, toxySortType])
  if (
    category_name === 'synonyms' &&
    data['synonyms']?.length === 1 &&
    data['synonyms']?.[0]?.total === 0
  ) {
    return (
      <Container
        id={getCategoryId(category_id)}
        isFullMode={isFullMode}
        style={{ position: 'relative' }}
      >
        <CategoryHeader
          className="molecule-category-header"
          isExpanded={isExpanded}
          onClick={toggleIsExpanded}
          noHoverStyles={true}
        >
          <TextBody fontWeight={500}>
            {t(getCategoryKey(category_name))}
          </TextBody>
        </CategoryHeader>
        <Subheadline
          color={theme.colors.text.secondary}
          fontWeight={400}
          className={'property-name'}
          style={{ marginTop: '0.25rem' }}
        >
          {t('molviewer.no_synonyms')}
        </Subheadline>
        <SynonymsHint
          data-tooltip-html={t('molviewer.why_that_tip')}
          data-tooltip-id="no-synonyms-tooltip"
          isFullMode={isFullMode}
        >
          <p>{t('molviewer.why_that')}</p>
          <Icon iconType="info" />
          <ReactTooltip
            id="no-synonyms-tooltip"
            className="c-tooltip c-tooltip-base c-tooltip-category-syn"
            classNameArrow="c-tooltip-arrow"
            place="top-end"
            positionStrategy={'fixed'}
          />
        </SynonymsHint>
      </Container>
    )
  }

  return (
    <Container id={getCategoryId(category_id)} isFullMode={isFullMode}>
      <CategoryHeader
        className="molecule-category-header"
        isExpanded={isExpanded}
        onClick={toggleIsExpanded}
      >
        <TextBody fontSize={'0.75rem'} lineHeight={'1rem'} fontWeight={500}>
          {t(getCategoryKey(category_name))}
        </TextBody>
        <Icon iconType="arrowDown" onClick={toggleIsExpanded} size={'20px'} />
      </CategoryHeader>
      {category_name === 'toxy' && (
        <CategorySort isExpanded={isExpanded} className="hidden-for-print">
          {toxySortConfig.map(({ name, value }, key) => {
            return (
              <CategorySortItem
                active={toxySortType === value}
                key={key}
                onClick={() => handleChangeSortType(value)}
              >
                {t(name)}
              </CategorySortItem>
            )
          })}
        </CategorySort>
      )}
      <CategoryContent
        isExpanded={isExpanded}
        className="molecule-category-content"
      >
        {innerFiltered.map(
          ({ category_id, type, name, desc, category_title }) => {
            if (name === 'synonyms') {
              return (
                <Fragment key={category_id}>
                  {data[name].map(({ synonym }, i) => (
                    <Subheadline key={i} fontWeight={400} textAlign="start">
                      {synonym}
                    </Subheadline>
                  ))}
                </Fragment>
              )
            }

            if (name === 'external') {
              return (
                <Fragment key={category_id}>
                  {data[name].map(({ value, view, link }, i) => (
                    <KeyValueProperty
                      className="molecule-key-value-property"
                      key={i}
                    >
                      <Subheadline
                        color={theme.colors.text.secondary}
                        fontWeight={400}
                      >
                        {view}
                      </Subheadline>
                      <ExternalLink
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Subheadline fontWeight={400}>{value}</Subheadline>
                        <Icon iconType={'link'} />
                      </ExternalLink>
                    </KeyValueProperty>
                  ))}
                </Fragment>
              )
            }

            if (type === 'headers') {
              const headers = getDataByCategoryName(name).filter(
                ({ value }) =>
                  value !== 'error' &&
                  !value?.find(
                    ({ type, text }) =>
                      type === 'header' &&
                      HEADERS_EXCEPTIONS.headers.includes(text)
                  )
              )

              if (headers?.length) {
                return (
                  <GroupedProperties key={category_id}>
                    {headers.map(({ value }, i) => {
                      const header = value.find(({ type }) => type === 'header')
                      const lines = value.filter(
                        ({ type }) => type !== 'header'
                      )

                      return (
                        <Group key={i}>
                          {(header ? [header, ...lines] : lines).map(
                            ({ type, text, units, value, color }, i) => (
                              <KeyValueProperty
                                className="molecule-key-value-property"
                                key={i}
                              >
                                {HEADERS_EXCEPTIONS.descriptions.includes(
                                  text
                                ) ? (
                                  <div>
                                    <DescriptedPropertyKey
                                      data-tooltip-id={text}
                                      className={
                                        'property-name molecule-descripted-key'
                                      }
                                      status={
                                        type === 'header' ? color : undefined
                                      }
                                    >
                                      <ColoredSubheadline
                                        color={theme.colors.text.secondary}
                                        fontWeight={400}
                                        status={
                                          type === 'header' ? color : undefined
                                        }
                                      >
                                        {t(`molviewer.${text}`)}
                                      </ColoredSubheadline>
                                      <Icon iconType={'description'} />
                                    </DescriptedPropertyKey>
                                    <ReactTooltip
                                      id={text}
                                      clickable={true}
                                      delayHide={100}
                                      className="c-tooltip c-tooltip-base"
                                      classNameArrow="c-tooltip-arrow"
                                      place={'bottom'}
                                      positionStrategy={'fixed'}
                                    >
                                      {getCustomHeadersDescription(text)}
                                    </ReactTooltip>
                                  </div>
                                ) : (
                                  <ColoredSubheadline
                                    color={'#616E7C'}
                                    status={
                                      type === 'header' ? color : undefined
                                    }
                                  >
                                    {t(`molviewer.${text}`)}
                                  </ColoredSubheadline>
                                )}
                                {value !== undefined && (
                                  <ColoredSubheadline
                                    color={'#1F2933'}
                                    status={color}
                                  >
                                    {value === 'not included'
                                      ? t(`molviewer.not_included`)
                                      : value}
                                    {units &&
                                      !HEADERS_EXCEPTIONS.units.includes(
                                        units
                                      ) &&
                                      ` ${t(`molviewer.${units}`)}`}
                                  </ColoredSubheadline>
                                )}
                              </KeyValueProperty>
                            )
                          )}
                        </Group>
                      )
                    })}
                  </GroupedProperties>
                )
              }
            }

            if (!type) {
              const value = getDataByCategoryName(name)
              const source = getSourceByCategoryName(name, sources)

              if (needHideProperty(name, value)) return null

              const hideCopyIconOnError =
                Object.prototype.hasOwnProperty.call(
                  POSSIBLE_ERR_VALUES_BY_GROUP,
                  name
                ) &&
                POSSIBLE_ERR_VALUES_BY_GROUP[name]?.some((val) => val === value)

              const isBlurable =
                isApplicabilityShow &&
                !!applicabilityData?.[category_title] &&
                typeof applicabilityData?.[category_title] === 'string' &&
                source?.type !== 'exp'

              return (
                <PropertyRow key={category_id}>
                  <KeyValueProperty className="molecule-key-value-property">
                    {desc ? (
                      <div>
                        <DescriptedPropertyKey
                          data-tooltip-id={`${category_id.toString()}-${name}`}
                          className={'property-name molecule-descripted-key'}
                        >
                          <Subheadline
                            color={theme.colors.text.secondary}
                            fontWeight={400}
                          >
                            {t(getCategoryKey(name))}
                          </Subheadline>
                          <Icon iconType={'description'} />
                        </DescriptedPropertyKey>
                        <ReactTooltip
                          id={`${category_id.toString()}-${name}`}
                          clickable={true}
                          delayHide={100}
                          className="c-tooltip c-tooltip-base"
                          classNameArrow="c-tooltip-arrow"
                          place={'bottom-start'}
                          positionStrategy={'fixed'}
                        >
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[gfm]}
                            components={{
                              a: LinkRenderer,
                              p: MarkdownParagraph,
                            }}
                          >
                            {desc}
                          </ReactMarkdown>
                        </ReactTooltip>
                      </div>
                    ) : (
                      <Subheadline
                        color={theme.colors.text.secondary}
                        fontSize="0.75rem"
                        fontWeight={400}
                        className={'property-name'}
                      >
                        {t(getCategoryKey(name))}
                      </Subheadline>
                    )}
                    <PropertyValue
                      name={name}
                      className={`property-value`}
                      onClick={() => {
                        isBlurable &&
                          blurNonApplicable &&
                          dispatch(setBlurNonApplicable(false))
                      }}
                    >
                      {COPIED_PROPERTY_NAMES.includes(name) &&
                        !hideCopyIconOnError && (
                          <Icon
                            iconType="copy"
                            onClick={() => handleCopy(name, value)}
                            size={'16px'}
                          />
                        )}
                      {value ? (
                        isBlurable && blurNonApplicable ? (
                          <>
                            <ReactTooltip
                              id={`blur-property-tooltip-${name}`}
                              className="c-tooltip c-tooltip-base c-tooltip-category-blured"
                              classNameArrow="c-tooltip-arrow"
                              positionStrategy={'fixed'}
                            />
                            <Subheadline
                              data-tooltip-html={t('molviewer.blur_value')}
                              data-tooltip-id={`blur-property-tooltip-${name}`}
                              name={name}
                              title={name === 'smiles' ? value : ''}
                              fontWeight={500}
                              as={'span'}
                              className={`property-value_blured-${
                                isBlurable && blurNonApplicable
                              }`}
                              color={
                                WARNING_PROPERTIES_VALUES.includes(value)
                                  ? '#FF3B30'
                                  : undefined
                              }
                            >
                              {value}
                            </Subheadline>
                          </>
                        ) : (
                          <Subheadline
                            name={name}
                            title={name === 'smiles' ? value : ''}
                            fontWeight={400}
                            as={'span'}
                            color={
                              WARNING_PROPERTIES_VALUES.includes(value)
                                ? '#FF3B30'
                                : undefined
                            }
                          >
                            {value}
                          </Subheadline>
                        )
                      ) : (
                        <Icon
                          iconType="loader"
                          className="loader-icon"
                          size="1rem"
                        />
                      )}
                      {source && (
                        <>
                          <PropertySource
                            data-tooltip-id={`${category_id.toString()}-${name}-ds`}
                            withTooltip={!!source?.source}
                          >
                            {source.type}
                          </PropertySource>
                          <ReactTooltip
                            id={`${category_id.toString()}-${name}-ds`}
                            className="c-tooltip c-tooltip-base"
                            classNameArrow="c-tooltip-arrow"
                            place={'bottom'}
                            hidden={!source?.source}
                            offset={8}
                            positionStrategy={'fixed'}
                          >
                            <Subheadline color={'#FFFFFF'}>
                              {source.source}
                            </Subheadline>
                          </ReactTooltip>
                        </>
                      )}
                    </PropertyValue>
                  </KeyValueProperty>
                  {isApplicabilityShow &&
                    !!applicabilityData?.[category_title] &&
                    source?.type !== 'exp' && (
                      <ApplicabilityIndicator
                        number={
                          typeof applicabilityData?.[category_title] ===
                          'string'
                            ? null
                            : applicabilityData?.[category_title]
                        }
                      />
                    )}
                </PropertyRow>
              )
            }

            return null
          }
        )}
      </CategoryContent>
    </Container>
  )
}
