import styled from 'styled-components'

export const FilterDoubleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FilterDoubleInputTitle = styled.span`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
`

export const DoubleInputContainer = styled.div`
  height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  transition: all 0.3s;
  display: flex;
  column-gap: 0.5rem;
`

export const InputWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  outline: none;
  border: none;
  background: #f5f7f7;
  border-radius: 0.75rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`
