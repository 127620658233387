import { Tooltip as ReactTooltip } from 'react-tooltip'
import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'
import CustomTag from 'components/common/customTag'
import { useTheme } from 'styled-components'

const IdBlock = ({
  num,
  noIdText,
  noIdDescription,
  type = 'grey',
  withTooltip = true,
}) => {
  const theme = useTheme()
  return (
    <>
      <CustomTag
        data-tip
        data-for={num ? '' : 'id'}
        num={num}
        type={num ? type : 'accent'}
        data-test="molecule-card-btn-id-div"
      >
        <Caption
          color={num ? theme.colors.text.primary : theme.colors.text.white}
          lineHeight={theme.text[14]}
        >
          {num ? `ID: ${num}` : noIdText}
        </Caption>
        {!num && <Icon iconType="magic" size={'1rem'} />}
      </CustomTag>{' '}
      {withTooltip && (
        <ReactTooltip
          id="id"
          className="c-tooltip c-tooltip-base c-tooltip-molviewer"
          offset={10}
          place="bottom-start"
          classNameArrow="c-tooltip-arrow"
        >
          {noIdDescription}
        </ReactTooltip>
      )}
    </>
  )
}

export default IdBlock
