import CustomButton from 'components/common/customButton'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FilterContainer,
  FilterBlock,
  ExitButton,
  Header,
  Footer,
  QueriesBlock,
  PlusIcon,
  AddButton,
} from './index.style'
import {
  setOpenLitFilter,
  setLitFilterConfig,
  setLitFilterDocType,
} from 'store/actions/literature'
import ConditionBlock from './components/ConditionBlock'
import { confirm } from 'store/actions/confirm'
import { setSearchText } from 'store/actions/search'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import CustomScrollbar from 'components/CustomScrollbar'
import { setLeftFilterLitDocType } from 'store/actions/filter'
import { CONFLICT_OPTIONS } from './constants'

const LiteratureFilter = ({ isOpen, config, leftFilterConfig }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsVisible(isOpen))
  }, [isOpen])

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCloseFilter = () => {
    setIsVisible(false)
    setTimeout(() => dispatch(setOpenLitFilter(false)), 500)
    handleSaveConfig()
  }

  const handleResetFilters = () => {
    dispatch(setLitFilterConfig({}))
  }

  const handleAddNewCondition = (option) => {
    const handleAddName = () =>
      dispatch(
        confirm(
          t('confirmation.add_condition'),
          () => {
            dispatch(setLitFilterConfig({ name: '' }))
            dispatch(setLitFilterDocType(option))
            dispatch(setSearchText(''))
          },
          t('filter.literature.name_warning')
        )
      )

    switch (option) {
      case 'doi':
        handleAddName()
        break
      case 'patent_number':
        handleAddName()
        break
      case 'published_date':
        dispatch(
          setLitFilterConfig({
            ...config,
            published_date: { from_date: '', to_date: '' },
          })
        )
        break
      case 'language':
        dispatch(
          setLitFilterConfig({
            ...config,
            language: {
              operator: 'must',
              exact: true,
              values: ['en'],
            },
          })
        )
        break
      case 'text':
        dispatch(
          setLitFilterConfig({
            ...config,
            text: {
              operator: 'must',
              exact: false,
              values: [''],
            },
          })
        )
        break
      case 'claims':
        dispatch(
          setLitFilterConfig({
            ...config,
            claims: {
              operator: 'must',
              exact: false,
              values: [''],
            },
          })
        )
        break
      default:
        dispatch(
          setLitFilterConfig({
            ...config,
            [option]: {
              operator: 'must',
              exact: false,
              values: [''],
            },
          })
        )
        break
    }
  }

  const handleSaveConfig = () => {
    setIsVisible(false)
    const configCopy = structuredClone(config)
    for (let item in configCopy) {
      if (item === 'name' && !configCopy[item]) {
        delete configCopy.name
        dispatch(setLeftFilterLitDocType(['article', 'patent']))
      }
      if (
        item !== 'name' &&
        configCopy[item].values.length === 1 &&
        configCopy[item].values[0] === ''
      )
        delete configCopy[item]
    }
    dispatch(setLitFilterConfig(configCopy))

    let conflictOptions = []
    Object.values(CONFLICT_OPTIONS).map((el) => {
      conflictOptions.push(...el)
    })
    if (conflictOptions.every((el) => !Object.keys(configCopy).includes(el))) {
      dispatch(setLeftFilterLitDocType(['article', 'patent']))
    }

    setTimeout(() => dispatch(setOpenLitFilter(false)), 500)
  }
  const handleOpenContextMenu = (e) => {
    if (Object.keys(config).includes('name')) return
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'literatureFilterMenu',
        item: {
          handler: handleAddNewCondition,
          config: {
            documentTypes: leftFilterConfig?.document_type || [],
            rightConfig: Object.keys(config),
          },
        },
      })
    )
  }
  return (
    isOpen && (
      <FilterContainer isOpen={isVisible} onClick={handleCloseFilter}>
        <FilterBlock isOpen={isVisible} onClick={(e) => e.stopPropagation()}>
          <Header>{t('filter.literature.header')}</Header>
          <CustomScrollbar>
            <QueriesBlock>
              {Object.keys(config).map((el, index) => {
                return (
                  <ConditionBlock key={`${el}-${index}`} currentElement={el} />
                )
              })}

              <AddButton
                type="secondary"
                disabled={Object.keys(config).includes('name')}
                onClick={handleOpenContextMenu}
                gap={'0.25rem'}
              >
                <PlusIcon iconType="add" size="1rem" />
                <Subheadline color="inherit">
                  {t('filter.literature.addCondition')}
                </Subheadline>
              </AddButton>
            </QueriesBlock>
          </CustomScrollbar>
          <Footer>
            <CustomButton
              type="secondary"
              width="100%"
              size="big"
              onClick={handleResetFilters}
              disabled={!Object.keys(config).length}
            >
              <TextBody color="inherit">
                {t('filter.literature.reset')}
              </TextBody>
            </CustomButton>
            <CustomButton
              type="accent"
              width="100%"
              size="big"
              onClick={handleSaveConfig}
              disabled={!Object.keys(config).length}
            >
              <TextBody color="inherit">
                {t('filter.literature.confirm')}
              </TextBody>
            </CustomButton>
          </Footer>
          <ExitButton
            iconType="close"
            size="1.25rem"
            onClick={handleCloseFilter}
          />
        </FilterBlock>
      </FilterContainer>
    )
  )
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.literature.filter.open,
    config: state.literature.filter.config,
    leftFilterConfig: state.filter.config,
  }
}

LiteratureFilter.displayName = 'LiteratureFilter'

export default connect(mapStateToProps)(LiteratureFilter)
