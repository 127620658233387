import {
  RESET_PAGINATION,
  RESET_SORTING_CONFIG,
  SET_DRAWN_SMILES,
  SET_PAGINATION_CONFIG,
  SET_SEARCH_TYPE,
  SET_SORTING_CONFIG,
  SET_SEARCH_SORTING_PARAM,
  SET_TEXT,
  UPDATE_SEARCH_STATE,
} from '../constants/search'

export const initialState = {
  pagination: {
    pagesAmount: 0,
    total: 0,
    perPage: 50,
    activePage: 1,
  },
  searchType: 'structure',
  sorting: {
    type: 'relevance',
    direction: 'desc',
  },
  searchText: '',
  drawnSmiles: '',
}

const searchReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_PAGINATION_CONFIG:
      return {
        ...state,
        pagination: payload.config,
      }
    case RESET_PAGINATION:
      return {
        ...state,
        pagination: initialState.pagination,
      }

    case SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: payload.value,
      }

    case SET_SEARCH_SORTING_PARAM:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [payload.key]: payload.value,
        },
      }
    case SET_SORTING_CONFIG:
      return {
        ...state,
        sorting: {
          ...payload.config,
        },
      }
    case RESET_SORTING_CONFIG:
      return {
        ...state,
        sorting: initialState.sorting,
      }
    case SET_TEXT:
      return {
        ...state,
        searchText: payload.text,
      }
    case SET_DRAWN_SMILES:
      return {
        ...state,
        drawnSmiles: payload.smiles,
      }
    case UPDATE_SEARCH_STATE:
      return {
        ...state,
        ...payload.newState,
      }
    default:
      return state
  }
}

export default searchReducer
