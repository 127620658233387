import IdBlock from 'components/IdBlock'
import IconButtons from './components/IconButtons'
import { IdWithIconButtons, IdsBlock } from './index.style'
import { useTranslation } from 'react-i18next'

const MoleculeIdWithButtons = ({
  num,
  noIdText,
  noPadding,
  noIdDescription,
  showIdWithoutMenu,
  iconButtonsConfig,
  isGoToSectionMenu,
  isBestMatch = false,
  tagType,
}) => {
  const { t } = useTranslation()
  return (
    <IdWithIconButtons noPadding={noPadding} isBestMatch={isBestMatch}>
      <IdsBlock isBestMatch={isBestMatch}>
        {isBestMatch && (
          <IdBlock
            noIdText={t('moleculeslist.best_match')}
            withTooltip={false}
            type={tagType}
          />
        )}
        <IdBlock
          type={tagType}
          {...{ num, noIdText, noIdDescription }}
          withTooltip={!isBestMatch}
        />
      </IdsBlock>
      {!showIdWithoutMenu && (
        <IconButtons
          {...{
            noPadding,
            showIdWithoutMenu,
            iconButtonsConfig,
            isGoToSectionMenu,
            num,
          }}
        />
      )}
    </IdWithIconButtons>
  )
}

export default MoleculeIdWithButtons
