import { TextButtonMedium } from '../text/index.style'
import { SwitchBtn, SwitchWrapper } from './index.style'
import { useTranslation } from 'react-i18next'

const CustomSwitch = ({
  items,
  disabled,
  active,
  wrapperWidth,
  btnWidth,
  minBtnWidth,
  onClick,
  keyTemplate,
  bg = 'grey',
}) => {
  const { t } = useTranslation()
  return (
    <SwitchWrapper wrapperWidth={wrapperWidth} bg={bg}>
      {items.map((item, i) => (
        <SwitchBtn
          key={`${keyTemplate}-${i}`}
          active={active === item.type}
          disabled={disabled}
          btnWidth={btnWidth}
          minBtnWidth={minBtnWidth}
          onClick={() => !disabled && onClick(item.type)}
          bg={bg}
        >
          <TextButtonMedium color="inherit">{t(item.title)}</TextButtonMedium>
        </SwitchBtn>
      ))}
    </SwitchWrapper>
  )
}

export default CustomSwitch
