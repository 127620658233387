import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  display: flex;
  width: ${({ wrapperWidth }) => (wrapperWidth ? wrapperWidth : 'fit-content')};
  padding: 0.125rem;
  border-radius: 0.75rem;
  background: ${({ theme, bg }) =>
    bg === 'grey'
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
`

export const SwitchBtn = styled.button`
  padding: 0.5rem;
  border-radius: 0.625rem;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  width: ${({ btnWidth }) => (btnWidth ? btnWidth : '100%')};
  min-width: ${({ minBtnWidth }) => (minBtnWidth ? minBtnWidth : '')};
  background: ${({ active, theme, bg }) =>
    active
      ? theme.colors.backgrounds.primary
      : bg === 'grey'
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};

  color: ${({ active, theme, disabled }) => {
    if (disabled) return theme.colors.text.tertiary
    if (active) return theme.colors.text.accentPrimary
    return theme.colors.text.secondary
  }};
  box-shadow: ${({ active, theme }) => (active ? theme.shadow.switch : '')};
  z-index: ${({ active }) => (active ? 2 : 1)};



  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
      color: ${({ theme }) => theme.colors.text.accentPrimary};
`
