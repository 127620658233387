import { put, select, takeEvery } from 'redux-saga/effects'

import {
  REPEAT_SEARCH,
  GET_HISTORY_INIT,
  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
} from 'store/constants/searchHistory'

import {
  SET_LIT_FILTER_CONFIG,
  SET_LIT_FILTER_DOC_TYPE,
} from 'store/constants/literature'
import { fetch } from 'services/rest'
import {
  SET_PAGINATION_CONFIG,
  SET_SEARCH_TYPE,
  SET_SORTING_CONFIG,
  SET_TEXT,
} from 'store/constants/search'
import {
  SET_BINGO_SEARCH,
  SET_LIT_SEARCH,
  SET_SEARCH_V2,
  SHOW_SEARCH_V2_RESULT,
} from 'store/constants/crud'
import {
  SET_OPEN_FILTER,
  SET_FILTER_CONFIG,
  SET_FILTER_BUTTONS_DISABLED,
} from 'store/constants/filter'
import { BINGO_PAGINATION_LIMIT, LIT_PAGINATION_LIMIT } from './utils/config'

function* getSearchHistory({ offset, limit }) {
  try {
    const response = yield fetch(
      `/search_history?offset=${offset}&limit=${limit}`
    )

    if (response.status === 200) {
      yield put({
        type: GET_HISTORY_SUCCESS,
        data: response.data,
      })
    } else {
      yield put({
        type: GET_HISTORY_ERROR,
        data: response.statusText,
      })
    }
  } catch (error) {
    yield put({
      type: GET_HISTORY_ERROR,
      data: error?.message || 'Error',
    })
  }
}

function* repeatSearch({ data, showPastResult }) {
  const { params } = data.query
  const filterConfig = yield select((state) => state.filter.config)
  const isFilterOpen = yield select((state) => state.filter.open)

  if (showPastResult) {
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })

    if (data.query.type === 'molecules_search') {
      yield put({
        type: SET_SEARCH_TYPE,
        value: 'structure',
      })
      let match_type = 'exact match'

      const config = { ...filterConfig, match_type }

      yield put({
        type: SET_FILTER_CONFIG,
        config,
      })

      yield put({
        type: SET_TEXT,
        text: params?.searchV2?.text || params?.searchV2?.smiles || '',
        basket: params?.basket,
      })

      if (!isFilterOpen) {
        yield put({
          type: SET_OPEN_FILTER,
          open: true,
        })
      }

      yield put({
        type: SHOW_SEARCH_V2_RESULT,
        taskUuid: data.id,
      })
    } else {
      const limit =
        data.query.type === 'full_text_search'
          ? LIT_PAGINATION_LIMIT
          : BINGO_PAGINATION_LIMIT
      const config = {
        total: data.result_count,
        pagesAmount: Math.ceil(data.result_count / limit),
        perPage: limit,
        activePage: 1,
      }
      yield put({
        type: SET_PAGINATION_CONFIG,
        config,
      })
    }
  }

  if (data.query.type === 'molecules_search' && !showPastResult) {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'structure',
    })
    let match_type = 'exact match'

    const config = { ...filterConfig, match_type }

    yield put({
      type: SET_FILTER_CONFIG,
      config,
    })

    yield put({
      type: SET_TEXT,
      text: params?.searchV2?.text || params?.searchV2?.smiles || '',
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_SEARCH_V2,
      ids: params?.searchV2.ids,
      text: params?.searchV2.text,
      smiles: params?.searchV2.smiles,
      basket: params?.basket,
    })
  }

  if (data.query.type === 'bingo_search') {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'structure',
    })
    let match_type = 'exact match'
    if (params.method === 'sub') match_type = 'substructural search'
    if (params.method === 'sim') match_type = 'similar structures'
    if (params.method === 'markush') match_type = 'markush structures'

    const similarity = {
      0:
        params?.bottom && params.method !== 'markush'
          ? params.bottom.toString()
          : 0,
      1:
        params?.top && params.method !== 'markush'
          ? params.top.toString()
          : 100,
    }
    const molecular_weight = {
      0:
        params?.bottom && params.method === 'markush'
          ? params.bottom.toString()
          : '',
      1:
        params?.top && params.method === 'markush' ? params.top.toString() : '',
    }

    const config = { ...filterConfig, match_type, similarity, molecular_weight }

    yield put({
      type: SET_FILTER_CONFIG,
      config,
    })

    yield put({
      type: SET_TEXT,
      text:
        params?.text ||
        params?.smiles ||
        params?.searchV2?.text ||
        params?.searchV2?.smiles ||
        '',
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_BINGO_SEARCH,
      text: params.smiles,
      label: params.smiles,
      task_uuid: showPastResult ? data.id : null,
      showPastResult,
    })
  } else if (data.query.type === 'full_text_search') {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'literature',
    })
    if (params?.name) {
      const DOIRe = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\\'<>])\S)+)\b/g
      // const patentRe = /([a-zA-Z]{2})([\d\\/]+)([a-zA-Z]\d)/gm
      const checkDOI = new RegExp(DOIRe).test(params.name)
      const docType = checkDOI ? 'doi' : 'patent_number'
      yield put({
        type: SET_LIT_FILTER_DOC_TYPE,
        docType,
      })
    }
    if (params?.title) {
      yield put({
        type: SET_TEXT,
        text: params.title.values[0],
      })
    }
    const { type, structures_ids, published_date, language, sort, ...rest } =
      params
    if (structures_ids) {
      try {
        const { data: fetchData } = yield fetch(
          `/molecule/${params.structures_ids.values[0]}`,
          2
        )
        const data = fetchData?.result || {}
        yield put({
          type: SET_TEXT,
          text: data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O',
        })
      } catch (e) {
        console.log(e)
        yield put({
          type: SET_TEXT,
          text: 'O=C(C)Oc1ccccc1C(=O)O',
        })
      }
    }

    if (sort) {
      yield put({
        type: SET_SORTING_CONFIG,
        config: {
          type: sort.key,
          direction: sort.order_type,
        },
      })
    }
    yield put({
      type: SET_LIT_FILTER_CONFIG,
      config: { ...rest },
    })
    const config = {}
    if (type) {
      config.document_type = params.type
    }
    if (params?.authors) {
      if (params.authors.values.length === 1)
        config.author = params.authors.values[0]
    }
    if (params?.published_date) {
      const { from_date, to_date } = params.published_date
      config.published_date = {
        0: from_date ? from_date.split('-')[0] : '',
        1: to_date ? to_date.split('-')[0] : '',
      }
    }
    if (params?.language) {
      config.language = params.language.values
    }
    yield put({
      type: SET_FILTER_CONFIG,
      config: { ...filterConfig, ...config },
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_LIT_SEARCH,
      task_uuid: showPastResult ? data.id : null,
      showPastResult,
    })
  }
}

export function* getSearchHistoryWatcher() {
  yield takeEvery(GET_HISTORY_INIT, getSearchHistory)
}

export function* repeatSearchWatcher() {
  yield takeEvery(REPEAT_SEARCH, repeatSearch)
}
