import FilterCheckboxes from '../components/FilterCheckboxes'
import FilterDoubleInput from '../components/FilterDoubleInput'
import FilterRange from '../components/FilterRange'
import FilterSelectPoints from '../components/FilterSelectPoints'
import FilterSingleInput from '../components/FilterSingleInput'
import FilterLitConditions from '../components/FilterLitConditions'
import CustomRadioList from '../components/CustomRadioList'

export const defaultFilterConfig = {
  match_type: 'exact match',
  disabled: false,
  similarity: {
    0: 0,
    1: 100,
  },
}

export const filterComponents = {
  filterRadioList: (props) => <CustomRadioList {...props} />,
  filterRange: (props) => <FilterRange {...props} />,
  filterDoubleInput: (props) => <FilterDoubleInput {...props} />,
  filterSingleInput: (props) => <FilterSingleInput {...props} />,
  filterSelectPoints: (props) => <FilterSelectPoints {...props} />,
  filterCheckboxes: (props) => <FilterCheckboxes {...props} />,
  filterLitConditions: (props) => <FilterLitConditions {...props} />,
}

export const filterConfig = {
  structure: [
    {
      type: 'filterRadioList',
      config: {
        title: 'filter.structure.match_type',
        key: 'match_type',
        include_exclude: 'include',
        btns: [
          { name: 'filter.include', value: 'include' },
          { name: 'filter.exclude', value: 'exclude' },
        ],
        options: [
          {
            name: 'filter.structure.exact_match',
            value: 'exact match',
          },
          {
            name: 'filter.structure.substructural_search',
            value: 'substructural search',
          },
          {
            name: 'filter.structure.similar_structures',
            value: 'similar structures',
          },
          {
            name: 'filter.structure.markush_structures',
            value: 'markush structures',
          },
        ],
      },
    },
    {
      type: 'filterRange',
      config: {
        title: 'filter.structure.similarity',
        numberOnly: true,
        step: '1',
        values: [
          {
            placeholder: 'filter.from',
            default: 0,
          },
          {
            placeholder: 'filter.to',
            default: 100,
          },
        ],
        key: 'similarity',
      },
    },
    {
      type: 'filterDoubleInput',
      config: {
        title: 'filter.structure.molecular_weight',
        numberOnly: true,
        nonIntegral: true,
        valueType: 'string',
        searchKey: 'markush structures',
        values: [
          {
            label: 'filter.label_min',
            placeholder: 'filter.min',
          },
          {
            label: 'filter.label_max',
            placeholder: 'filter.max',
          },
        ],

        key: 'molecular_weight',
      },
    },
    // {
    //   type: 'filterDoubleInput',
    //   config: {
    //     title: 'filter.structure.ALogP',
    //     numberOnly: true,
    //     values: [
    //       {
    //         placeholder: 'filter.min',
    //       },
    //       {
    //         placeholder: 'filter.max',
    //       },
    //     ],

    //     key: 'ALogP',
    //   },
    // },
    // {
    //   type: 'filterSelectPoints',
    //   config: {
    //     title: 'filter.structure.elements',
    //     hint: {
    //       icon: 'info',
    //       hintText: '',
    //     },
    //     values: [
    //       {
    //         name: 'C',
    //         value: 'c',
    //       },
    //       {
    //         name: 'H',
    //         value: 'h',
    //       },
    //       {
    //         name: 'N',
    //         value: 'n',
    //       },
    //       {
    //         name: 'O',
    //         value: 'o',
    //       },
    //     ],

    //     key: 'elements',
    //   },
    // },
  ],
  literature: [
    { type: 'filterLitConditions', config: { key: 'literature_conditions' } },
    {
      type: 'filterCheckboxes',
      config: {
        title: 'filter.literature.document_type',
        key: 'document_type',
        values: [
          {
            name: 'filter.structure.article',
            value: 'article',
          },
          {
            name: 'filter.structure.patent',
            value: 'patent',
          },
        ],
      },
    },
    {
      type: 'filterCheckboxes',
      config: {
        title: 'filter.literature.language',
        key: 'language',
        values: [
          {
            name: 'filter.structure.ru',
            value: 'ru',
          },
          {
            name: 'filter.structure.en',
            value: 'en',
          },
          // {
          //   name: 'filter.structure.de',
          //   value: 'de',
          // },
        ],
      },
    },
    {
      type: 'filterDoubleInput',
      config: {
        title: 'filter.structure.published_date',
        numberOnly: true,
        valueType: 'string',
        validate: 'year',
        nonIntegral: false,
        values: [
          {
            placeholder: 'filter.from_rus',
          },
          {
            placeholder: 'filter.to_rus',
          },
        ],

        key: 'published_date',
      },
    },
    // {
    //   type: 'filterSingleInput',
    //   config: {
    //     title: 'filter.structure.author',
    //     numberOnly: false,
    //     valueType: 'string',
    //     value: {
    //       placeholder: 'filter.author_surname',
    //     },
    //     key: 'author',
    //   },
    // },
  ],
}
