import { Point } from '../index.style'

export const getValue = (value, withColors) => {
  const colors = {
    1: '#34C759',
    2: '#FFCC00',
    3: '#FF3B30',
    0: '#9E9E9E',
    '-1': '#212121',
  }

  if (!withColors) {
    return value
  } else {
    return <Point color={colors[value] ?? '#9E9E9E'} />
  }
}

export const getTitle = (key, value) => {
  const label =
    key === 'tox'
      ? {
          1: 'Extremely Toxic (less 5 mg/kg)',
          2: 'Highly Toxic (5-50 mg/kg)',
          3: 'Moderately Toxic(50-300 mg/kg)',
          4: 'Slightly Toxic (300-2000 mg/kg)',
          5: 'Practically Non-toxic(2000 or more mg/kg)',
          0: 'not calculated',
          '-1': 'calculation error',
        }
      : {
          3: 'min',
          2: 'mid',
          1: 'max',
          0: 'not calculated',
          '-1': 'calculation error',
        }

  return `${key}: ${label[value]}`
}
