import { memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { confirm } from 'store/actions/confirm'
import CustomButton from 'components/common/customButton'
import { Headline, TextButtonMedium } from 'components/common/text/index.style'
import { addNotification } from 'store/actions/notifications'
import { settingsMolsize } from 'store/actions/settings'
import { calcTLight, deleteMolecule, updateBasket } from 'store/actions/crud'
import TripleToggleSwitch from 'components/common/customTripleSwitch'
import MainSearchBar from 'components/main-search-bar/main-search-bar'
import HistoryPastResultHeader from 'pages/MoleculesList/HistoryPastResultHeader'
import MoleculesListTopToolbarRestyled from 'components/MoleculesListTopToolbarRestyled'

import {
  Tools,
  Header,
  Toolbar,
  Container,
  BasketInfo,
  SearchBlock,
  BasketHeader,
  ToggleWrapper,
  BackButtonContent,
  BasketDescription,
  BasketInfoAndToolbar,
  BasketInfoAndEditBtn,
} from './index.style'

import { MOLECULES_LIST_PATH } from '../config/config'
import { listViewOptions, valueSelector } from './config'
import {
  selectBasket,
  setBasketDesc,
  setBasketName,
  setEditId,
  setEditMode,
  setShowAddDialog,
} from 'store/actions/basket'
import AddEditDialog from 'components/AddEditDialog'
import SkeletonComponent from 'components/common/skeleton'
import Panel from '../Panel'

const TopBlock = memo(
  ({
    push,
    path,
    basket,
    confirm,
    isPublic,
    selected,
    showTools,
    basketName,
    basketInfo,
    pastResult,
    calcTLight,
    searchType,
    unselectAll,
    usedBasketsNames,
    deleteMolecule,
    onShowAddDialog,
    addNotification,
    scrollTopEnable,
    showHistory,
    settingsMolsize,
    searchV2,
    bingoSearch,
    setBasketDesc,
    setBasketName,
    setEditId,
    setEditMode,
    setShowAddDialog,
    showAddDialog,
    updateBasket,
    selectBasket,
    isBasketLoading,
    isBasketEmpty,
    onShowExportDialog,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const isSearchPage = !path.split('/').includes(MOLECULES_LIST_PATH)
    const showSort = searchType === 'literature' && isSearchPage
    const isLoading =
      searchV2.status === 'loading' || bingoSearch.status === 'loading'

    const handleEditDataset = () => {
      setBasketDesc(basketName)
      setBasketName(basketInfo)
      setEditId(basket)
      setEditMode(true)
      setShowAddDialog(true)
    }

    const handleCloseAddDialog = () => {
      setShowAddDialog(false)
      setBasketName('')
      setBasketDesc('')
      setEditId(null)
      setEditMode(false)
    }

    const closeWithSave = async (name, desc) => {
      selectBasket(null)
      await updateBasket(basket, name, desc)
      handleCloseAddDialog()
    }

    const handleCalcTLight = () => {
      const id = uuidv4()
      const notify = {
        id,
        name: 'moleculeslist.calcStarted',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      unselectAll()
      addNotification(notify)

      calcTLight(
        selected.map(({ id }) => id),
        basket
      )
    }

    const handleOpenConfirmationDialog = () => {
      confirm(
        `${t('moleculeslist.areYouSureDelete')} ${t(
          'moleculeslist.theseItems'
        )}?`,
        () => handleMassDelete()
      )
    }

    const handleMassDelete = () => {
      selected.forEach(({ id }) => {
        deleteMolecule(id, basket)
      })
      unselectAll()
    }
    const handleResize = (value) => {
      const valueToUse = valueSelector?.[value]
      valueToUse && settingsMolsize(+valueToUse)
    }

    return (
      <Container>
        {isSearchPage ? (
          <>
            <HistoryPastResultHeader />
            {!showHistory && (
              <SearchBlock isSearchPage={isSearchPage} pastResult={pastResult}>
                <MainSearchBar isSearchHidden={scrollTopEnable} />
              </SearchBlock>
            )}

            {showTools && !isLoading && (
              <ToggleWrapper>
                <TripleToggleSwitch
                  switcherID="structures"
                  options={listViewOptions}
                  onChange={handleResize}
                />
              </ToggleWrapper>
            )}

            {searchType === 'literature' && showSort && (
              <Panel showOnlySort={!!pastResult} />
            )}
          </>
        ) : (
          <>
            <CustomButton
              type={'text_accent'}
              onClick={() => push('/datasets')}
            >
              <BackButtonContent>
                <Icon iconType={'arrowLeft'} size="1rem" />
                <TextButtonMedium color="inherit">
                  {t('moleculeslist.return_to_datasets')}
                </TextButtonMedium>
              </BackButtonContent>
            </CustomButton>
            <BasketHeader>
              <BasketInfoAndToolbar>
                <BasketInfoAndEditBtn>
                  {isBasketLoading ? (
                    <SkeletonComponent
                      width="37.5rem"
                      height="3.125rem"
                      variant="dark"
                    />
                  ) : (
                    <BasketInfo>
                      <Header>{basketName}</Header>
                      {basketInfo?.trim()?.length > 0 && (
                        <BasketDescription>
                          <Headline
                            fontWeight={theme.fontWeight.medium}
                            color={theme.colors.text.secondary}
                          >
                            {basketInfo}
                          </Headline>
                        </BasketDescription>
                      )}
                    </BasketInfo>
                  )}
                  {!isPublic && (
                    <CustomButton
                      type="primary"
                      gap="0.25rem"
                      disabled={isBasketLoading}
                      onClick={handleEditDataset}
                    >
                      {t('pdf2smiles.dashboard.menu.edit_name')}
                      <Icon iconType="edit" size="1rem" />
                    </CustomButton>
                  )}
                </BasketInfoAndEditBtn>
                {!isBasketEmpty && !isPublic && (
                  <Toolbar>
                    <SearchBlock isSearchPage={isSearchPage}>
                      <MainSearchBar />
                    </SearchBlock>
                    <CustomButton
                      type="primary"
                      gap="0.25rem"
                      disabled={!basket || isBasketLoading}
                      onClick={() => onShowExportDialog()}
                    >
                      {t('dataset_table.table_analysis')}{' '}
                      <Icon iconType="reveal" size="1rem" />
                    </CustomButton>

                    <Tools>
                      <MoleculesListTopToolbarRestyled
                        data={selected.map(({ id }) => id)}
                        onDelete={handleOpenConfirmationDialog}
                        onCalcTLight={handleCalcTLight}
                        isPublic={isPublic}
                        handleShowAddDialog={onShowAddDialog}
                      />
                    </Tools>
                  </Toolbar>
                )}
              </BasketInfoAndToolbar>
              {!isBasketEmpty && (
                <TripleToggleSwitch
                  switcherID="structures"
                  options={listViewOptions}
                  onChange={handleResize}
                  position="relative"
                />
              )}
            </BasketHeader>
          </>
        )}
        {showAddDialog && (
          <AddEditDialog
            closeWithSave={closeWithSave}
            editMode={true}
            handleCloseAddDialog={handleCloseAddDialog}
            name={basketName}
            desc={basketInfo}
            withPortal={true}
            usedNames={usedBasketsNames?.filter((n) => n !== basketName)}
          />
        )}
      </Container>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    isPublic: state.crud.public,
    viewSize: state.settings.viewSize,
    searchType: state.search.searchType,
    path: state.router.location.pathname,
    pastResult: state.searchHistory.pastResult,
    showHistory: state.searchHistory.showHistory,
    searchV2: state.crud.searchV2,
    bingoSearch: state.crud.bingoSearch,
    showAddDialog: state.basket.showAddDialog,
  }
}

const mapDispatchToProps = {
  push,
  confirm,
  calcTLight,
  deleteMolecule,
  addNotification,
  settingsMolsize,
  setBasketDesc,
  setBasketName,
  setEditId,
  setEditMode,
  setShowAddDialog,
  updateBasket,
  selectBasket,
}

TopBlock.displayName = 'TopBlock'
export default connect(mapStateToProps, mapDispatchToProps)(TopBlock)
