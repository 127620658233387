import React, { useState, useEffect, Fragment } from 'react'

import { fetch } from '../../services/rest'
import {
  Content,
  Info,
  Log,
  LogData,
  LogDate,
  LogMessage,
  LogTitle,
} from './index.style'
import { useTranslation } from 'react-i18next'
import { formatUtcDigitDate } from 'utils/dateTime/formatUtcDate'
import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'

const PREFIX = 'baskets.log_dialog.'

const BasketLogDialog = ({ handleClose, id }) => {
  const { t } = useTranslation()
  const [logList, setLogList] = useState([])
  const [loadingStatus, setLoadingStatus] = useState('nope')

  const isLogsListEmpty = logList.length === 0 && loadingStatus === 'success'

  useEffect(() => {
    if (id) loadLog(id)
  }, [id])

  const loadLog = async (id) => {
    try {
      setLoadingStatus('loading')
      const { data } = await fetch(`/baskets_log?id=${id}`)
      setLogList(data)
      setLoadingStatus('success')
    } catch (e) {
      setLoadingStatus('error')
    }
  }

  const getMaxHeight = (status, logsList) => {
    switch (status) {
      case 'loading':
        return '128px'
      case 'success':
        return logsList.length === 0 ? '112px' : '424px'
      default:
        return '424px'
    }
  }

  return (
    <CustomDialog
      width="30rem"
      maxHeight={getMaxHeight(loadingStatus, logList)}
      padding="1.5rem 1rem 1.5rem 1.5rem"
      onClose={handleClose}
    >
      <CustomDialogHeader>
        {t(`${PREFIX}log_messages_viewer`)}
      </CustomDialogHeader>
        <CustomScrollbar>
      <Content>
          {logList.length > 0 && (
            <Fragment>
              {logList.map(({ id, title, message, date }, i) => (
                <Log key={i}>
                  <LogData>
                    <LogTitle key={title}>{title}</LogTitle>
                    {message.map((msg, i) => (
                      <LogMessage key={i}>{msg}</LogMessage>
                    ))}
                  </LogData>
                  <LogDate>{formatUtcDigitDate(date)}</LogDate>
                </Log>
              ))}
            </Fragment>
          )}
          {isLogsListEmpty && <Info>{t(`${PREFIX}logs_are_empty`)}</Info>}
          {loadingStatus === 'error' && (
            <Info>{t(`${PREFIX}failed_to_fetch_log_messages`)}</Info>
          )}
          {loadingStatus === 'loading' && (
            <Icon iconType="loader" size="3rem" />
          )}
      </Content>
        </CustomScrollbar>
    </CustomDialog>
  )
}

export default BasketLogDialog
