import styled from 'styled-components'

export const CustomTextAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  overflow-y: hidden;
`

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  padding: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  height: ${({ height }) => (height ? height : '7rem')};
  padding-right: ${({ showClearButton }) => showClearButton && '2.625rem'};
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  cursor: auto;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`

export const CustomTextAreaClearButton = styled.div`
  background: ${({ theme }) => theme.colors.buttons.primary};
  color: ${({ theme }) => theme.colors.icons.tertiary};
  top: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  right: 0.375rem;
  cursor: pointer;
  padding: 0.25rem;
  position: absolute;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`
