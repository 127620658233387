import MoleculeViewer from '../../MoleculeViewer'
import { BORDER_DELTA } from '../config'
import { getCardType } from '../utils/utils'
import { CarouselDiv, CarouselWrapper } from './index.style'
import { withResizeDetector } from 'react-resize-detector'

const Carousel = ({
  carouselCounter,
  structures,
  width,
  isDialog,
  litSearchSmiles,
}) => {
  const paddingDelta = isDialog ? 8 : 0
  const viewCount = width < 692 ? 2 : width < 1400 ? 3 : 4
  const preparedData =
    structures?.length && structures.slice(0, carouselCounter + viewCount + 2)

  const getTranslate = () => {
    return `translate(-${
      carouselCounter *
      (Math.floor((width - paddingDelta - (viewCount - 1) * 12) / viewCount) +
        12)
    }px)`
  }

  return (
    <CarouselWrapper id="carouselWrapper">
      <CarouselDiv transformValue={getTranslate} isDialog={isDialog}>
        {preparedData &&
          preparedData.map((el, ind) => (
            <MoleculeViewer
              key={el.id}
              molid={el.id}
              withColors={true}
              num={el.id}
              noEdit
              noDelete
              noMenu
              showIdWithoutMenu
              onShowProps={() => {}}
              smiles={el?.smiles_kekule ?? el.smiles}
              type={getCardType(
                isDialog,
                ind === 0 && el.smiles === litSearchSmiles
              )}
              width={
                Math.floor(
                  (width - paddingDelta - (viewCount - 1) * 12) / viewCount
                ) - BORDER_DELTA
              }
              height={248}
              noPadding={true}
              cursor="pointer"
              withData
              params={JSON.stringify(
                el.tlight ? el.tlight : { tox: 0, phys: 0, bio: 0, eco: 0 }
              )}
              litSearchSmiles={litSearchSmiles}
              referencesCount={el?.references_in_documents}
            />
          ))}
      </CarouselDiv>
    </CarouselWrapper>
  )
}

export default withResizeDetector(Carousel)
