//molecule
export const getMoleculeServices = (state) => state.molecule.services.data

export const getMoleculeBasketId = (state) => state.molecule.properties.basketId

export const getMoleculeStructuralData = (state) =>
  state.molecule.properties.data?.structural

//mmap
export const getIs3dMapMode = (state) => state.mmap.is3dMapMode

export const getLayers3d = (state) => state.mmap.layers3d

export const getMMapLayers = (state) => state.mmap.layers
