import styled from 'styled-components'

export const ProgressBar = styled.div`
  width: 100%;
  height: 0.75rem;
  position: relative;
  place-items: center;
  border-radius: 0.75rem;
  box-sizing: border-box;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};

  & > span {
    height: 100%;
    display: block;
    position: relative;
    border-radius: 0.75rem;
    transition: all 0.5s ease;
    background-size: 200% 200%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
  }
`

export const BasketBody = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

export const BasketTopArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BasketSize = styled.span`
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
`

export const BasketToolsButton = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.icons.accentPrimary : theme.colors.icons.tertiary};

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`
export const BasketNameWithDescription = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const BasketName = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  max-width: 47.5rem;
  font-size: ${({ theme }) => theme.text[14]};
  line-height: ${({ theme }) => theme.text[18]};
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

export const BasketDescription = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
`

export const BasketBottomArea = styled.div`
  margin-top: auto;
`
export const DateWithBtn = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const OpenBasketButton = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.icons.accentPrimary};
`

export const BasketWrapper = styled.div`
  padding: 1rem;
  display: flex;
  cursor: pointer;
  height: 11.5rem;
  width: 100%;
  min-width: 15.875rem;
  user-select: none;
  box-sizing: border-box;
  border-radius: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? '0px 4px 12px 0px rgba(45, 55, 69, 0.04), 0px -4px 12px 0px rgba(45, 55, 69, 0.04), 0px 0px 4px 0px rgba(45, 55, 69, 0.02);'
      : 'none'};
  border: 1.2px solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.colors.icons.accentPrimary}` : 'transparent'};

  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(45, 55, 69, 0.04),
      0px -4px 12px 0px rgba(45, 55, 69, 0.04),
      0px 0px 4px 0px rgba(45, 55, 69, 0.02);

    ${OpenBasketButton} {
      visibility: visible;
      visibility: ${({ isSelectingNow }) =>
        isSelectingNow ? 'hidden' : 'visible'};
    }

    ${BasketName} {
      color: ${({ isSelectingNow, theme }) =>
        isSelectingNow ? 'inherit' : theme.colors.text.accentPrimary};
    }
  }
`
