import { useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Rect } from '../../index.style'
import { scaleLinear } from '@visx/scale'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'
import { withResizeDetector } from 'react-resize-detector'
import { useTranslation } from 'react-i18next'
import {
  setNmrAdditionalAtom,
  setNmrChartActiveAtom,
} from 'store/actions/spectra'
import { TABLE_WIDTH } from '../../config'
import { Zoom } from '@visx/zoom'
import { v4 as uuidv4 } from 'uuid'
import { getAverage, getScrollScale } from '../../helpers'
import { useTheme } from 'styled-components'

const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
}

const DEFAULT_OFFSET = 16

const HydroChart = ({
  nmrData,
  activeAtoms,
  additionalAtom,
  width = TABLE_WIDTH,
  duplicates,
}) => {
  const chartWidth = width - TABLE_WIDTH
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()

  const ICONS_SEC = theme.colors.icons.secondary
  const TEXT_PRIMARY = theme.colors.text.primary
  const TEXT_ACCENT = theme.colors.text.accentPrimary
  const BACK_SEC = theme.colors.backgrounds.secondary

  const height = 464
  const margin = { top: 0, right: 16, bottom: 40, left: 40 }

  const shiftValues = nmrData.shifts
  const intensities = nmrData.intensities
  const atom_index = nmrData.atom_index

  const flattedShiftValues = shiftValues.flat(Infinity)
  const flattedIntensities = intensities.flat(Infinity)

  const minShift = Math.min(...flattedShiftValues) - 1
  const maxShift = Math.max(...flattedShiftValues) + 1

  const intensitiesFlats = useMemo(() => {
    const flats = {}
    intensities.forEach((el, index) => {
      if (flats[getAverage(el)]) {
        flats[getAverage(el)] = [...flats[getAverage(el)], index]
      } else flats[getAverage(el)] = [index]
    })
    return flats
  }, [intensities])

  const handleMouseOver = (shift, index) => {
    dispatch(setNmrChartActiveAtom(duplicates[shift]))
    dispatch(
      setNmrAdditionalAtom({ shifts: shift, intensities: intensities[index] })
    )
  }

  const handleMouseOut = () => {
    dispatch(setNmrChartActiveAtom([]))
    dispatch(setNmrAdditionalAtom(null))
  }

  return (
    <Zoom
      width={width}
      height={height}
      scaleXMin={1}
      scaleXMax={30}
      scaleYMin={1}
      scaleYMax={1}
      transformMatrix={initialTransform}
    >
      {(zoom) => {
        const rescaleXAxis = (scale, zoom) => {
          let newDomain = scale.range().map((r) => {
            return scale.invert(
              (r - zoom.transformMatrix.translateX) /
                zoom.transformMatrix.scaleX
            )
          })
          return scale.copy().domain(newDomain)
        }

        const xScale = scaleLinear({
          range: [chartWidth - margin.right, margin.left],
          domain: [minShift, maxShift],
        })

        const zoomedScaleX = rescaleXAxis(xScale, zoom)

        const getTranslate = (index) => {
          const x = getTranslateX(index)
          const y = getTranslateY(index)
          return `translate(${x}, -${y})`
        }

        const getTranslateX = (index) => {
          if (duplicates[shiftValues[index].toString()].length > 1) {
            const duplicatesArray = duplicates[shiftValues[index]]
            const index2 = duplicatesArray.findIndex((el) => el === index)

            if (duplicatesArray.length % 2) {
              return (
                (index2 - Math.floor(duplicatesArray.length / 2)) *
                DEFAULT_OFFSET
              )
            } else {
              const centeredTranslateX = -6
              return (
                (index2 - (duplicatesArray.length / 2 - 1)) * DEFAULT_OFFSET +
                centeredTranslateX
              )
            }
          } else {
            return 0
          }
        }

        const getTranslateY = (index) => {
          const defaultTranslate = 0

          const curEl = zoomedScaleX(getAverage(shiftValues[index]))
          const nextEl = zoomedScaleX(getAverage(shiftValues[index + 1]))

          const flatKey = getAverage(intensities[index])
          const currentElFlatIndex = intensitiesFlats[flatKey].indexOf(index)

          const nextIntensityFlatEl =
            currentElFlatIndex + 1 === intensitiesFlats[flatKey].length
              ? null
              : zoomedScaleX(
                  getAverage(
                    shiftValues[
                      intensitiesFlats[flatKey][currentElFlatIndex + 1]
                    ]
                  )
                )

          if (duplicates[shiftValues[index].toString()].length > 1) {
            return defaultTranslate
          }
          if (index === shiftValues.length - 1) {
            return defaultTranslate
          }
          if (
            curEl - nextEl < DEFAULT_OFFSET &&
            yScale(getAverage(intensities[index])) ===
              yScale(getAverage(intensities[index + 1]))
          ) {
            return 24 + getTranslateY(index + 1)
          }

          if (
            nextIntensityFlatEl &&
            curEl - nextIntensityFlatEl < DEFAULT_OFFSET
          ) {
            return (
              24 +
              getTranslateY(intensitiesFlats[flatKey][currentElFlatIndex + 1])
            )
          }
          return defaultTranslate
        }

        const topOffsetCount = () => {
          if (Math.max(...flattedIntensities) < 5) return 0.5
          return Math.max(...flattedIntensities) * 0.1
        }

        const yScale = scaleLinear({
          domain: [0, Math.max(...flattedIntensities) + topOffsetCount()],
          range: [height - margin.bottom, margin.top],
        })

        return (
          <svg
            width={chartWidth}
            height={height}
            ref={zoom.containerRef}
            style={{ userSelect: 'none' }}
            onWheel={(e) => {
              getScrollScale(e, zoom)
            }}
            onTouchStart={zoom.dragStart}
            onTouchMove={zoom.dragMove}
            onTouchEnd={zoom.dragEnd}
            onMouseDown={(e) => {
              zoom.dragStart(e)
            }}
            onMouseMove={zoom.dragMove}
            onMouseUp={zoom.dragEnd}
            onMouseLeave={() => {
              if (zoom.isDragging) zoom.dragEnd()
            }}
          >
            <GridRows
              scale={yScale}
              left={margin.left}
              width={chartWidth - margin.left - margin.right}
              numTicks={10}
              stroke={BACK_SEC}
              strokeWidth={1}
              strokeOpacity={1}
            />
            <GridColumns
              scale={zoomedScaleX}
              top={height - margin.bottom}
              height={-height + margin.top + margin.bottom}
              width={chartWidth - margin.left - margin.right}
              numTicks={5}
              stroke={BACK_SEC}
              strokeWidth={1}
              strokeOpacity={1}
              tickValues={zoomedScaleX.ticks()}
            />
            <rect
              x={chartWidth - margin.right}
              y={margin.top}
              width={1}
              height={height - margin.top - margin.bottom}
              fill={BACK_SEC}
            />

            {shiftValues.map((shift, index) => {
              const currentDomain = zoomedScaleX.domain()
              const translate = getTranslate(index)
              if (
                shift[0] < currentDomain[1] &&
                shift[shift.length - 1] > currentDomain[0]
              ) {
                return (
                  Array.isArray(shift) &&
                  shift.map((el, idx) => {
                    if (el > currentDomain[0] && el < currentDomain[1]) {
                      let zoomedX = zoomedScaleX(el)
                      const translateX = getTranslateX(index)

                      const centerIndex =
                        shift.length % 2
                          ? Math.floor(shift.length / 2)
                          : shift.length / 2 - 1

                      const currentXScale =
                        shift.length % 2
                          ? zoomedScaleX(el)
                          : zoomedScaleX(el) +
                            (zoomedScaleX(shift[centerIndex + 1]) -
                              zoomedScaleX(el)) /
                              2

                      const clipPathXF = () => {
                        // calc x offset for duplicates by shifts || returns number
                        if (duplicates[shiftValues[index]].length > 1) {
                          if (
                            zoomedX >
                            margin.left - translateX - DEFAULT_OFFSET
                          ) {
                            return shift.length % 2
                              ? zoomedX - DEFAULT_OFFSET
                              : currentXScale - DEFAULT_OFFSET
                          } else {
                            return (
                              zoomedX - (zoomedX + translateX - margin.left)
                            )
                          }
                        }
                        // calc x offset for single shift || returns number
                        if (shiftValues[index].length > 1) {
                          zoomedX = zoomedScaleX(
                            shiftValues[index].reduce((p, c) => p + c, 0) /
                              shiftValues[index].length
                          )
                        }
                        return (
                          zoomedX -
                          (zoomedX > margin.left + DEFAULT_OFFSET
                            ? DEFAULT_OFFSET
                            : zoomedX - margin.left)
                        )
                      }
                      const clipPathX = clipPathXF()

                      return (
                        <g
                          key={`${index}-${idx}-${el}-g`}
                          onMouseOver={() => handleMouseOver(shift, index)}
                          onMouseOut={handleMouseOut}
                        >
                          <Rect
                            id={`${idx}-${el}`}
                            x={zoomedScaleX(el)}
                            y={yScale(intensities[index][idx])}
                            width={1}
                            height={
                              height -
                              margin.bottom -
                              yScale(intensities[index][idx])
                            }
                            fill={
                              activeAtoms.includes(index)
                                ? TEXT_ACCENT
                                : ICONS_SEC
                            }
                          />
                          <Rect
                            id={`${index}-${idx}-${el}_1`}
                            x={zoomedScaleX(el)}
                            y={yScale(intensities[index][idx])}
                            width={8}
                            height={height - margin.bottom - margin.top}
                            fill="transparent"
                          />
                          {
                            <defs>
                              <clipPath id={`${index}-${idx}-${shift}-clip`}>
                                <rect
                                  x={clipPathX}
                                  y={yScale(intensities[index][idx]) - 36}
                                  width="36"
                                  height="36"
                                />
                              </clipPath>
                            </defs>
                          }
                          {idx === centerIndex && (
                            <g
                              x={currentXScale}
                              y={yScale(intensities[index][idx] + 0.3)}
                              transform={translate}
                              key={`${index}-${index}-number`}
                              clipPath={`url(#${index}-${idx}-${shift}-clip)`}
                            >
                              <filter
                                id="filter"
                                x="-20%"
                                y="-20%"
                                width="140%"
                                height="140%"
                                filterUnits="objectBoundingBox"
                                primitiveUnits="userSpaceOnUse"
                                colorInterpolationFilters="linearRGB"
                              >
                                <feDropShadow
                                  stdDeviation="4 4"
                                  in="SourceGraphic"
                                  dx="0"
                                  dy="0"
                                  floodColor="#1f2933"
                                  floodOpacity="0.06"
                                  x="0%"
                                  y="0%"
                                  width="100%"
                                  height="100%"
                                  result="dropShadow1"
                                />
                                <feDropShadow
                                  stdDeviation="8 8"
                                  in="dropShadow1"
                                  dx="0"
                                  dy="-4"
                                  floodColor="#1f2933"
                                  floodOpacity="0.02"
                                  x="0%"
                                  y="0%"
                                  width="100%"
                                  height="100%"
                                  result="dropShadow"
                                />
                                <feDropShadow
                                  stdDeviation="8 8"
                                  in="dropShadow1"
                                  dx="0"
                                  dy="-4"
                                  floodColor="#1f2933"
                                  floodOpacity="0.02"
                                  x="0%"
                                  y="0%"
                                  width="100%"
                                  height="100%"
                                  result="dropShadow3"
                                />
                              </filter>

                              <Rect
                                filter="url(#filter)"
                                x={currentXScale}
                                y={yScale(intensities[index][idx]) - 30}
                                rx={8}
                                width="20"
                                height="20"
                                fill="#ffffff"
                                transform={'translate(-10)'}
                              />

                              <text
                                x={currentXScale}
                                y={yScale(intensities[index][idx]) - 19}
                                fontSize="11"
                                fill={
                                  activeAtoms.includes(index)
                                    ? TEXT_ACCENT
                                    : ICONS_SEC
                                }
                                textAnchor="middle"
                                alignmentBaseline="middle"
                              >
                                {atom_index[index]}
                              </text>
                            </g>
                          )}
                        </g>
                      )
                    }
                    return null
                  })
                )
              }
              return null
            })}
            {additionalAtom?.shifts &&
              additionalAtom.shifts.map((shift, index) => (
                <Rect
                  key={uuidv4()}
                  id={`${index}-${shift}-additional`}
                  x={zoomedScaleX(shift)}
                  y={yScale(additionalAtom.intensities[index])}
                  width={1}
                  height={
                    height -
                    margin.bottom -
                    yScale(additionalAtom.intensities[index])
                  }
                  fill={TEXT_ACCENT}
                />
              ))}

            <AxisBottom
              scale={zoomedScaleX}
              top={height - margin.bottom}
              tickFormat={(value) => value}
              stroke={ICONS_SEC}
              hideTicks={true}
              tickLabelProps={() => ({
                fill: ICONS_SEC,
                fontSize: 11,
                textAnchor: 'end',
                fontWeight: 400,
                fontFamily: 'Geologica Cursive',
                lineHeight: 14,
              })}
              label={t('spectra.chart.shift')}
              labelProps={{
                fontSize: 11,
                lineHeight: 14,
                fontWeight: 400,
                fill: TEXT_PRIMARY,
                fontFamily: 'Geologica Cursive',
                textAnchor: 'middle',
                x: (width - 250) / 2,
              }}
            />

            <AxisLeft
              scale={yScale}
              left={margin.left}
              numTicks={Math.max(...flattedIntensities)}
              hideZero={false}
              tickFormat={(value) => value}
              stroke={ICONS_SEC}
              hideTicks={true}
              tickLabelProps={() => ({
                fill: ICONS_SEC,
                fontSize: 11,
                textAnchor: 'end',
                fontWeight: 400,
                fontFamily: 'Geologica Cursive',
                lineHeight: 12,
                angle: -90,
              })}
              label={t('spectra.chart.relative_intensity')}
              labelOffset={20}
              labelProps={{
                fontSize: 11,
                lineHeight: 14,
                fontWeight: 400,
                fill: TEXT_PRIMARY,
                textAnchor: 'middle',
                fontFamily: 'Montserrat',
                x: -height / 2,
              }}
            />
          </svg>
        )
      }}
    </Zoom>
  )
}

const mapStateToProps = (state) => {
  return {
    nmrData: state.spectra.nmr.data,
    activeAtoms: state.spectra.nmr.chartActiveAtoms,
    additionalAtom: state.spectra.nmr.additionalAtom,
  }
}

export default connect(mapStateToProps)(withResizeDetector(HydroChart))
