import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { addNotification } from 'store/actions/notifications'

import {
  Container,
  ImageBlock,
  CommentsBlock,
  FeedbackLabel,
  CommentElement,
} from './index.style'
import LoadingOverlay from '../LoadingOverlay'
import { fetch, update } from '../../services/rest'
import { DataNameBlock, OpenedMessage } from '../FeedbackForm/index.style'
import CustomScrollbar from 'components/CustomScrollbar'

const FeedbackViewer = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState({})
  const dispatch = useDispatch()

  const { t } = useTranslation()

  useEffect(() => {
    loadFeedbackList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadOneFeedback = (id) => async () => {
    try {
      if (id === loaded?.id) {
        setLoaded({})
      } else {
        setLoading(true)
        const { data } = await fetch(`/feedback/${id}`)
        setLoading(false)
        setLoaded(data)
      }
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
      setLoading(false)
    }
  }

  const loadFeedbackList = async () => {
    try {
      setLoading(true)
      const { data } = await fetch('/feedback')
      setLoading(false)
      setData(data)
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: e.message,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
        needTranslateText: false,
      }
      dispatch(addNotification(notify))
      setLoading(false)
    }
  }

  const setHandled = (id, ishandled) => async () => {
    try {
      await update(`/feedback/${id}`, { handled: !ishandled })
      setLoaded({})
      loadFeedbackList()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <CustomScrollbar className="margin-4-right">
      <Container>
        <FeedbackLabel>{t('feedback.header')}</FeedbackLabel>
        <CommentsBlock>
          {data.map(({ comment, date, id, ishandled, login }) => {
            return (
              <>
                <CommentElement
                  key={id}
                  handled={ishandled}
                  onClick={loadOneFeedback(id)}
                >
                  <div>{format(new Date(date), 'dd.MM.yyyy HH:mm')}</div>
                  <div style={{ wordBreak: 'break-all' }}>{login}</div>
                  <div>{comment}</div>
                </CommentElement>
                {loaded?.id === id && (
                  <OpenedMessage>
                    <DataNameBlock>
                      <div>{loaded.mail}</div>
                      <div>
                        {format(new Date(loaded.date), 'dd.MM.yyyy HH:mm')}
                      </div>
                    </DataNameBlock>

                    <div style={{ fontWeight: '600' }}>{loaded.comment}</div>
                    <div>
                      {loaded.attach.map(({ data, name }) => {
                        return (
                          <div key={name}>
                            {data && <ImageBlock src={data} />}
                          </div>
                        )
                      })}
                    </div>
                    <CustomButton
                      type="accent"
                      onClick={setHandled(id, ishandled)}
                      style={{ justifySelf: 'center' }}
                    >
                      <Icon
                        iconType={!ishandled ? 'tick' : 'close'}
                        size="1rem"
                      />
                    </CustomButton>
                  </OpenedMessage>
                )}
              </>
            )
          })}
        </CommentsBlock>
        {loading && <LoadingOverlay />}
      </Container>
    </CustomScrollbar>
  )
}

export default FeedbackViewer
