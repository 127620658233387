import { useEffect, useRef } from 'react'

import Icon from 'components/Icon'
import { Label } from 'components/common/text/index.style'

import { Menu, MenuItem } from './index.style'

const CustomNodeMenu = ({ type, menuButtonsConfig, onCloseMenu }) => {
  const menuRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef?.current && !menuRef.current.contains(e.target)) onCloseMenu()
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <Menu type={type} ref={menuRef}>
      {menuButtonsConfig?.map(
        ({ text, isHidden, onClick, disabled, withSubActions }, i) =>
          !isHidden && (
            <MenuItem
              key={i}
              onClick={(e) => {
                e.stopPropagation()
                !disabled && onClick(e)
              }}
              disabled={disabled}
            >
              <Label color="inherit">{text}</Label>
              {withSubActions && <Icon iconType="arrowRight" size="1rem" />}
            </MenuItem>
          )
      )}
    </Menu>
  )
}

export default CustomNodeMenu
