import { memo, useMemo } from 'react'
import { connect } from 'react-redux'

import {
  setBingoSearch,
  loadMoreMolecules,
  setLiteratureSearch,
} from 'store/actions/crud'
import Icon from 'components/Icon'
import { setPaginationConfig } from 'store/actions/search'

import {
  IndicatorBtn,
  PaginationBtn,
  PaginationBtns,
  PaginationBlock,
} from './index.style'
import {
  MIDDLE_RANGE_COUNT,
  PAGINATION_SIBLINGS_COUNT,
  PAGINATION_VISIBLE_PAGES_COUNT,
} from '../config/config'
import { getPagesRange } from '../utils/utils'

const Pagination = memo(
  ({
    basket,
    litTaskId,
    pagination,
    pastResult,
    bingoTaskId,
    setBingoSearch,
    loadMoreMolecules,
    setLiteratureSearch,
    setPaginationConfig,
  }) => {
    const { pagesAmount, activePage, perPage } = pagination

    const pages = useMemo(() => {
      const totalPageNumbers =
        PAGINATION_SIBLINGS_COUNT + PAGINATION_VISIBLE_PAGES_COUNT
      if (totalPageNumbers >= pagesAmount) getPagesRange(1, pagesAmount)

      const showLeftDots =
        activePage > PAGINATION_VISIBLE_PAGES_COUNT &&
        activePage - PAGINATION_SIBLINGS_COUNT > 3

      const showRightDots =
        pagesAmount > PAGINATION_VISIBLE_PAGES_COUNT &&
        (activePage <= PAGINATION_VISIBLE_PAGES_COUNT ||
          activePage + PAGINATION_SIBLINGS_COUNT < pagesAmount - 2)

      if (!showLeftDots && showRightDots) {
        let leftItemCount = 3 + 2 * PAGINATION_SIBLINGS_COUNT
        let leftRange = getPagesRange(1, leftItemCount)
        return [...leftRange, '...']
      }

      if (showLeftDots && !showRightDots) {
        let rightRange = getPagesRange(activePage, pagesAmount)
        return ['...', ...rightRange]
      }

      if (showLeftDots && showRightDots) {
        let middleRange = getPagesRange(
          activePage,
          activePage + PAGINATION_SIBLINGS_COUNT + 2
        )
        return ['...', ...middleRange, '...']
      }

      return getPagesRange(1, pagesAmount)
    }, [activePage, pagesAmount])

    const getPage = (activePage) => {
      if (
        activePage <
        MIDDLE_RANGE_COUNT +
          PAGINATION_VISIBLE_PAGES_COUNT +
          PAGINATION_SIBLINGS_COUNT
      ) {
        if (
          activePage ===
          PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
        )
          return PAGINATION_VISIBLE_PAGES_COUNT
        if (
          activePage >
          PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
        )
          return PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
      } else {
        return activePage - MIDDLE_RANGE_COUNT
      }
    }

    const handleChangeActivePage = (value, ind) => {
      let page
      if (typeof value === 'string') {
        const pagesNumbers = pages.filter((el) => typeof el === 'number')
        page = ind === 0 ? getPage(activePage) : pagesNumbers.pop() + 1
      }
      let newValue = typeof value === 'string' ? page : value
      setPaginationConfig({
        ...pagination,
        activePage: newValue,
      })
      if (!(bingoTaskId || litTaskId || pastResult)) {
        loadMoreMolecules({
          basket,
          limit: pagination.perPage,
          page: newValue,
        })
      } else if (bingoTaskId || pastResult?.query?.type === 'bingo_search') {
        setBingoSearch({
          task_uuid: bingoTaskId || pastResult.id,
          offset: (newValue - 1) * perPage,
        })
      } else if (litTaskId || pastResult?.query?.type === 'full_text_search') {
        setLiteratureSearch({
          task_uuid: litTaskId || pastResult.id,
          offset: (newValue - 1) * perPage,
        })
      }
    }

    return (
      <PaginationBlock>
        <IndicatorBtn
          disabled={activePage === 1}
          data-test="pagination-btn-prev"
          onClick={() =>
            activePage > 1 && handleChangeActivePage(activePage - 1)
          }
        >
          <Icon iconType="arrowLeft" size="1rem" />
        </IndicatorBtn>
        <PaginationBtns>
          {pages?.map((el, key) => {
            return (
              <PaginationBtn
                key={key}
                selected={el === activePage}
                data-test="pagination-btn"
                onClick={() => handleChangeActivePage(el, key)}
              >
                {el}
              </PaginationBtn>
            )
          })}
        </PaginationBtns>
        <IndicatorBtn
          disabled={activePage === pagesAmount}
          data-test="pagination-btn-next"
          onClick={() =>
            activePage < pagesAmount && handleChangeActivePage(activePage + 1)
          }
        >
          <Icon iconType="arrowRight" size={'1rem'} />
        </IndicatorBtn>
      </PaginationBlock>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    basket: state.crud.openedBasketID,
    pagination: state.search.pagination,
    litTaskId: state.crud.litSearch.taskId,
    bingoTaskId: state.crud.bingoSearch.taskId,
    pastResult: state.searchHistory.pastResult,
  }
}

const mapDispatchToProps = {
  setBingoSearch,
  loadMoreMolecules,
  setPaginationConfig,
  setLiteratureSearch,
}

Pagination.displayName = 'Pagination'
export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
