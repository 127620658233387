import { memo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Subheadline } from 'components/common/text/index.style'

const MyDropzone = memo(({ setFiles }) => {
  const { t } = useTranslation()
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map(async (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          setFiles((f) => [...f, { name: file.name, data: reader.result }])
        }
      })
    },
    [setFiles]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    noClick: true,
  })

  return (
    <div {...getRootProps()}>
      <input id="add-file-input" {...getInputProps()} />
      {isDragActive ? (
        <Subheadline>{t('feedback.dragMsg')}</Subheadline>
      ) : (
        <CustomButton
          type="text"
          gap="0.25rem"
          as={'label'}
          htmlFor="add-file-input"
        >
          {t('feedback.addFileButton')}
          <Icon iconType="upload" size="1rem" />
        </CustomButton>
      )}
    </div>
  )
})

MyDropzone.displayName = 'MyDropzone'
export default MyDropzone
