import axios from 'axios'
import { t } from 'i18next'
import { fetch, getHeaders, store } from 'services/rest'
import { takeEvery, put, delay } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import {
  REACTION_CALC_INIT,
  REACTION_CALC_FAIL,
  REACTION_CALC_SUCCESS,
  REACTION_SYNTH_CALC_INIT,
  REACTION_RETRO_CALC_INIT,
} from '../constants/reactions'
import { API_URL } from '../../config/config'
import { reactionEncode } from '../../utils/reactions/reactions'
import { ADD_NOTIFICATION } from 'store/constants/notifications'

function* doCalculate({ mode, data, model, naug, beam }) {
  const apiUrl = `${API_URL}apply_transformer`
  const smiles = data.join('.')

  try {
    const resp = yield axios.post(
      apiUrl,
      { smiles, model, naug, beam },
      {
        headers: getHeaders(),
      }
    )
    let reactions
    if (mode === 'forward') {
      reactions = resp.data.map((reaction) => {
        let rightSide = reaction.products.map((product) => product.smiles)
        return { ...reaction, encoded: reactionEncode(data, rightSide) }
      })
    } else if (mode === 'reverse') {
      reactions = resp.data.map((reaction) => {
        let leftSide = reaction.products.map((product) => product.smiles)
        return { ...reaction, encoded: reactionEncode(leftSide, data) }
      })
    }

    yield put({
      type: REACTION_CALC_SUCCESS,
      calculation: reactions,
    })
  } catch (err) {
    yield put({
      type: REACTION_CALC_FAIL,
      error: err,
    })
  }
}

function* calculateSynth({ data, model, naug, beam }) {
  const smiles = data.join('.')

  try {
    const resp = yield store(`${API_URL}apply_transformer`, {
      smiles,
      model,
      naug,
      beam,
    })

    if (resp.data[0].products[0].smiles.includes('fail'))
      yield put({
        type: REACTION_CALC_SUCCESS,
        calculation: [],
      })
    else {
      let reactions = resp.data.map((reaction) => {
        let rightSide = reaction.products.map((product) => product.smiles)
        return { ...reaction, encoded: reactionEncode(data, rightSide) }
      })

      yield put({
        type: REACTION_CALC_SUCCESS,
        calculation: reactions,
      })
    }
  } catch (error) {
    yield put({
      type: REACTION_CALC_FAIL,
      error:
        error?.response?.data?.detail ||
        t('reactions_prediction.synthesis_failed'),
    })
  }
}

function* calculateRetro({ smiles }) {
  try {
    const response = yield store(`${API_URL}run-task`, {
      service: 'reactions_tree',
      params: {
        smiles,
      },
      type: 'delayed',
    })
    if (response?.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response?.data?.task_uuid
        const tasks = yield fetch(`/userTasksStatus`)
        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          if (task?.status === 'ok') {
            status = 'ok'
            const res = yield fetch(
              `/userTasksStatus/${response.data.task_uuid}`
            )
            yield put({
              type: REACTION_CALC_SUCCESS,
              calculation: res?.data?.result,
            })
          }
        }
        yield delay(2000)
      }
    }
  } catch (error) {
    yield put({
      type: REACTION_CALC_FAIL,
      error:
        error?.response?.data?.detail ||
        t('reactions_prediction.retrosynthesis_failed'),
    })

    const id = uuidv4()
    const isSmilesError =
      error?.response?.data?.detail?.endsWith('is not a smiles')
    const notify = {
      id,
      name: t(
        isSmilesError
          ? 'notification.we_couldnt_find_desired_structure'
          : 'notification.retrosynthesis_failed'
      ),
      text: t(
        isSmilesError
          ? 'notification.check_your_spelling_or_enter_SMILES'
          : 'reactions_prediction.retrosynthesis_failed'
      ),
      notification_type: 'warning',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* doCalculateWatcher() {
  yield takeEvery(REACTION_CALC_INIT, doCalculate)
}

export function* calculateSynthWatcher() {
  yield takeEvery(REACTION_SYNTH_CALC_INIT, calculateSynth)
}

export function* calculateRetroWatcher() {
  yield takeEvery(REACTION_RETRO_CALC_INIT, calculateRetro)
}

const watchers = [
  doCalculateWatcher(),
  calculateSynthWatcher(),
  calculateRetroWatcher(),
]
export default watchers
