import {
  REACTION_CALC_INIT,
  REACTION_CALC_FAIL,
  REACTION_CALC_SUCCESS,
  REACTION_RETRO_CALC_INIT,
  REACTION_SYNTH_CALC_INIT,
  REACTION_MOLECULE_ADD,
  REACTION_MOLECULE_EDIT,
  REACTION_MOLECULE_REMOVE,
  REACTION_MOLECULE_REMOVE_ALL,
  REACTION_CALCULATION_RESET,
  REACTION_SET_MODE,
} from '../constants/reactions'

const initialState = {
  list: [],
  calculation: null,
  loading: false,
  error: '',
  mode: 'forward',
}

const reactionsReducer = (state = initialState, payload) => {
  let list, calculation
  switch (payload.type) {
    case REACTION_MOLECULE_ADD:
      list = [...state.list, payload.smiles]
      return { ...state, list }
    case REACTION_MOLECULE_REMOVE:
      list = [
        ...state.list.slice(0, payload.id),
        ...state.list.slice(payload.id + 1),
      ]
      return { ...state, list }
    case REACTION_MOLECULE_REMOVE_ALL:
      return { ...state, list: [] }
    case REACTION_MOLECULE_EDIT:
      list = [
        ...state.list.slice(0, payload.id),
        payload.smiles,
        ...state.list.slice(payload.id + 1),
      ]
      return { ...state, list }
    case REACTION_CALC_INIT:
      return { ...state, calculation: null, loading: true }

    case REACTION_SYNTH_CALC_INIT:
      return { ...state, calculation: null, loading: true }

    case REACTION_RETRO_CALC_INIT:
      return { ...state, calculation: null, loading: true, error: '' }

    case REACTION_CALC_SUCCESS:
      calculation = payload.calculation
      return {
        ...state,
        calculation,
        loading: false,
        error: '',
      }
    case REACTION_CALC_FAIL:
      return {
        ...state,
        error: payload.error,
        calculation: null,
        loading: false,
      }

    case REACTION_CALCULATION_RESET:
      return {
        ...state,
        calculation: null,
        loading: false,
        error: '',
      }
    case REACTION_SET_MODE:
      return {
        ...state,
        mode: payload.mode,
      }

    default:
      return state
  }
}

export default reactionsReducer
