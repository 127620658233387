import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import LiteratureCard from 'components/LiteratureCard'
import SearchHistory from 'components/Search/SearchHistory'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import LoaderRestyling from 'components/LoaderRestyling'

import { LoaderContainer, SearchList } from './index.style'

const LiteratureList = ({
  litSearchStatus,
  litSearchResult,
  showPagination,
  listView,
}) => {
  const { t } = useTranslation()
  const { data: litData, smiles: litSearchSmiles } = litSearchResult
  const twoRows = listView && listView === 'brick'

  return (
    <>
      {litSearchStatus === 'loading' && (
        <LoaderContainer>
          <LoaderRestyling />
        </LoaderContainer>
      )}
      {litSearchStatus === 'hold' && <SearchHistory />}
      {litSearchStatus === 'done' && !litData.length && (
        <>
          <TextBody style={{ marginTop: '24px' }}>
            {t('filter.literature.requestNotFound')}
          </TextBody>
          <Subheadline
            fontWeight={500}
            color="#616E7C"
            style={{ marginTop: '8px' }}
          >
            {t('filter.literature.changeRequest')}
          </Subheadline>
        </>
      )}

      {litSearchStatus === 'done' && !!litData.length && (
        <SearchList showPagination={showPagination} twoRows={twoRows}>
          {litData.map((el, index) => {
            return (
              <LiteratureCard
                key={index}
                data={el}
                litSearchSmiles={litSearchSmiles}
              />
            )
          })}
        </SearchList>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    litSearchStatus: state.crud.litSearch.status,
    litSearchResult: state.crud.litSearch.result,
    listView: state.literature.listView,
  }
}

export default connect(mapStateToProps)(LiteratureList)
