export const languages = {
  en: 'English',
  ru: 'Русский',
}

export const footerOptions = [
  { value: 'literatureCard.readFull', numberPointer: '', isDisabled: false },
  {
    value: 'literatureCard.structures',
    numberPointer: 'structures_count',
    isDisabled: false,
  },
  { value: 'literatureCard.reactions', numberPointer: '', isDisabled: true },
]

export const TABS_CONFIG = [
  {
    value: 'literatureCard.articleStructures',
    numberPointer: 'structures',
    isDisabled: false,
  },
  // {
  //   value: 'literatureCard.articleReactions', закомменчено в рамках SYN-2956 до времени
  //   numberPointer: '',
  //   isDisabled: true,
  // },
  // {
  //   value: 'literatureCard.similarArticles',
  //   numberPointer: '',
  //   isDisabled: true,
  // },
]

export const BORDER_DELTA = 2
export const LANGUAGES_KEYS = ['en', 'ru']

export const getLanguageKeys = (data) =>
  Object.keys(data).filter((k) => LANGUAGES_KEYS.includes(k))
