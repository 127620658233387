import { CustomTagElement } from './index.style'

const CustomTag = ({
  onClick = () => {},
  children,
  className,
  type = 'grey',
  width = '',
  height = '',
  withInteraction = false,
  defaultColor = '',
  ...props
}) => (
  <CustomTagElement
    onClick={onClick}
    type={type}
    className={className}
    width={width}
    height={height}
    withInteraction={withInteraction}
    defaultColor={defaultColor}
    {...props}
  >
    {children}
  </CustomTagElement>
)

export default CustomTag
