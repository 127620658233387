import { t } from 'i18next'

export const getProgressNotifIcon = (isLongTaskReady, isLongTaskError) => {
  if (isLongTaskReady) return 'success'
  if (isLongTaskError) return 'error'
  return 'deleted'
}

export const getNeedToDeleteTitle = (name, text) => {
  if (name.includes(t('synthCost.synth_cost')))
    return t('synthCost.cancel_calculating')
  if (name.includes(t('baskets.calculating')))
    return t('baskets.tasks_status.cancel_set_calculation', {
      name: text,
    })
  if (name.includes(t('synmap.copy_molecules')))
    return t('synmap.cancel_copy_molecules')
  if (name.includes(t('search.search_by_structures_in_progress')))
    return t('search.cancel_search_by_structures')
  return `${t('notifications.cancel')}?`
}
