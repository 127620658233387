import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  emailReg,
  validatePasswordSymb,
  validatePasswordLength,
  validatePasswordLetters,
} from 'utils/common/common'
import Icon from 'components/Icon'
import Promo from 'components/Promo'
import { store } from 'services/rest'
import SidebarButton from 'components/SidebarButton'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { addNotification } from 'store/actions/notifications'
import { Caption, Label } from 'components/common/text/index.style'
import { clearUserTempCreds, setUserTempCreds } from 'store/actions/auth'

import {
  Wrapper,
  LogInText,
  LogInButton,
  SignUpBlock,
  SignUpWithPromo,
  LogInWithCopyRight,
} from './index.style'
import { PROGRESS_VALUE } from './config'

import Activation from './components/Activation'
import PersonalData from './components/PersonalData'
import EmailAndPassword from './components/EmailAndPassword'
import RegistrationInfo from './components/RegistrationInfo'
import ReCaptchaStep from './components/ReCaptchaStep'

const RegistrationPageNew = ({ handleGoLogin, push, path }) => {
  const logoImage = useGetLogoImage()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tempUserCreds = useSelector((store) => store.auth?.userCreds)
  const [progress, setProgress] = useState(0)

  const theme = useTheme()

  const [data, setData] = useState({
    password: tempUserCreds?.password ?? '',
    password2: '',
    email: tempUserCreds?.email ?? '',
    username: '',
    surname: '',
    company: '',
    role: '',
    is_license: false,
    license_key: '',
  })

  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false)
  const [isAgreeWithPrivPolicy, setIsAgreeWithPrivPolicy] = useState(false)

  const {
    email,
    password,
    password2,
    username,
    surname,
    company,
    role,
    is_license: isLicense,
    license_key: licenseKey,
  } = data

  const isPasswordValidationError =
    !!password &&
    (!validatePasswordLength(password) ||
      !validatePasswordLetters(password) ||
      !validatePasswordSymb(password) ||
      (!!email && password.includes(email)))

  useEffect(() => {
    if (step === 2) {
      let newProgress = 50

      if (username.trim()) newProgress += PROGRESS_VALUE
      if (surname.trim()) newProgress += PROGRESS_VALUE
      if (company.trim()) newProgress += PROGRESS_VALUE
      if (role.trim()) newProgress += PROGRESS_VALUE
      if (progress !== newProgress) setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, surname, company, role])

  const [emailError, setEmailError] = useState('')
  const [password2Error, setPassword2Error] = useState('')
  const [step, setStep] = useState(0)

  const updateProgress = () => {
    setIsPasswordsMatch(false)
    let newProgress = 0
    if (email && emailReg.test(email)) newProgress += PROGRESS_VALUE

    if (email && !emailReg.test(data.email)) setEmailError('account.emailError')
    else if (emailError) setEmailError('')

    if (password && !isPasswordValidationError) newProgress += PROGRESS_VALUE
    if (!password2 && password2Error) setPassword2Error('')
    if (isAgreeWithPrivPolicy) newProgress += PROGRESS_VALUE

    return newProgress
  }

  useEffect(() => {
    if (step === 1) {
      if (password2Error === 'account.password2Error') setPassword2Error('')
      let newProgress = updateProgress()
      setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, password2, t])

  useEffect(() => {
    if (step === 1) {
      let newProgress = updateProgress()

      if (
        password &&
        !isPasswordValidationError &&
        password2 &&
        !password2Error
      ) {
        newProgress += PROGRESS_VALUE
      }

      setProgress(newProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, t, isAgreeWithPrivPolicy])

  const handleChange = (value, id) => setData({ ...data, [id]: value })

  const handleSubmit = async () => {
    if (progress < 100) return

    // try {
    //   setLoading(true)
    //   const { data: response } = await store('/profile/register', data)

    //   setLoading(false)
    //   if (response.status === 'error') {
    //     let errorText = response.error
    //     let needTranslateText = false
    //     let translationTextParams
    //     if (response.errorType === 'email') {
    //       setEmailError(response.error)
    //       if (errorText.startsWith('User with email')) {
    //         translationTextParams = { email: data.email }
    //         errorText = 'registration.email_error'
    //         needTranslateText = true
    //       }
    //     }

    //     const id = uuidv4()
    //     const notify = {
    //       id,
    //       name: 'notification.error',
    //       text: errorText,
    //       notification_type: 'error',
    //       autoRemove: true,
    //       timeout: 5000,
    //       needTranslateText,
    //       translationTextParams,
    //     }
    //     dispatch(addNotification(notify))
    //   } else if (response.status === 'ok') {
    //     handleGoToNextStep()
    //   }
    // } catch (e) {
    //   const id = uuidv4()
    //   const notify = {
    //     id,
    //     name: 'notification.error',
    //     text: e || 'notification.unhandled',
    //     notification_type: 'error',
    //     autoRemove: true,
    //     timeout: 5000,
    //     needTranslateText: !e,
    //   }
    //   dispatch(addNotification(notify))
    //   setLoading(false)
    // } finally {
    //   dispatch(clearUserTempCreds())
    // }
    handleGoToNextStep()
  }

  const handleBlur = () => {
    if (password && password2) {
      if (password !== password2) {
        setPassword2Error('account.password2Error')
        setIsPasswordsMatch(false)
      } else {
        setPassword2Error('')
        if (!isPasswordsMatch) {
          setProgress((prev) => prev + PROGRESS_VALUE)
          setIsPasswordsMatch(true)
        }
      }
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
  }

  const handleClickLogin = () => {
    if (path === '/register2') {
      if (!!data.email || !!data.password) {
        dispatch(
          setUserTempCreds({ email: data.email, password: data.password })
        )
      }
      push('/login')
    } else {
      handleGoLogin()
    }
  }

  const handleCompleteRecaptcha = (token) => {
    if (token) {
      setTimeout(() => handleSubmit(), 1000)
    }
  }

  const handleGoToPrevStep = () => setStep((prev) => prev - 1)
  const handleGoToNextStep = () => setStep((prev) => prev + 1)

  return (
    <Wrapper>
      <SignUpWithPromo>
        <SignUpBlock>
          <img
            src={logoImage}
            alt="syntelly"
            height="4.375rem"
            style={{ margin: 0 }}
          />
          {step === 0 && (
            <RegistrationInfo onGoToNextStep={handleGoToNextStep} />
          )}
          {/* {step === 1 && (
            <TestPeriod
              onComeBack={handleGoToPrevStep}
              onGoRegister={handleGoToNextStep}
            />
          )} */}
          {step === 1 && (
            <EmailAndPassword
              {...{
                email,
                password,
                progress,
                password2,
                emailError,
                isLicense,
                licenseKey,
                password2Error,
                isPasswordsMatch,
                isAgreeWithPrivPolicy,
                isPasswordValidationError,
              }}
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
              onChange={handleChange}
              onChangeAgreeWithPrivPolicy={() =>
                setIsAgreeWithPrivPolicy((prev) => !prev)
              }
              onGoBack={handleGoToPrevStep}
              onGoToNextStep={handleGoToNextStep}
            />
          )}
          {step === 2 && (
            <PersonalData
              onChange={handleChange}
              {...{
                username,
                surname,
                company,
                role,
                progress,
              }}
              onGoBack={handleGoToPrevStep}
              onGoToNextStep={handleGoToNextStep}
            />
          )}

          {step === 3 && (
            <ReCaptchaStep onChangeRecaptcha={handleCompleteRecaptcha} />
          )}
          {step === 4 && <Activation />}

          <LogInWithCopyRight>
            {step === 0 && (
              <>
                <LogInText>
                  <Label
                    style={{ margin: 0 }}
                    color={theme.colors.text.secondary}
                  >
                    {' '}
                    {t('registration.alreadyRegistered')}
                  </Label>
                  <LogInButton
                    type="text"
                    onClick={handleClickLogin}
                    gap="0.25rem"
                    data-test="registration-login-btn"
                  >
                    {t('account.signIn')}
                    <Icon iconType="arrowRight" size="1rem" />
                  </LogInButton>
                </LogInText>
                <SidebarButton />
              </>
            )}

            <Caption
              fontWeight={theme.fontWeight.medium}
              color={theme.colors.text.tertiary}
            >
              {t('account.copyRight').toUpperCase()}
              {new Date().getFullYear()}
            </Caption>
          </LogInWithCopyRight>
        </SignUpBlock>
        <Promo />
      </SignUpWithPromo>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  path: state.router.location.pathname,
})

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPageNew)
