import styled, { css, keyframes } from 'styled-components'

const glow = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const anim = css`
  ${glow} 0.5s forwards;
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  backdrop-filter: blur(5px);
  z-index: 100;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  row-gap: 1.75rem;
  padding: 1.75rem 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px -4px 8px 0px rgba(31, 41, 51, 0.02),
    0px 4px 8px 0px rgba(31, 41, 51, 0.02);
  background-color: #fff;
  opacity: 0;
  animation: ${anim};
  align-content: center;

  & > img {
    margin: auto;
    height: 2rem;
  }
`

export const FieldsBlock = styled.form`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 1.25rem;
  width: 600px;
  margin-top: 10px;
  margin-bottom: 15px;
`

export const SuccessMessage = styled.div`
  color: #616161;
  font-size: 18px;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 24px;
  }
`

export const ThinBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`

export const Text = styled.span`
  margin-top: 0.75rem;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  display: flex;
  justify-content: center;
  column-gap: 0.25rem;
  color: #616e7c;

  & > span {
    text-decoration: underline;
    cursor: pointer;
  }

  & > a {
    color: #616e7c;
  }
`

export const Text2 = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
  display: flex;
  justify-content: center;
  column-gap: 0.25rem;
  color: #616e7c;

  & > span {
    color: #42c1bf;
    cursor: pointer;
  }
`

export const CloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  & > svg {
    color: #cbd2d9;
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const InputLabel = styled.label`
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: #6e809d;
  display: flex;
`

export const ContactUs = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;

  & > a {
    color: #42c1bf;
    text-decoration: none;
  }
`

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  ${({ type }) => type === 'email' && `flex-basis: 49%;`}
  ${({ type }) =>
    type === 'token' &&
    `flex-basis: 49%;
    & > ${InputLabel} {
      justify-content: space-between;
    };
  
  `}
  ${({ type }) => type === 'password' && `flex-basis: 49%;`}
  ${({ type }) => type === 'password2' && `flex-basis: 49%;`}
  ${({ type }) => type === 'name' && `flex-basis: 32%;`}
  ${({ type }) => type === 'company' && `flex-basis: 32%;`}
  ${({ type }) => type === 'position' && `flex-basis: 32%;`}
`

export const Input = styled.input`
  padding: 0.5rem 0.75rem;
  border: none;
  outline: none;
  border-radius: 0.75rem;
  background: #f5f7f9;
  border: ${({ error }) =>
    error ? '0.0625rem solid #FF3B30' : '0.0625rem solid transparent'};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  min-height: 2.25rem;

  transition: border 0.3s;

  &::placeholder {
    color: #ced6e3;
  }
`

export const InputError = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  color: #f00;
`

export const CopyRight = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  display: flex;
  justify-content: center;
  color: #cbd2d9;
`
