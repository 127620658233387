import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useRef } from 'react'

import {
  FileInfo,
  Container,
  Thumbnail,
  SuccessText,
  DialogActions,
  AdditionalText,
  ThumbnailsBlock,
  DropzoneWrapper,
  ThumbnailContainer,
} from './index.style'
import MyDropzone from './myDropzone'
import { store } from '../../services/rest'

import {
  Caption,
  Subheadline,
  TitleTertiary,
} from 'components/common/text/index.style'
import Icon from 'components/Icon'
import { useTheme } from 'styled-components'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { CustomDialog } from 'components/common/customDialog'
import CustomTextArea from 'components/common/customTextArea'
import { emailReg } from 'utils/common/common'
import CustomScrollbar from 'components/CustomScrollbar'

const FeedbackForm = ({ handleClose }) => {
  const theme = useTheme()
  const [text, setText] = useState('')
  const [contact, setContact] = useState('')
  const [files, setFiles] = useState([])
  const [showSuccessMsg, setShowSuccesMsg] = useState(false)

  const { t } = useTranslation()

  const nameRefs = useRef([])

  const mail = useSelector((store) => store.auth?.userdata?.email)

  const [isEmailError, setIsEmailError] = useState(false)

  const validateEmail = () => {
    return emailReg.test(contact)
  }

  useEffect(() => {
    if (mail) {
      setContact(mail)
    }
  }, [mail])

  useEffect(() => {
    if (!contact || validateEmail()) {
      setIsEmailError(false)
    } else {
      setIsEmailError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  const sendFeedback = async () => {
    const req = {
      text,
      contact,
      files,
    }
    await store('/feedback', req)
  }

  const handleSendFeedback = async () => {
    if (!validateEmail()) {
      setIsEmailError(true)
      return
    }

    try {
      await sendFeedback()
      setShowSuccesMsg(true)
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteFile = (id) => () => {
    setFiles((f) => {
      return f.filter((_, idx) => idx !== id)
    })
  }

  return (
    <CustomDialog onClose={handleClose} width="26.375rem" showCloseIcon={false}>
      {showSuccessMsg ? (
        <Container alignItems="center">
          <Icon iconType="success" size="4rem" />

          <SuccessText>
            <TitleTertiary
              fontWeight={500}
              textAlign="center"
              color={theme.colors.text.primary}
            >
              {t('feedback.feedback_received')}
            </TitleTertiary>
            <AdditionalText
              fontWeight={300}
              textAlign="center"
              color={theme.colors.text.secondary}
            >
              {t('feedback.we_will_answer')}
            </AdditionalText>
          </SuccessText>
          <CustomButton type="accent" width="100%" onClick={handleClose}>
            {t('feedback.ok')}
          </CustomButton>
        </Container>
      ) : (
        <Container>
          <TitleTertiary fontWeight={500} color={theme.colors.text.primary}>
            {t('feedback.label')}
          </TitleTertiary>
          <CustomTextArea
            value={text}
            onChange={(value) => setText(value)}
            placeholder={t('feedback.textFieldPlaceholder')}
            withClearButton
          />
          <CustomInput
            onChange={(value) => setContact(value)}
            value={contact}
            placeholder={t('feedback.emailPlaceholder')}
            label={t('feedback.emailLabel')}
            withClearButton
          />

          {!!files.length && (
            <CustomScrollbar
              noScrollY={true}
              noScrollX={false}
              style={{ height: '6.5rem' }}
            >
              <ThumbnailsBlock>
                {files.map(({ name, data }, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <ThumbnailContainer>
                        <Thumbnail src={data} alt="Thumbnail" />
                        <FileInfo>
                          <Subheadline
                            data-test="feedback-attachment-name-div"
                            id={`thumbnail-${idx}`}
                            data-tooltip-id={`file-info-name${idx}`}
                            ref={(ref) => (nameRefs.current[idx] = ref)}
                          >
                            {name}
                          </Subheadline>
                          <Icon
                            iconType="deleted"
                            size="1rem"
                            onClick={handleDeleteFile(idx)}
                            dataTest="feedback-attachment-remove-btn"
                          />
                        </FileInfo>
                      </ThumbnailContainer>

                      {nameRefs?.current?.[idx]?.scrollWidth >
                        nameRefs?.current?.[idx]?.clientWidth && (
                        <ReactTooltip
                          id={`file-info-name${idx}`}
                          className="c-tooltip c-tooltip-base c-tooltip-filename"
                          place={'bottom'}
                          offset={14}
                          classNameArrow="c-tooltip-arrow"
                          positionStrategy="fixed"
                        >
                          {name}
                        </ReactTooltip>
                      )}
                    </React.Fragment>
                  )
                })}
              </ThumbnailsBlock>
            </CustomScrollbar>
          )}
          <DropzoneWrapper>
            <MyDropzone setFiles={setFiles} />{' '}
            <Caption color={theme.colors.text.secondary}>
              {t('feedback.attach_the_file_in_png_jpeg_format')}
            </Caption>
          </DropzoneWrapper>

          <DialogActions>
            <CustomButton onClick={handleClose} type="secondary" width="50%">
              {t('feedback.closeButton')}
            </CustomButton>
            <CustomButton
              width="50%"
              onClick={handleSendFeedback}
              type="accent"
              disabled={!text || !contact || isEmailError}
            >
              {t('feedback.sendButton')}
            </CustomButton>
          </DialogActions>
        </Container>
      )}
    </CustomDialog>
  )
}

export default FeedbackForm
