import { useTheme } from 'styled-components'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import { useTranslation } from 'react-i18next'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { useState, useRef, memo, useEffect } from 'react'

import { fetch } from 'services/rest'

import CustomFilter from './CustomFilter'
import CustomTooltip from './CustomTooltip'
import FloatingFilter from './FloatingFilter'
import { textCells } from './CustomFilter/config/config'
import { drugs, eco, synt, toxy } from './config/config'
import { DatasetTableWrapper, Header, Table } from './index.style'
import { checkFilterConditions } from './CustomFilter/utils/utils'
import SelectWithGroups from './CustomFilter/SelectWithGroups'
import { TextComparator, NumberComparator } from './utils/utils'

const DatasetTable = memo(({ rows, basketName }) => {
  const gridRef = useRef()
  const theme = useTheme()
  const { t } = useTranslation()

  const options = Object.keys(rows[0]).map((el) => ({ value: el, label: el }))

  const [paintingModel, setPaintingModel] = useState({})
  const [groupedOptions, setGroupedOptions] = useState([])
  const [selectedColumns, setSelectedColumns] = useState(options)

  const getCategories = async () => {
    try {
      const { data } = await fetch('/categories')
      let groups = []

      options.forEach((opt) => {
        const cat = data.find((cat) => {
          return cat?.inner?.some((catInner) => {
            return catInner.name === opt.value
          })
        })
        let category = cat ? `molviewer.${cat.category_name}` : 'no_group'
        if (toxy.some((el) => opt.value.toLowerCase().includes(el)))
          category = 'molviewer.toxy'
        if (drugs.some((el) => opt.value.toLowerCase().includes(el)))
          category = 'molviewer.drug'
        if (eco.some((el) => opt.value.toLowerCase().includes(el)))
          category = 'molviewer.eco'
        if (synt.some((el) => opt.value.toLowerCase().includes(el)))
          category = 'molviewer.synt'

        if (groups.some((el) => el.group === category))
          groups = groups.map((el) =>
            el.group === category
              ? { ...el, options: [...el.options, opt] }
              : el
          )
        else groups = [...groups, { group: category, options: [opt] }]
      })
      setGroupedOptions(groups)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  const onChangePaintingModel = (type, column, newState, operator) => {
    if (type === 'apply') {
      const model = operator
        ? [
            {
              conditions: [...newState],
              operator,
            },
          ]
        : [{ ...newState[0] }]
      const newModel = { ...paintingModel }

      newModel[column] = {
        model,
        operator,
      }
      setPaintingModel(newModel)
    } else {
      if (!paintingModel?.[column]) return
      const newModel = { ...paintingModel }
      delete newModel[column]
      setPaintingModel(newModel)
    }
  }

  const columnDefs =
    rows.length > 0
      ? options.map(({ value }) => ({
          headerName: value,
          field: value,
          sortable: true,
          filter: CustomFilter,
          headerTooltip: value,
          tooltipComponent: CustomTooltip,
          tooltipValueGetter: (params) => params.value,
          comparator: textCells.includes(value)
            ? TextComparator
            : NumberComparator,
          hide: !selectedColumns.some((el) => el.value === value),
          filterParams: {
            paintingModel,
            columnType: textCells.includes(value) ? 'string' : 'number',

            onChangePaintingModel,
          },
          cellStyle: (params) => {
            if (!paintingModel?.[value]) return {}
            if (
              checkFilterConditions(
                params.value,
                paintingModel[value].model[0],
                textCells.includes(value) ? 'string' : 'number',
                paintingModel[value].operator
              )
            )
              return {
                background: theme.colors.backgrounds.accentSecondary,
              }
            return {}
          },
          floatingFilterComponent: FloatingFilter,
          floatingFilterComponentParams: {
            paintingModel,
          },
        }))
      : []

  return (
    <DatasetTableWrapper>
      <Header>
        {t('dataset_table.table_analysis_of_the_dataset', {
          name: basketName,
        })}
      </Header>
      <SelectWithGroups
        withTranslation={false}
        allSelectedLabel={t('dataset_table.all_columns')}
        options={groupedOptions}
        width="15rem"
        selectedValue={selectedColumns}
        onChange={(value) => setSelectedColumns(value)}
      />
      <Table className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          columnDefs={columnDefs}
          tooltipShowDelay={0}
          tooltipHideDelay={10000}
          defaultColDef={{
            floatingFilter: true,
            filter: true,
            sortable: true,
          }}
        />
      </Table>
    </DatasetTableWrapper>
  )
})

DatasetTable.displayName = 'DatasetTable'

export default DatasetTable
