import { bisector } from 'd3-array'
import { localPoint } from '@visx/event'

export const getX = (d) => d.x
export const getY = (d) => d.y
export const bisect = bisector((d) => d.x).left

export const numZeroesAfterPoint = (x) => {
  if (x % 1 === 0) {
    return 0
  } else {
    return -1 - Math.floor(Math.log10(x % 1))
  }
}

export const getAverage = (arr) => {
  return Array.isArray(arr) ? arr.reduce((p, c) => p + c, 0) / arr.length : arr
}

export const getScrollScale = (e, zoom) => {
  const delta = e.deltaY

  if (Math.abs(delta) > 10) {
    zoom.scale({
      scaleX: delta > 0 ? 1.1 : 0.9,
      scaleY: 1,
      point: localPoint(e),
    })
  } else if (Math.abs(delta) > 100) {
    zoom.scale({
      scaleX: delta > 0 ? 1.15 : 0.85,
      scaleY: 1,
      point: localPoint(e),
    })
  } else {
    zoom.scale({
      scaleX: delta > 0 ? 1.03 : 0.97,
      scaleY: 1,
      point: localPoint(e),
    })
  }
}
