import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from 'components/common/customInput'
import { storeV2 } from '../../services/rest'
import { VariantBlock } from 'components/main-search-bar/molecules/variant-block'
import { validateEnglishInput } from 'utils/common/common'
import { useDebounce } from 'hooks/useDebounce'

const InputHelper = ({ onAdd, onBlur, onFocus, showOptions }) => {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('')
  const [noLoadOptions, setNoLoadOptions] = useState(true)
  const { t } = useTranslation()

  const debouncedInputValue = useDebounce(value, 300)
  const synonymSelectedValueRef = useRef('')

  useEffect(() => {
    if (value === synonymSelectedValueRef.current) return
    if (value) {
      !noLoadOptions && requestSearch(value, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue])

  const requestSearch = async (text, isQuick = false) => {
    const { data } = await storeV2('molecule/global_search', {
      params: {
        quick: isQuick,
        query: text,
      },
    })

    const search_data = data?.result?.items || []
    if (Array.isArray(search_data)) {
      const eax = search_data.filter((e) => e.id)
      setOptions(eax)
    }
  }

  const handleChange = (msg, noLoadOptions) => {
    if (!!msg && !validateEnglishInput(msg)) return
    setValue(msg)
    setNoLoadOptions(noLoadOptions)
    if (!msg) {
      !!options.length && setOptions([])
    }
  }

  const handleAdd = (_, smiles) => {
    handleChange(smiles, true)
    if (smiles) {
      onAdd(smiles)
      synonymSelectedValueRef.current = smiles
    }
    setOptions([])
  }

  return (
    <div style={{ position: 'relative' }}>
      <CustomInput
        onChange={handleChange}
        placeholder={t('moleculeslist.enter_synonym')}
        withClearButton
        showSearchIcon
        {...{ value, onBlur, onFocus }}
        focused={showOptions || value}
      />
      {!!options.length && showOptions && (
        <VariantBlock
          data={options}
          withMaxHeight={true}
          handleSelect={handleAdd}
          withSelectStyles
        />
      )}
    </div>
  )
}

export default InputHelper
