import {
  SELECT_BASKET,
  SET_BASKET_DESC,
  SET_BASKET_NAME,
  SET_EDIT_ID,
  SET_EDIT_MODE,
  SET_SHOW_ADD_DIALOG,
  SET_SHOW_LOG_DIALOG,
  SET_SELECTED_BASKET,
  SET_SHOW_JOIN_COPY_DIALOG,
  REMOVE_BASKET_IDS_FROM_DB_INIT,
  SET_BASKETS_SORTING_PARAM,
} from '../constants/basket'

export const selectBasket = (value) => {
  return {
    type: SELECT_BASKET,
    value,
  }
}

export const setShowAddDialog = (value) => {
  return {
    type: SET_SHOW_ADD_DIALOG,
    value,
  }
}
export const setBasketName = (value) => {
  return {
    type: SET_BASKET_NAME,
    value,
  }
}
export const setBasketDesc = (value) => {
  return {
    type: SET_BASKET_DESC,
    value,
  }
}

export const setEditMode = (value) => {
  return {
    type: SET_EDIT_MODE,
    value,
  }
}

export const setEditId = (value) => {
  return {
    type: SET_EDIT_ID,
    value,
  }
}

export const setShowLogDialog = (value) => {
  return {
    type: SET_SHOW_LOG_DIALOG,
    value,
  }
}

export const setSelected = (value) => {
  return {
    type: SET_SELECTED_BASKET,
    value,
  }
}

export const setShowJoinCopyDialog = (value) => {
  return {
    type: SET_SHOW_JOIN_COPY_DIALOG,
    value,
  }
}

export const removeLastActionsIDsFromDB = (ids) => {
  return {
    type: REMOVE_BASKET_IDS_FROM_DB_INIT,
    ids,
  }
}

export const setBasketsSortingParam = (key, value) => {
  return {
    type: SET_BASKETS_SORTING_PARAM,
    key,
    value,
  }
}
