import styled from 'styled-components'

export const Indicator = styled.div`
  width: 38px;
  height: 24px;
  display: flex;
  font-size: 10px;
  padding: 6px 10px;
  line-height: 12px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'rgba(97, 110, 124, 0.08)'};
  color: ${({ color }) => (color ? color : '#2E90FA')};

  svg {
    width: 16px;
    height: 16px;
  }
`
