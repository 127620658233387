import { t } from 'i18next'
import {
  validatePasswordSymb,
  validatePasswordLength,
  validatePasswordLetters,
} from 'utils/common/common'

export const validate = (password, email) => [
  {
    text: t('registration.includes_no_less_8'),
    isValid: validatePasswordLength(password),
  },
  {
    text: t('registration.includes_a_z'),
    isValid: validatePasswordLetters(password),
  },
  {
    text: t('registration.includes_symb'),
    isValid: validatePasswordSymb(password),
  },
  {
    text: t('registration.no_includes_email'),
    isValid: email ? !password.includes(email) : true,
  },
]

export const getParams = (value, theme) => {
  return {
    icon: value ? 'tick' : 'description',
    iconColor: value ? theme.statuses.statuses : theme.statuses.error,
    textColor: value ? theme.colors.text.primary : theme.statues.error,
  }
}
