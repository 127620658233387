import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import crudReducer from './crud'
import reactionsReducer from './reactions'
import authReducer from './auth'
import mmapReducer from './mmap'
import settingsReducer from './settings'
import tasksReducer from './tasks'
import spectraReducer from './spectra'
import searchReducer from './search'
import confirmationReducer from './confirm'
import moleculeReducer from './molecule'
import filterReducer from './filter'
import literatureReducer from './literature'
import notificationsReducer from './notifications'
import basketReducer from './baskets'
import pdf2SmilesReducer from './pdf2smiles'
import synthCostReducer from './synthcost'
import smilesIupacReducer from './smilesIupac'
import searchHistoryReducer from './searchHistory'
import retrosynthesisReducer from './retrosynthesis'
import feedbackReducer from './feedback'
import contextMenuReducer from './contextMenu'
import blurReducer from './blur'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    crud: crudReducer,
    reactions: reactionsReducer,
    auth: authReducer,
    mmap: mmapReducer,
    settings: settingsReducer,
    search: searchReducer,
    tasks: tasksReducer,
    notifications: notificationsReducer,
    spectra: spectraReducer,
    confirmation: confirmationReducer,
    molecule: moleculeReducer,
    filter: filterReducer,
    literature: literatureReducer,
    basket: basketReducer,
    pdf2Smiles: pdf2SmilesReducer,
    synthcost: synthCostReducer,
    smilesIupac: smilesIupacReducer,
    searchHistory: searchHistoryReducer,
    retrosynthesis: retrosynthesisReducer,
    feedback: feedbackReducer,
    contextMenu: contextMenuReducer,
    blur: blurReducer,
  })

export default rootReducer
