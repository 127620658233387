import { connect } from 'react-redux'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import { resetFilter, setOpenFilter } from 'store/actions/filter'
import CustomButton from 'components/common/customButton'
import { setLitFilterConfig } from 'store/actions/literature'
import { openSearchHistory, resetPastResult } from 'store/actions/searchHistory'
import { TitleTertiary } from 'components/common/text/index.style'
import { resetPagination, setSearchText } from 'store/actions/search'
import {
  resetLitSearch,
  resetSearch,
  setBingoSearch,
  setLiteratureSearch,
  setSearchV2,
} from 'store/actions/crud'

import {
  StyledButton,
  ResultsHeader,
  HistoryResultsActions,
} from './index.style'

const HistoryPastResultHeader = memo(
  ({
    text,
    pastResult,
    searchType,
    resetFilter,
    resetSearch,
    setSearchV2,
    setSearchText,
    resetLitSearch,
    setBingoSearch,
    resetPastResult,
    resetPagination,
    setLitFilterConfig,
    setLiteratureSearch,
    openSearchHistory,
    isFilterOpen,
    setOpenFilter,
  }) => {
    const { t } = useTranslation()

    const goBackToHistory = (props) => {
      if (searchType === 'structure') resetSearch()
      resetFilter()
      resetPagination()
      setSearchText('')
      setLitFilterConfig({})
      if (searchType === 'literature') {
        resetLitSearch()
      }
      isFilterOpen && setOpenFilter(false)
      if (props?.openHistory) {
        resetPastResult()
        openSearchHistory(true)
      }
    }

    const handleRepeatSearch = () => {
      resetPastResult()
      if (searchType === 'structure') {
        if (pastResult?.query?.type === 'bingo_search') {
          setBingoSearch({
            text,
            label: text,
          })
        }

        if (pastResult?.query?.type === 'molecules_search') {
          setSearchV2({
            ids: pastResult?.query?.params?.searchV2?.ids || [],
            text: pastResult?.query?.params?.searchV2?.text || '',
            smiles: pastResult?.query?.params?.searchV2?.smiles || '',
            basket: pastResult?.query?.params?.basket || 1,
          })
        }
      } else if (searchType === 'literature') setLiteratureSearch({})
    }

    useEffect(() => {
      return () => {
        goBackToHistory()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <HistoryResultsActions pastResult={pastResult}>
        <ResultsHeader>
          <CustomButton
            onClick={() => goBackToHistory({ openHistory: true })}
            type="text_accent"
            gap="0.25rem"
          >
            <Icon iconType={'arrowLeft'} size="1rem" />
            {t('search.search_history.back_to_history')}
          </CustomButton>
          <TitleTertiary color="#1F2933" fontWeight={500}>
            {t('search.search_history.search_results', {
              text,
            })}
          </TitleTertiary>
        </ResultsHeader>
        <StyledButton
          type="secondary"
          onClick={handleRepeatSearch}
          gap="0.25rem"
        >
          <Icon iconType={'refresh'} size="1rem" />
          {t('search.search_history.repeat_request')}
        </StyledButton>
      </HistoryResultsActions>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    text: state.search.searchText,
    searchType: state.search.searchType,
    pastResult: state.searchHistory.pastResult,
    isFilterOpen: state.filter.open,
  }
}

const mapDispatchToProps = {
  resetFilter,
  resetSearch,
  setSearchV2,
  setSearchText,
  setOpenFilter,
  setBingoSearch,
  resetLitSearch,
  resetPastResult,
  resetPagination,
  setLitFilterConfig,
  setLiteratureSearch,
  openSearchHistory,
}

HistoryPastResultHeader.displayName = HistoryPastResultHeader

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryPastResultHeader)
