import { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { linksList } from 'config/linksList'
import { resetSearch } from 'store/actions/crud'
import { CustomCloseIcon } from 'components/common/customCloseIcon'
import { Subheadline, TextBody } from 'components/common/text/index.style'
import { StyledSnackbar, SnackbarContent } from './index.style'
import { readStorage, setStorage } from 'utils/storage/storage'
import { TIPS_DELAY } from 'config/constants'
import { isMoleculesPath } from 'utils/common/common'

const HOT_TIPS_STORAGE_KEY = 'hot_tips'

const CustomSnackbar = ({ path, showTips }) => {
  const theme = useTheme()
  const [tips, setTips] = useState([])
  const currentPath = isMoleculesPath(path) ? '/molecules' : path

  const isMoleculesPage =
    window.location.pathname.includes('/molecules') ||
    window.location.pathname.includes('/search')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCloseTip = () => {
    const currentDate = new Date()
    const storedTips = readStorage(HOT_TIPS_STORAGE_KEY)

    let propTips = storedTips ?? []

    if (propTips.find((tip) => tip.link === currentPath)) {
      const index = propTips.findIndex((tip) => tip.link === currentPath)

      if (Number.isInteger(index)) propTips[index].prevDate = currentDate
    } else {
      propTips.push({ link: currentPath, prevDate: currentDate })
    }
    setStorage(HOT_TIPS_STORAGE_KEY, propTips)
    setTips([])
  }
  useEffect(() => {
    const currentDate = new Date()
    const storedTips = readStorage(HOT_TIPS_STORAGE_KEY)

    let propTips = storedTips ?? []

    const res = linksList.find(({ link }) => {
      if (link === path) return true
      if (isMoleculesPath(path) && link === '/molecules') {
        return true
      }
      return false
    })

    if (
      propTips?.length &&
      propTips.find(
        (tip) =>
          tip?.link === currentPath &&
          currentDate - new Date(tip?.prevDate) < TIPS_DELAY
      )
    ) {
      return
    }

    if (res) {
      if (path === '/molecules') {
        setTips([])
      } else {
        setTips(res.tips || [])
      }
    } else {
      if (path === '/' || path.includes('molecules')) {
        return setTips([
          'hot_tips.molecules.left_click',
          'hot_tips.molecules.select',
          'hot_tips.molecules.select_all',
        ])
      }
      setTips([])
    }

    dispatch(resetSearch())
  }, [dispatch, path])

  if (tips.length === 0 || !showTips) return null

  return (
    <StyledSnackbar
      isMoleculesPage={isMoleculesPage}
      className="custom-snackbar-outbound-exception"
    >
      <CustomCloseIcon onClick={handleCloseTip} size="1rem" />

      <TextBody fontWeight={500} color={theme.colors.text.white}>
        {t('hot_tips.label')}
      </TextBody>
      <SnackbarContent>
        {tips.map((tip, i) => (
          <Subheadline key={i} fontWeight={300} color={theme.colors.text.white}>
            {t(`${tip}`)}
          </Subheadline>
        ))}
      </SnackbarContent>
    </StyledSnackbar>
  )
}

const mapStateToProps = (state) => {
  return {
    path: state.router.location.pathname,
    showTips: state.settings.showTips,
  }
}

export default connect(mapStateToProps)(CustomSnackbar)
