export const toxySortConfig = [
  {
    name: 'categoriesFiler.all',
    value: 'all',
  },
  {
    name: 'categoriesFiler.lethal_dose_models',
    value: 'lethal_dose_models',
  },
  {
    name: 'categoriesFiler.general_toxicity_models',
    value: 'general_toxicity_models',
  },
]
