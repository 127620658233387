import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Latex from 'react-latex-next'
import {
  ButtonContent,
  LeftBlock,
  ButtonWrapper,
  ButtonsBlock,
  AbstractBlock,
  TabsBlock,
} from './index.style'
import Carousel from '../../../components/LiteratureCard/carousel/index.js'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { goBack } from 'connected-react-router'
import history from '../../../services/history'
import { TextBody, TitleSecondary } from 'components/common/text/index.style'
import { v4 as uuidv4 } from 'uuid'
import { addSimpleNotification } from 'store/actions/notifications'
import { TABS_CONFIG } from 'components/LiteratureCard/config'
import { useTranslation } from 'react-i18next'
import {
  DialogCardFullText,
  DialogCardFullTextBlock,
  FullTextSelectorBlock,
} from 'components/LiteratureCard/index.style'
import CustomTab from 'components/common/customTab'

const LeftPanel = ({
  docData,
  withCarousel,
  showFullText,
  setShowFullText,
}) => {
  const [carouselCounter, setCarouselCounter] = useState(0)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const documentCode =
    docData.type === 'patent' ? docData.patent_number : docData.doi

  const handlePrevClick = () => {
    setCarouselCounter((prev) => prev - 1)
  }
  const handleNextClick = () => {
    const currentTabArrLength =
      docData[TABS_CONFIG[activeTabIndex].numberPointer].length

    if (carouselCounter + 3 < currentTabArrLength)
      setCarouselCounter((prev) => prev + 1)
  }

  return (
    <LeftBlock>
      {history?.location?.state?.prev && (
        <ButtonWrapper>
          <CustomButton
            type={'text_accent'}
            onClick={() => dispatch(goBack())}
            className={'hidden-for-print'}
          >
            <ButtonContent>
              <Icon iconType={'arrowLeft'} size="1rem" />
              <div>{t('literatureCard.returnToResults')}</div>
            </ButtonContent>
          </CustomButton>
        </ButtonWrapper>
      )}
      <TitleSecondary>
        <Latex>
          {String.raw({ raw: docData?.title }).replaceAll(/\\/g, '\\') || ''}
        </Latex>
      </TitleSecondary>
      <ButtonsBlock>
        <CustomButton
          type="primary"
          onClick={() => {
            navigator.clipboard.writeText(window.location.href)

            dispatch(addSimpleNotification('notification.link_copied'))
          }}
        >
          <Icon iconType="share" size="1rem" />
          {t('literatureCard.copyLink')}
        </CustomButton>
        <CustomButton
          type="primary"
          onClick={() => {
            navigator.clipboard.writeText(documentCode)

            dispatch(
              addSimpleNotification(
                `notification.${
                  docData.type === 'article'
                    ? 'DOI_copied'
                    : 'patent_number_copied'
                }`
              )
            )
            navigator.clipboard.writeText(documentCode)
          }}
        >
          <Icon iconType="copy" size="1rem" />
          {docData.type === 'article'
            ? t('literatureCard.copyDOI')
            : t('literatureCard.copyPatentNumber')}
        </CustomButton>
      </ButtonsBlock>
      {docData?.abstract && (
        <AbstractBlock>
          <TextBody fontWeight={300} color="#6E809D">
            <Latex>
              {String.raw({ raw: docData?.abstract }).replaceAll(/\\/g, '\\') ||
                ''}
            </Latex>
          </TextBody>
          {docData?.text && (
            <>
              <DialogCardFullTextBlock>
                <span>{t('literatureCard.full_text')}</span>
                <FullTextSelectorBlock
                  showFullText={showFullText}
                  onClick={() => setShowFullText(!showFullText)}
                >
                  <p>{t('literatureCard.show')}</p>
                  <Icon
                    iconType={showFullText ? 'arrowTop' : 'arrowDown'}
                    size="1rem"
                  />
                </FullTextSelectorBlock>
              </DialogCardFullTextBlock>
              {showFullText && (
                <DialogCardFullText lineHeight="18px" fontSize="14px">
                  {docData.text}
                </DialogCardFullText>
              )}
            </>
          )}
        </AbstractBlock>
      )}
      {withCarousel && (
        <>
          <TabsBlock>
            <div>
              {TABS_CONFIG.map((tab, index) => (
                <CustomTab
                  key={uuidv4()}
                  isActive={index === activeTabIndex}
                  disabled={tab.isDisabled}
                  type="grey"
                >
                  {t(tab.value)}{' '}
                  {docData[tab.numberPointer] &&
                    `(${docData[tab.numberPointer].length})`}
                </CustomTab>
              ))}
            </div>
            <div>
              <CustomButton
                type="primary"
                onClick={handlePrevClick}
                width="2.25rem"
                defaultPadding="0.625rem"
                disabled={carouselCounter === 0}
              >
                <Icon iconType="arrowLeft" size="1rem" />
              </CustomButton>
              <CustomButton
                type="primary"
                width="2.25rem"
                onClick={handleNextClick}
                defaultPadding="0.625rem"
                disabled={
                  carouselCounter + 3 ===
                    docData[TABS_CONFIG[activeTabIndex].numberPointer]
                      ?.length ||
                  docData[TABS_CONFIG[activeTabIndex].numberPointer]?.length < 4
                }
              >
                <Icon iconType="arrowRight" size="1rem" />
              </CustomButton>
            </div>
          </TabsBlock>
          <Carousel
            carouselCounter={carouselCounter}
            structures={docData.structures}
          />
        </>
      )}
    </LeftBlock>
  )
}

export default LeftPanel
