import MoleculeLinks from 'components/MoleculeLinks'

import {
  Info,
  Params,
  DataLabel,
  DataElement,
  DataContainer,
} from './index.style'
import { getTitle, getValue } from './utils/utils'

const MoleculeFooter = ({
  params,
  withColors,
  referencesCount,
  onLinkActionClick,
  isBestMatch,
}) => {
  return (
    <DataContainer isBestMatch={isBestMatch}>
      <Info>
        <Params>
          {params &&
            Object.entries(JSON.parse(params)).map(([key, value], idx) => {
              return (
                <DataElement key={idx} title={getTitle(key, value)}>
                  <div>{getValue(value, withColors)}</div>
                  <DataLabel>{key}</DataLabel>
                </DataElement>
              )
            })}
        </Params>
      </Info>
      <MoleculeLinks {...{ onLinkActionClick, referencesCount, isBestMatch }} />
    </DataContainer>
  )
}
export default MoleculeFooter
