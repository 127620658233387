import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  max-height: 220px;
  min-height: 220px;
  overflow-x: hidden;
`

export const NewButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 0;
  background: transparent;
  color: ${({ selected }) => (selected ? '#42C1BF' : '#1F2933')};
  display: flex;
  justify-content: space-between;
  user-select: none;
  column-gap: 0.5rem;

  & > span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.144px;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:last-child {
    margin-bottom: 0.25rem;
  }

  &:hover {
    color: #299b9a;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbced13d;
    border-radius: 12px;
  }

  & > svg {
    color: #42c1bf;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`

export const LoadButton = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 12px;
  background: ${({ disabled }) => (disabled ? '#F5F7F7' : '#58d5d3')};
  border-radius: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
  color: ${({ disabled }) => (disabled ? '#CBD2D9' : '#ffffff')};

  &:hover {
    background: ${({ disabled }) => (disabled ? '#58d5d3' : '#3AA9A7')};
    transition: background 0.3s;
  }
`

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  z-index: 9999;
`

export const ModalDialog = styled.div`
  position: relative;
  background: #ffffff;
  padding: 24px;
  box-shadow: 0px 0px 4px 0px rgba(31, 41, 51, 0.06),
    0px -4px 8px 0px rgba(31, 41, 51, 0.02),
    0px 4px 8px 0px rgba(31, 41, 51, 0.02);
  margin: auto;
  width: calc(100% - 64px);
  max-width: 372px;
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  position: relative;
  overflow-y: auto; 
  row-gap: 20px;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.192px;
`

export const CloseBtn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  & > svg {
    color: #cbd2d9;
    width: 20px;
    height: 20px;
  }
`

export const Filter = styled.div`
  width: 100%;
  padding: 10px 16px;
  border-radius: 0.75rem;
  background: #f5f7f7;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  & > svg {
    width: 1rem;
    height: 1rem;
    color: #cbd2d9;
  }
`

export const FilterInput = styled.input`
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;

  &::placeholder {
    color: #cbd2d9;
  }
`

export const ModalBtns = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 0.75rem;
`

export const CreateBtn = styled.button`
  border: none;
  outline: none;
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: 0.75rem;
  background: #f5f7f7;
  color: ${({ active }) => (active ? '#1f2933' : '#CBD2D9')};
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.144px;
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};

  &:hover {
    color: ${({ active }) => (active ? '#42c1bf' : '#CBD2D9')};
    transition: color 0.3s;
  }
`

export const ListText = styled.div`
  color: #cbd2d9;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  margin-bottom: 0.5rem;
`

export const SelectedList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
`

export const SelectedItem = styled.div`
  background: #f5f7f7;
  padding: 0.25rem 0.5rem;
  border-radius: 0.75rem;
  display: flex;
  column-gap: 0.25rem;
  align-items: center;

  & > span {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.12px;
    max-width: 68px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;

    display: flex;
    align-items: center;

    & > svg {
      width: 0.75rem;
      height: 0.75rem;
      color: #cbd2d9;
      cursor: pointer;

      &:hover {
        color: #1f2933;
        transition: color 0.3s;
      }
    }
  }
`

export const ListContainer = styled.div`
  &:after {
    content: '';
    width: 75%;
    height: 26px;
    position: absolute;
    bottom: 4.5rem;
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.8) 50%
    );
  }
`
