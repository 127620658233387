import {
  SELECT_BASKET,
  SET_SHOW_ADD_DIALOG,
  SET_BASKET_NAME,
  SET_BASKET_DESC,
  SET_EDIT_MODE,
  SET_EDIT_ID,
  SET_SHOW_LOG_DIALOG,
  SET_SELECTED_BASKET,
  SET_SHOW_JOIN_COPY_DIALOG,
  REMOVE_BASKET_IDS_FROM_DB_INIT,
  REMOVE_BASKET_IDS_FROM_DB_SUCCESS,
  REMOVE_BASKET_IDS_FROM_DB_ERROR,
  SET_BASKETS_SORTING_PARAM,
} from '../constants/basket'

export const initialState = {
  basketSelected: null,
  name: '',
  showAddDialog: false,
  desc: '',
  editMode: false,
  editId: null,
  showLogDialog: false,
  selected: [],
  showJoinCopyDialog: null,
  basketsRemoveInProgress: false,
  basketsRemoveError: false,
  sorting: {
    type: 'relevance',
    direction: 'asc',
  },
}

const basketReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SELECT_BASKET:
      return {
        ...state,
        basketSelected: payload.value,
      }
    case SET_SHOW_ADD_DIALOG:
      return {
        ...state,
        showAddDialog: payload.value,
      }
    case SET_BASKET_NAME:
      return {
        ...state,
        name: payload.value,
      }
    case SET_BASKET_DESC:
      return {
        ...state,
        desc: payload.value,
      }
    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: payload.value,
      }
    case SET_EDIT_ID:
      return {
        ...state,
        editId: payload.value,
      }
    case SET_SHOW_LOG_DIALOG:
      return {
        ...state,
        showLogDialog: payload.value,
      }
    case SET_SELECTED_BASKET:
      return {
        ...state,
        selected: payload.value,
      }
    case SET_SHOW_JOIN_COPY_DIALOG:
      return {
        ...state,
        showJoinCopyDialog: payload.value,
      }
    case REMOVE_BASKET_IDS_FROM_DB_INIT:
      return {
        ...state,
        basketsRemoveInProgress: true,
        basketsRemoveError: false,
      }
    case REMOVE_BASKET_IDS_FROM_DB_SUCCESS:
      return {
        ...state,
        basketsRemoveInProgress: false,
        basketsRemoveError: false,
      }
    case REMOVE_BASKET_IDS_FROM_DB_ERROR: {
      return {
        ...state,
        basketsRemoveInProgress: false,
        basketsRemoveError: true,
      }
    }
    case SET_BASKETS_SORTING_PARAM:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [payload.key]: payload.value,
        },
      }
    default:
      return state
  }
}

export default basketReducer
