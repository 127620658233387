import { t } from 'i18next'
import { getRandomColor } from 'utils/common/common'

export const getSmilesBySuccessType = (res) => {
  const items = res?.data?.result?.items ?? []
  const invalidSmiles = []
  const repeatedSmiles = []

  items.forEach((item) => {
    if (item?.is_success === false) {
      if (item.error_message === 'Structure is not valid') {
        invalidSmiles.push(item)
      } else if (item.error_message === 'Structure already exists in basket') {
        repeatedSmiles.push(item)
      }
    }
  })

  return { invalidSmiles, repeatedSmiles }
}

export const countAddedMolItems = (res) => {
  const items = res?.data?.result?.items ?? []
  const succeedCount = []
  const failedCount = []

  items.forEach((item) => {
    if (item?.is_success === true) {
      succeedCount.push(item)
    } else {
      failedCount.push(item)
    }
  })

  return { succeedCount, failedCount }
}

export const checkMarkushFieldsError = (
  molecular_weight,
  molecular_weight_default,
  weightDiff
) =>
  (!molecular_weight?.[0] && !molecular_weight?.[1]) ||
  !molecular_weight?.[0] ||
  molecular_weight?.[0] === molecular_weight?.[1] ||
  Number(molecular_weight[0]) < Number(molecular_weight_default[0]) ||
  weightDiff > 100

export const getMarkushNotification = (
  text,
  isSubstructure,
  isMarkush,
  molecular_weight,
  molecular_weight_default,
  weightDiff
) => {
  let notificationName = ''
  let translationNameParams

  if (isSubstructure.length > 2) notificationName = 'filter.notify_sub'
  else if (isMarkush && !text.includes('|'))
    notificationName = 'filter.notify_not_ext_smiles'
  else if (!molecular_weight?.[0] && !molecular_weight?.[1])
    notificationName = 'filter.notify_not_both_weights'
  else if (!molecular_weight?.[0])
    notificationName = 'filter.notify_not_min_weight'
  else if (
    molecular_weight?.[0] &&
    Number(molecular_weight[0]) < Number(molecular_weight_default[0])
  ) {
    notificationName = 'filter.notify_min_weight_not_less'
    translationNameParams = {
      value: molecular_weight_default[0],
    }
  } else if (molecular_weight?.[0] === molecular_weight?.[1])
    notificationName = 'filter.notify_min_and_max_equal'
  else if (weightDiff > 100) notificationName = 'filter.notify_mol_weight_error'

  return { notificationName, translationNameParams }
}

export const generatorCreateLayer = (id, name) => {
  const color1 = 'FAFAFA'
  const color2 = getRandomColor({ withHash: false })

  const options = {
    id,
    name,
    opacity: 1,
    visible: true,
    isGenerator: true,
    color: [color1, color2],
    size: 'sm',
    status: 'loading',
    score: 0.4,
  }
  return options
}

export const getBasketsQueryParams = (sortingConfig, withPublic) => {
  const sortQuery = `order_by=${sortingConfig.type}&descending=${
    sortingConfig.direction === 'desc'
  }`
  const publicQuery = withPublic ? 'public=true' : ''
  if (!(sortQuery || publicQuery)) return ''
  if (sortQuery && publicQuery) return `${sortQuery}&${publicQuery}`
  return sortQuery || publicQuery
}
