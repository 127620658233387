import { useTranslation } from 'react-i18next'

import {
  BackButtonContent,
  Container,
  CurrentPageInput,
  DocumentContainer,
  DocumentName,
  DocumentTooltipName,
  Header,
  Main,
  PageSelector,
  PagesCount,
} from './index.style'
import Icon from 'components/Icon'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import PdfLoader from './PdfLoader'
import PdfViewer from './PdfViewer'
import { memo, useEffect, useState } from 'react'
import CustomButton from 'components/common/customButton'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import HighlightsSidebar from './HighlightsSidebar'
import { useParams } from 'react-router-dom'
import { getPdf2SmilesDocument } from 'store/actions/pdf2smiles'
import LoadingOverlay from 'components/LoadingOverlay'

const Pdf2SmilesViewer = memo(
  ({ push, pdf2Smiles, isSidebarCollapsed, getPdf2SmilesDocument }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const {
      currentPage,
      isDocumentHeaderCollapsed,
      loading,
      error,
      filename,
      file_url,
    } = pdf2Smiles
    const [pagesNumber, setPagesNumber] = useState(0)
    const [selectedPage, setSelectedPage] = useState(currentPage)

    const onLoad = (currentPagesNumber) => {
      setPagesNumber(currentPagesNumber)
    }

    const onChangeSelectedPage = (value) => {
      setSelectedPage(value)
    }

    const onKeyUpSeletedPage = (e) => {
      if (e.key === 'Escape') {
        setSelectedPage(currentPage)
        setTimeout(() => e.target.blur())
        return
      }

      if (e.key === 'Enter') {
        e.target.blur()
      }
    }

    const onBlurSeletedPage = () => {
      const nSelectedPage = Number(selectedPage)
      if (nSelectedPage === currentPage) return

      if (nSelectedPage > 0 && nSelectedPage <= pagesNumber) {
        window.PdfViewer.currentPageNumber = nSelectedPage
      } else {
        setSelectedPage(currentPage)
      }
    }

    useEffect(() => {
      setSelectedPage(currentPage)
    }, [currentPage])

    useEffect(() => {
      id && getPdf2SmilesDocument(id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
      <Container>
        <Main>
          <DocumentContainer
            isSidebarCollapsed={isSidebarCollapsed}
            isDocumentHeaderCollapsed={isDocumentHeaderCollapsed}
          >
            <Header isDocumentHeaderCollapsed={isDocumentHeaderCollapsed}>
              <CustomButton
                type={'text'}
                onClick={() => {
                  push('/pdf2smiles')
                }}
              >
                <BackButtonContent>
                  <Icon iconType={'arrowLeft'} size="1rem" />
                  <div>{t('pdf2smiles.viewer.back')}</div>
                </BackButtonContent>
              </CustomButton>
              {filename && (
                <>
                  <DocumentName fontWeight={600} data-tooltip-id={id}>
                    {filename}
                  </DocumentName>
                  <ReactTooltip
                    id={id}
                    className="c-tooltip c-tooltip-base"
                    classNameArrow="c-tooltip-arrow"
                    place={'bottom-end'}
                    hidden={!isDocumentHeaderCollapsed}
                    offset={26}
                  >
                    <DocumentTooltipName>{filename}</DocumentTooltipName>
                  </ReactTooltip>
                </>
              )}
            </Header>
            {!error && file_url && (
              <PdfLoader onLoad={onLoad}>
                {(pdfDocument) => <PdfViewer pdfDocument={pdfDocument} />}
              </PdfLoader>
            )}
            {!error && pagesNumber > 0 && (
              <PageSelector>
                <CurrentPageInput
                  value={selectedPage}
                  onChange={onChangeSelectedPage}
                  onKeyUp={onKeyUpSeletedPage}
                  onBlur={onBlurSeletedPage}
                />
                <PagesCount fontWeight={500} color={'#616E7C'}>
                  {`/ ${pagesNumber}`}
                </PagesCount>
              </PageSelector>
            )}
          </DocumentContainer>
          {!error && <HighlightsSidebar />}
        </Main>

        {loading && <LoadingOverlay />}
      </Container>
    )
  }
)

Pdf2SmilesViewer.displayName = 'Pdf2SmilesViewer'

const mapStateToProps = (state) => {
  return {
    pdf2Smiles: state.pdf2Smiles,
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
  }
}

const mapDispatchToProps = {
  push,
  getPdf2SmilesDocument,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pdf2SmilesViewer)
