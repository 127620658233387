import styled, { css } from 'styled-components'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import CustomTag from 'components/common/customTag'

export const Row = styled.div`
  display: flex;
  position: relative;
  width: ${({ width }) => (width ? width : 'auto')};
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  height: ${({ height }) => (height ? height : 'auto')};
  margin: ${({ margin }) => (margin ? margin : '')};
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  padding: 1.25rem 1.25rem 0 1.25rem;
`

export const DrawContainer = styled(Column)`
  align-items: center;
`

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 0.75rem 0.75rem;

  @media print {
    & .hidden-for-print {
      display: none;
    }
  }
`

export const Content = styled.div`
  gap: 1.25rem;
  width: 100%;
  display: flex;
  padding: 20px;
  background: #fff;
  position: relative;
  align-items: flex-start;
  border-radius: 12px;
  @media (max-width: 1170px) {
    flex-direction: ${({ isSidebarCollapsed }) =>
      isSidebarCollapsed ? 'row' : 'column-reverse'};
  }
`
export const EditButton = styled(CustomButton)`
  gap: 0.25rem;
  left: 0.75rem;
  bottom: 0.75rem;
  position: absolute;
  transform: 'translate(-50%,-50%)';
  svg {
    width: 16px;
    height: 16px;
    color: black;
  }
  &:hover {
    div {
      color: #42c1bf;
      transition: all 0.2s ease;
    }
    svg {
      color: #42c1bf;
      transition: color 0.2s;
    }
  }
`

export const MoleculeWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;

  background: ${({ isSmilesExists }) => (isSmilesExists ? '#FFF' : '#f5f7f9')};

  border-radius: 12px;
  border: none;
  cursor: ${({ isSmilesExists }) => (isSmilesExists ? 'default' : 'pointer')};

  color: #cbd2d9;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.168px;
  box-shadow: ${({ isSmilesExists }) =>
    isSmilesExists
      ? '0px 4px 12px 0px rgba(45, 55, 69, 0.04), 0px -4px 12px 0px rgba(45, 55, 69, 0.04), 0px 0px 4px 0px rgba(45, 55, 69, 0.02)'
      : 'none'};

  & ${EditButton} {
    display: ${({ isSmilesExists }) => (isSmilesExists ? 'flex' : 'none')};
  }

  & ${DrawContainer} {
    display: ${({ isSmilesExists }) => (isSmilesExists ? 'none' : 'flex')};
  }
`

export const SvgWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: white;
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  & svg {
    color: #2d3745;
  }
  &:hover {
    svg {
      color: #42c1bf;
    }
  }
`
export const Tag = styled(CustomTag)`
  top: 20px;
  left: 20px;
  z-index: 1;
  position: absolute;
  border-radius: 0.375rem;
`

export const Schemes = styled.div`
  height: 50px;
  display: flex;
  background: #fff;
  width: fit-content;
  padding-bottom: 18px;
  border-radius: 12px 12px 0 0;
`

export const Scheme = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem 1.25rem;
  background: #fff;
  color: ${({ selected, disabled }) =>
    selected ? '#FFFFFF' : disabled ? '#CBD2D9' : '#616E7C'};
  border-radius: 12px 12px 0 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 1rem;
      background: #e6ebf1;
    }
  }

  @media (max-width: 980px) {
    .hidden {
      display: none;
    }
  }
`

export const ResultsWrapper = styled.div`
  z-index: 2;
  display: flex;
  background: #fff;
  margin-top: -42px;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: ${({ downloadAllInPdfClicked }) =>
    !downloadAllInPdfClicked &&
    `0px 0px 4px 0px rgba(45, 55, 69, 0.04), 0px -4px 8px 0px rgba(45, 55, 69, 0.06)`};
`

export const StageTag = styled.div`
  gap: 8px;
  min-width: 28px;
  justify-content: center;
  z-index: 1;
  cursor: ${({ noCursor }) => (noCursor ? 'default' : 'pointer')};
  margin-left: ${({ noCursor }) => (noCursor ? '8px' : '0px')};
  padding: ${({ noCursor }) => (noCursor ? '8px' : '6px 10px')};
  border-radius: 8px;
  background: ${({ selected }) => (selected ? '#42C1BF' : '#F5F7F9')};
`

export const StyledButton = styled(CustomButton)`
  gap: 4px;
  padding: ${({ noPadding }) => noPadding && '0px'};
  margin-left: ${({ isRightPosition }) => isRightPosition && 'auto'};
  svg {
    color: ${({ disabled }) => (disabled ? '#CBD2D9' : '#1F2933')};
  }
  &:hover {
    svg {
      color: ${({ disabled }) => (disabled ? '#CBD2D9' : '#42C1BF')};
    }
  }
  &:active {
    color: #42c1bf;
    background: transparent;
  }
`

export const Box = styled.div`
  row-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  margin-bottom: -1rem;
  padding-bottom: 1rem;
  justify-content: space-between;
`

export const SwitchBlock = styled(Box)`
  align-items: flex-start;
  margin-top: -0.5rem;
`

export const DrawnMolecule = styled.div`
  position: absolute;
  border-radius: 12px;
  width: 100%;
`

export const SmilesSvg = styled.svg`
  width: ${({ isSmilesExists }) => (isSmilesExists ? '184px' : '0px')};
  height: ${({ isSmilesExists }) => (isSmilesExists ? '217px' : '0px')};
`

export const ArrowDownSvg = styled(Icon)`
  color: inherit;
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'none')};
`

const openedStyles = css`
  height: fit-content;
`

const closedStyles = css`
  max-height: 2.25rem;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`

export const HeaderWrapper = styled.div`
  ${({ opened }) => (opened ? openedStyles : closedStyles)};
`

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-top: -0.5rem;
`

export const StagesBlock = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const ArrowBtn = styled(CustomButton)`
  padding: 0.375rem;
  border-radius: 0.5rem;
`
