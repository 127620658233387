import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useContextMenuAction } from '../../../hooks/useContextMenuActions'
import {
  CONTEXT_MENU_ITEM_HEIGHT,
  CONTEXT_MENU_OFFSET_RIGHT,
  ContextMenuConfig,
} from './config'
import { getSubActionsList, renderActionItem } from './utils'
import { OutboundClick } from '../outboundClick/outboundClick'
import { useDispatch, useSelector } from 'react-redux'
import { hideContextMenu } from 'store/actions/contextMenu'
import './index.css'
import { useAuth } from 'utils/auth/auth'
import { useTranslation } from 'react-i18next'

const ContextMenu = memo(() => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const language = i18n.language
  const { isAuthenticated } = useAuth()
  const contextMenuRef = useRef(null)
  const contextMenuStore = useSelector(
    (store) => store.contextMenu.contextMenuState
  )
  const { e, className = '', item = {}, menu = '' } = contextMenuStore || {}
  const { exceptions } = item
  const { mouseLeaveHide = false } = item
  const isPropagate = menu !== 'profileMenu'

  const selectedDatasets = useSelector((store) => store.basket.selected)
  const isDatasetSelected = useMemo(() => {
    return selectedDatasets?.find((id) => item?.bask?.id === id)
  }, [selectedDatasets, item?.bask?.id])
  const getMenuParams = (menu, item, isAuthenticated, language) => {
    switch (menu) {
      case 'profileMenu':
        return { isAuthenticated, language }
      case 'literatureCardMenu':
        return item?.dataType
      case 'literatureFilterMenu':
        return item?.config
      case 'dataSetMenu':
        return isDatasetSelected
      default:
        return item
    }
  }

  const getActionsList = ContextMenuConfig?.[menu]

  const actionsList = useMemo(() => {
    const menuParams = getMenuParams(menu, item, isAuthenticated, language)
    return getActionsList ? getActionsList(menuParams) : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActionsList, item, menu, isAuthenticated, language])

  const handleAction = useContextMenuAction(item)

  const handleClick = (action) => {
    handleAction(action)
    !getSubActionsList(action) && dispatch(hideContextMenu())
  }

  const handleScroll = useCallback(
    (event) => {
      const target = event?.target
      const isContextMenuOrChild = contextMenuRef.current?.contains(target)
      !isContextMenuOrChild &&
        target !== document &&
        dispatch(hideContextMenu())
    },
    [dispatch]
  )

  const handleMouseLeave = () => {
    return mouseLeaveHide ? dispatch(hideContextMenu()) : {}
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, dispatch])

  const isActionDisabled = (action) => {
    return item.disabledActions?.[action]
  }

  const listLength = actionsList?.length || 1

  const offsetStyles = useMemo(() => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const offsetX = e?.pageX
    const offsetY = e?.pageY

    const nearRight = windowWidth - offsetX < CONTEXT_MENU_OFFSET_RIGHT
    const nearBottom =
      windowHeight - offsetY <=
      CONTEXT_MENU_ITEM_HEIGHT * listLength +
        16 +
        10 +
        (listLength > 1 ? 8 * listLength - 1 : 0) //учитываются паддинги меню и gap между элементами +10 для лучшего пользовательского опыта

    return {
      top: nearBottom ? 'auto' : offsetY + 10,
      left: nearRight ? 'auto' : offsetX + 10,
      bottom: nearBottom ? windowHeight - offsetY : 'auto',
      right: nearRight ? windowWidth - offsetX : 'auto',
    }
  }, [e?.pageX, e?.pageY, listLength])
  if (!e) return null

  return (
    <OutboundClick
      exceptions={exceptions || []}
      onClick={() => {
        dispatch(hideContextMenu())
      }}
      isPropagate={isPropagate}
    >
      <div
        id="context-menu-wrap"
        className={`context-menu rounded-b ${className}`}
        style={offsetStyles}
        ref={contextMenuRef}
        onMouseLeave={handleMouseLeave}
      >
        {actionsList.map((actionItem, index) =>
          renderActionItem({
            ...actionItem,
            index,
            disabled: isActionDisabled(actionItem.action),
            handleClick,
            subActions: getSubActionsList(actionItem),
          })
        )}
      </div>
    </OutboundClick>
  )
})

ContextMenu.displayName = 'ContextMenu'
export default ContextMenu
