import styled from 'styled-components'

export const Panel = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  margin-top: ${({ showOnlySort }) => showOnlySort && '1rem'};
`

export const TotalFoundAmount = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 0.75rem;
  margin-right: auto;
  align-self: end;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: #6e809d;
`

export const LiteratureSwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 4.125rem;
  height: 2.25rem;
`
