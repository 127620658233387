import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { memo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import {
  DocumentWrapper,
  DocumentImage,
  Container,
  DocumentCard,
  DocumentStructureCount,
  DocumentCardTopArea,
  DocumentName,
  DocumentMenuButton,
  DocumentArrow,
  DocumentCardBottomArea,
  DocumentTooltipName,
} from './index.style'
import { getCountNoun } from '../config/config'
import Icon from 'components/Icon'
import { Subheadline } from 'components/common/text/index.style'
import '../../../components/common/tooltip/style.css'
import { confirm } from 'store/actions/confirm'
import { addNotification } from 'store/actions/notifications'
import { setNeedUpdateDocuments } from 'store/actions/pdf2smiles'
import { handleShowContextMenu } from 'store/actions/contextMenu'

const Document = ({
  id,
  image,
  name,
  structuresCount = 0,
  pagesCount = 0,
  push,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <>
      <Container
        onClick={(e) => {
          e.stopPropagation()
          push(`/pdf2smiles/${id}`)
        }}
      >
        <DocumentWrapper>
          <DocumentImage
            src={image}
            alt={t('pdf2smiles.dashboard.document_preview')}
          />
        </DocumentWrapper>
        <DocumentCard>
          <DocumentCardTopArea>
            <DocumentStructureCount fontWeight={500} color={'#42C1BF'}>
              {t(
                `pdf2smiles.dashboard.structure.${getCountNoun(
                  structuresCount
                )}`,
                {
                  count: structuresCount,
                }
              )}
            </DocumentStructureCount>
            <DocumentMenuButton>
              <Icon
                iconType="threeDots"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    handleShowContextMenu({
                      e,
                      menu: 'pdf2smilesMenu',
                      item: { id },
                    })
                  )
                }}
              />
            </DocumentMenuButton>
          </DocumentCardTopArea>
          <div>
            <DocumentName fontWeight={600} data-tooltip-id={String(id)}>
              {name}
            </DocumentName>
            <ReactTooltip
              id={String(id)}
              className="c-tooltip c-tooltip-base"
              classNameArrow="c-tooltip-arrow"
              place={'bottom-start'}
            >
              <DocumentTooltipName>{name}</DocumentTooltipName>
            </ReactTooltip>
          </div>
          <DocumentCardBottomArea>
            <Subheadline color={'#616E7C'}>
              {t(`pdf2smiles.dashboard.page.${getCountNoun(pagesCount)}`, {
                count: pagesCount,
              })}
            </Subheadline>
            <DocumentArrow menuOpened={menuOpened}>
              <Icon iconType="arrowRight" size="1.25rem" />
            </DocumentArrow>
          </DocumentCardBottomArea>
        </DocumentCard>
      </Container>
    </>
  )
}

const mapDispatchToProps = {
  push,
  confirm,
  addNotification,
  setNeedUpdateDocuments,
}

export default connect(undefined, mapDispatchToProps)(memo(Document))
