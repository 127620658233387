/*eslint-disable*/
import { useState, useEffect, useRef } from 'react'
import { fetch } from '../../services/rest'
import LoadingOverlay from '../LoadingOverlay'
import {
  Container,
  StyledTable,
  HeaderText,
  CategoriesCard,
  CategoriesSwiper,
  CategoriesSwiperWrapper,
  TableName,
  StatisticCard,
  StatisticCardHeader,
  TableWrapper,
} from './index.style'
import { useTranslation } from 'react-i18next'
import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'

const SWIPER_HEIGHT = 76
const SWIPER_OFFSET_LIMIT = 72
const SWIPER_SHADOW_STEP = 8

const StatisticsPage = () => {
  const [statisticsData, setStatisticsData] = useState(null)
  const [state, setState] = useState({ status: 'pending' })
  const [opened, setOpened] = useState([])

  const [isDragging, setIsDragging] = useState(false)
  const [startPosition, setStartPosition] = useState(0)
  const [leftGradient, setLeftGradient] = useState(0)
  const [rightGradient, setRightGradient] = useState(64)

  const { t } = useTranslation()

  const scrollDivRef = useRef(null)

  useEffect(() => {
    loadStatisticsData()
  }, [])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartPosition(e.clientX)
  }

  const handleMouseUp = (e) => {
    setIsDragging(false)

    if (e.clientX === startPosition) handleSwiperToggle(e)
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return

    const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current

    scrollDivRef.current.scrollTo({
      left: scrollLeft - e.movementX * 1.5,
    })

    const getCurrentOffset = (offset, start = SWIPER_OFFSET_LIMIT) => {
      if (offset < start) {
        return getCurrentOffset(offset, start - SWIPER_SHADOW_STEP)
      } else return start
    }

    scrollLeft < SWIPER_OFFSET_LIMIT &&
      setLeftGradient(getCurrentOffset(scrollLeft))

    const rightSideOffset = scrollWidth - (scrollLeft + clientWidth)
    rightSideOffset < SWIPER_OFFSET_LIMIT &&
      setRightGradient(getCurrentOffset(rightSideOffset))
  }

  const scrollToTable = (id) => {
    const preparedId = getPreparedId(id)
    const currentTable = document.getElementById(preparedId)
    const container = document.getElementById('stat-container')

    if (currentTable && container)
      container.scrollTo({
        behavior: 'smooth',
        top: currentTable.offsetTop - SWIPER_HEIGHT,
      })
  }

  const getPreparedId = (id) => {
    return id.replace(/.*?-/, '')
  }

  const setTableToOpened = (tableId) => {
    const preparedId = getPreparedId(tableId)
    if (opened.includes(preparedId)) {
      setOpened((s) => s.filter((el) => el !== preparedId))
    } else {
      setOpened((s) => [...s, preparedId])
    }
  }

  const handleSwiperToggle = (e) => {
    const id = e.target.id
    setTableToOpened(id)
    scrollToTable(id)
  }

  const handleToggle = (e) => {
    setTableToOpened(e.target.id)
  }

  const category_i18n = (title) => {
    return t(`statistics.categories.${title}`)
  }

  const loadStatisticsData = async () => {
    try {
      setState({ status: 'loading' })
      const { data } = await fetch('/plugins_statistic')

      const preparedData = {}

      data.result.map((item) => {
        if (!preparedData[item.category]) preparedData[item.category] = []
        preparedData[item.category].push({ ...item })
      })

      setStatisticsData(preparedData)
      setState({ status: 'done' })
    } catch (e) {
      setState({ status: 'error', message: e })
    }
  }

  const getTable = (section) => {
    if (Array.isArray(statisticsData[section])) {
      return (
        <TableWrapper>
          <StyledTable>
            <thead>
              <tr>
                <td>{t('statistics.table_column.parameter')}</td>
                <td>{t('statistics.table_column.average')}</td>
                <td>{t('statistics.table_column.unit')}</td>
                <td>{t('statistics.table_column.metric')}</td>
                {Object.keys(statisticsData[section][0]).map((item) => {
                  if (item.startsWith('fold')) {
                    return (
                      <td key={`z-${item}`}>Fold {item.replace('fold', '')}</td>
                    )
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {statisticsData[section].map((item, index) => {
                const folds = Object.keys(item).filter((item) =>
                  item.startsWith('fold')
                )

                return (
                  <tr key={`${item}-${index}`}>
                    <td>
                      {t(`statistics.table_parameter.${item.parameter}`.trim())}
                    </td>
                    <td>{item.average ? item.average.toFixed(2) : ''}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {item.unit && t(`statistics.table_units.${item.unit}`)}
                    </td>
                    <td>{item.metric ? item.metric : ''}</td>
                    {folds.map((fold, i) => {
                      return (
                        <td key={`${item}-${fold}-${i}`}>
                          {item[fold] ? item[fold].toFixed(2) : 0}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
        </TableWrapper>
      )
    }
  }

  if (state.status === 'loading' || state.status === 'pending')
    return <LoadingOverlay />
  if (state.status === 'error')
    return <div style={{ padding: 20 }}>Error occurred</div>

  return (
    <CustomScrollbar className="margin-4-right">
      <Container id="stat-container">
        <HeaderText>{t('statistics.header')}</HeaderText>
        <CategoriesSwiperWrapper
          leftGradient={leftGradient}
          rightGradient={rightGradient}
        >
          <CategoriesSwiper onMouseDown={handleMouseDown} ref={scrollDivRef}>
            {Object.keys(statisticsData).map((name) => {
              return (
                <CategoriesCard
                  key={name}
                  id={`swiper-${name}`}
                  active={opened.includes(name)}
                >
                  {category_i18n(name)}
                </CategoriesCard>
              )
            })}
          </CategoriesSwiper>
        </CategoriesSwiperWrapper>
        <div>
          {statisticsData &&
            Object.keys(statisticsData).map((section) => {
              const isCardExpanded = opened.includes(section)
              return (
                <StatisticCard
                  onClick={handleToggle}
                  id={section}
                  key={`col-${section}`}
                >
                  <StatisticCardHeader
                    isActive={isCardExpanded}
                    id={`header-${section}`}
                  >
                    <TableName id={`headername-${section}`}>
                      {category_i18n(section)}
                    </TableName>
                    <Icon
                      id={`arrow-${section}`}
                      iconType={'arrowDown'}
                      size="1.25rem"
                    />
                  </StatisticCardHeader>
                  {isCardExpanded && getTable(section)}
                </StatisticCard>
              )
            })}
        </div>
      </Container>
    </CustomScrollbar>
  )
}
export default StatisticsPage
