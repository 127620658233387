import styled, { css, keyframes } from 'styled-components'

const openElementAnimation = keyframes`
0% {
	opacity: 0;
    display: none;
    width: 0;
}
70% {	
	opacity: 0;
    display: none;
    width: 0;
}
100% {
    opacity: 1;
    diplay: block;
    width: fit-content;
}
`

const hideElementAnimation = keyframes`
0% {
	opacity: 1;
    diplay: block;
    width: fit-content;
}
20% {
	opacity: 0;
    display: none;
     width: 0;
    }
100% {
    opacity: 0;
    display: none;
    width: 0;
}`

export const LangBlock = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: ${({ showLabel, type }) => {
    if (showLabel) return '0rem'
    if (type === 'telegram') return '0.5rem'
    return '0.375rem'
  }};
  gap: ${({ type }) => (type === 'language' ? '0.25rem' : '0.5rem')};
  align-items: center;
  align-self: flex-start;
  ${({ type }) =>
    type === 'telegram' &&
    css`
      height: 1.25rem;
    `};

  p {
    color: inherit;
  }
  min-width: 1.25rem;
  width: fit-content;

  color: ${({ theme }) => theme.colors.text.tertiary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
  transition: all 0.5s;
`
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;

  ${({ withBackground, theme }) =>
    withBackground &&
    css`
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      background: ${theme.colors.backgrounds.secondary};
    `}
`

export const AnimLabelWrapper = styled.div`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  width: max-content;
  animation: ${({ opened }) =>
      opened ? openElementAnimation : hideElementAnimation}
    0.4s;
  will-change: auto;
`
