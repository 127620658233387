import i18n from 'i18n'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import {
  PRIVACY_POLICY_LINK_EN,
  PRIVACY_POLICY_LINK_RU,
} from 'components/RegistrationPageNew/config'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import { Caption } from 'components/common/text/index.style'
import CheckboxWithTitle from 'components/CheckboxWithTitle'
import { FooterButtons } from 'components/RegistrationPageNew/index.style'

import LicenseKeyBlock from './LicenseKeyBlock'
import InfoWithProgress from '../InfoWithProgress'
import { Agreement, Container, FieldsBlock, PasswordBlock } from './index.style'

const EmailAndPassword = ({
  email,
  onBlur,
  onKeyUp,
  password,
  onChange,
  progress,
  onGoBack,
  password2,
  emailError,
  isLicense,
  licenseKey,
  password2Error,
  onGoToNextStep,
  isPasswordsMatch,
  isAgreeWithPrivPolicy,
  isPasswordValidationError,
  onChangeAgreeWithPrivPolicy,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const userLang = i18n.language

  return (
    <>
      <InfoWithProgress
        progress={progress}
        title={t('registration.email_and_password')}
        description={t('registration.enter_email_and_create_password')}
      />
      <Container>
        <FieldsBlock>
          <CustomInput
            error={emailError}
            id="email"
            onChange={(value) => onChange(value, 'email')}
            value={email}
            placeholder={t('registration.email_placeholder')}
            required
            type="email"
            autoComplete="email"
            label="E-mail"
            withClearButton
          />
          <PasswordBlock>
            <CustomInput
              id="password"
              onChange={(value) => onChange(value, 'password')}
              value={password}
              label={t('registration.password')}
              placeholder={t('registration.password_placeholder')}
              type="password"
              required
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              autoComplete="new-password"
              withClearButton
              isValidated={!!password && !isPasswordValidationError}
              withPasswordTooltip={isPasswordValidationError}
              email={email}
              error={isPasswordValidationError}
            />

            <CustomInput
              id="password2"
              label={t('registration.password2_placeholder')}
              onChange={(value) => onChange(value, 'password2')}
              value={password2}
              placeholder={t('registration.password2_placeholder')}
              type="password"
              required
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              autoComplete="new-password"
              withClearButton
              error={password2Error}
              isValidated={!!password2 && !password2Error && isPasswordsMatch}
              tooltipPlace={'top'}
            />
          </PasswordBlock>
        </FieldsBlock>
        <LicenseKeyBlock {...{ isLicense, licenseKey, onChange }} />

        <Agreement>
          <Caption
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.medium}
          >
            {t('registration.registering')}
          </Caption>
          <CheckboxWithTitle
            id="toggle-privacy-agree"
            value={isAgreeWithPrivPolicy}
            withCustomTitle
            onChange={onChangeAgreeWithPrivPolicy}
          >
            <Caption fontWeight={theme.fontWeight.medium}>
              {t('registration.user_agreement')}
              {t('registration.and')}
              <a
                href={
                  userLang === 'ru'
                    ? PRIVACY_POLICY_LINK_RU
                    : PRIVACY_POLICY_LINK_EN
                }
                rel="noreferrer"
                target="_blank"
              >
                {t('registration.privacyPolicy')}
              </a>
            </Caption>
          </CheckboxWithTitle>
        </Agreement>
      </Container>
      <FooterButtons>
        <CustomButton
          type="text"
          gap="0.25rem"
          onClick={onGoBack}
          data-test="go-back-btn"
        >
          <Icon iconType="arrowLeft" size="1rem" />
          {t('registration.back')}
        </CustomButton>
        <CustomButton
          type="text_accent"
          disabled={
            progress < 50 ||
            !isAgreeWithPrivPolicy ||
            (isLicense && !licenseKey)
          }
          gap="0.25rem"
          onClick={onGoToNextStep}
          data-test="go-to-personal-data-btn"
        >
          {t('registration.to_personal_data')}
          <Icon iconType="arrowRight" size="1rem" />
        </CustomButton>
      </FooterButtons>
    </>
  )
}

export default EmailAndPassword
